import RolePermissionApi from '@/api/RolePermissionApi';
import { PERMISSION_ACTION } from '@/typings/enums/PERMISSION_ACTION';
import { useNotif } from '@orion.ui/orion';
import useSelfService from './SelfService';


class RolePermissionService {
	permissionGuardMessage = `Vous n'avez pas les droits requis pour effectuer cette opération`;

	get isAgency () {
		return useSelfService().self.isAgency();
	}

	private get rolesPermissionsList () {
		return [
			{
				title: `Permissions des Contrats`,
				children: [
					{
						action: PERMISSION_ACTION.ClientContractRead,
						label: `${this.isAgency
							? 'Visualisation des contrats client'
							: 'Visualisation des contrats'}`,
						description: `Ce droit permet la consultation des
					${this.isAgency ? '<strong>contrats de mise à disposition</strong>' : 'contrats'}.
					<br/>
					Sans celui-ci, l'utilisateur ne verra pas apparaitre l'élément sur son menu principal et ne pourra pas accéder à la liste.
					<br/>
					${this.isAgency
							? `Enfin, il permet également de visualiser ou télécharger un ou plusieurs contrats sur Armado
              et d'accéder aux demandes de prolongations.`
							: `Enfin, il permet également de visualiser,
							télécharger et d\'être notifié lors du dépôt d\'un ou plusieurs contrats sur Armado.<br>
              En activant ce droit, vos utilisateurs pourront accéder aux prolongations de contrats
              et initier une demande de prolongation auprès de votre agence d'emploi.`}`,
						onDeactivate: {
							deactivate: [
								PERMISSION_ACTION.ClientContractValidate,
								PERMISSION_ACTION.ClientContractSign,
								PERMISSION_ACTION.ContractProlongation],
						},
					},
					{
						action: PERMISSION_ACTION.ClientContractValidate,
						label: `Validation des contrats`,
						description: `En sélectionnant ce droit, vos utilisateurs
					pourront valider un contrat, depuis le détail de ce dernier.
					<br/>
					Ceci vous permettra d'introduire une notion de vérification du document avant sa signature.
					<br/>
					Il n'y a cependant pas d'obligation de validation du contrat pour le signer, cette fonctionnalité est présente pour répondre à des
					processus propres à chacun d'entre vous.`,
						onActivate: { activate: [PERMISSION_ACTION.ClientContractRead] },
					},
					{
						action: PERMISSION_ACTION.ClientContractSign,
						label: `Signature des contrats`,
						description: `La signature de vos contrats d'intérim est primordiale et doit
					être assujettie à des droits rigoureusement controlés.
					<br/>
					Celui-ci autorise les utilisateurs à procéder à la signature électronique des contrats, à l'unité ou par lot.
					<br/>
					En affectant ce rôle et en cochant ce droit, vos utilisateurs seront également relancés automatiquement par vos
					agences partenaires lorsque certains contrats ne sont pas encore signés par vos soins.
					<br/>
					À toute fin utile, l'ensemble des notifications peuvent être désactivées
					depuis <a href="/mon-profil/compte" target="_blank"> votre profil</a>.
					Cette option est cependant à désactiver en connaissance de cause.`,
						onActivate: { activate: [PERMISSION_ACTION.ClientContractRead] },
					},
					{
						action: PERMISSION_ACTION.TempWorkerContractRead,
						label: `Visualisation des contrats intérimaire`,
						description: `Ce droit permet la consultation des <strong>contrats de mission</strong>.
					<br/>
					Sans celui-ci, l'utilisateur ne verra pas apparaitre l'élément sur son menu principal et ne pourra pas accéder à la liste.
					<br/>
					Enfin, il permet également de visualiser ou télécharger un ou plusieurs contrats sur Armado.`,
					},
					{
						action: PERMISSION_ACTION.ContractProlongation,
						label: `Prolongation de contrat`,
						description: `En activant ce droit, vos utilisateurs pourront accéder à la liste des contrats pouvant être
					prolongés ${this.isAgency ? '.' : `et effectuer une demande de prolongation à votre agence d'emploi.`}`,
						onActivate: { activate: [PERMISSION_ACTION.ClientContractRead] },
					},
				],
			},
			{
				title: `Permissions des Factures`,
				children: [
					{
						action: PERMISSION_ACTION.BillRead,
						label: `Visualisation des factures`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
					aux factures.
					<br/>
					Ce faisant, ils pourront filtrer, visualiser, télécharger les documents
					${this.isAgency
							? '.'
							: 'et être notifiés lors du dépôt.'}`,
					},
				],
			},
			{
				title: `Permissions des Registres`,
				children: [
					{
						action: PERMISSION_ACTION.RegisterRead,
						label: `Visualisation des registres du personnel`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
					aux registres du personnel.
					<br/>
					Ce faisant, ils pourront filtrer, visualiser, télécharger les documents
					${this.isAgency ? '.' : 'et être notifiés lors du dépôt.'}`,
					},
				],
			},
			{
				title: `Permissions des Listes de travailleurs handicapés`,
				children: [
					{
						action: PERMISSION_ACTION.HandicappedWorkerListRead,
						label: `Visualisation des listes de travailleurs handicapés`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
					aux listes de travailleurs handicapés.
					<br/>
					Ce faisant, ils pourront filtrer, visualiser, télécharger les documents
					${this.isAgency ? '.' : 'et être notifiés lors du dépôt.'}`,
					},
				],
			},
			{
				title: `Permissions des Certificats`,
				children: [
					{
						action: PERMISSION_ACTION.CertificateRead,
						label: `Visualisation des certificats`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
						aux certificats de travail.
						<br/>
						Ce faisant, ils pourront filtrer, visualiser et télécharger les documents.`,
					},
				],
			},
			{
				title: `Permissions des Fiches de salaires`,
				children: [
					{
						action: PERMISSION_ACTION.SalarySlipRead,
						label: `Visualisation des bulletins de salaires`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
						aux bulletins de salaire.
						<br/>
						Ce faisant, ils pourront filtrer, visualiser et télécharger les documents.`,
					},
				],
			},
			{
				title: `Permissions des Attestations de prélèvement à la source`,
				children: [
					{
						action: PERMISSION_ACTION.WithholdingTaxAttestationRead,
						label: `Visualisation des Attestations de prélèvement à la source`,
						description: `L'activation de ce droit permet à vos utilisateurs d'afficher l'élément du menu correspondant
						aux attestations de prélèvement à la source émis pour vos intérimaires.
						<br/>
						Ce faisant, ils pourront filtrer, visualiser et télécharger les documents.`,
					},
				],
			},
			{
				title: `Permissions des Attestations de déplacements`,
				children: [
					{
						action: PERMISSION_ACTION.TripAttestationRead,
						label: `Visualisation des attestations de déplacements`,
						description: `Ces documents, qui tendent heureusement à disparaitre, seront accessibles par vos utilisateurs
						en activant cette permission spécifique.
						<br/>
						Ce faisant, ils pourront filtrer, visualiser et télécharger les documents.`,
					},
				],
			},
			{
				title: `Permissions des Attestations France Travail`,
				children: [
					{
						action: PERMISSION_ACTION.PoleEmploiAttestationRead,
						label: `Visualisation des attestations France Travail`,
						description: `Même si ces documents sont transmis à France Travail au travers de la DSN, il
						arrive encore fréquemment que ceux-ci les réclament à vos intérimaires.
						<br/>
						Si vous déposez lesdites attestations sur Armado, l'activation de ce droit permettra de les retrouver dans la
						liste adéquate.
						<br/>
						Les utilisateurs disposant de ce droit pourront, comme pour l'ensemble des documents Armado,
						filtrer, visualiser et télécharger les documents.`,
					},
				],
			},
			{
				title: `Permissions des Documents mis à disposition`,
				children: [
					{
						action: PERMISSION_ACTION.TempWorkerProvisionDocumentRead,
						label: `Visualisation des Documents mis à disposition des intérimaires`,
						description: `En activant ce droit, vous autoriserez vos utilisateurs à
						accéder aux documents qui ne rentrent pas dans les grandes gestions
						proposées par Armado et sont alors ajoutés dans une liste que nous appelons : "Documents mis à disposition".
						<br/>
						Ils pourront alors filtrer, visualiser et télécharger les documents en question.`,
					},
					{
						action: PERMISSION_ACTION.ClientProvisionDocumentRead,
						label: `Visualisation des Documents mis à disposition
					${this.isAgency ? 'des clients' : ''}`,
						description: `${this.isAgency
							? `En activant ce droit, vous autoriserez vos utilisateurs à accéder aux documents qui ne rentrent pas dans les grandes gestions
							proposées par Armado et sont alors ajoutés dans une liste que nous appelons : "Documents mis à disposition".
						<br/>
						Ils pourront alors filtrer, visualiser et télécharger les documents en question.`
							: `Vos agences partenaires peuvent être amenées à vous envoyer de nombreux documents qui ne rentrent pas toujours
						dans les grandes gestions que propose Armado.
						<br/>
						Pour leur permettre ce partage, nous regroupons ces documents dans une liste que nous appelons : "Documents mis à disposition".
						<br/>
						Il peut s'agir de notes d'informations, lettres annuelles, etc.
						Documents qui ne doivent être accessibles qu'à certains utilisateurs, concernés par ce droit.
						<br/>
						Ils pourront alors filtrer, visualiser, télécharger les documents en question et être notifiés lors du dépôt.`}`,
						onDeactivate: { deactivate: [PERMISSION_ACTION.ClientProvisionDocumentSign] },
					},
					{
						action: PERMISSION_ACTION.ClientProvisionDocumentSign,
						label: `Signature des Documents mis à disposition`,
						description: `Les documents apparaissant dans cette liste peuvent également,
					au choix de votre agence partenaire, être signés.
					<br/>
					C'est en appliquant ce droit que vous permettrez à certains utilisateurs de procéder à leur signature electronique.`,
						onActivate: { activate: [PERMISSION_ACTION.ClientProvisionDocumentRead] },
					},
				],
			},
			{
				title: `Permissions liées aux Demandes d'Acompte`,
				children: [
					{
						action: PERMISSION_ACTION.AdvancedPaymentDemandRead,
						label: `Visualisation des demandes d'acomptes`,
						description: `Lorsque vos intérimaires en mission vous adresseront des demandes d'acompte,
						celles-ci se retrouveront dans une liste soumise à ce droit.
					<br/>
					Il n'y a pas d'actions particulières sur Armado concernant cette liste, mais vous pouvez limiter la visibilité des acomptes
					et des réponses apportées par vos outils BackOffice à certains collaborateurs.`,
					},
				],
			},
			{
				title: `Permissions des Relevés d'heures`,
				children: [
					{
						action: PERMISSION_ACTION.TimeTicketRead,
						label: `Visualisation des relevés d'heures`,
						description: `${this.isAgency
							? `En activant ce droit, vous autoriserez vos utilisateurs à accéder aux relevés d'heures à saisir par
						vos intérimaires et clients.
						<br/>
						Si des pièces jointes ont été ajoutées par les intérimaires, elles seront également visible dans le détail d'un relevé d'heures
						hebdomadaire.
						<br/>
						Ce sera également le cas pour les éventuels commentaires saisis par le client ou l'intérimaire.
						<br/>
						Il n'existe aucun droit sur Armado relatif à la saisie des heures et primes par l'agence. Cette action
						est uniquement à destination de vos tiers.`
							: `Les droits associés aux relevés d'heures sont scindés en trois notions.
						<br/>
						Celle-ci est la première qui permettra à vos utilisateurs d'accéder à une vision annuelle des heures et son détail hebdomadaire.
						<br/>
						Ce droit autorise vos utilisateurs à être notifiés lors du dépôt de relevés d'heures et lorsque l'intérimaire,
						si l'option lui a été donnée par l'agence, saisit et valide ses heures en premier lieu.`}
						<br/>
						Les utilisateurs concernés par ce droit pourront également visualiser et télécharger une version PDF du ou des relevés
						d'heures sélectionnés.`,
						onDeactivate: { deactivate: [PERMISSION_ACTION.TimeTicketUpdate, PERMISSION_ACTION.TimeTicketValidate] },
					},
					{
						action: PERMISSION_ACTION.TimeTicketUpdate,
						label: `Saisie des relevés d'heures`,
						description: `Saisir les heures, les primes, un commentaire à destination de l'agence ou encore prendre la main sur
					un RH qui ne serait pas saisi dans les temps par l'intérimaire, sont autant d'action
					qui nécessitent un droit bien spécifique.
					<br/>
					C'est cette option qui doit être cochée pour que vos utilisateurs soient autorisés à manipuler et mettre à jour les données
					associées aux relevés d'heures.`,
						onActivate: { activate: [PERMISSION_ACTION.TimeTicketRead] },
					},
					{
						action: PERMISSION_ACTION.TimeTicketValidate,
						label: `Validation des relevés d'heures`,
						description: `La dernière étape de la saisie des heures tient en sa validation par un utilisateur,
					qui peut être différent de celui qui a effectué la saisie.
					<br/>
					C'est la raison pour laquelle nous avons scindé ce droit de la saisie.
					<br/>
					Dans la grande majorité des cas, vos agences partenaires attendront une validation définitive des heures saisies
					pour prise en compte dans leurs outils internes.
					<br/>
					Il est donc essentiel d'affecter ce droit à l'un de vos utilisateurs qui effectuera une ultime vérification et validera le RH.`,
						onActivate: { activate: [PERMISSION_ACTION.TimeTicketRead] },
					},
				],
			},
			{
				title: `Permissions de gestion des stocks de Signatures`,
				children: [
					{
						action: PERMISSION_ACTION.SignatureManagement,
						label: `Gestion des stocks de signatures`,
						description: `Selon votre abonnement à la plateforme Armado,
						vous avez peut-être souscrit aux packs de signatures.
						<br/>
						Si vous activez cette option, vos utilisateurs auront accès à la répartition des stocks,
						les agences concernées et les différents seuils de notification établis.
						<br/>
						Informations accessibles depuis le tableau de bord principal.
						<br/>
						Ce droit autorise également vos utilisateurs à passer commande d'un nouveau pack depuis la plateforme Armado.`,
					},
				],
			},
			{
				title: `Permissions des Archives`,
				children: [
					{
						action: PERMISSION_ACTION.CompanyDigitalSafeDocumentRead,
						label: `Visualisation des Archives`,
						description: `Ces documents, uniquement accessibles pour vos agences et jamais partagés à vos tiers,
						peuvent être de bien des natures.
						<br/>
						Journaux de paie par rubrique, matricule, bulletins de paies simplifiés etc.
						<br/>
						Les possibilités de stockage et de hiérarchisation sont uniques pour chacun d'entre vous, et les documents
						archivés, pas nécessairement accessibles à tous vos collaborateurs.
						<br/>
						Ce droit est donc présent pour permettre d'autoriser les utilisateurs adéquats.
						<br/>
						Pour information, l'ensemble des documents peuvent se voir apposés d'une signature horodatée lors du dépôt.`,
					},
				],
			},
			{
				title: `Permissions des CGV & CTT`,
				children: [
					{
						action: PERMISSION_ACTION.ConditionRead,
						label: `Visualisation des CGV & CTT`,
						description: `Même si les modifications de vos conditions générales évoluent peu, il est toujours intéressant de retrouver
						sur Armado ces documents, automatiquement ajoutés en dernière page de vos contrats déposés
						sur la plateforme.
						<br/>
						Ce droit permet à vos utilisateurs d'accéder à la liste des conditions générales de vente
						et des conditions de travail temporaire.`,
						onDeactivate: { deactivate: [PERMISSION_ACTION.ConditionCreate] },
					},
					{
						action: PERMISSION_ACTION.ConditionCreate,
						label: `Dépôt des CGV & CTT`,
						description: `Les conditions générales font parti des documents que vous pouvez déposer depuis
						la plateforme Armado, sans passer par votre outil métier.
						<br/>
						Ils viennent automatiquement s'ajouter en dernière page des contrats de mission et de mise à disposition lors du partage
						auprès de vos tiers.
						<br/>
						Même si leurs modifications ne sont pas nécessairement régulières, il convient d'attribuer une permission particulières
						aux utilisateurs définis par vos soins.
						<br/>
						Ce droit restreint donc à certains collaborateurs la capacité de déposer des conditions générales sur Armado.
						<br/>
						Grâce à l'organisation, vous pouvez également ajouter chaque condition une seule fois et l'appliquer sans effort
						à l'ensemble de vos sociétés : un véritable gain de temps.`,
						onActivate: { activate: [PERMISSION_ACTION.ConditionRead] },
					},
				],
			},
			{
				title: `Permissions des Commandes`,
				children: [
					{
						action: PERMISSION_ACTION.OrderRead,
						label: `Visualisation des Commandes`,
						description: `${this.isAgency
							? `Le module de gestion des commandes est composé d'un grand nombre d'actions et de possibilités.
                <br/>
                Cependant, tout ce qui concerne la visibilité du module, des commandes en elles-mêmes et de certains automatismes
                sous jacent, se trouve assujeti au droit de visualisation.
                <br/>
                En activant cette option, vos utilisateurs pourront donc accéder aux statistiques - tableau de bord,
                aux commandes enregistrées et leur détail.
                <br/>
                Enfin, les utilisateurs concernés par ce droit recevront les notifications associées aux processus automatisés par Armado.
                <br/>
                A titre d'exemple : lorsqu'une EU enregistre une nouvelle commande, qu'un intérimaire accepte ou refuse la mission etc.`
							: `En activant ce droit, vos utilisateurs pourront accéder aux commandes saisies par vous-même
                ou créées et partagées par vos agences partenaires.
                <br/>
                Ils pourront également visualiser le détail des commandes et, entre autres choses, les profils de candidats
                que seront amenés à vous partager vos agences.
                <br/>
                Vos utilisateurs concernés par ce droit recevront par ailleurs des notifications propres aux commandes.
                Tel que la création d'une commande par l'agence, le partage d'un profil de candidat, la validation des profils,
                la validation de la commande...`}`,
						onActivate: { activate: [PERMISSION_ACTION.TempWorkerUserRead] },
						onDeactivate: { deactivate: [PERMISSION_ACTION.OrderCreate, PERMISSION_ACTION.OrderUpdate] },
					},
					{
						action: PERMISSION_ACTION.OrderCreate,
						label: `Création des Commandes`,
						description: `Même si un utilisateur est autorisé à visualiser les commandes, il n'a pas forcément
              autorité pour en créer.
              <br/>
              En activant ce droit et en appliquant le rôle à certains utilisateurs clés, vous pourrez restreindre
              la capacité d'adresser une nouvelle commande.`,
						onActivate: { activate: [PERMISSION_ACTION.OrderRead] },
					},
					{
						action: PERMISSION_ACTION.OrderUpdate,
						label: `Modification des Commandes`,
						description: `De la même manière que la faculté de créer une commande ne peut être ouverte à tous,
              la modification également.
              <br/>
              Cela fait donc l'objet d'un droit complémentaire.
              <br/>
              Etant donné que ces modifications, par exemple lorsqu'une mission a d'ores et déjà été proposée à un candidat,
              peut impliquer de nouveaux échanges avec vos tiers, il est important de n'affecter ce droit qu'à des collaborateurs
              clairement établis.`,
						onActivate: { activate: [PERMISSION_ACTION.OrderRead] },
					},
					{
						action: PERMISSION_ACTION.OrderCandidateUpdate,
						label: `Gestion des candidatures`,
						description: `Vos agences partenaires pourront vous proposer, depuis leur espace Armado, des profils de candidat.
						<br/>
						Le droit de visualisation ne permet que de voir les profils partagés, sans pouvoir répondre à l'agence.
						<br/>
						Pour autoriser un utilisateur à répondre favorablement ou non à
						la proposition de l'agence, il vous faudra activer ce droit.`,
						onActivate: { activate: [PERMISSION_ACTION.OrderRead] },
					},
					{
						action: PERMISSION_ACTION.OrderToolbox,
						label: `Accès et modification sur la Boite à Outils`,
						description: `Associée au module de gestion des commandes, la boite à outils comporte plusieurs sous-gestions
              destinées à vous apporter des fonctionnalités partagées à travers l'ensemble de votre organisation.
              <br/>
              Les descriptions de poste évitent à vos clients une ressaisie du besoin et vous font gagner du temps lorsque
              vous proposez la mission au candidat.
              <br/>
              Les modèles de tâche aident vos collaborateurs à planifier simplement et efficacement des actions à mener
              auprès de candidats et intérimaires.
              <br/>
              Enfin, les viviers sont là pour vous aider à y voir plus clair parmi vos nombreux profils qualifiés lorsque vous recherchez
              le candidat idéal.
              <br/>
              Toutes ces briques fonctionnelles étant mutualisés sur vos agences au travers de l'organisation, il était nécessaire de vous
              donner un droit spécifique pour que certains administrateurs du groupe les manipulent.`,
						onActivate: { activate: [PERMISSION_ACTION.TempWorkerUserRead] },
					},
				],
			},
			{
				title: `Permissions liées aux Utilisateurs`,
				children: [
					{
						action: PERMISSION_ACTION.UserRead,
						label: `Visualisation des Utilisateurs`,
						description: `Accéder à la liste des utilisateurs et parcourir leurs droits, rôles, tags et sociétés associées fait
					l'objet d'un droit adéquat.
					<br/>
					En activant cette option, vous autorisez un collaborateur à visualiser l'ensemble de ces éléments,
					sans possibilité d'action cependant.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRead] },
						onDeactivate: { deactivate: [PERMISSION_ACTION.UserUpdate, PERMISSION_ACTION.UserCreate, PERMISSION_ACTION.UserDelete] },
					},
					{
						action: PERMISSION_ACTION.UserUpdate,
						label: `Modification des droits et tags des Utilisateurs`,
						description: `Une fois les rôles et tags créés, il est nécessaire de pouvoir les assigner
					aux collaborateurs de l'organisation.
					<br/>
					L'activation de ce droit permettra donc, via la liste des utilisateurs de votre organisation, d'attribuer
					ces dits éléments aux bonnes personnes.
					<br/>
					Ce droit permet également d'ajouter ou supprimer le lien qui relie vos utilisateurs aux sociétés de votre organisation.
					<br/>
					Enfin, cette option autorise le renvoi du mail d'activation à un collaborateur qui ne se serait pas encore activé.`,
						onActivate: { activate: [PERMISSION_ACTION.UserRead, PERMISSION_ACTION.TagRead, PERMISSION_ACTION.OrganizationRoleRead] },
					},
					{
						action: PERMISSION_ACTION.UserCreate,
						label: `Création des Utilisateurs`,
						description: `Comme son nom l'indique, ce droit autorise tout simplement la création d'un  nouvel utilisateur
					sur votre organisation.
					<br/>
					L'association de sociétés, rôles et tags se fait dans un deuxième temps, une fois le nouveau
					collaborateur créé, et c'est le droit de modification qui le permet.`,
						onActivate: { activate: [PERMISSION_ACTION.UserRead] },
					},
					{
						action: PERMISSION_ACTION.UserDelete,
						label: `Suppression des Utilisateurs`,
						description: `Supprimer un utilisateur fait l'objet d'un dernier droit.
					<br/>
					Lorsque l'on parle de suppression, n'est concerné réellement que la suppression des liens entre un utilisateur et les sociétés
					composant votre organisation.
					<br/>
					La suppression complète et définitive de son espace Armado ne peut se faire qu'à la demande de l'utilisateur auprès
					de notre société Armado.`,
						onActivate: { activate: [PERMISSION_ACTION.UserRead] },
					},
					{
						action: PERMISSION_ACTION.ClientUserRead,
						label: `Visualisation des Utilisateurs de vos Clients`,
						description: `A l'instar de vos utilisateurs agence, vos clients bénéficient également des rôles et tags.
						<br/>
						Vous ne pourrez rien modifier les concernants, mais aurez la possibilité de vérifier ou contrôler la bonne
						attribution des droits si vos clients vous indiquent ne pas voir ou signer un tel document.
						<br/>
						Une action autorisée sur Armado est le renvoi du mail d'activation si l'utilisateur n'est pas encore activé.`,
					},
					{
						action: PERMISSION_ACTION.TempWorkerUserRead,
						label: `Visualisation des Intérimaires et Candidats`,
						description: `L'ensemble des candidats et intérimaires qui travaillent avec vous sur Armado seront visibles par les utilisateurs
						disposant de ce droit.
						<br/>
						Ils pourront voir les expériences professionnelles, les justificatifs, les disponibilités,
            tout ce qui constitue finalement le profil de l'intérimaire.
						<br/>
						Certaines actions seront possibles depuis le détail, à savoir : demander le partage des disponibilités, des justificatifs, renvoyer
						le mail d'activation etc.
						<br/>
						Ils recevront également les mails associés aux réponses des intérimaires à ces demandes de partage.`,
						onDeactivate: { deactivate: [PERMISSION_ACTION.OrderRead] },
					},
				],
			},
			{
				title: `Permissions liées aux Organisations`,
				children: [
					{
						action: PERMISSION_ACTION.OrganizationRead,
						label: `Visualisation de l'Organisation`,
						description: `Votre organisation est le centre névralgique de votre groupe.
					<br/>
					Il permet de regrouper vos sociétés sous un référentiel unique et mutualise les informations attenantes.
					<br/>
					Attribuer le droit de visualisation sur l'organisation à un collaborateur lui permettra donc d'accéder aux informations
					globales de vos entreprises, sans actions possibles cependant.
					<br/>
					Actions en attente, statistiques, paramètres associés...`,
						onDeactivate: {
							deactivate: [
								PERMISSION_ACTION.UserRead,
								PERMISSION_ACTION.OrganizationUpdate,
								PERMISSION_ACTION.MissionPlace,
								PERMISSION_ACTION.TagRead,
								PERMISSION_ACTION.OrganizationRoleRead,
								PERMISSION_ACTION.CompanySettingUpdate,
							],
						},
					},
					{
						action: PERMISSION_ACTION.OrganizationUpdate,
						label: `Ajout de sociétés à l'Organisation`,
						description: `Au cours de votre utilisation d'Armado, il arrivera que certaines sociétés ne soient pas
					correctement rattachées à votre organisation.
					<br/>
					${this.isAgency
							? `Lorsque vous ouvrirez une nouvelle agence, par exemple, et que nos services procèdent à
					sa création, il vous reviendra de la rattacher à l'organisation.`
							: `Lorsque l'une de vos agences partenaire, par exemple, créé une nouvelle société pour votre compte.
				<br/>
				Tant qu'elle n'exploite pas l'adresse mail associée à votre organisation pour rattacher automatiquement la société,
				vous serez emmené à le faire manuellement.`}
				<br/>
				Rien de bien sorcier, mais il fallait un droit spécifique pour gérer ce cas.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRead] },
					},
					{
						action: PERMISSION_ACTION.CompanySettingUpdate,
						label: `Gestion des paramètres de l'organisation`,
						description: `Vos utilisateurs concernés par ce droit pourront avoir accès aux paramètres
				de votre organisation et des sociétés liées.
				<br/>
        ${this.isAgency
							? `Relances automatiques, affichage du statut des factures pour remonter un état soldé ou non aux clients,
				autorisation des demandes d'acompte et informations relatives, tous ces paramètres pourront être modifiés par
				un utilisateur disposant du droit ci-présent.`
							: `L'activation de cette permission est nécessaire pour pouvoir modifier le représentant légal de la société.`}`,
						onActivate: {
							activate: this.isAgency
								? [PERMISSION_ACTION.OrganizationRead]
								: [PERMISSION_ACTION.OrganizationRead, PERMISSION_ACTION.CompanyUpdateDelegate],
						},
						onDeactivate: { deactivate: [PERMISSION_ACTION.CompanyUpdateDelegate] },
					},
					{
						action: PERMISSION_ACTION.CompanyLogo,
						label: `Gestion des Logos de vos sociétés`,
						description: `Votre image de marque ne doit pas forcément être modifiable par tous.
						<br/>
						Nous avons donc modifié l'autorisation de mise à jour des logos en incluant ce droit.
						<br/>
						Pour information, les photos des enseignes se modifient dans la <a href="/mon-profil/agences" target="__blank">liste des agences<a/> !`,
					},
					{
						action: PERMISSION_ACTION.CompanyUpdateDelegate,
						label: `Gestion des Représentants Légaux de vos sociétés`,
						description: `${this.isAgency ? `Avant tout dépôt de document qui doit au préalable être signé par l'agence, il est nécessaire
						de renseigner un représentant légal.` :
							`Avant de pouvoir signer un document sur Armado, il est nécessaire de renseigner un représentant légal pour chaque société
							de l'organisation concernée par un document.`}
					<br/>
					L'affectation de ce droit permet, après ressaisie du mot de passe de l'utilisateur, la mise à jour du nom et prénom du
					représentant légal.`,
						onActivate: { activate: [PERMISSION_ACTION.CompanySettingUpdate] },
					},
					{
						action: PERMISSION_ACTION.Plugin,
						label: `Gestion des Modules Complémentaires`,
						description: `Les modules complémentaires, qui viennent compléter l'offre initiale et l'expérience Armado, peuvent
						être débloqué par nos soins une fois que vous en avez fait la demande et sous réserve des conditions négociées.
						<br/>
						L'accès aux demandes de déblocage se fait depuis le tableau de bord du module ou l'écran d'accueil Armado.
						<br/>
						Autoriser des utilisateurs à disposer de ce droit leur permettra de nous contacter pour demander le déblocage des modules.
						<br/>
						Actuellement, seul le module de gestion des commandes est concerné.`,
					},
					{
						action: PERMISSION_ACTION.MissionPlace,
						label: `Gestion des Lieux de mission`,
						description: `Vous pouvez, depuis Armado, créer et actualiser les lieux de mission que manipule votre organisation.
						<br/>
						Cette fonctionnalité vous permet de mutualiser, sur un seul et même espace, les chantiers géolocalisés que vous souhaitez
						partager avec vos agences partenaires.
						<br/>
						Ces lieux de missions se retrouveront également lors de la création d'une commande, afin de gagner du temps lorsque
						vous exprimerez un besoin au travers de notre plateforme.
						<br/>
						Ce droit donne donc l'accès à la liste des lieux de missions ainsi que leurs mises à jour.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRead] },
					},
				],
			},
			{
				title: `Permissions liées aux Tags`,
				children: [
					{
						action: PERMISSION_ACTION.TagRead,
						label: `Visualisation des Tags`,
						description: `En activant ce droit, vos utilisateurs pourront accéder à la liste des Tags de votre organisation
					et des statistiques associées.
					<br/>
					Il s'agit bien de la liste dans le menu Organisation et non du fait de voir le ou les tags associés aux documents.
					<br/>
					Que vos utilisateurs disposent ou non de ce droit ne les empechera donc pas de visualiser les tags associés aux
					contrats, factures, relevés d'heures, etc.
					<br/>
					Petit rappel : dès lors qu'un tag est associé à l'un de vos collaborateurs, celui-ci ne verra plus que les documents
					tagués en conséquence.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRead] },
						onDeactivate: { deactivate: [PERMISSION_ACTION.TagCreate, PERMISSION_ACTION.TagUpdate, PERMISSION_ACTION.TagDelete] },
					},
					{
						action: PERMISSION_ACTION.TagCreate,
						label: `Création des Tags`,
						description: `Cette option déverrouille le droit de création d'un Tag sur votre organisation.
					<br/>
					N'affectez ce droit qu'aux utilisateurs clés.
					<br/>
					${this.isAgency ?
							`Vos clients peuvent également créer des Tags depuis Armado.
						<br/>
						Ils pourront s'en servir pour plusieurs raisons : désigner des chantiers particuliers,
						nommer des services internes ou encore scinder leurs entreprises en briques administratives correspond à des activités distinctes.
						<br/>
						C'est en récupérant ces Tags depuis vos outils de BackOffice que vous automatiserez l'association de documents
						avec les Tags des clients.
						<br/>
						Les Tags, sur votre organisation d'agence, sont pour vos processus internes et vous ne verrez qu'eux sur
						votre espace Armado.`
							: `Les Tags que vous allez créer sur Armado seront partagés à vos agences partenaires !
					<br/>
					Ainsi, ils pourront automatiser l'association de Tags aux documents lors des dépôts.`}
					Les facultés de créer un rôle et d'y associer un utilisateur sont scindées en deux droits.
					<br/>
					L'association d'un utilisateur est gérée par la permission : Modification des Droits et Tags des Utilisateurs.`,
						onActivate: { activate: [PERMISSION_ACTION.TagRead] },
					},
					{
						action: PERMISSION_ACTION.TagUpdate,
						label: `Modification des Tags`,
						description: `En activant ce droit, vos utilisateurs pourront modifier le nom ou la couleur
					des tags.
					<br/>
					Attention aux repercussions d'une telle action : une fois associé à des documents, les Tags
					vont avoir du sens pour vos collaborateurs. Modifier le nom d'un Tag peut donc porter à confusion.`,
						onActivate: { activate: [PERMISSION_ACTION.TagRead] },
					},
					{
						action: PERMISSION_ACTION.TagDelete,
						label: `Suppression des Tags`,
						description: `Les utilisateurs disposant de ce droit pourront supprimer les Tags.
					<br/>
					Point d'attention : un Tag peut être supprimé même s'il est affecté à des utilisateurs ou des documents.
					<br/>
					Ce droit est donc à donner en connaissance de cause, aucun retour en arrière ne pourra être effectué.`,
						onActivate: { activate: [PERMISSION_ACTION.TagRead] },
					},
					{
						action: PERMISSION_ACTION.TagLink,
						label: `Association des Tags aux documents`,
						description: `Une fois les Tags créés et les utilisateurs correctement associés, la dernière étape est
				de lier les Tags aux différents documents et processus accessibles sur Armado.
				<br/>
				Ce droit autorise l'affectation ou la suppression de Tags depuis les listes Armado, sur chaque élément ou en lot.
				<br/>
				Listes assujetties aux droits ci-dessus.`,
					},
				],
			},
			{
				title: `Permissions liées aux Rôles`,
				children: [
					{
						action: PERMISSION_ACTION.OrganizationRoleRead,
						label: `Visualisation des Rôles`,
						description: `Un rôle est une composition de droits que vous créerez une fois et affecterez à un ou plusieurs
					collaborateurs.
					<br/>
					Ces derniers pourront se voir attribuer plusieurs rôles, permettant des combinaisons de permissions.
					<br/>
					Activer ce droit de visualisation permettra à l'utilisateur concerné d'accéder à la gestion dans laquelle vous vous trouvez actuellement.
					<br/>
					Il est donc plutôt destiné aux administrateurs, chargés de ressources humaines, manager ou responsables.
					Autant d'interlocuteurs qui peuvent avoir besoin de lire les descriptions ci présentes, afin de comprendre les raisons pour lesquelles
					certaines actions sont impossibles pour leurs collègues.
					<br/>
					Ce droit ne permet aucune action.
					<br/>
					Un utilisateur ne disposant d'aucun rôle pourra, dans certains cas notamment celui d'un utilisateur créé avant la 5.0.0 Armado,
					tout de même accéder aux gestions qui lui étaient auparavant accordées.
					<br/>
					Un utilisateur, créé après la 5.0.0, qui ne dispose d'aucun rôle n'accédera à aucun élément de votre organisation et sociétés sur Armado.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRead] },
						onDeactivate: {
							deactivate: [
								PERMISSION_ACTION.OrganizationRoleCreate,
								PERMISSION_ACTION.OrganizationRoleUpdate,
								PERMISSION_ACTION.OrganizationRoleDelete,
							],
						},
					},
					{
						action: PERMISSION_ACTION.OrganizationRoleCreate,
						label: `Création des Rôles`,
						description: `L'activation de ce droit fait apparaitre l'action "nouveau rôle" en tête de liste.
					<br/>
					Le rôle est constitué d'un nom et d'une couleur pour les différencier - ou associer visuellement - selon vos préférences.
					Les facultés de créer un rôle et d'y associer un utilisateur sont scindées en deux droits.
					<br/>
					L'association d'un utilisateur est gérée par la permission : Modification des Droits et Tags des Utilisateurs.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRoleRead] },
					},
					{
						action: PERMISSION_ACTION.OrganizationRoleUpdate,
						label: `Modification des Rôles`,
						description: `En cochant cette option, l'utilisateur pourra modifier le nom ou la couleur des rôles
					créés sur votre organisation.
					<br/>
					En attribuant ce droit, l'utilisateur pourra également modifier les permissions constitutifs d'un rôle.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRoleRead] },
					},
					{
						action: PERMISSION_ACTION.OrganizationRoleDelete,
						label: `Suppression des Rôles`,
						description: `Le dernier droit constitutif des rôles est celui de leurs suppressions.
					<br/>
					Attention, tous les utilisateurs concernés peuvent alors se retrouver sans aucun rôle et de ce fait, sans
					visibilité sur les documents, gestions et processus sur Armado.
					<br/>
					Assurez-vous donc que ces collaborateurs disposent d'un autre rôle adéquat qui puisse leur être attribué.`,
						onActivate: { activate: [PERMISSION_ACTION.OrganizationRoleRead] },
					},
				],
			},
			{
				title: `Permissions liées aux Statistiques`,
				children: [
					{
						action: PERMISSION_ACTION.Statistics,
						label: `Visualisation des statistiques de dépôt et signatures`,
						description: `Ce droit autorise vos utilisateurs à accéder à la page de statistiques
						de dépôt et signature par vos tiers.`,
					},
				],
			},
			{
				title: `Permissions liées aux Actualités`,
				children: [
					{
						action: PERMISSION_ACTION.AgencyNewsRead,
						label: `Visualisation des Actualités`,
						description: `Ce droit autorise vos utilisateurs à accéder à la liste des actualités publiées par vos
						agences, ainsi que le nombre de vues et les éventuelles dates de publication.
						<br/>
						Ce droit ne permet cependant pas de rédiger et de publier une nouvelle actualité.`,
						onDeactivate: { deactivate: [PERMISSION_ACTION.AgencyNewsCreate] },
					},
					{
						action: PERMISSION_ACTION.AgencyNewsCreate,
						label: `Création d'Actualité`,
						description: `Les actualités pouvant être partagées à de très nombreux intérimaires ou clients,
						il est nécessaire de n'autoriser que certains collaborateurs à les rédiger et à les publier.
						<br/>
						C'est ce droit qui doit être coché pour permettre ces différentes actions.`,
						onActivate: { activate: [PERMISSION_ACTION.AgencyNewsRead] },
					},
				],
			},
			{
				title: `Permissions liées au Chat en ligne`,
				children: [
					{
						action: PERMISSION_ACTION.Discussion,
						label: `Utilisation de la messagerie instantannée`,
						description: `Ce droit permet d'afficher l'icône de messagerie pour les utilisateurs concernés.
						<br/>
						En plus de ce droit, ayez à l'esprit que chaque discussion, venant obligatoirement d'un contexte de document ou
						d'un processus sur Armado, est également assujettie du droit du dit contexte.
						<br/>
						${this.isAgency ?
							`Un exemple : vous souhaitez autoriser un collaborateur à discuter uniquement avec les intérimaires
							concernant leurs contrats sur Armado.
							<br/>
							Dans ce cas, vous devrez lui autoriser ce droit du Chat en ligne et le droit de visualisation des contrats intérimaires.`
							:`Un exemple : vous souhaitez autoriser un collaborateur à discuter uniquement avec vos agences
							partenaires concernant les factures déposées sur Armado.
							<br/>
							Dans ce cas, vous devrez lui autoriser ce droit du Chat en ligne et le droit de visualisation des factures.`}
						`,
					},
				],
			},
		] as const;
	}

	get list () {
		return this.rolesPermissionsList as unknown as Armado.RolePermission.ServicePermission[];
	}

	async permissionGuardAsync (
		permission: keyof typeof PERMISSION_ACTION,
		message = `Vous n'avez pas les droits requis pour effectuer cette opération`) {
		return new Promise<void>((resolve) => {
			if (useSelfService().self.can(permission)) {
				resolve();
			} else {
				useNotif.warning(message);
			}
		});
	}

	async updateAsync (...args: Parameters<typeof RolePermissionApi.update>) {
		const { data } = await RolePermissionApi.update(...args);
		return data;
	}

	async selfAsync (...args: Parameters<typeof RolePermissionApi.self>) {
		const { data } = await RolePermissionApi.self(...args);
		return data;
	}
}

const rolePermissionsServiceSingleton = new RolePermissionService();

export default function useRolePermissionsService () {
	return rolePermissionsServiceSingleton;
}
