import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import pack from '../package.json';

// let replay: Sentry.Replay;

let armadoApp: App<any> | undefined;

export function setArmadoApp (app: App<any>) {
	armadoApp = app;
}

export function getArmadoApp () {
	return armadoApp;
}

export function initSentry (app: any, router: Router) {
	/* replay = new Sentry.Replay({
		maskAllText: false,
		maskAllInputs: false,
	}); */

	Sentry.init({
		app,
		environment: import.meta.env.VITE_NODE_ENV,
		dsn: 'https://6823cc2d87074d598a09ea9cfe1ece9c@sentry.armado.fr/9',
		integrations: [
			new Sentry.BrowserTracing({ routingInstrumentation: Sentry.vueRouterInstrumentation(router) }),
		],
		tracesSampleRate: 0.7,
		tracePropagationTargets: ['localhost:8080', 'myarmado.fr', 'dev.myarmado.fr', 'staging.myarmado.fr', 'demo.myarmado.fr', /^\//],
		release: pack.version,
		enabled: import.meta.env.VITE_NODE_ENV !== 'development',
	});
}

export { Sentry };
