import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationCompanyListItemEntity from '@/entity/organization-company/OrganizationCompanyListItemEntity';
import OrganizationCompanyDetailedEntity from '@/entity/organization-company/OrganizationCompanyDetailedEntity';
import useCompanyService from '@/services/CompanyService';

type Props = SetupProps<typeof SharedLogoCompanySetupService.props>

export default class SharedLogoCompanySetupService extends BaseSetupService<Props> {
	static readonly props = {
		isClientCompany: Boolean,
		showLogoUpdate: Boolean,
		company: {
			type: Object as PropType<{ name?: string, id: number } | OrganizationCompanyListItemEntity | OrganizationCompanyDetailedEntity>,
			default: null,
		},
	};

	async updateLogoAsync () {
		if (this.props.company instanceof OrganizationCompanyListItemEntity
      || this.props.company instanceof OrganizationCompanyDetailedEntity) {
			await useCompanyService(this.props.company).promptLogoModalAsync(this.props.company.id, this.props.company.logoId);
		}
	}
}
