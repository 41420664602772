<template>
	<o-select
		v-model="setup.agencyQualificationId"
		label="Qualifications de l'agence"
		display-key="label"
		track-key="id"
		value-key="id"
		:options="setup.agencyQualificationsOptions"
		:disabled="!setup.agencyQualificationsOptions.length"
		:searchable="setup.agencyQualificationsOptions.length > 10"
		clearable
		multiple>
		<template #option="{ item, markedSearch }: {item: IQualificationForListModel, markedSearch: (content: string) => string }">
			<div>
				<div
					v-if="item.label"
					class="shared-select-agency-qualifications__option-label"
					v-html="markedSearch(item.label)"/>
				<div v-else>{{ item.label }}</div>
				<o-label
					v-if="!agencyCompanyId"
					size="sm"
					color="grey-light">
					<div class="flex ai-c g-xs">
						{{ item.agencyCompanyName }}
						<em>{{ item.agencyCompanyContactDetailCity }}</em>
					</div>
				</o-label>
			</div>
		</template>
	</o-select>
</template>

<script setup lang="ts">
import SharedSelectAgencyQualificationsSetupService, { Emits } from '@/setup/shared/SharedSelectAgencyQualificationsSetupService';
const emits = defineEmits<Emits>();
const props = defineProps(SharedSelectAgencyQualificationsSetupService.props);
const setup = new SharedSelectAgencyQualificationsSetupService(props, emits);
</script>

<style scoped lang="less">
.shared-select-agency-qualifications {
  &__option-label {
    color: var(--grey-darker);
  }
}
</style>
