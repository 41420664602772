<template>
	<o-select
		v-model="setup.groups"
		label="Vivier"
		track-key="id"
		display-key="label"
		:options="setup.groupOptions"
		multiple
		@add="setup.addGroupAsync($event)"
		@remove="setup.removeGroupAsync($event)"/>
</template>

<script setup lang="ts">
import SharedOrderGroupSelectSetupService from '@/setup/shared/SharedOrderGroupSelectSetupService';
const props = defineProps(SharedOrderGroupSelectSetupService.props);
const setup = new SharedOrderGroupSelectSetupService(props);
</script>
