<template>
	<shared-tag-flat-select
		placement="bottom-end"
		is-quick-filter
		hide-new
		:model-value="setup.valueAsLinkModel"
		@add="setup.updateFilter($event as ITagListItemModel)"
		@delete="setup.updateFilter($event as ITagListItemModel)">
		<o-chips
			class="pointer"
			:color="setup.valueAsLinkModel.first()?.tagColorName">
			{{ setup.valueAsLinkModel.first()?.tagName ?? 'Filtrer sur un tag' }}
			<template
				v-if="modelValue?.length > 1"
				#dual>
				+{{ modelValue.length - 1 }}
			</template>
		</o-chips>
	</shared-tag-flat-select>
</template>

<script setup lang="ts">
import SharedTagQuickFilterSetupService from '@/setup/shared/SharedTagQuickFilterSetupService';
type Emits = {(e: 'update:modelValue', val: number[]) : void}
const emits = defineEmits<Emits>();
const props = defineProps(SharedTagQuickFilterSetupService.props);
const setup = new SharedTagQuickFilterSetupService(props, emits);
</script>
