import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useTagService from '@/services/tag/TagService';
import TagListItemEntity from '@/entity/TagListItemEntity';

type Props = SetupProps<typeof SharedTagSelectSetupService.props>
type Emits = {(e: 'update:modelValue', val: number | number[] | undefined): void}

export default class SharedTagSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		multiple: Boolean,
		modelValue: {
			type: [Number, Array] as PropType<number | number[] | undefined>,
			default: undefined,
		},
	};

	private emits : Emits;
	private readonly state = reactive({
		dataLoaded: false,
		options: [] as TagListItemEntity[],
	});

	get dataLoaded () { return this.state.dataLoaded; }
	get options () { return this.state.options; }
	get selectedTagIds () { return this.props.modelValue; }
	set selectedTagIds (val) { this.emits('update:modelValue', val); }


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	async onBeforeMountAsync () {
		this.state.options = TagListItemEntity.map(await useTagService().orderedListAsync());
		this.state.dataLoaded = true;
	}


	getTagById (id:number) {
		return this.state.options.findByKey(id);
	}
}
