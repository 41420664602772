export enum TEMP_WORKER_DOCUMENT_TYPE {
	IdentityPiece = 0,
	AddressProof = 1,
	Iban = 2,
	SocialCertificate = 3,
	CurriculumVitae = 4,
	Diploma = 5,
	DriverLicence = 6,
	Custom = 7,
}
