import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useTagService from '@/services/tag/TagService';

type Props = SetupProps<typeof SharedTagQuickFilterSetupService.props>
type Emits = {(e: 'update:modelValue', val: number[]) : void}

export default class SharedTagQuickFilterSetupService extends BaseSetupService<Props> {
	static readonly props = {
		modelValue: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
	};

	private emits: Emits;
	private readonly state = reactive({ tagsList: [] as ITagListItemModel[] });

	get valueAsLinkModel () {
		return this.state.tagsList
			.filter(x => this.props.modelValue?.includes(x.id))
			?.map(x => ({
				id: 0,
				linkId: 0,
				tagId: x.id,
				tagName: x.name,
				tagColorName: (x.color?.name?.replace(/(grad|light)/, 'alt') ?? 'default') as Orion.ColorExtended,
			}));
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	async onBeforeMountAsync () {
		this.state.tagsList = await useTagService().listAsync();
	}

	updateFilter (tag: ITagListItemModel) {
		this.emits('update:modelValue', [...this.props.modelValue].toggle(tag.id));
	}
}
