import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/company');

class CompanyApiAgency {
	static clientList (parameters: OmitUserId<ICompanyListByUserIdParameters>) {
		return client.get<ICompanyModel[]>('clientlist', { params: parameters });
	}

	static pluginOrderList (companyWithOrderPluginPagedListParameters: ICompanyWithOrderPluginPagedListParameters) {
		return client.post<IPagedList<ICompanyWithOrderPluginModel>>('plugin/order', companyWithOrderPluginPagedListParameters);
	}

	static getClientForOrganizationalChart (pagedListParameters: ICompanyFilterForOrganizationPagedListParameters) {
		return client.get<ICompanyForOrganizationalChartModel[]>('organizationalChart/clientList', { params: pagedListParameters });
	}

	static getCompaniesByUserId (userId: number, pagedListParameters: ICompanyForDetailOrganizationAgencyUserPagedListParameters) {
		return client.post<IPagedList<ICompanyForDetailOrganizationAgencyUserModel>>(userId.toString(), pagedListParameters);
	}

	static getClientCompaniesByUserId (userId: number, pagedListParameters: ICompanyForDetailOrganizationAgencyUserPagedListParameters) {
		return client.post<IPagedList<IClientCompanyForDetailOrganizationAgencyUserModel>>(`client/${userId}`, pagedListParameters);
	}

	static getCompaniesForOrganization (pagedListParameters: ICompanyForAgencyOrganizationPagedListParameters) {
		return client.get<IPagedList<ICompanyForAgencyOrganizationItemModel>>('organization', { params: pagedListParameters });
	}
}

export default CompanyApiAgency;
