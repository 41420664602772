<template>
	<metainfo>
		<template #description="{ content }">
			{{ content }}
		</template>
	</metainfo>
	<router-view @vue:mounted="routerViewMounted"/>
	<!-- <div class="debug">
		<div>
			reponsive :
			<span class="debug__layoutFull">@layoutFull</span>
			<span class="debug__layoutOneAside">@layoutOneAside</span>
			<span class="debug__layoutNoAside">@layoutNoAside</span>
			<span class="debug__layoutNavFull">@layoutNavFull</span>
			<span class="debug__layoutNavCollapsed">@layoutNavCollapsed</span>
			<span class="debug__layoutNavBottom">@layoutNavBottom</span>
			<span class="debug__phone">@phone</span>
		</div>
	</div> -->
</template>

<script setup lang="ts">
const SessionManager = () => import('@/utils/SessionManager');

function routerViewMounted () {
	SessionManager();
}
</script>

<style lang="less">
@import (css) url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700");
@import '@/styles/styles.less';

#app {
  height: 100vh;
  height: 100dvh;
  display: flex;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
</style>
