import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { Bus } from '@orion.ui/orion';

type Props = SetupProps<typeof SharedPagedListOrderHeaderSetupService.props>

type Emits = {
	(e: 'update:modelValue', value: OrderValue): void,
	(e: 'refreshList'): void,
	(e: 'selectAll'): void,
}

type OrderValue = {
	property: string
	ascending: boolean
}

type OrderOptions = {
	key: string
	label: string
	clickable?: boolean
	class?: string
}


export default class SharedPagedListOrderHeaderSetupService extends BaseSetupService<Props> {
	static readonly props = {
		hideSelection: Boolean,
		listSelectedItems: {
			type: Array,
			required: true,
		},
		listHasAllItemsSelected: {
			type: [Number, Boolean],
			default: false,
		},
		orders: {
			type: Array as PropType<OrderOptions[]>,
			required: true,
		},
		modelValue: {
			type: Object as PropType<OrderValue>,
			required: true as const,
		},
	};

	private emits: Emits;
	private highlighterTimer?: number;
	private readonly state = reactive({
		highlighter: {
			animate: false,
			show: false,
			left: 0,
			width: 0,
		},
	});

	get highlighterAnimate () { return this.state.highlighter.animate; }
	get highlighterShow () { return this.state.highlighter.show; }
	get highlighterLeft () { return this.state.highlighter.left; }
	get highlighterWidth () { return this.state.highlighter.width; }

	get activeValue () { return this.props.modelValue; }
	set activeValue (val: OrderValue) { this.emits('update:modelValue', val); }

	get allSelected () { return this.props.listHasAllItemsSelected; }

	get checkboxChecked () {
		return this.allSelected || this.props.listSelectedItems?.length;
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	getOrderStatus (key: string) {
		return {
			color: this.activeValue.property === key ? 'info' : 'default' as Orion.Color,
			icon: this.activeValue.property === key && this.activeValue.ascending ? 'chevron_up' : 'chevron_down',
		};
	}

	toggleOrder (order: OrderOptions) {
		if (order.clickable !== false) {
			this.activeValue = {
				ascending: this.activeValue.property === order.key ? !this.activeValue.ascending : false,
				property: order.key,
			};
			this.emits('refreshList');
		}
	}

	emitOverOrder (key: string, e: MouseEvent) {
		// Prevent highlighter remove from previous mouseout
		clearTimeout(this.highlighterTimer);

		// Define coordinates
		const { left, width } = (e.target as HTMLElement).getBoundingClientRect();
		this.state.highlighter.left = left;
		this.state.highlighter.width = width;

		setTimeout(() => {
			// Enable animation after coord for first appearance
			this.state.highlighter.animate = true;
			// Scale and opacity animation
			this.state.highlighter.show = true;
		}, 10);

		Bus.emit('paged-list-order-header-over', key);
	}

	emitOutOrder (key: string) {
		Bus.emit('paged-list-order-header-out', key);

		// Scale and opacity animation
		this.state.highlighter.show = false;

		// Reset state after css animation 300ms
		this.highlighterTimer = setTimeout(() => {
			this.state.highlighter.animate = false;
			this.state.highlighter.left = 0;
			this.state.highlighter.width = 0;
		}, 350);
	}
}
