import { PropType, reactive, ref } from 'vue';
import { useNotif } from '@orion.ui/orion';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationRoleListItemEntity from '@/entity/OrganizationRoleListItemEntity';
import type OrganizationUserListItemEntity from '@/entity/organization-user/OrganizationUserListItemEntity';
import type OrganizationUserEntity from '@/entity/organization-user/OrganizationUserEntity';
import useRoleUserService from '@/services/RoleUserService';
import useOrganizationRoleService from '@/services/OrganizationRoleService';
import { FlatSelectOptionItem } from './SharedFlatSelectSetupService';
import useColorService from '@/services/ColorService';
import SelfEntity from '@/entity/SelfEntity';

type Props = SetupProps<typeof SharedRoleFlatSelectSetupService.props>
type Emits = {
	(e: 'update:opened', val: boolean): void
	(e: 'update:modelValue', val: IRoleUserModel[]): void
	(e: 'add' | 'delete', val: OrganizationRoleListItemEntity): void,
}

export default class SharedRoleFlatSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		hideNew: Boolean,
		opened: Boolean,
		disabled: Boolean,
		modelValue: {
			type: Array as PropType<IRoleUserModel[]>,
			default: () => [],
		},
		user: {
			type: Object as PropType<OrganizationUserListItemEntity | OrganizationUserEntity | SelfEntity>,
			default: undefined,
		},
		placement: {
			type: String,
			default: 'bottom-start',
		},
		label: {
			type: String,
			default: 'Sélectionnez un rôle',
		},
	};

	_popover = ref<Dropdown>();

	private emits: Emits;
	private readonly state = reactive({
		popoverOpened: false,
		loading: false,
		search: undefined as Undef<string>,
		roles: [] as OrganizationRoleListItemEntity[],
	});


	get loading () { return this.state.loading; }
	get roles () { return this.state.roles as OrganizationRoleListItemEntity[]; }
	get popoverOpened () { return this.state.popoverOpened; }

	get rolesList () {
		return this.roles
			.filter(x => !this.search?.trim().length || x.name?.toLowerCase().includes(this.search))
			.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
	}

	get rolesListOptionsForFlatSelect (): FlatSelectOptionItem[] {
		return this.rolesList.map(x => ({
			id: x.id,
			name: x.name ?? '',
			colorName: x.colorName,
		}));
	}

	get search () { return this.state.search; }
	set search (val) { this.state.search = val; }


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}


	swapRoleColor (color?: string) {
		return (useColorService().swapColor(color) ?? 'default');
	}

	roleIsSelected (role: FlatSelectOptionItem) {
		return this.props.modelValue?.map(x => x?.organizationRole?.id).includes(role.id);
	}

	async handlePopoverShowAsync () {
		this.emits('update:opened', true);
		this.state.popoverOpened = true;
		this.state.loading = true;
		this.state.roles = OrganizationRoleListItemEntity.map(await useOrganizationRoleService().listAsync());
		this.state.loading = false;
	}

	handlePopoverHide () {
		this.emits('update:opened', false);
		this.state.popoverOpened = false;
	}

	async linkAsync (role: IOrganizationRoleListItemModel) {
		if (!this.props.user?.userId) return;

		const newRole = await useRoleUserService().addAsync(this.props.user.userId, role.id);

		//if (newRole) this.props.user.addUserRole(newRole);

		this.emits('update:modelValue', [...this.props.modelValue, (newRole as IRoleUserModel)]);
		const roleEntity = this.roles.findByKey(role.id);
		if (roleEntity) this.emits('add', roleEntity);

		useNotif.success(`Sauvegarde`, `Le rôle ${role.name} a bien été attribué à ${this.props.user.name}`);
	}

	async unlinkAsync (role: IOrganizationRoleListItemModel) {
		if (!this.props.user?.userId) return;

		const userRoleIndex = this.props.modelValue?.findIndex(r => r.organizationRole?.id === role.id);
		if (userRoleIndex === -1) return;

		const roleUserId = this.props.modelValue?.[userRoleIndex]?.id;

		if (roleUserId) await useRoleUserService().deleteAsync(roleUserId, this.props.user.userId);

		this.props.user.deleteUserRole(roleUserId);

		this.emits('update:modelValue', this.props.modelValue?.filter(x => x.organizationRole?.id !== role.id));

		const roleEntity = this.roles.findByKey(role.id);
		if (roleEntity) this.emits('delete', roleEntity);

		useNotif.success(`Le rôle ${role.name} a bien été retiré à ${this.props.user.name}`);
	}

	show () {
		this._popover.value?.show();
	}

	hide () {
		this._popover.value?.hide();
	}

	async createRoleAsync () {
		this.hide();
		try {
			await useOrganizationRoleService().promptNewRoleAsideAsync();
			this.state.roles = OrganizationRoleListItemEntity.map(await useOrganizationRoleService().listAsync(true));
		} finally {
			setTimeout(() => {
				this.show();
			}, 600);
		}
	}
}
