import axios from 'axios';
import { ARMADO_URL } from '@/utils/CONST';
import { loggerRequestInterceptor,
	loggerResponseErrorInterceptor,
	loggerResponseInterceptor,
	responseErrorInterceptorAsync } from './axios.interceptor';

const isDevelopement = import.meta.env.VITE_NODE_ENV === 'development';

export default function () {
	const httpClient = axios.create({
		baseURL: `${ARMADO_URL}/app`,
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Cache-Control': 'no-cache',
		},
		paramsSerializer: {
			indexes: null,
			dots: true,
		},
	});

	if (isDevelopement) {
		httpClient.interceptors.request.use(loggerRequestInterceptor);
		httpClient.interceptors.response.use(loggerResponseInterceptor, loggerResponseErrorInterceptor);
	}

	httpClient.interceptors.response.use(undefined, responseErrorInterceptorAsync);

	return httpClient;
}
