import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('tempworker/salaryslip');

class SalarySlipApiTempWorker {
	static grid (filter: ISalarySlipPagedListForTempWorkerParameters) {
		return client.post<IPagedList<ISalarySlipItemForTempWorkerModel>>('grid', filter);
	}

	static getDetailed (id: number) {
		return client.get<ISalarySlipOverPageForTempWorkerModel>(id.toString());
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}

	static salaryCurrentYear () {
		return client.get<ISalarySlipMonthRecapModel[]>('salarycurrentyear');
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/salaryslip${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/salaryslip${idsQuery}`, '_blank');
	}
}

export default SalarySlipApiTempWorker;
