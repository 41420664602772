import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { HELP, ONBOARDING } from '@/utils/CONST';

const routesTempWorker = [
	/* {
		path: '/signature-statut/:status',
		component: () => import('@Views/yousign/SignatureStatus.vue'),
		props: true,
	},*/
	{
		path: '/',
		component: () => import('@/components/shared/SharedLayout.vue'),
		meta: { requiresAuth: true },
		children: [
			{
				path: 'contrats',
				name: 'TempWorkerContract',
				component: () => import('@/components/contract/ContractPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List | HELP.Signature },
				children: [
					{
						path: ':id',
						name: 'TempWorkerContractDetails',
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
						props: { documentType: DOCUMENT_TYPE.TempWorkerContract },
					},
				],
			},
			{
				path: 'bulletins',
				name: 'SalarySlip',
				component: () => import('@/components/salary-slip/SalarySlipPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'SalarySlipDetails',
						component: () => import('@/components/salary-slip/SalarySlipAsideDetails.vue'),
					},
				],
			},
			{
				path: 'certificats-de-travail',
				name: 'Certificate',
				component: () => import('@/components/certificate/CertificatePagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'CertificateDetails',
						component: () => import('@/components/certificate/CertificateAsideDetails.vue'),
					},
				],
			},
			{
				path: 'attestations-declaration-impot',
				name: 'WithholdingTaxAttestation',
				component: () => import('@/components/withholding-tax-attestation/WithholdingTaxAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'justificatifs-deplacement-professionnel',
				name: 'TripAttestation',
				component: () => import('@/components/trip-attestation/TripAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'attestations-pole-emploi',
				name: 'PoleEmploiAttestation',
				component: () => import('@/components/pole-emploi-attestation/PoleEmploiAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'documents-mis-a-disposition',
				name: 'TempWorkerProvisionDocument',
				component: () => import('@/components/provision-document/ProvisionDocumentPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'demandes-acompte',
				name: 'AdvancedPaymentDemand',
				component: () => import('@/components/advanced-payment-demand/AdvancedPaymentDemandPagedListView.vue'),
				children: [
					{
						path: ':id',
						name: 'AdvancedPaymentDemandDetails',
						component: () => import('@/components/advanced-payment-demand/AdvancedPaymentDemandAsideDetails.vue'),
					},
				],
			},
			{
				path: 'offres-mission',
				name: 'JobOffer',
				component: () => import('@/components/job-offer/JobOfferPagedListView.vue'),
				meta: { onBoarding: ONBOARDING.JobOffer },
				children: [
					{
						path: ':id',
						name: 'JobOfferDetails',
						// props: true,
						component: () => import('@/components/job-offer/JobOfferAsideDetails.vue'),
					},
				],
			},
			{
				path: 'vie-personnelle',
				name: 'DigitalSafe',
				component: () => import('@/components/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentListView.vue'),
				meta: { helpArmadoPage: HELP.DigitalSafe },
				children: [
					{
						path: ':id',
						name: 'DigitalSafeDetails',
						component: () => import('@/components/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mon-profil',
				name: 'UserProfile',
				component: () => import('@/components/user-profile/UserProfileView.vue'),
				children: [
					{
						path: 'informations',
						name: 'UserProfileInformations',
						component: () => import('@/components/user-profile/UserProfileInformations.vue'),
					},
					{
						path: 'disponibilite',
						name: 'UserProfileAvailabilities',
						component: () => import('@/components/user-profile/UserProfileAvailabilities.vue'),
					},
					{
						path: 'agence',
						name: 'UserProfileCompanies',
						component: () => import('@/components/user-profile/UserProfileCompanies.vue'),
					},
					{
						path: 'compte',
						name: 'UserProfileAccount',
						component: () => import('@/components/user-profile/UserProfileAccount.vue'),
					},
					{
						path: 'justificatifs',
						name: 'UserProfileTempWorkerDocuments',
						component: () => import('@/components/user-profile/UserProfileTempWorkerDocuments.vue'),
					},
					{
						path: 'appareils',
						name: 'UserProfileDevices',
						component: () => import('@/components/user-profile/UserProfileDevices.vue'),
					},
				],
			},
			{
				path: 'releves-heures',
				name: 'TimeTicket',
				component: () => import('@/components/time-ticket/TimeTicketTempworker.vue'),
				children: [
					{
						path: ':year/:weekNumber',
						name: 'TimeTicketWeek',
						component: () => import('@/components/time-ticket/TimeTicketWeekSection.vue'),
					},
				],
			},
			{
				path: 'actualites',
				name: 'News',
				component: () => import('@/components/news/NewsPagedListView.vue'),
			},
		],
	},
] as const;

export default routesTempWorker;
