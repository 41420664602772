import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/salaryslip');

class SalarySlipApiAgency {
	static grid (filter: ISalarySlipPagedListForAgencyParameters) {
		return client.post<IPagedList<ISalarySlipItemForAgencyModel>>('grid', filter);
	}

	static getDetailed (id: number) {
		return client.get<ISalarySlipOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/salaryslip${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/salaryslip${idsQuery}`, '_blank');
	}
}

export default SalarySlipApiAgency;
