import { PropType, reactive, ref } from 'vue';
import { throttle } from 'lodash-es';
import BaseSetupService from '@/setup/BaseSetupService';
import anime from 'animejs';

type Props = SetupProps<typeof SharedMagneticBlobSetupService.props>
export type Emits = {(e: 'path-click'): void}

export default class SharedMagneticBlobSetupService extends BaseSetupService<Props> {
	static readonly props = {
		proximityFactor: {
			type: Number,
			default: 1,
		},
		curveFactor: {
			type: Number,
			default: 1,
		},
		side: {
			type: String as PropType<'right' | 'left'>,
			default: 'right',
		},
	};

	_el = ref<HTMLElement>();
	_v = ref<SVGElement>();
	private emits: Emits;
	private readonly state = reactive({
		x: 0,
		y: 50,
	});

	get x () { return this.state.x; }
	get y () { return this.state.y; }


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
		this.initMouseMoveListener();
	}


	mouseMoveListenerThrottle = throttle((e: MouseEvent) => {
		if (!this._el.value || !this._v.value) return;

		const { top, bottom, height, right, left } = this._el.value?.getBoundingClientRect();

		let relativeY = 0;
		let relativeX = 0;

		if (this.props.side === 'left' || this.props.side === 'right') {
			relativeY = e.clientY <= top ? 0
				: e.clientY >= bottom ? 100
					: (e.clientY - top) * 100 / height;

			if (this.props.side === 'right') {
				relativeX = e.clientX < left ? 0
					: e.clientX > right ? 100 - ((e.clientX - right) / this.props.proximityFactor)
						: 100;
			} else if (this.props.side === 'left') {
				relativeX = e.clientX > right ? 0
					: e.clientX < left ? 100 - ((left - e.clientX) / this.props.proximityFactor)
						: 100;
			}
		}

		this.state.x = (relativeX < 0 ? 0 : relativeX);
		this.state.y = relativeY;

		const topHandlerY = relativeY - 10;
		const factoredTopHandlerY = relativeY - (10 / this.props.curveFactor);
		// const bottomHandlerY = relativeY + 10;
		const factoredBottomHandlerY = relativeY + (10 / this.props.curveFactor);

		anime({
			targets: this._v.value.getElementsByTagName('path').item(0),
			d: `M0,0 C0,${factoredTopHandlerY}, ${this.x * 0.9},${topHandlerY} ${this.x * 0.9},${this.y} S 0,${factoredBottomHandlerY} 0,100`,
			duration: 300,
		});
	}, 10);

	initMouseMoveListener () {
		document.addEventListener('mousemove', this.mouseMoveListenerThrottle.bind(this));
	}

	handlePathClick () {
		this.emits('path-click');
	}
}
