import { PropType, reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import TagListItemEntity from '@/entity/TagListItemEntity';
import { useNotif } from '@orion.ui/orion';
import { isNil } from 'lodash-es';
import useTagService from '@/services/tag/TagService';
import { FlatSelectOptionItem } from './SharedFlatSelectSetupService';

type Props = SetupProps<typeof SharedTagFlatSelectSetupService.props>
type Emits = {
	(e: 'update:opened', val: boolean): void,
	(e: 'update:modelValue', val: ({ id: number, tagId: number } | TagListItemEntity)[]): void,
	(e: 'add' | 'delete', val:
    | TagListItemEntity[]
    | TagListItemEntity
    | ITagUserModel[]
    | ITagLinkModel[]
    | [number[], TagListItemEntity]
  ): void,
}

export default class SharedTagFlatSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		opened: Boolean,
		hideNew: Boolean,
		isQuickFilter: Boolean,
		type: {
			type: String as PropType<'document' | 'user'>,
			default: 'document',
		},
		placement: {
			type: String,
			default: 'bottom-end',
		},
		modelValue: {
			type: Array as PropType<{ id: number, tagId: number }[]>,
			default: () => [],
		},
		mappedValue: {
			type: Map as PropType<Map<number, { id: number, tagId: number }[]>>,
			default: undefined,
		},
		linkIds: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		linkType: {
			type: Number as unknown as PropType<Undef<TAG_LINK_TYPE>>,
			default: undefined,
		},
	};

	_popover = ref<Dropdown>();

	private emits: Emits;
	private readonly state = reactive({
		popoverOpened: false,
		loading: false,
		search: undefined as Undef<string>,
		tags: [] as TagListItemEntity[],
	});


	get tags () { return this.state.tags as TagListItemEntity[];}
	get popoverOpened () { return this.state.popoverOpened; }

	get search () { return this.state.search;}
	set search (val) { this.state.search = val;}

	get loading () { return this.state.loading;}

	get tagsList () {
		return this.tags
			.filter(x => !this.state.search?.trim().length || x.name?.toLowerCase().includes(this.state.search))
			.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
	}

	get tagsListOptionsForFlatSelect (): FlatSelectOptionItem[] {
		return this.tagsList.map(x => ({
			id: x.id,
			name: x.name ?? '',
			colorName: x.colorName,
		}));
	}

	get distinctTags () {
		return Array.from(this.props.mappedValue?.values() ?? [])
			.flat()
			.mapKey('tagId')
			.distinct();
	}

	get selectedTags () {
		if (this.props.mappedValue) {
			return this.distinctTags.filter((tagId) => {
				return Array.from(this.props.mappedValue?.values() ?? [])
					.filter(x => x.mapKey('tagId').includes(tagId))
					.length === this.props.mappedValue?.size;
			});
		}

		return this.props.modelValue.mapKey('tagId');
	}

	get partialTags () {
		return this.distinctTags.filter((tagId) => {
			const selectedIn = Array.from(this.props.mappedValue?.values() ?? [])
				.filter(x => x.mapKey('tagId').includes(tagId)).length;

			if (this.props.mappedValue)
				return selectedIn > 0 && selectedIn < this.props.mappedValue.size;
		});
	}


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}


	async handlePopoverShowAsync () {
		this.emits('update:opened', true);
		this.state.popoverOpened = true;
		this.state.loading = true;
		this.state.tags = TagListItemEntity.map(await useTagService().orderedListAsync());
		this.state.loading = false;
	}

	handlePopoverHide () {
		this.emits('update:opened', false);
		this.state.popoverOpened = false;
	}

	tagIsSelected (tag: FlatSelectOptionItem) {
		return !!this.selectedTags.find(x => x === tag.id);
	}

	tagIsPartiallySelected (tag: FlatSelectOptionItem) {
		return !!this.partialTags.find(x => x === tag.id);
	}

	async linkAsync (tag: FlatSelectOptionItem) {
		const tagEntity = this.tags.findByKey(tag.id, 'id');
		if (!tagEntity) return;

		if (!this.props.isQuickFilter) {
			const targetIds = this.targetIds(tag);

			if (!targetIds.length) {
				useNotif.warning(`Vous devez sélectionner au moins un élément`);
				return;
			}

			if (this.props.type === 'document') {
				if (!isNil(this.props.linkType)) {
					const newTagLinks = await tagEntity.linkDocumentAsync(this.props.linkType, targetIds);
					if (newTagLinks) {
						this.emits('update:modelValue', [...this.props.modelValue, ...newTagLinks]);
						this.emits('add', newTagLinks);
					}
				}
			} else if (this.props.type === 'user') {
				const newTagUsers = await tagEntity.linkUserAsync(targetIds);
				if (newTagUsers) {
					this.emits('update:modelValue', [...this.props.modelValue, ...newTagUsers]);
					this.emits('add', newTagUsers);
				}
			}
		} else {
			this.emits('update:modelValue', [...this.props.modelValue, tagEntity]);
			this.emits('add', tagEntity);
		}
	}

	async unlinkAsync (tag: FlatSelectOptionItem) {
		const tagEntity = this.tags.findByKey(tag.id, 'id');
		if (!tagEntity) return;

		if (!this.props.isQuickFilter) {
			const tagLinkIds: number[] = [];

			if (this.props.mappedValue) {
				tagLinkIds.push(...Array.from(this.props.mappedValue.values())
					.flat()
					.filter(x => x.tagId === tag.id)
					.mapKey('id'),
				);
			} else {
				const tagLinkIndex = this.selectedTags.findIndex(x => x === tag.id);
				const tagLinkId = this.props.modelValue[tagLinkIndex]?.id;
				if (tagLinkId) tagLinkIds.push(tagLinkId);
			}

			if (!tagLinkIds.length) {
				useNotif.warning(`Vous devez sélectionner au moins un élément`);
				return;
			}

			this.props.type === 'document'
				? await tagEntity.unlinkDocumentAsync(tagLinkIds)
				: await tagEntity.unlinkUserAsync(tagLinkIds);

			this.emits('delete', [tagLinkIds, tagEntity]);
		} else {
			this.emits('delete', tagEntity);
		}

		this.emits('update:modelValue', this.props.modelValue.filter(x => x.tagId !== tag.id));
	}

	show () {
		this._popover.value?.show();
	}

	hide () {
		this._popover.value?.hide();
	}

	async createTagAsync () {
		this.hide();
		try {
			await useTagService().promptNewTagAsideAsync();
			this.state.tags = TagListItemEntity.map(await useTagService().orderedListAsync(true));
		} finally {
			setTimeout(() => {
				this.show();
			}, 600);
		}
	}

	targetIds (tag: FlatSelectOptionItem, toLink = true): number[] {
		if (this.props.mappedValue) {
			if (toLink && !this.tagIsPartiallySelected(tag)) {
				return Array.from(this.props.mappedValue.keys());
			} else {
				return Array.from(this.props.mappedValue)
					// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
					.filter(([id, values]) => !values.mapKey('tagId').includes(tag.id))
					.map(([id]) => id);
			}
		}
		return this.props.linkIds;
	}
}
