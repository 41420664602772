import { AxiosInstance, AxiosRequestConfig } from 'axios';
import AxiosFactory from './configuration/axios.factory';

class ApiClient {
	private route!: string;
	private axios!: AxiosInstance;

	constructor (route: string) {
		this.route = route;
		this.axios = AxiosFactory();
	}

	/**
  * @param {string} path
  * @param {*=} data
  * @param {AxiosRequestConfig} config
  * @memberof ApiClient
  */
	post <T, D = any> (path: string, data?: D, config?: AxiosRequestConfig) {
		return this.axios.post<T>(`${this.route}${this.constructPath(path)}`, data, config);
	}

	/**
  * @param {string} path
  * @param {*=} data
  * @param {AxiosRequestConfig} config
  * @memberof ApiClient
  */
	put <T, D = any> (path: string, data?: D, config?: AxiosRequestConfig) {
		return this.axios.put<T>(`${this.route}${this.constructPath(path)}`, data, config);
	}

	patch <T, D = any> (path: string, data?: D, config?: AxiosRequestConfig) {
		return this.axios.patch<T>(`${this.route}${this.constructPath(path)}`, data, config);
	}

	get <T> (path: string, config?: AxiosRequestConfig) {
		return this.axios.get<T>(`${this.route}${this.constructPath(path)}`, config);
	}

	delete <T> (path: string, config?: AxiosRequestConfig) {
		return this.axios.delete<T>(`${this.route}${this.constructPath(path)}`, config);
	}

	windowOpen (url: string, target: string) {
		// useWindowOpenDocument(url, target);
		window.open(url, target);
	}

	constructPath (path: string) {
		if (!path) return '';

		return '/' + path;
	}
}

export default ApiClient;
