import { nextTick, reactive, watch } from 'vue';
import { AxiosResponse } from 'axios';
import BaseSetupService from '@/setup/BaseSetupService';
import router from '@/router/index.router';
import { Bus } from '@orion.ui/orion';


type Props = SetupProps<typeof BaseAsideEntitySetupService.props>

export default abstract class BaseAsideEntitySetupService<P, T> extends BaseSetupService<Props & P> {
	protected abstract get api (): {
		getDetailed?: (id: number) => Promise<AxiosResponse<T, any>>
		selectById?: (id: number) => Promise<AxiosResponse<T, any>>
		read?: (id: number) => Promise<AxiosResponse<T, any>>
	}

	protected abstract entity: {
		assignData: (dbData: any) => void
		addBusListener?: () => void
		// assignData: (dbData: T) => void
	}

	static readonly props = {
		id: {
			type: Number,
			default: undefined,
		},
	};

	protected routeParamKey = 'id';

	protected readonly state = reactive({
		dataLoaded: false,
		activeTab: 'document',
	});

	get dataLoaded () { return this.state.dataLoaded; }

	get activeTab () { return this.state.activeTab; }
	set activeTab (val) { this.state.activeTab = val; }


	constructor (props?: Props & P) {
		super(props);

		watch(() => this.route.params[this.routeParamKey], (val) => {
			nextTick(() => {
				if (val) this.getDetailsAsync();
				Bus.emit('SharedLayoutSetupService:toggle-left-aside', false);
			});
		});
	}

	protected async onBeforeMountAsync () {
		await this.getDetailsAsync();
		await this.onEntityLoadedAsync();
	}


	protected async getDetailsAsync (id: number = this.props.id ?? +router.currentRoute.value.params[this.routeParamKey]) {
		const apiMethod = this.api.getDetailed ?? this.api.selectById ?? this.api.read;
		if (!apiMethod) throw `Missing 'getDetailed' or 'selectById' method in api`;

		this.state.dataLoaded = false;
		const { data } = await apiMethod(id);
		this.entity.assignData(data);
		this.entity.addBusListener?.();
		this.state.dataLoaded = true;
	}

	protected async onEntityLoadedAsync () {}
}
