import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useTalentPoolService from '@/services/TalentPoolService';
import { useNotif } from '@orion.ui/orion';
import useBusService from '@/services/BusService';

type Props = SetupProps<typeof SharedOrderGroupSelectSetupService.props>

export default class SharedOrderGroupSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		value: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		candidateId: {
			type: Number,
			required: true as const,
		},
	};

	private readonly state = reactive({
		groupOptions: [] as ITalentPoolForProfileModel[],
		groups: [] as ITalentPoolForProfileModel[],
	});

	get groupOptions () { return this.state.groupOptions; }

	get groups () { return this.state.groups; }
	set groups (val) { this.state.groups = val; }

	constructor (props?: Props) {
		super(props);
	}

	protected override async onBeforeMountAsync (): Promise<void> {
		await this.getTalentPoolAsync();
	}

	async getTalentPoolAsync () {
		this.state.groupOptions = await useTalentPoolService().listAsync();
		this.state.groups = this.props.value.map(id => ({
			id,
			label: this.state.groupOptions.findByKey(id, 'id')?.label,
		}));
	}

	async addGroupAsync (talentPool: ITalentPoolForProfileModel) {
		await useTalentPoolService().addTalentAsync(talentPool.id, { userIds: [this.props.candidateId] });

		[this.props.candidateId].forEach((userId) => {
			useBusService().emit(`${userId}:add-talent-pool`, talentPool);
		});

		useNotif.success(`Le candidat a bien été ajouté au vivier ${talentPool.label}`);
	}

	async removeGroupAsync (talentPool: ITalentPoolForProfileModel) {
		await useTalentPoolService().deleteTalentAsync(talentPool.id, this.props.candidateId);

		[this.props.candidateId].forEach((userId) => {
			useBusService().emit(`${userId}:remove-talent-pool`, talentPool);
		});

		useNotif.success(`Le candidat a bien été supprimé du vivier ${talentPool.label}`);
	}
}
