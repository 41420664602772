import { Bus, useUi, useWindow } from '@orion.ui/orion';
import { debounce } from 'lodash-es';
import { reactive, watch } from 'vue';

class UiService {
	private ui = useUi();

	private readonly state = reactive({
		windowSize: {
			width: 0,
			height: 0,
		},
		asideLeftSlideOut: false,
		asideRightSlideOut: false,
	});

	private get ww () { return this.state.windowSize.width; }

	private readonly BPLayoutFull = 1696;
	private readonly BPLayoutNoAside = 1152;
	private readonly BPNavFull = 1392;
	private readonly BPLayoutNavCollapsed = 768;

	get token () {
		return this.ui.token;
	}

	get onLayoutFull () { return this.ww >= this.BPLayoutFull;}
	get onLayoutOneAside () { return this.ww >= this.BPLayoutNoAside && this.ww < this.BPLayoutFull;}
	get onLayoutNoAside () { return this.ww < this.BPLayoutNoAside;}

	get onLayoutNavFull () { return this.ww >= this.BPNavFull;}
	get onLayoutNavCollapsed () { return this.ww > this.BPLayoutNavCollapsed && this.ww < this.BPNavFull;}
	get onLayoutNavBottom () { return this.ww <= this.BPLayoutNavCollapsed;}


	get asideLeftSlideOut () { return this.state.asideLeftSlideOut;}
	set asideLeftSlideOut (val) { this.state.asideLeftSlideOut = val;}

	get asideRightSlideOut () { return this.state.asideRightSlideOut;}
	set asideRightSlideOut (val) { this.state.asideRightSlideOut = val;}


	constructor () {
		this.setWindowSize();

		useWindow()?.addEventListener('resize', debounce(() => {
			this.setWindowSize();
		}, 50));

		watch([
			() => this.onLayoutFull,
			() => this.onLayoutOneAside,
			() => this.onLayoutNoAside,
			() => this.onLayoutNavFull,
			() => this.onLayoutNavCollapsed,
			() => this.onLayoutNavBottom,
		], () => {
			Bus.emit('SharedPagedListActionsSetupService:breakpoint', [
				{
					bp: 'onLayoutFull',
					value: this.onLayoutFull,
				},
				{
					bp: 'onLayoutOneAside',
					value: this.onLayoutOneAside,
				},
				{
					bp: 'onLayoutNoAside',
					value: this.onLayoutNoAside,
				},
				{
					bp: 'onLayoutNavFull',
					value: this.onLayoutNavFull,
				},
				{
					bp: 'onLayoutNavCollapsed',
					value: this.onLayoutNavCollapsed,
				},
				{
					bp: 'onLayoutNavBottom',
					value: this.onLayoutNavBottom,
				},
			].filter(x => !!x.value).map(x => x.bp));

		});
	}


	setWindowSize () {
		this.state.windowSize.width = useWindow()?.innerWidth ?? 0;
		this.state.windowSize.height = useWindow()?.innerHeight ?? 0;
	}

	setAsideLeftSlideOut (val: boolean) {
		this.state.asideLeftSlideOut = val;
	}

	setAsideRightSlideOut (val: boolean) {
		this.state.asideRightSlideOut = val;
	}

	update () {
		this.ui.update();
	}
}

const uiServiceSingleton = new UiService();

export default function useUiService () {
	return uiServiceSingleton;
}
