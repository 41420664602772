import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/clientcontract');

class ClientContractApiAgency {
	static getDetailed (id: number) {
		return client.get<IClientContractOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static grid (filters: IClientContractPagedListForAgencyParameters) {
		return client.post<IPagedList<IClientContractItemForAgencyModel>>('grid', filters);
	}

	static getNotSigned () {
		return client.get<number>('notsigned');
	}

	static getNotSignedLate () {
		return client.get<number>('notsignedLate');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/clientcontract${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/clientcontract${idsQuery}`, '_blank');
	}

	static getProof (id: number) {
		client.windowOpen(`${ARMADO_URL}/app/agency/clientcontract/${id}/proof`, '_blank');
	}
}

export default ClientContractApiAgency;
