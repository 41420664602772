import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { HELP, ONBOARDING } from '@/utils/CONST';

const routesClient = [
	{
		path: '/',
		component: () => import('@/components/shared/SharedLayout.vue'),
		meta: { requiresAuth: true },
		children: [
			// #region Mes documents
			{
				path: 'mes-documents/contrats',
				name: 'ClientContract',
				component: () => import('@/components/contract/ContractPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List | HELP.Signature },
				children: [
					{
						path: ':id',
						name: 'ClientContractDetails',
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
						props: { documentType: DOCUMENT_TYPE.ClientContract },
					},
				],
			},
			{
				path: 'mes-documents/factures',
				name: 'Bill',
				component: () => import('@/components/bill/BillPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'BillDetails',
						component: () => import('@/components/bill/BillAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-documents/regitres-du-personnel',
				name: 'Register',
				component: () => import('@/components/register/RegisterPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-documents/liste-des-travailleurs-handicapes',
				name: 'HandicappedWorkerList',
				component: () => import('@/components/handicapped-worker-list/HandicappedWorkerListPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-documents/documents-mis-a-disposition',
				name: 'ClientProvisionDocument',
				component: () => import('@/components/provision-document/ProvisionDocumentPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mon-profil',
				name: 'UserProfile',
				component: () => import('@/components/user-profile/UserProfileView.vue'),
				children: [
					{
						path: 'informations',
						name: 'UserProfileInformations',
						component: () => import('@/components/user-profile/UserProfileInformations.vue'),
					},
					{
						path: 'entreprises',
						name: 'UserProfileCompanies',
						component: () => import('@/components/user-profile/UserProfileCompanies.vue'),
					},
					{
						path: 'compte',
						name: 'UserProfileAccount',
						component: () => import('@/components/user-profile/UserProfileAccount.vue'),
					},
					{
						path: 'appareils',
						name: 'UserProfileDevices',
						component: () => import('@/components/user-profile/UserProfileDevices.vue'),
					},
				],
			},

			// #endregion

			// #region Mes traîtements
			{
				path: 'mes-traitements/releves-heures/:year',
				name: 'TimeTicketYear',
				component: () => import('@/components/time-ticket/TimeTicketYearPagedListView.vue'),
			},
			{
				path: 'mes-traitements/releves-heures/:year/:weekNumber',
				name: 'TimeTicketWeek',
				component: () => import('@/components/time-ticket/TimeTicketWeekPagedListView.vue'),
			},
			{
				path: 'mes-traitements/prolongations-de-contrats',
				props: true,
				name: 'ContractProlongation',
				component: () => import('@/components/contract-prolongation/ContractProlongationPagedListView.vue'),
				children: [
					{
						path: ':contractId',
						name: 'ContractProlongationDetails',
						props: { documentType: DOCUMENT_TYPE.ClientContract },
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-commandes',
				name: 'Order',
				component: () => import('@/components/order/OrderPagedListView.vue'),
				meta: { onBoarding: ONBOARDING.Order },
			},
			{
				path: 'mes-commandes/:id',
				name: 'OrderDetails',
				component: () => import('@/components/order/details/OrderDetails.vue'),
				meta: { onBoarding: `${ONBOARDING.OrderDetails}Client` },
				redirect: { name: 'OrderDetailsStatus' },
				children: [
					{
						path: 'status',
						name: 'OrderDetailsStatus',
						component: () => import('@/components/order/details/OrderDetailsStatus.vue'),
					},
					{
						path: 'details',
						name: 'OrderDetailsRecap',
						component: () => import('@/components/order/details/OrderDetailsRecap.vue'),
					},
					{
						path: 'contrats',
						name: 'OrderDetailsClientContract',
						component: () => import('@/components/order/details/OrderDetailsClientContracts.vue'),
						children: [
							{
								path: ':contractId',
								name: 'OrderDetailsClientContractDetails',
								component: () => import('@/components/contract/ContractAsideDetails.vue'),
								props: { documentType: DOCUMENT_TYPE.ClientContract },
							},
						],
					},

				],
			},
			// #endregion

			// #region Organization
			{
				path: 'organisation/vision-d-ensemble',
				name: 'OrganizationDashboard',
				component: () => import('@/components/organization/OrganizationDashboard.vue'),
				redirect: { name: 'OrganizationPendingActions' },
				children: [
					{
						path: 'actions-en-attente',
						name: 'OrganizationPendingActions',
						component: () => import('@/components/organization/OrganizationPendingActions.vue'),
					},
					{
						path: 'demandes-de-fusion',
						name: 'OrganizationFusionRequests',
						component: () => import('@/components/organization/OrganizationFusionRequests.vue'),
					},
					{
						path: 'fonctionnement',
						name: 'OrganizationUsage',
						component: () => import('@/components/organization/OrganizationUsage.vue'),
					},
				],
			},
			{
				path: 'organisation/societes',
				name: 'OrganizationCompany',
				component: () => import('@/components/organization-company/OrganizationCompanyPagedListView.vue'),
			},
			{
				path: 'organisation/roles',
				name: 'OrganizationRoles',
				component: () => import('@/components/organization/OrganizationRoles.vue'),
				meta: { helpArmadoPage: HELP.Role },
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationRoleDetails',
						component: () => import('@/components/role/RoleDetails.vue'),
						meta: { helpArmadoPage: HELP.Role },
						props: true,
					},
				],
			},
			{
				path: 'organisation/tags',
				name: 'OrganizationTags',
				component: () => import('@/components/organization/OrganizationTags.vue'),
				meta: { helpArmadoPage: HELP.Tag },
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationTagDetails',
						component: () => import('@/components/tag/TagDetails.vue'),
						meta: { helpArmadoPage: HELP.Tag },
						props: true,
					},
				],
			},
			{
				path: 'organisation/utilisateurs',
				name: 'OrganizationUser',
				component: () => import('@/components/organization-user/OrganizationUserPagedListView.vue'),
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationUserDetails',
						component: () => import('@/components/organization-user/OrganizationUserAsideDetails.vue'),
						props: true,
					},
				],
			},
			{
				path: 'organisation/parametres',
				name: 'Settings',
				component: () => import('@/components/settings/SettingsClient.vue'),
			},
			{
				path: 'organisation/lieux-de-mission',
				name: 'MissionPlace',
				component: () => import('@/components/mission-place/MissionPlacePagedListView.vue'),
				children: [
					{
						path: ':id(\\d+)',
						name: 'MissionPlaceDetails',
						component: () => import('@/components/mission-place/MissionPlaceAsideDetails.vue'),
						props: true,
					},
				],
			},
			// #endregion

			{
				path: 'actualites',
				name: 'News',
				component: () => import('@/components/news/NewsPagedListView.vue'),
			},
		],
	},
] as const;

export default routesClient;
