import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useDigitalSafeService from '@/services/digital-safe/DigitalSafeService';
import { Bus } from '@orion.ui/orion';
import useDigitalSafeTempWorkerService from '@/services/digital-safe/DigitalSafeTempWorkerService';
import useColorService from '@/services/ColorService';

type Props = SetupProps<typeof SharedDigitalSafeArboLabelSetupService.props>

export default class SharedDigitalSafeArboLabelSetupService extends BaseSetupService<Props> {
	static readonly props = {
		isFold: Boolean,
		id: {
			type: Number,
			required: true as const,
		},
		parentId: {
			type: Number,
			default: null,
		},
		color: {
			type: String as PropType<Orion.ColorExtendedAndGreys>,
			default: undefined,
		},
		caption: {
			type: String,
			default: undefined,
		},
	};

	readonly _aside?: OrionAside;

	private readonly state = reactive({
		isFolded: false,
		foldChildren: false,
	});

	get isFolded () { return this.state.isFolded; }
	get foldChildren () { return this.state.foldChildren; }
	get children () { return this.service.getChildrenLabels(this.props.id);}
	get swapColor () { return useColorService().swapColor(this.props.color);}

	get service () {
		return this.self.isAgency() ? useDigitalSafeService() : useDigitalSafeTempWorkerService();
	}

	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;
	}

	protected onMounted () {
		this.state.isFolded = this.props.isFold;
	}

	toggleFold () {
		this.state.isFolded = !this.state.isFolded;

		if (this.self.isTempWorker()) {
			this.service.saveLabelFoldingAsync(this.props.id, this.state.isFolded);
		}
	}

	setActiveLabel () {
		if (this.self.isAgency()) {
			Bus.emit('DigitalSafePagedListViewSetupService:update-active-label', this.props.id);
		} else {
			this.service.activeLabelId = this.props.id;
			if (this._aside) {
				this._aside.close();
			}
		}
	}
}
