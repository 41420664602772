<template>
	<div>
		<o-section>
			<o-password
				v-model="setup.password"
				label="Mot de passe"
				autofocus
				required
				@keydown.enter.stop="setup.validateAsync()"/>
		</o-section>
		<o-section align="right">
			<o-button
				outline
				color="danger"
				@click="setup.cancel()">
				Annuler
			</o-button>
			<o-button
				color="info"
				@click="setup.validateAsync()">
				Valider
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import ModalConfirmPasswordSetupService from '@/setup/modal/ModalConfirmPasswordSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(ModalConfirmPasswordSetupService.props);
const setup = new ModalConfirmPasswordSetupService(props, _modal);
</script>
