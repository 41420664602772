<template>
	<div class="temp-worker-prevent-add-membership-modal">
		<img
			class="temp-worker-prevent-add-membership-modal__img"
			src="@/assets/img/onboarding/locked-feature.svg"
			alt="">

		<h4>Oops, impossible de vous lier</h4>

		<o-alert
			center
			color="info">
			Votre compte est déjà lié à une agence
		</o-alert>

		<br>

		<p>
			Nous ne proposons pas la possibilité<br>
			de se lier à plusieurs comptes agences.<br>
		</p>

		<p><strong>Contactez votre agence pour en savoir plus !</strong></p>

		<o-button
			outline
			color="danger"
			@click="_modal?.close()">
			J'ai compris, fermer
		</o-button>
	</div>
</template>

<script setup lang="ts">
import OnboardingTempWorkerPreventAddMembershipModalSetupService from '@/setup/onboarding/OnboardingTempWorkerPreventAddMembershipModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OnboardingTempWorkerPreventAddMembershipModalSetupService.props);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setup = new OnboardingTempWorkerPreventAddMembershipModalSetupService(props);
</script>

<style scoped lang="less">
@import '@/styles/variables.less';

.temp-worker-prevent-add-membership-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  text-align: center;

  /* @media @tablet {
    text-align: center;
  } */

  h4 {
    margin-bottom: 1.25rem;
  }

  p {
    color: var(--grey-dark);
  }

  &__img {
    padding-top: 1.25rem;
    width: 70%;
    transform: translateX(2rem);
    margin-bottom: 2rem;

    @media @tablet {
      width: 50%;
    }
  }

  .orion-button {
    margin: 2rem 0 0.5rem;
  }
}

</style>
