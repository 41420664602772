import { Bus, useModal, useNotif } from '@orion.ui/orion';
import RoleUserApi from '@/api/RoleUserApi';
import SharedSelectUserList from '@/components/shared/SharedSelectUserList.vue';
import useSelfService from './SelfService';
import router from '@/router/index.router';
import OrganizationApi from '@/api/OrganizationApi';

class RoleUserService {
	private get api () { return RoleUserApi; }
	private get self () { return useSelfService().self; }


	async addAsync (userId: number, organizationRoleId: number) {
		try {
			const { data } = await this.api.add({
				userId,
				organizationRoleId,
			});

			Bus.emit(`OrganizationUserAsideDetailsSetupService:${userId}:addUserRole`, data);
			Bus.emit(`OrganizationUserStickerSetupService:${userId}:addUserRole`, data);

			return data;
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async deleteAsync (roleUserId: number, userId?: number) {
		try {
			const { data } = await this.api.delete(roleUserId);

			if (userId) {
				Bus.emit(`OrganizationUserAsideDetailsSetupService:${userId}:deleteUserRole`, roleUserId);
				Bus.emit(`OrganizationUserStickerSetupService:${userId}:deleteUserRole`, roleUserId);
			}

			return data;
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async promptUserSelectAsync (roleId: number, organizationId = this.self.organization?.id, transferCreator = false) {
		if (!organizationId) {
			router.push({ name: 'UserGroupDashboard' });
			return;
		}

		return new Promise<Undef<IOrganizationUserListItemModel[]>>((resolve) => {
			useModal({
				Nested: SharedSelectUserList,
				NestedProps: {
					title: transferCreator ? `Sélectionnez un utilisateur` : `Sélectionnez des utilisateurs`,
					excludeRoleId: roleId,
					uniq: transferCreator,
					transferCreator,
				},
				size: 'sm',
				customClass: 'user-select-modal-wrapper',
				events: {
					'cancel': () => resolve(undefined),
					'add': async (Modal, payload: IOrganizationUserListItemModel[]) => {
						Modal._loader()?.show(`Enregistrement des rôles...`);

						if (transferCreator && payload?.[0].user) {
							try {
								await OrganizationApi.transferCreator(organizationId, { newCreatorId: payload[0].user.id });
								location.reload();
							} catch (e: any) {
								useNotif.danger(`Oops`, e?.response?.data);
							}
						} else {
							await Promise.all(payload.map(async (orgUser) => {
								if (orgUser.user) {
									try {
										await this.api.add({
											userId: orgUser.user?.id,
											organizationRoleId: roleId,
										});
									} catch (e: any) {
										useNotif.danger(`Oops`, e?.response?.data);
									}
								}
							}));
						}

						Modal._loader()?.hide();
						resolve(payload);
						Modal.close();
					},
				},
			});
		});
	}
}

const roleUserServiceSingleton = new RoleUserService();

export default function useRoleUserService () {
	return roleUserServiceSingleton;
}
