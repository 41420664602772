import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('tempworker/timeticketweek');

class TimeTicketWeekApiTempWorker {

	static list () {
		return client.get<ITimeTicketWeekForTempWorkerModel[]>('');
	}

	static listDetail (from: Date, to: Date) {
		return client.get<ITimeTicketWeekDetailForTempWorkerModel[]>(`detail?from=${from.toPost()}&to=${to.toPost()}`);
	}

	static update (id: number, updateTimeTicketWeekModel: IUpdateTimeTicketWeekModel) {
		return client.put<void>(id.toString(), updateTimeTicketWeekModel);
	}

	static validate (id: number) {
		return client.put<void>(`${id}/validate`);
	}

	static countToFill () {
		return client.get<number>('tofill');
	}

	static detailForContract (tempWorkerContractId: number) {
		return client.get<ITimeTicketWeekTempWorkerContractModel[]>(`tempworkercontract/${tempWorkerContractId}`);
	}

	static updateComment (id: number, tempWorkerComment: IUpdateTimeTicketWeekTempWorkerCommentModel) {
		return client.put<void>(`${id}/comment`, { tempWorkerComment });
	}

	static download (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/tempworker/timeticketweek/file${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/tempworker/timeticketweek/file${idsQuery}`, '_blank');
	}
}

export default TimeTicketWeekApiTempWorker;
