import { PropType, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { agencyAsideNavigation, clientAsideNavigation, tempWorkerAsideNavigation } from '@/utils/asideNavigationItems';

type Props = SetupProps<typeof SharedAsideNavigationSetupService.props>

export type Marker = {
	label: string,
	value: boolean | number
}

export default class SharedAsideNavigationSetupService extends BaseSetupService<Props> {
	static readonly props = {
		page: {
			type: String,
			required: true as const,
		},
		markers: {
			type: Object as PropType<Marker[]>,
			default: undefined,
		},
	};

	private state = reactive({ asideNavigation: [] as Armado.AsideNavigationItemChildren[] });

	get asideNavigation () { return this.state.asideNavigation; }
	constructor (props?: Props) {
		super(props);

		watch(() => this.props.markers, () => {
			this.setMarkers();
		});
	}

	readonly _navAside = ref<OrionNavMain>();

	setAsideNavigation () {
		if (this.self.isAgency()) this.state.asideNavigation = agencyAsideNavigation[this.props.page];
		if (this.self.isClient()) this.state.asideNavigation = clientAsideNavigation[this.props.page];
		if (this.self.isTempWorker()) {
			this.state.asideNavigation = tempWorkerAsideNavigation[this.props.page];
		} else return [];
	};

	protected onMounted () {

		this.setAsideNavigation();
		if (this.props.markers) {
			this.setMarkers();
		}
	}

	setMarkers () {
		this.props.markers?.forEach((marker) => {
			this.asideNavigation.forEach((item) => {
				if (marker.label === item.label) {
					item.marker = marker.value;
				}
			});
		});
	}
}
