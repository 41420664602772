import { reactive, ref, watch } from 'vue'; import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedGoogleAutoCompleteSetupService.props>

type Emits = {
  (e: 'google-found', value: any): void,
}

export default class SharedGoogleAutoCompleteSetupService extends BaseSetupService<Props> {
	static readonly props = {
		modelValue: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: 'Adresse',
		},
	};

	private emits: Emits;

	private readonly state = reactive({
		address: undefined as Undef<google.maps.places.AutocompletePrediction>,
		addressToReturn: {
			latitude: undefined,
			longitude: undefined,
			address: undefined,
			postalCode: undefined,
			city: undefined,
			country: undefined,
		} as GoogleAutoCompleteFoundPayload,
	});

	readonly _address = ref<OrionSelect>();

	get address () { return this.state.address; }
	set address (val) { this.state.address = val; }

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	handleSelect ($event: google.maps.places.AutocompletePrediction) {
		if ($event.description !== this.props.modelValue && $event) {
			this.state.addressToReturn.address = $event.structured_formatting.main_text;
			this.state.addressToReturn.country = $event.terms.last()?.value;
			this.getAllAddressInformations($event.place_id);
		}
	}

	async getGooglePredictionsAsync () {
		return new Promise((resolve) => {
			const service = new google.maps.places.AutocompleteService();
			service.getPlacePredictions({ input: this._address.value?.getSearchTerm() ?? '' }, (predictions, status) => {

				if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
					resolve([]);
				}
				resolve(predictions);
			});
		});
	}

	getAllAddressInformations (placeId: string) {
		if (!placeId) return;

		const request = {
			placeId,
			fields: ['geometry', 'adr_address', 'address_components'],
		};

		this.state.addressToReturn.postalCode = undefined;
		const map = document.getElementById('map') as HTMLDivElement;
		if (!map) return;
		const service = new window.google.maps.places.PlacesService(map);
		service.getDetails(request, (place, status) => {
			if (status !== window.google.maps.places.PlacesServiceStatus.OK || !place?.adr_address) return;
			this.parseAddress(place.adr_address);
			this.state.addressToReturn.latitude = place.geometry?.location?.toJSON().lat
				? Math.round(place.geometry.location.toJSON().lat * 1000000)/1000000
				: undefined;
			this.state.addressToReturn.longitude = place.geometry?.location?.toJSON().lng
				? Math.round(place.geometry.location.toJSON().lng * 1000000)/1000000
				: undefined;
			this.state.addressToReturn.countryCode = place.address_components?.find(x => x.types.includes('country'))?.short_name;
			this.emits('google-found', this.state.addressToReturn);
		});
	}

	parseAddress (value: string) {
		const addressSplitted = value.split(`${'<span class="'}`);
		for (let i=0; i < addressSplitted?.length; i++) {
			const line = addressSplitted[i];
			const key = line.split(`${'">'}`).first();
			const value = line.split(`${'">'}`)[1];

			if (key === 'locality')
				this.state.addressToReturn.city = value.split(`${'</span>'}`).first();

			if (key === 'postal-code')
				this.state.addressToReturn.postalCode = value.split(`${'</span>'}`).first();
		}
	}
}
