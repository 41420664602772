/* eslint-disable max-len */
import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import BillApiAgency from '@/api/BillApiAgency';
import BillApiClient from '@/api/BillApiClient';
import CertificateApiAgency from '@/api/CertificateApiAgency';
import CertificateApiTempWorker from '@/api/CertificateApiTempWorker';
import ClientContractApiAgency from '@/api/ClientContractApiAgency';
import ClientContractApiClient from '@/api/ClientContractApiClient';
import ClientProvisionDocumentApiAgency from '@/api/ClientProvisionDocumentApiAgency';
import ClientProvisionDocumentApiClient from '@/api/ClientProvisionDocumentApiClient';
import TempWorkerContractApiAgency from '@/api/TempWorkerContractApiAgency';
import TempWorkerContractApiTempWorker from '@/api/TempWorkerContractApiTempWorker';
import RegisterApiAgency from '@/api/RegisterApiAgency';
import RegisterApiClient from '@/api/RegisterApiClient';
import HandicappedWorkerListApiClient from '@/api/HandicappedWorkerListApiClient';
import HandicappedWorkerListApiAgency from '@/api/HandicappedWorkerListApiAgency';
import TempWorkerProvisionDocumentApiAgency from '@/api/TempWorkerProvisionDocumentApiAgency';
import TempWorkerProvisionDocumentApiTempWorker from '@/api/TempWorkerProvisionDocumentApiTempWorker';
import SalarySlipApiTempWorker from '@/api/SalarySlipApiTempWorker';
import SalarySlipApiAgency from '@/api/SalarySlipApiAgency';
import WithholdingTaxAttestationApiAgency from '@/api/WithholdingTaxAttestationApiAgency';
import WithholdingTaxAttestationApiTempWorker from '@/api/WithholdingTaxAttestationApiTempWorker';
import TripAttestationApiAgency from '@/api/TripAttestationApiAgency';
import TripAttestationApiTempWorker from '@/api/TripAttestationApiTempWork';
import PoleEmploiAttestationApiAgency from '@/api/PoleEmploiAttestationApiAgency';
import PoleEmploiAttestationApiTempWorker from '@/api/PoleEmploiAttestationApiTempWorker';
import ContractProlongationApiClient from '@/api/ContractProlongationApiClient';
import ContractProlongationApiAgency from '@/api/ContractProlongationApiAgency';
import TimeTicketWeekApiAgency from '@/api/TimeTicketWeekApiAgency';
import TimeTicketWeekApiClient from '@/api/TimeTicketWeekApiClient';
import TimeTicketWeekApiTempWorker from '@/api/TimeTicketWeekApiTempWorker';


type Apis =
| BillApiAgency
| BillApiClient
| CertificateApiAgency
| CertificateApiTempWorker
| ClientContractApiAgency
| ClientContractApiClient
| TempWorkerContractApiAgency
| TempWorkerContractApiTempWorker
| ClientProvisionDocumentApiAgency
| ClientProvisionDocumentApiClient
| RegisterApiAgency
| RegisterApiClient
| HandicappedWorkerListApiAgency
| HandicappedWorkerListApiClient
| ClientProvisionDocumentApiAgency
| ClientProvisionDocumentApiAgency
| TempWorkerProvisionDocumentApiAgency
| TempWorkerProvisionDocumentApiTempWorker
| SalarySlipApiTempWorker
| SalarySlipApiAgency
| CertificateApiAgency
| CertificateApiTempWorker
| WithholdingTaxAttestationApiAgency
| WithholdingTaxAttestationApiTempWorker
| PoleEmploiAttestationApiAgency
| PoleEmploiAttestationApiTempWorker
| ContractProlongationApiAgency
| ContractProlongationApiClient

export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.Bill): typeof BillApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.ClientContract): typeof ClientContractApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientProvisionDocumentApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.Register): typeof RegisterApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.HandicappedWorkerList): typeof HandicappedWorkerListApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientProvisionDocumentApiClient;
export function getApi (userType: USER_TYPE.Client, documentType: DOCUMENT_TYPE.ContractProlongation): typeof ContractProlongationApiClient;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.Bill): typeof BillApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.ClientContract): typeof ClientContractApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientProvisionDocumentApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.TempWorkerContract): typeof TempWorkerContractApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.Register): typeof RegisterApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.HandicappedWorkerList): typeof HandicappedWorkerListApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientProvisionDocumentApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.TempWorkerProvisionDocument): typeof TempWorkerProvisionDocumentApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.SalarySlip): typeof SalarySlipApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.Certificate): typeof CertificateApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.WithholdingTaxAttestation): typeof WithholdingTaxAttestationApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.TripAttestation): typeof TripAttestationApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.PoleEmploiAttestation): typeof PoleEmploiAttestationApiAgency;
export function getApi (userType: USER_TYPE.Agency, documentType: DOCUMENT_TYPE.ContractProlongation): typeof ContractProlongationApiAgency;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.TempWorkerContract): typeof TempWorkerContractApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.SalarySlip): typeof SalarySlipApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.Certificate): typeof CertificateApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.WithholdingTaxAttestation): typeof WithholdingTaxAttestationApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.TripAttestation): typeof TripAttestationApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.PoleEmploiAttestation): typeof PoleEmploiAttestationApiTempWorker;
export function getApi (userType: USER_TYPE.TempWorker, documentType: DOCUMENT_TYPE.TempWorkerProvisionDocument): typeof TempWorkerProvisionDocumentApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.Bill): typeof BillApiClient | typeof BillApiAgency;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.ClientContract): typeof ClientContractApiClient | typeof ClientContractApiAgency;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientProvisionDocumentApiClient | typeof ClientProvisionDocumentApiAgency;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.TempWorkerContract): typeof TempWorkerContractApiAgency | typeof TempWorkerContractApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.Register): typeof RegisterApiAgency | typeof RegisterApiClient;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.HandicappedWorkerList): typeof HandicappedWorkerListApiAgency | typeof HandicappedWorkerListApiClient;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.ClientProvisionDocument): typeof ClientContractApiAgency | typeof ClientContractApiClient;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.TempWorkerProvisionDocument): typeof TempWorkerProvisionDocumentApiAgency | typeof TempWorkerProvisionDocumentApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.SalarySlip): typeof SalarySlipApiAgency | typeof SalarySlipApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.Certificate): typeof CertificateApiAgency | typeof CertificateApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.WithholdingTaxAttestation): typeof WithholdingTaxAttestationApiAgency | typeof WithholdingTaxAttestationApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.TripAttestation): typeof TripAttestationApiAgency | typeof TripAttestationApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE.PoleEmploiAttestation): typeof PoleEmploiAttestationApiAgency | typeof PoleEmploiAttestationApiTempWorker;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE): Apis;
export function getApi (userType: USER_TYPE, documentType: DOCUMENT_TYPE): Apis | undefined {
	if (userType === USER_TYPE.Client) {
		switch (documentType) {
		case DOCUMENT_TYPE.ClientContract:
			return ClientContractApiClient;
		case DOCUMENT_TYPE.ClientProvisionDocument:
			return ClientProvisionDocumentApiClient;
		case DOCUMENT_TYPE.Bill:
			return BillApiClient;
		case DOCUMENT_TYPE.Register:
			return RegisterApiClient;
		case DOCUMENT_TYPE.HandicappedWorkerList:
			return HandicappedWorkerListApiClient;
		case DOCUMENT_TYPE.ClientProvisionDocument:
			return ClientProvisionDocumentApiClient;
		case DOCUMENT_TYPE.ContractProlongation:
			return ContractProlongationApiClient;
		case DOCUMENT_TYPE.TimeTicket:
			return TimeTicketWeekApiClient;
		}
	} else if (userType === USER_TYPE.Agency) {
		switch (documentType) {
		case DOCUMENT_TYPE.ClientContract:
			return ClientContractApiAgency;
		case DOCUMENT_TYPE.ClientProvisionDocument:
			return ClientProvisionDocumentApiAgency;
		case DOCUMENT_TYPE.Bill:
			return BillApiAgency;
		case DOCUMENT_TYPE.TempWorkerContract:
			return TempWorkerContractApiAgency;
		case DOCUMENT_TYPE.Register:
			return RegisterApiAgency;
		case DOCUMENT_TYPE.HandicappedWorkerList:
			return HandicappedWorkerListApiAgency;
		case DOCUMENT_TYPE.ClientProvisionDocument:
			return ClientProvisionDocumentApiAgency;
		case DOCUMENT_TYPE.TempWorkerProvisionDocument:
			return TempWorkerProvisionDocumentApiAgency;
		case DOCUMENT_TYPE.SalarySlip:
			return SalarySlipApiAgency;
		case DOCUMENT_TYPE.Certificate:
			return CertificateApiAgency;
		case DOCUMENT_TYPE.WithholdingTaxAttestation:
			return WithholdingTaxAttestationApiAgency;
		case DOCUMENT_TYPE.TripAttestation:
			return TripAttestationApiAgency;
		case DOCUMENT_TYPE.PoleEmploiAttestation:
			return PoleEmploiAttestationApiAgency;
		case DOCUMENT_TYPE.ContractProlongation:
			return ContractProlongationApiAgency;
		case DOCUMENT_TYPE.TimeTicket:
			return TimeTicketWeekApiAgency;
		}
	} else if (userType === USER_TYPE.TempWorker) {
		switch (documentType) {
		case DOCUMENT_TYPE.TempWorkerContract:
			return TempWorkerContractApiTempWorker;
		case DOCUMENT_TYPE.TempWorkerProvisionDocument:
			return TempWorkerProvisionDocumentApiTempWorker;
		case DOCUMENT_TYPE.SalarySlip:
			return SalarySlipApiTempWorker;
		case DOCUMENT_TYPE.Certificate:
			return CertificateApiTempWorker;
		case DOCUMENT_TYPE.WithholdingTaxAttestation:
			return WithholdingTaxAttestationApiTempWorker;
		case DOCUMENT_TYPE.TripAttestation:
			return TripAttestationApiTempWorker;
		case DOCUMENT_TYPE.PoleEmploiAttestation:
			return PoleEmploiAttestationApiTempWorker;
		case DOCUMENT_TYPE.TimeTicket:
			return TimeTicketWeekApiTempWorker;
		}
	}
}
