import { App, Plugin } from 'vue';
import { setupDevtools } from './devtool';

const ArmadoVuePlugin: Plugin = {
	install (app: App<any>) {
		setupDevtools(app);
	},
};

export default ArmadoVuePlugin;
