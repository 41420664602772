import CompanyApi from '@/api/CompanyApi';
import { useModal } from '@orion.ui/orion';
import CompanyLogoApiAgency from '@/api/CompanyLogoApiAgency';
import SharedLogoCompanyModal from '@/components/shared/SharedLogoCompanyModal.vue';
import type OrganizationCompanyListItemEntity from '@/entity/organization-company/OrganizationCompanyListItemEntity';
import useUiService from './UiService';

class CompanyService {
	private companyEntity?: OrganizationCompanyListItemEntity;

	constructor (companyEntity?: OrganizationCompanyListItemEntity) {
		this.companyEntity = companyEntity;
	}

	async promptLogoModalAsync (companyId: number, companyLogoId?: number) {
		useModal({
			Nested: SharedLogoCompanyModal,
			NestedProps: {
				companyId,
				companyLogoId,
				companyEntity: this.companyEntity,
			},
			size: 'sm',
		});
	}

	async addLogoAsync (...payload: Parameters<typeof CompanyLogoApiAgency.add>) {
		const { data } = await CompanyLogoApiAgency.add(...payload);
		if (this.companyEntity) this.companyEntity.setLogo(data);
		useUiService().update();
	}

	async updateLogoAsync (...payload: Parameters<typeof CompanyLogoApiAgency.update>) {
		await CompanyLogoApiAgency.update(...payload);
		useUiService().update();
	}

	async deleteLogoAsync (logoId: number) {
		await CompanyLogoApiAgency.delete(logoId);
		if (this.companyEntity) this.companyEntity.deleteLogo();
		useUiService().update();
	}

	async getDetailedAsync (...args: Parameters<typeof CompanyApi.getDetailed>) {
		const { data } = await CompanyApi.getDetailed(...args);
		return data;
	}

	async getAsync (...args: Parameters<typeof CompanyApi.get>) {
		const { data } = await CompanyApi.get(...args);
		return data;
	}
}

export default function useCompanyService (companyEntity?: OrganizationCompanyListItemEntity) {
	return new CompanyService(companyEntity);
}
