import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('tempworker/tempworkercontract');

class TempWorkerContractApiTempWorker {
	static getDetailed (id: number) {
		return client.get<ITempWorkerContractOverPageForTempWorkerModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static grid (filters: ITempWorkerContractPagedListForTempWorkerParameters) {
		return client.post<IPagedList<ITempWorkerContractItemForTempWorkerModel>>('grid', filters);
	}

	static getUpcomingMissions (fromDate: string) {
		return client.get<IUpcomingTempWorkerContractModel[]>(`upcoming?fromDate=${fromDate}`);
	}

	static getNotSigned () {
		return client.get<number[]>('notsigned');
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tempworkercontract${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tempworkercontract${idsQuery}`, '_blank');
	}

	static getProof (id: number) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tempworkercontract/${id}/proof`, '_blank');
	}

	static getSiriusProcedure (ids: number[]) {
		return client.get<string>(`signature${`?ids=${ids.join('&ids=')}`}`);
	}
}

export default TempWorkerContractApiTempWorker;
