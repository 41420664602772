import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedSettingCardSetupService.props>

export default class SharedSettingCardSetupService extends BaseSetupService<Props> {
	static readonly props = {
		title: {
			type: String,
			default: null,
		},
		fontIcon: {
			type: String,
			default: null,
		},
	};

	constructor (props?: Props) {
		super(props);
	}
}
