<template>
	<v-dropdown
		:ref="setup._popover"
		:placement="placement"
		@apply-show="setup.handlePopoverShowAsync()"
		@apply-hide="setup.handlePopoverHide()">
		<slot>
			<o-icon
				ripple="info"
				font-icon="icon-interface-bookmark-tag"
				@click.stop/>
		</slot>

		<template #popper>
			<shared-flat-select
				v-model:search="setup.search"
				:show-search="setup.tags.length > 10"
				:loading="setup.loading"
				:value="setup.selectedTags"
				:partial="setup.partialTags"
				:options="setup.tagsListOptionsForFlatSelect"
				:selected-function="setup.tagIsSelected.bind(setup)"
				:partial-function="setup.tagIsPartiallySelected.bind(setup)"
				:new-label="hideNew ? undefined : 'Nouveau tag'"
				:popover-opened="setup.popoverOpened"
				@add="setup.linkAsync($event)"
				@delete="setup.unlinkAsync($event)"
				@new="setup.createTagAsync()">
				<template #default="{ item: tag }">
					<div class="tag-option swap-colors">
						<div
							class="tag-color"
							:class="`background--${tag.colorName}`"/>
						<span v-html="tag.name.mark(setup.search)"/>
					</div>
				</template>

				<template #empty>
					<o-alert
						color="info"
						center>
						Vous n'avez pas encore de tags.
					</o-alert>
				</template>
			</shared-flat-select>
		</template>
	</v-dropdown>
</template>

<script setup lang="ts">
import TagListItemEntity from '@/entity/TagListItemEntity';
import SharedTagFlatSelectSetupService from '@/setup/shared/SharedTagFlatSelectSetupService';
type Emits = {
	(e: 'update:opened', value: boolean): void,
	(e: 'update:modelValue', val: ({ id: number, tagId: number } | TagListItemEntity)[]): void,
	(e: 'add' | 'delete', val: TagListItemEntity[] | TagListItemEntity | ITagUserModel[] | ITagLinkModel[] | [number[], TagListItemEntity]): void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedTagFlatSelectSetupService.props);
const setup = new SharedTagFlatSelectSetupService(props, emits);
</script>

<style scoped lang="less">
.tag-color {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
}

.tag-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.v-popper {
	display: flex;
	align-items: center;
}
</style>
