<template>
	<o-section id="role-aside-new__step-1">
		<h4 class="role-aside-new__title">
			<span class="text--brand">
				Étape 1
			</span> - Renseignez le nom et la couleur
		</h4>
		<div class="role-aside-new__custom-infos">
			<o-input
				:ref="setup._title"
				v-model="role.name"
				autofocus
				required
				class="role-aside-new__role-title"
				label="Nom du rôle"/>
			<div class="roles__custom-color-section">
				<shared-select-color v-model="role.color"/>
			</div>
		</div>
	</o-section>
	<o-section align="center">
		<o-button
			outline
			color="danger"
			@click="setup.cancel()">
			Annuler
		</o-button>
		<o-button
			outline
			suffix-font-icon="icon-interface-arrows-button-right"
			@click="setup.verifyAndGoNext()">
			Suivant
		</o-button>
	</o-section>
</template>

<script setup lang="ts">
import RoleAsideNewStep1SetupService from '@/setup/role/RoleAsideNewStep1SetupService';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
type Emits = { (e: 'next'): void }
const emits = defineEmits<Emits>();
const props = defineProps(RoleAsideNewStep1SetupService.props);
const setup = new RoleAsideNewStep1SetupService(props, emits, _aside);
</script>


<style lang="less">
.role-aside-new {
	&__title {
		text-align: center;
		margin: 0.6rem 0 3.1rem;
	}

	&__role-title {
		margin-bottom: 1.3rem;
	}
}
</style>
