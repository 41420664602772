import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/label');

class LabelApiAgency {
	static list () {
		return client.get<ILabelForCompanyModel[]>('');
	}
}

export default LabelApiAgency;
