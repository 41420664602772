<template>
	<div v-if="document.document">
		<shared-app-aside-item
			title="Date de dépôt le">
			{{ document.document.createdDate.toReadableDate('$DD/$MM/$YYYY à $hhh$mm') }}
		</shared-app-aside-item>
		<shared-app-aside-item
			v-if="document.document.viewedDetailDate"
			title="Détail consulté le">
			{{ document.document.viewedDetailDate.toReadableDate('$DD/$MM/$YYYY à $hhh$mm') }}
		</shared-app-aside-item>
		<shared-app-aside-item
			v-if="document.document.viewedDate"
			title="Visualisé le">
			{{ document.document.viewedDate.toReadableDate('$DD/$MM/$YYYY à $hhh$mm') }}
		</shared-app-aside-item>
		<shared-app-aside-item
			v-if="document.document.downloadedDate"
			title="Téléchargé le">
			{{ document.document.downloadedDate.toReadableDate('$DD/$MM/$YYYY à $hhh$mm') }}
		</shared-app-aside-item>
		<shared-app-aside-item
			v-if="useTimeStamp && setup.isDocumentWithSignature()"
			title="Horodaté le">
			{{ setup.props.document.document.agencySignatureDate
				? setup.props.document.document.agencySignatureDate.toReadableDate('$DD/$MM/$YYYY à $hhh$mm')
				: `Horodatage en cours` }}
		</shared-app-aside-item>
		<slot/>
	</div>
</template>

<script setup lang="ts">
import SharedDetailsDocumentHistorySetupService from '@/setup/shared/SharedDetailsDocumentHistorySetupService';
const props = defineProps(SharedDetailsDocumentHistorySetupService.props);
const setup = new SharedDetailsDocumentHistorySetupService(props);
</script>
