<template>
	<v-dropdown
		class="shared-add-button-dropdown"
		placement="bottom">
		<o-button
			class="shared-add-button"
			color="default"
			prefix-icon="add_plus"
			outline/>

		<template #popper>
			<slot/>
		</template>
	</v-dropdown>
</template>

<script setup lang="ts">
import SharedAddButtonSetupService from '@/setup/shared/SharedAddButtonSetupService';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setup = new SharedAddButtonSetupService();
</script>

<style lang="less" scoped>
@import '@/styles/mixins';

.shared-add-button {
	&-dropdown {
		width: fit-content;
		height: fit-content;
	}

	.square(3.4rem);
		border: 0.06rem dashed var(--grey);
		position: relative;
		border-radius: 0.2rem;
		background: transparent;
		overflow: hidden;

		:deep(> .orion-button__icon) {
			color: var(--grey-dark);
			font-size: 1.5rem;
		}

		&:hover {
			background-color: rgba(var(--rgb-info), 0.1);
			border: 0.06rem dashed var(--info);

			:deep(> .orion-button__icon) {
			color: var(--info);
		}
	}
}
</style>
