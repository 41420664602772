import useColorService from '@/services/ColorService';
import BaseEntity from './BaseEntity';
import useTagLinkService from '@/services/tag/TagLinkService';
import useTagUserService from '@/services/tag/TagUserService';

export default class TagListItemEntity extends BaseEntity<ITagListItemModel> {
	get name () { return this.state.name; }
	get color () { return this.state.color; }
	get colorName () { return useColorService().swapColor(this.state.color?.name); }


	async linkDocumentAsync (type: TAG_LINK_TYPE, linkIds: number[]) {
		return await useTagLinkService().addAsync(this.id, type, linkIds);
	}

	async unlinkDocumentAsync (ids: number[]) {
		return await useTagLinkService().deleteAsync(ids);
	}

	async linkUserAsync (userIds: number[], organizationId = this.self.organization?.id) {
		return await useTagUserService().addAsync(this.id, userIds, organizationId);
	}

	async unlinkUserAsync (ids: number[]) {
		return await useTagUserService().deleteAsync(ids);
	}
}
