import ColorApi from '@/api/ColorApi';

class ColorService {
	private colors: IColorModel[] = [];

	async listAsync (forceRefresh = false) {
		if (!this.colors.length || forceRefresh) {
			const { data: colors } = await ColorApi.list();
			this.colors = colors;
		}
		return this.colors.map(x => this.cleanColorName(x));
	}

	swapColor (color?: string) {
		return color?.replace('-light', '-alt').replace('-grad', '-alt') as Orion.ColorExtended;
	}

	cleanColorName (color: IColorModel) {
		return {
			...color,
			name: this.swapColor(color.name),
		};
	}

	cleanRoleColorName (role: IOrganizationRoleListItemModel) {
		return {
			...role,
			colorName: this.swapColor(role.colorName),
		};
	}

	cleanTagColorName (tag: ITagListItemModel) {
		return {
			...tag,
			color: {
				...tag.color,
				name: this.swapColor(tag.color?.name),
			},
		} as ITagListItemModel;
	}
}

const colorServiceSingleton = new ColorService();

export default function useColorService () {
	return colorServiceSingleton;
}
