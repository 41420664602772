import { } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof OnboardingTempWorkerPreventAddMembershipModalSetupService.props>

export default class OnboardingTempWorkerPreventAddMembershipModalSetupService extends BaseSetupService<Props> {
	static readonly props = {};

	constructor (props?: Props) {
		super(props);
		localStorage.removeItem('agency-company-code');
	}
}
