<template>
	<div class="shared-toggle">
		<div
			v-for="(item, index) in setup.options"
			:key="index"
			class="shared-toggle__toggle-item"
			:class="[
				{ 'shared-toggle__toggle-item--active' : item.label === setup.modelValue.label },
				{ 'shared-toggle__toggle-item--with-counter' : item.counter !== undefined },
			]"
			@click="setup.modelValue = item">
			{{ item.label }}
			<div
				v-if="item.counter !== undefined"
				class="shared-toggle__toggle-item-counter">
				{{ item.counter }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedToggleSetupService, { SharedToggleItemModel } from '@/setup/shared/SharedToggleSetupService';
type Emits = {(e: 'update:modelValue', val: SharedToggleItemModel): void}
const emits = defineEmits<Emits>();
const props = defineProps(SharedToggleSetupService.props);
const setup = new SharedToggleSetupService(props, emits);
</script>

<style lang="less" scoped>
.shared-toggle {
	display: flex;
	align-items: center;
	padding: 0.5rem;
	background-color: rgba(var(--rgb-grey-light), 0.5);
	border-radius: 1.5rem;


	&__toggle-item {
		cursor: pointer;
		padding: 0.25rem 1rem;
		border-radius: 1rem;
		font-weight: 600;
		color: var(--grey-dark);
		display: flex;
		gap: 0.25rem;

		&--with-counter {
			padding-right: 0.25rem;
		}

		&--active {
			background: var(--info);
			color: white;
			box-shadow:
				0 0.125rem 0.125rem rgba(0, 0, 0, 0.03),
				0 0.25rem 0.25rem rgba(0, 0, 0, 0.03),
				0 0.5rem 0.5rem rgba(0, 0, 0, 0.03),
				0 1rem 1rem rgba(0, 0, 0, 0.03),
				0 2rem 2rem rgba(0, 0, 0, 0.03);

		.shared-toggle__toggle-item-counter {
			background-color: rgba(255, 255, 255, 0.1);
		}
		}

	}
	&__toggle-item-counter {
		border-radius: 0.75rem;
		padding: 0 0.5rem;
		background-color: rgba(0, 0, 0, 0.05);
	}
}
</style>
