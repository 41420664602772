import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('client/register');

class RegisterApiClient {

	static async grid (filter: IRegisterPagedListForClientParameters) {
		return client.post<IPagedList<IRegisterItemForClientModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/register${idsQuery}&isDownload=true&name=liste-travailleurs-handicapes`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/register${idsQuery}`, '_blank');
	}
}

export default RegisterApiClient;
