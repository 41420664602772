import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'tempworker/tripattestation';
const client = new ApiClient(ROUTE);

class TripAttestationApiTempWorker {
	static grid (filter: ITripAttestationPagedListForTempWorkerParameters) {
		return client.post<IPagedList<ITripAttestationItemForTempWorkerModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tripattestation${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tripattestation${idsQuery}`, '_blank');
	}

	static count () {
		return client.get<number>('count');
	}
}

export default TripAttestationApiTempWorker;
