import ApiClient from '@/api/ApiClient';

const client = new ApiClient('roleuser');

class RoleUserApi {

	static add (addRoleUserModel: IAddRoleUserModel) {
		return client.post<IRoleUserDetailModel>(``, addRoleUserModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

}

export default RoleUserApi;
