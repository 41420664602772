<template>
	<o-section
		title="Création d'un nouveau tag"
		subtitle="Renseignez le nom et la couleur">
		<div class="flex fd-c g-xs">
			<o-input
				v-model.lazy="setup.tag.name"
				label="Nom du tag"
				:validation="setup.validator.rule('name')"
				validation-error-message="Vous devez spécifier un nom"
				autofocus/>
			<o-textarea
				v-model="setup.tag.description"
				:max-length="300"
				label="Description"/>
			<shared-select-color
				v-model="setup.tag.color"
				class="mv-sm"/>
			<o-toggle
				v-model="setup.tag.shareToAgency"
				:label="setup.self.isAgency()
					? `Autoriser l'accès depuis les APIs`
					: `Autoriser l'accès pour mes agences`
				"/>
		</div>
	</o-section>

	<o-section align="stretch">
		<o-button
			outline
			color="danger"
			@click="setup.cancel()">
			Annuler
		</o-button>
		<o-button
			color="brand"
			prefix-icon="check"
			@click="setup.save()">
			Sauvegarder
		</o-button>
	</o-section>
</template>

<script setup lang="ts">
import TagAsideNewSetupService from '@/setup/tag/TagAsideNewSetupService';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(TagAsideNewSetupService.props);
const setup = new TagAsideNewSetupService(props, _aside);
</script>
