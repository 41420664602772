<template>
	<o-select
		v-if="setup.dataLoaded"
		v-model="setup.missionPlaceId"
		v-bind="$attrs"
		:label="label"
		:options="setup.options"
		:searchable="setup.options.length > 10"
		track-key="id"
		value-key="id"
		display-key="name">
		<template #option="{ item, markedSearch }">
			<div class="list__option">
				<div><strong v-html="markedSearch(item.name)"/></div>
				<div>{{ item.address }}</div>
				<div>{{ item.postalCode }} {{ item.city }}</div>
			</div>
		</template>
	</o-select>
</template>

<script setup lang="ts">
import SharedMissionPlaceSelectSetupService from '@/setup/shared/SharedMissionPlaceSelectSetupService';
type Emits = {
	(e: 'input', val: number): void,
	(e: 'update:missionPlace', val: IMissionPlaceDropDownListItemModel | undefined) : void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedMissionPlaceSelectSetupService.props);
const setup = new SharedMissionPlaceSelectSetupService(props, emits);
</script>
