export enum DOCUMENT_TYPE {
	None = -1,
	ClientContract = 1,
	TempWorkerContract = 2,
	Bill = 3,
	Register = 4,
	TimeTicket = 5,
	FollowedMission = 6,
	Divers = 7,
	Certificate = 8,
	SalarySlip = 9,
	Cgv = 10,
	Ctt = 11,
	Condition = 15,
	Prefacturation = 16,
	Proof = 17,
	Discussion = 18,
	ContractProlongation = 19,
	WithholdingTaxAttestation = 20,
	HandicappedWorkerList = 21,
	TripAttestation = 22,
	PoleEmploiAttestation = 23,
	TempWorkerProvisionDocument = 24,
	ClientProvisionDocument = 25,
	BillTimeStamp = 26,
	AgencyDigitalSafeDocument = 27,
	Order = 28,
	AgencyDigitalSafeDocumentTimeStamp = 29,
}
