import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useRolePermissionsService from '@/services/RolePermissionService';
import { useLocalStorage, useNotif } from '@orion.ui/orion';

type Props = SetupProps<typeof PermissionListSetupService.props>
type Emits = { (e: 'input', val: IRolePermissionModel): void }


export default class PermissionListSetupService extends BaseSetupService<Props> {
	static readonly props = {
		togglable: Boolean,
		hideGlobalToggle: Boolean,
		hideActiveRightsToggle: Boolean,
		roleId: {
			type: Number,
			default: undefined,
		},
		rolesPermissions: {
			type: Array as PropType<Armado.RolePermission.PermissionListItem[]>,
			default: () => [],
		},
	};

	private emit!: Emits;
	private readonly state = reactive({
		showDescriptions: true,
		onlyActiveRights: false,
		toggleAllRights: false,
		visible: true,
		loading: false,
		permissionSearch: '',
	});

	get visible () { return this.state.visible; }
	get loading () { return this.state.loading; }

	get descriptionLabel () {
		return this.ui.onLayoutNavBottom
			? 'descriptions'
			: 'Afficher les descriptions';
	}

	get rightsLabel () {
		return this.ui.onLayoutNavBottom
			? 'droits actifs'
			: 'Afficher uniquement les droits actifs';
	}

	get permissionsList () {
		const permissionsList = useRolePermissionsService().list
			.map(rp => ({
				...rp,
				children: rp.children.filter(x => this.props.rolesPermissions
					.filter(x => this.props.hideActiveRightsToggle || (this.state.onlyActiveRights ? x.value : x))
					.map(x => x.action)
					.includes(x.action)),
			}))
			.filter(rp => !!rp.children.length);

		if ((this.state.permissionSearch?.trim()?.length ?? 0) < 3) {
			return permissionsList;
		}

		return permissionsList
			.filter(rp => !!this.state.permissionSearch?.trim()?.length
				&& rp.children
					.map(x => `${x.label} ${x.description}`.toLowerCase())
					.filter(x => x.includes(this.state.permissionSearch?.toLowerCase())).length,
			)
			.map(rp => ({
				...rp,
				children: rp.children.filter(
					x => `${x.label} ${x.description}`.toLowerCase().includes(this.state.permissionSearch?.toLowerCase()),
				),
			}));
	}

	get showDescriptions () { return this.state.showDescriptions; }
	set showDescriptions (val) {
		this.state.showDescriptions = val;
		useLocalStorage()?.setItem('showRolesPermissionsDescriptions', `${val}`);
	}

	get onlyActiveRights () { return this.state.onlyActiveRights; }
	set onlyActiveRights (val) {
		this.state.onlyActiveRights = val;
		useLocalStorage()?.setItem('showOnlyActiveRolesPermissionsRights', `${val}`);
	}

	get toggleAllRights () { return this.state.toggleAllRights; }
	set toggleAllRights (val) { this.state.toggleAllRights = val; }

	get permissionSearch () { return this.state.permissionSearch; }
	set permissionSearch (val) { this.state.permissionSearch = val; }


	constructor (props: Props, emit: Emits) {
		super(props);
		this.emit = emit;
	}

	protected onMounted () {
		this.state.showDescriptions = (useLocalStorage()?.getItem('showRolesPermissionsDescriptions') ?? 'true') === 'true';
		this.state.onlyActiveRights = useLocalStorage()?.getItem('showOnlyActiveRolesPermissionsRights') === 'true';
	}


	permissionIsActive (permission: IRolePermissionModel | Armado.RolePermission.PermissionListChildren) {
		return !!this.props.rolesPermissions.filter(rp => rp.action === permission.action && rp.active && rp.value).length;
	}

	permissionSectionIsActive (section: Armado.RolePermission.ServicePermission) {
		return !this.props.rolesPermissions.length || !!section.children.filter(p => this.permissionIsActive(p)).length;
	}

	permissionsForItem (permissionType: PERMISSION_ACTION) {
		return this.props.rolesPermissions.filter(rp => Number(rp.action) === Number(permissionType));
	}

	updateActiveRights (payload: boolean) {
		this.props.rolesPermissions.forEach(p => p.value = payload);
	}

	async handlePermissionDependenciesAsync (payload: { value: boolean, action: PERMISSION_ACTION }) {
		const flatPermissions = useRolePermissionsService().list.map(x => x.children).flat();
		const permissionDep = flatPermissions.find(x => x.action === payload.action);

		const permission = this.props.rolesPermissions.findByKey(payload.action, 'action');
		if (permission) permission.value = payload.value;

		if (payload.value && permissionDep?.onActivate) {
			if (permissionDep.onActivate.activate) {
				for await (const rp of permissionDep.onActivate.activate) {
					const linkedPermission = flatPermissions.find(x => x.action === rp);
					const targetRolePermission = this.props.rolesPermissions.find(x => x.action === linkedPermission?.action);

					if (targetRolePermission && !targetRolePermission.value) {
						targetRolePermission.value = true;

						if (!!targetRolePermission.id) {
							await useRolePermissionsService().updateAsync(
								targetRolePermission.id,
								targetRolePermission,
							);
						}

						this.emit('input', targetRolePermission);

						useNotif.info({
							title: `Permission liée activée`,
							message: `La permission <strong>${linkedPermission?.label}</strong> a également été activée.`,
							duration: 10,
						});

						await this.handlePermissionDependenciesAsync(targetRolePermission);
					}
				}
			}
		} else if (!payload.value && permissionDep?.onDeactivate) {
			if (permissionDep?.onDeactivate.deactivate) {
				for await (const rp of permissionDep.onDeactivate.deactivate) {
					const linkedPermission = flatPermissions.find(x => x.action === rp);
					const targetRolePermission = this.props.rolesPermissions.find(x => x.action === linkedPermission?.action);

					if (targetRolePermission && targetRolePermission.value) {
						targetRolePermission.value = false;

						if (!!targetRolePermission.id) {
							await useRolePermissionsService().updateAsync(
								targetRolePermission.id,
								targetRolePermission,
							);
						}

						this.emit('input', targetRolePermission);

						useNotif.info({
							title: `Permission liée désactivée`,
							message: `La permission <strong>${linkedPermission?.label}</strong> a également été désactivée.`,
							duration: 10,
						});

						await this.handlePermissionDependenciesAsync(targetRolePermission);
					}
				}
			}
		}
	}
}
