<template>
	<div class="shared-paged-list-order-header">
		<div
			class="shared-paged-list-order-header__highlighter"
			:class="{
				'shared-paged-list-order-header__highlighter--animate': setup.highlighterAnimate,
				'shared-paged-list-order-header__highlighter--show': setup.highlighterShow,
			}"
			:style="{
				left: `${setup.highlighterLeft}px`,
				width: `${setup.highlighterWidth}px`,
			}"/>

		<div class="row row--grid-xs">
			<div
				v-for="(order, i) in orders"
				:key="i"
				class="shared-paged-list-order-header__cell"
				:class="order.class || `col`">
				<o-button
					v-if="order.key"
					nude
					block
					:disabled="order.clickable === false"
					:class="{ active: setup.activeValue.property === order.key }"
					:color="setup.getOrderStatus(order.key).color"
					:suffix-icon="order.clickable !== false ? setup.getOrderStatus(order.key).icon as Orion.Icon : undefined"
					@click="setup.toggleOrder(order)"
					@mouseenter="setup.emitOverOrder(order.key, $event)"
					@mouseleave="setup.emitOutOrder(order.key)">
					{{ order.label }}
				</o-button>
			</div>
			<o-checkbox
				v-if="!hideSelection && !setup.isTouch"
				v-tooltip.left="`${!setup.allSelected ? `Tout sélectionner` : `Désélectionner`} `"
				:model-value="setup.checkboxChecked"
				:icon-check="!setup.allSelected && setup.checkboxChecked ? 'remove_minus' : undefined"
				class="shared-paged-list-order-header__checkbox"
				@update:model-value="$emit('selectAll')"/>
		</div>

		<slot/>
	</div>
</template>

<script setup lang="ts">
import SharedPagedListOrderHeaderSetupService from '@/setup/shared/SharedPagedListOrderHeaderSetupService';
type Emits = {
	(e: 'update:modelValue', value: OrderValue): void,
	(e: 'refreshList'): void,
	(e: 'selectAll'): void,
}
type OrderValue = {
	property: string
	ascending: boolean
}
const props = defineProps(SharedPagedListOrderHeaderSetupService.props);
const emits = defineEmits<Emits>();
const setup = new SharedPagedListOrderHeaderSetupService(props, emits);
</script>

<style lang="less" scoped>

.shared-paged-list-order-header {
  flex: 1 0 100%;
  padding: 0 1rem;

	.orion-checkbox{
		:deep(i) {
			font-size:1rem;
		}
	}

  &__cell {
    > .orion-button {
      justify-content: space-between;
      padding-left: 1rem;
      padding-right: 1rem;

      &:hover:not(.active) {
        background: rgba(var(--rgb-grey), 0.2);
      }
    }
  }

  &__highlighter {
    position: fixed;
    top: 0;
    height: 100vh;
    z-index: 9;
    pointer-events: none;
    opacity: 0;
    transform: scale3d(0, 1, 1);
    transform-origin: center;
    background: linear-gradient(
      rgba(var(--rgb-info), 0) 2rem,
      rgba(var(--rgb-info), 0.1),
    );

    &--show {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }

    &--animate {
      transition: opacity 0.3s, transform 0.3s, left 0.3s ease-out, width 0.3s;
    }
  }

	&__checkbox {
		position: absolute;
		right: -1.5rem;
	}
}
</style>
