<template>
	<o-nav-main
		:ref="setup._navAside"
		:key="setup.asideNavigation.length"
		class="arm-nav-menu shared-aside-navigation"
		:items="setup.asideNavigation">
		<template #default="{ item }">
			<div
				class="arm-nav-menu__item"
				:class="{
					'arm-nav-menu__item--parent': item.children?.length || setup.self.isTempWorker(),
					'arm-nav-menu__item--section-title': item.sectionTitle,
				}">
				{{ item.label }}
				<span
					v-if="item.marker"
					class="marker"
					:class="{ 'marker__number': typeof item.marker === 'number' }">{{ typeof item.marker === 'number' ? item.marker : null }}</span>
			</div>
		</template>
	</o-nav-main>
</template>

<script setup lang="ts">
import SharedAsideNavigationSetupService from '@/setup/shared/SharedAsideNavigationSetupService';
const props = defineProps(SharedAsideNavigationSetupService.props);
const setup = new SharedAsideNavigationSetupService(props);
</script>

<style scoped lang="less">
@import '@/styles/mixins';

.shared-aside-navigation {
	.arm-nav-menu__item {
		position: relative;
	}
}
.marker {
	position: absolute;
	.circle(0.5rem);
	background-color: var(--danger);
	color: white;
	font-size: 0.75rem;
	top: -0.25rem;
	right: -1rem;

	&__number {
		display: flex;
		.circle(1rem);
		top: -0.5rem;
		right: -1.5rem;
		justify-content: center;
	}
}
</style>
