<template>
	<div class="shared-foldable">
		<div
			class="shared-foldable__header"
			@click="setup.isOpen = !setup.isOpen">
			<div class="shared-foldable__toggle">
				<div
					v-if="icon || fontIcon"
					class="shared-foldable__icon">
					<o-icon
						v-if="icon || fontIcon"
						:icon="icon"
						:font-icon="fontIcon"/>
				</div>
				<o-icon
					class="shared-foldable__chevron"
					:icon="setup.isOpen ? 'chevron_down' : 'chevron_right'"/>
			</div>
			<slot
				name="header"
				v-bind="{ isOpen: setup.isOpen }"/>
		</div>

		<div
			v-if="setup.isOpen"
			class="shared-foldable__content">
			<slot v-bind="{ isOpen: setup.isOpen }"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedFoldableSetupService, { Emits } from '@/setup/shared/SharedFoldableSetupService';
const emits = defineEmits<Emits>();
const props = defineProps(SharedFoldableSetupService.props);
const setup = new SharedFoldableSetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/mixins.less';
@import '@/styles/variables.less';

.shared-foldable {
  // background: transparent;
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    cursor: pointer;

    &::before {
      content: '';
      transition: 0.3s background-color;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: -0.5rem;
      z-index: -1;
      border-radius: 0.5rem;
    }

    /* &:hover {
      background-color:rgba(var(--rgb-grey-darker), 0.05);
    } */

    &:hover::before {
      background-color: rgba(var(--rgb-grey-darker), 0.05);
    }

    > :not(.shared-foldable__toggle) {
      flex: 1;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    --color: v-bind('setup.rgbColor');
    .square(2.5rem);
    .flex-align();
    color: rgb(var(--color));
    border-radius: 0.25rem;
    background-color: white;
    box-shadow: 0 2px 4px fade(black, 5);

    .orion-icon {
      font-size: 1.25rem;
    }
  }

  &__chevron {
    font-size: 1.5rem;
  }

  &__content {
    margin-top: 1rem;
  }
}
</style>
