import ApiClient from '@/api/ApiClient';

const client = new ApiClient('organizationuser');

class OrganizationUserApi {

	static grid (pagedListParameters: IOrganizationUserPagedListParameters) {
		return client.post<IPagedList<IOrganizationUserListItemModel>>(`grid`, pagedListParameters);
	}

	static selectById (id: number) {
		return client.get<IOrganizationUserDetailModel>(`${id}`);
	}

	static add (addOrganizationUserModel: IAddOrganizationUserModel) {
		return client.post<IOrganizationUserDetailModel>(``, addOrganizationUserModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static selfDetail () {
		return client.get<IOrganizationUserDetailModel>(`self`);
	}
}

export default OrganizationUserApi;
