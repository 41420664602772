<template>
	<div class="company-code-modal">
		<div class="company-code-modal__select">
			<shared-select-user-company
				v-model="setup.agencyCompanyId"
				v-model:selected="setup.selectedAgency"
				organization-only
				:clearable="false"
				@vue:mounted="setup.onSelectMounted()"/>
		</div>
		<div class="company-code-modal__qrcode-section">
			<div class="company-code-modal__main">
				<!-- <div class="company-code-modal__select">
					<select-company
						v-model="agencyCompanyId"
						:clearable="false"/>
				</div> -->
				<div class="company-code-modal__qrcode-section-circle">
					<div class="company-code-modal__qrcode-section-qrcode">
						<o-card :class="{ 'company-code-modal__qr-skeleton': !setup.code }">
							<div
								v-if="setup.code"
								v-html="setup.code.svg"/>
						</o-card>
					</div>
				</div>

				<h4>
					Faites scanner le QR code <br>
					de votre agence<br>
					{{ setup.selectedAgency?.name }}
				</h4>

				<p>En scannant le QR code, l'intérimaire <br> sera lié à votre compte agence.</p>
			</div>

			<div class="company-code-modal__action">
				<o-button
					class="company-code-modal__actions"
					color="brand"
					@click="setup.downloadCode()">
					Téléchargez le code
				</o-button>
			</div>
		</div>

		<div class="company-code-modal__separator">
			<div class="company-code-modal__separator-text">OU</div>
		</div>

		<div class="company-code-modal__code-section">
			<div class="company-code-modal__main">
				<h4>Partagez le code à 4 caractères <br> de votre agence {{ setup.selectedAgency?.name }}</h4>
				<p>
					Partagez votre code à 4 caractères par <br>
					mail ou SMS à votre intérimaire
				</p>

				<div class="company-code-modal__code-container">
					<o-otp
						v-if="setup.code && setup.agencyCompanyId"
						:value="setup.code.code"
						readonly/>
					<div
						v-else
						class="company-code-modal__code-skeleton">
						<div class="company-code-modal__code-unit-skeleton"/>
						<div class="company-code-modal__code-unit-skeleton"/>
						<div class="company-code-modal__code-unit-skeleton"/>
						<div class="company-code-modal__code-unit-skeleton"/>
					</div>
				</div>

				<div class="company-code-modal__share-section">
					<p><strong>Choisissez votre méthode de partage :</strong></p>
					<div class="company-code-modal__share-options">
						<o-icon
							v-tooltip="`Partager par SMS`"
							class="company-code-modal__share-option"
							font-icon="icon-phone-mobile-phone"
							:class="{ 'company-code-modal__share-option--selected': setup.selectedShareMethod === 'phone' }"
							@click="setup.selectedShareMethod = 'phone'"/>
						<o-icon
							v-tooltip="`Partager par email`"
							class="company-code-modal__share-option"
							font-icon="icon-mail-send-email-envelope"
							:class="{ 'company-code-modal__share-option--selected': setup.selectedShareMethod === 'email' }"
							@click="setup.selectedShareMethod = 'email'"/>
						<o-icon
							v-tooltip="`Copier dans le presse-papier`"
							class="company-code-modal__share-option"
							font-icon="icon-interface-file-clipboard-text"
							@click="setup.copyCodeToClipboard()"/>
					</div>
					<o-phone
						v-if="setup.selectedShareMethod === 'phone'"
						v-model="setup.phone"
						label="Numéro de téléphone portable"
						required/>
					<o-input
						v-else-if="setup.selectedShareMethod === 'email'"
						v-model="setup.email"
						type="email"
						prefix-font-icon="icon-mail-inbox-envelope-open"
						label="Adresse e-mail"
						required
						@keydown.enter.self="setup.share()"/>
					<o-alert
						v-else-if="setup.selectedShareMethod === 'copy'"
						color="info"
						center>
						Code copié dans votre presse-papier
					</o-alert>
				</div>
			</div>

			<div class="company-code-modal__action">
				<o-button
					:disabled="!setup.isValid"
					:outline="!setup.isValid"
					class="company-code-modal__actions"
					color="brand"
					@click="setup.share()">
					Partager le code
				</o-button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import OnboardingCompanyCodeModalSetupService from '@/setup/onboarding/OnboardingCompanyCodeModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OnboardingCompanyCodeModalSetupService.props);
const setup = new OnboardingCompanyCodeModalSetupService(props, _modal);
</script>

<style scoped lang="less">
@import '@/styles/variables.less';

.company-code-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;

  @media @tablet {
    justify-content: center;
    flex-direction: row;
  }

  h3 {
    font-size: 1.1rem;
    color: var(--grey-darker);
    font-weight: 700;
    margin-bottom: 2.5rem;
    text-align: center;
  }

  h4 {
    font-size: 1.1rem;
    color: var(--grey-darker);
    font-weight: 700;
    margin-bottom: 2rem;
    line-height: 1.5rem;
    text-align: center;
  }

  p {
    color: var(--grey-dark);
    font-size: 0.85rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__select {
    padding: 1.25rem 2.5rem 1.25rem;
    z-index: 3;
    min-width: 300px;
    background:white;
    border-top: unset;
    border-radius:0 0 0.25rem 0.25rem;

    @media @tablet {
      position: absolute;
      top: -1.25rem;
    }
  }

  &__qrcode-section, &__code-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 0 3rem;

    @media @tablet {
      padding: 6rem 0 4rem;
      height: 720px;
    }
  }

  &__qrcode-section {
    &-circle {
      height: 220px;
      width: 220px;
      border-radius: 50%;
      background-color: rgba(var(--rgb-brand), 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      .orion-card {
        height: 140px;
        width: 140px;
      }
    }
  }

  &__code-section {
    @media @tablet {
      padding-top: 110px;
    }
  }

  &__separator {
    position: relative;
    font-size: 1.85rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      height: 1px;
      width: 100%;
      background: var(--grey-light);
      position: absolute;
      z-index: 0;

      @media @tablet {
        height: 100%;
        width: 1px;
      }
    }

    &-text {
      position: relative;
      height: 70px;
      width: 50px;
      text-align: center;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
  }

  &__code-container {
    padding: 1.25rem;
    margin-bottom: 2.5rem;
  }

  &__code-skeleton {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  &__code-unit-skeleton {
    position: relative;
    width: 3rem;
    height: 3rem;
    border-radius: 0.25rem;
    background: var(--grey-light);
    overflow: hidden;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        fade(white, 0) 0,// rgba(#fff, 0) 0,
        fade(white, 20) 20%,// rgba(#fff, 0.2) 20%,
        fade(white, 50) 60%,// rgba(#fff, 0.5) 60%,
        fade(white, 0)// rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
    }
  }

  &__share-section {
    width: 280px;
    margin-bottom: 2.5rem;

    p {
      margin-bottom: 0.75rem;
    }
  }

  &__share-options {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1.25rem;
  }

  &__share-option {
    font-size: 1.5rem;
    padding: 0.5rem;
    background: var(--grey-lighter);
    border: 1px solid transparent;
    border-radius: 50%;
    color: var(--grey-dark);
    transition: all .2s;
    cursor: pointer;

    &:hover:not(.company-code-modal__share-option--selected){
      border-color: var(--grey);
    }

    &--selected {
      background-color: rgba(var(--rgb-info), 0.1);
      border-color: var(--info);
      color: var(--info);
    }
  }

  &__qr-skeleton {
    background: var(--grey-light);
    overflow:hidden;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        fade(white, 0) 0,// rgba(#fff, 0) 0,
        fade(white, 20) 20%,// rgba(#fff, 0.2) 20%,
        fade(white, 50) 60%,// rgba(#fff, 0.5) 60%,
        fade(white, 0)// rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
