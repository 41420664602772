<template>
	<div class="tag-dots-expandable">
		<o-chips
			v-for="(tag, index) in setup.formattedTags"
			:key="index"
			class="tag-dots-expandable__dot"
			size="sm"
			:color="tag.color">
			<span class="tag-dots-expandable__name">
				{{ tag.name }}
			</span>
		</o-chips>
	</div>
</template>

<script setup lang="ts">
import SharedTagDotsExpandableSetupService from '@/setup/shared/SharedTagDotsExpandableSetupService';
const props = defineProps(SharedTagDotsExpandableSetupService.props);
const setup = new SharedTagDotsExpandableSetupService(props);
</script>
