import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('client/clientcontract');

class ClientContractApiClient {
	static getDetailed (id: number) {
		return client.get<IClientContractOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static validate (id: number) {
		return client.put<void>(`${id}/validate`);
	}

	static cancelValidation (id: number) {
		return client.put<void>(`${id}/cancelValidation`);
	}

	static grid (filters: IClientContractPagedListForClientParameters) {
		return client.post<IPagedList<IClientContractItemForClientModel>>('grid', filters);
	}

	static getNotSigned () {
		return client.get<number>('notsigned');
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}

	static listInProgress () {
		return client.get<IClientContractItemForClientModel[]>('listInProgress');
	}

	static listForConfirm (ids: number[]) {
		return client.get<IClientContractForConfirmAsideModel[]>(`confirmSignature${ids && ids.length ? `?ids=${ids.join('&ids=')}` : null}`);
	}

	static sign (ids: number[]) {
		return client.get<void>(`signature${`?ids=${ids.join('&ids=')}`}`);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/clientcontract${idsQuery}&isDownload=true`, '_blank');
	}

	static getProof (id: number) {
		client.windowOpen(`${ARMADO_URL}/app/client/clientcontract/${id}/proof`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/clientcontract${idsQuery}`, '_blank');
	}

	static countByMonth () {
		return client.get<IClientContractByMonthGroupedModel[]>('countByMonth');
	}

	static countByMissionPlaceId (missionPlaceId: number) {
		return client.get<number>(`countByMissionPlace/${missionPlaceId}`);
	}
}

export default ClientContractApiClient;
