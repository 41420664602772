import { PropType } from 'vue';
import { Reactive } from '@/utils/decorators';
import BaseSetupService from '@/setup/BaseSetupService';
import useQualificationService from '@/services/QualificationService';

type Props = SetupProps<typeof SharedSelectAgencyQualificationsSetupService.props>;
export type Emits = {(e: 'update:modelValue', ids?: number[]): void}

export default class SharedSelectAgencyQualificationsSetupService extends BaseSetupService<Props> {
	static readonly props = {
		modelValue: {
			type: Array as PropType<number[]>,
			required: true as const,
		},
		agencyCompanyId: {
			type: Number,
			default: undefined,
		},
	};

	@Reactive private readonly state = { agencyQualificationsOptions: [] as IQualificationForListModel[] };

	get agencyQualificationsOptions () {
		const res = this.props.agencyCompanyId
			? this.state.agencyQualificationsOptions.filter(x => x.agencyCompanyId === this.props.agencyCompanyId)
			: this.state.agencyQualificationsOptions;

		return res.sort((a, b) => a.label!.localeCompare(b.label!));
	}

	get agencyQualificationId () { return this.props.modelValue; }
	set agencyQualificationId (val) { this.emits('update:modelValue', val); }

	constructor (props: Props, private emits: Emits) {
		super(props);
	}

	protected async onBeforeMountAsync (): Promise<void> {
		this.state.agencyQualificationsOptions = await useQualificationService().listAsync();
	}
}
