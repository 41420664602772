import { PropType, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { useNotif } from '@orion.ui/orion';
import useDigitalSafeTempWorkerService from '@/services/digital-safe/DigitalSafeTempWorkerService';
import DigitalSafeTempWorkerDocumentEntity from '@/entity/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentEntity';

type Props = SetupProps<typeof DigitalSafeTempWorkerDocumentLabelsManagerSetupService.props>

export default class DigitalSafeTempWorkerDocumentLabelsManagerSetupService extends BaseSetupService<Props> {
	static readonly props = {
		applyNewLabelTo: {
			type: Object as PropType<DigitalSafeTempWorkerDocumentEntity>,
			default: undefined,
		},
	};

	digitalSafeTempWorkerService = useDigitalSafeTempWorkerService();

	private readonly state = reactive({
		shouldReloadDocs: true,
		showNewLabel: false,
		newLabel: {
			caption: undefined,
			color: 'grey' as Orion.ColorExtendedAndGreys,
			parentId: undefined,
		},
		userLabelsWithLevels: this.digitalSafeTempWorkerService.userLabelsWithLevels,
		colorOptions: [],
	});

	readonly _aside?: OrionAside;
	readonly _newLabelCaption = ref<OrionInput>();

	get shouldReloadDocs () { return this.state.shouldReloadDocs; }
	get colorOptions () { return this.state.colorOptions;}
	get userLabelsWithLevels () { return this.state.userLabelsWithLevels;}

	get showNewLabel () { return this.state.showNewLabel; }
	set showNewLabel (val) { this.state.showNewLabel = val; }

	get newLabel () { return this.state.newLabel; }
	set newLabel (val) { this.state.newLabel = val; }


	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;

		watch(() => this.digitalSafeTempWorkerService.userLabelsWithLevels, (val) => {
			this.state.userLabelsWithLevels = val;
		});
	}

	protected onMounted () {
		this._aside?.bus.on('leave-start', () => {
			if (this.shouldReloadDocs) {
				this._aside?.bus.emit('refreshList');
			}
		});
	}

	async createLabelAsync () {
		const label = await this.digitalSafeTempWorkerService.createLabelDigitalSafeDocumentAsync(this.state.newLabel);

		if (this.props.applyNewLabelTo) {
			await this.digitalSafeTempWorkerService.addLabelToDigitalSafeDocumentAsync(
				this.props.applyNewLabelTo.id,
				label.id,
			);
		}

		useNotif.success(`Votre nouveau label a bien été créé`);

		if (this.props.applyNewLabelTo) {
			this.router.push({
				name: 'DigitalSafeDetails',
				params: { id: this.props.applyNewLabelTo.id },
			});
		}

		this._aside?.close();
	}

	showNewLabelCreationForm () {
		this.state.showNewLabel = true;
		setTimeout(() => this._newLabelCaption.value?.focus(), 500);
	}
}
