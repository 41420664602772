<template>
	<div
		:ref="setup._el"
		class="shared-magnetic-blob"
		:style="{ transform: `scaleX(${side === 'left' ? '-1' : '1'})` }">
		<slot>
			<svg
				:ref="setup._v"
				width="100%"
				height="100%"
				viewBox="0 0 100 100"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink"
				preserveAspectRatio="none">

				<path
					d="M0,0 C0,40, 0,40 0,50 S 0,60 0,100"
					@click="setup.handlePathClick()"/>
			</svg>
		</slot>

		<slot
			name="icon"
			v-bind="{ x: setup.x, y: setup.y }"/>
	</div>
</template>

<script setup lang="ts">
import SharedMagneticBlobSetupService, { Emits } from '@/setup/shared/SharedMagneticBlobSetupService';
const emits = defineEmits<Emits>();
const props = defineProps(SharedMagneticBlobSetupService.props);
const setup = new SharedMagneticBlobSetupService(props, emits);
</script>

<style scoped lang="less">
.shared-magnetic-blob {
  > svg > path {
    cursor: pointer;
    fill: var(--brand);
  }
}
</style>
