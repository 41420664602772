export async function delayAsync (ms: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
};

export async function delayRequestAsync<TResult> (callBackPromise: Promise<TResult>, ms: number) {
	return new Promise<TResult>(async (resolve, reject) => {
		const delayPromise = delayAsync(ms);
		const results = await Promise.allSettled([delayPromise, callBackPromise]);
		if (results[1].status === 'rejected') {
			reject(results[1].reason);
			return;
		}
		resolve(results[1].value);
	});
};
