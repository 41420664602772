import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { HELP, ONBOARDING } from '@/utils/CONST';

const routesAgency = [
	{
		path: '/',
		component: () => import('@/components/shared/SharedLayout.vue'),
		meta: { requiresAuth: true },
		children: [
			{
				path: 'archives',
				name: 'DigitalSafe',
				component: () => import('@/components/digital-safe/DigitalSafePagedListView.vue'),
			},

			// #region User profile
			{
				path: 'mon-profil',
				name: 'UserProfile',
				component: () => import('@/components/user-profile/UserProfileView.vue'),
				children: [
					{
						path: 'informations',
						name: 'UserProfileInformations',
						component: () => import('@/components/user-profile/UserProfileInformations.vue'),
					},
					{
						path: 'agences',
						name: 'UserProfileCompanies',
						component: () => import('@/components/user-profile/UserProfileCompanies.vue'),
					},
					{
						path: 'compte',
						name: 'UserProfileAccount',
						component: () => import('@/components/user-profile/UserProfileAccount.vue'),
					},
					{
						path: 'appareils',
						name: 'UserProfileDevices',
						component: () => import('@/components/user-profile/UserProfileDevices.vue'),
					},
				],
			},
			// #endregion

			// #region Mes clients
			{
				path: 'mes-clients',
				name: 'User',
				component: () => import('@/components/user-client/UserClientPagedListView.vue'),
				//meta: { navSectionSlug: 'mes-clients' },
				children: [
					{
						path: ':id(\\d+)',
						name: 'UserDetails',
						component: () => import('@/components/organization-user/OrganizationUserAsideDetails.vue'),
						meta: { helpArmadoPage: HELP.Tag },
						props: true,
					},
				],
			},
			{
				path: 'mes-clients/documents/contrats',
				name: 'ClientContract',
				component: () => import('@/components/contract/ContractPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'ClientContractDetails',
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
						props: { documentType: DOCUMENT_TYPE.ClientContract },
					},
				],
			},
			{
				path: 'mes-clients/documents/factures',
				name: 'Bill',
				component: () => import('@/components/bill/BillPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'BillDetails',
						component: () => import('@/components/bill/BillAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-clients/documents/regitres-du-personnel',
				name: 'Register',
				component: () => import('@/components/register/RegisterPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-clients/documents/liste-des-travailleurs-handicapes',
				name: 'HandicappedWorkerList',
				component: () => import('@/components/handicapped-worker-list/HandicappedWorkerListPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-clients/documents/documents-mis-a-disposition',
				name: 'ClientProvisionDocument',
				component: () => import('@/components/provision-document/ProvisionDocumentPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-clients/traitements/releves-heures/:year',
				name: 'TimeTicketYear',
				component: () => import('@/components/time-ticket/TimeTicketYearPagedListView.vue'),
			},
			{
				path: 'mes-clients/traitements/releves-heures/:year/:weekNumber',
				name: 'TimeTicketWeek',
				component: () => import('@/components/time-ticket/TimeTicketWeekPagedListView.vue'),
			},
			{
				path: 'mes-clients/traitements/prolongations-de-contrats',
				name: 'ContractProlongation',
				component: () => import('@/components/contract-prolongation/ContractProlongationPagedListView.vue'),
				children: [
					{
						path: ':contractId',
						name: 'ContractProlongationDetails',
						props: { documentType: DOCUMENT_TYPE.ClientContract },
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
					},
				],
			},
			// #endregion

			// #region Mes intérimaires
			{
				path: 'mes-interimaires/interimaires',
				name: 'TempWorker',
				component: () => import('@/components/temp-worker/TempWorkerPagedListView.vue'),
				children: [
					{
						path: ':id(\\d+)',
						name: 'TempWorkerDetails',
						props: true,
						component: () => import('@/components/temp-worker/TempWorkerAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-interimaires/documents/contrats',
				name: 'TempWorkerContract',
				component: () => import('@/components/contract/ContractPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'TempWorkerContractDetails',
						component: () => import('@/components/contract/ContractAsideDetails.vue'),
						props: { documentType: DOCUMENT_TYPE.TempWorkerContract },
					},
				],
			},
			{
				path: 'mes-interimaires/documents/bulletins',
				name: 'SalarySlip',
				component: () => import('@/components/salary-slip/SalarySlipPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'SalarySlipDetails',
						component: () => import('@/components/salary-slip/SalarySlipAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-interimaires/documents/certificats-de-travail',
				name: 'Certificate',
				component: () => import('@/components/certificate/CertificatePagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
				children: [
					{
						path: ':id',
						name: 'CertificateDetails',
						component: () => import('@/components/certificate/CertificateAsideDetails.vue'),
					},
				],
			},
			{
				path: 'mes-interimaires/documents/attestations-declaration-impot',
				name: 'WithholdingTaxAttestation',
				component: () => import('@/components/withholding-tax-attestation/WithholdingTaxAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-interimaires/documents/justificatifs-deplacement-professionnel',
				name: 'TripAttestation',
				component: () => import('@/components/trip-attestation/TripAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-interimaires/documents/attestations-pole-emploi',
				name: 'PoleEmploiAttestation',
				component: () => import('@/components/pole-emploi-attestation/PoleEmploiAttestationPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-interimaires/documents/documents-mis-a-disposition',
				name: 'TempWorkerProvisionDocument',
				component: () => import('@/components/provision-document/ProvisionDocumentPagedListView.vue'),
				meta: { helpArmadoPage: HELP.List },
			},
			{
				path: 'mes-interimaires/traitements/demandes-acompte',
				name: 'AdvancedPaymentDemand',
				component: () => import('@/components/advanced-payment-demand/AdvancedPaymentDemandPagedListView.vue'),
				children: [
					{
						path: ':id',
						name: 'AdvancedPaymentDemandDetails',
						component: () => import('@/components/advanced-payment-demand/AdvancedPaymentDemandAsideDetails.vue'),
					},
				],
			},
			// #endregion

			// #region Mes commandes
			{
				path: 'tableau-de-bord-commandes',
				name: 'OrderDashboard',
				redirect: () => ({ name: 'OrderDashboardOverView' }),
				component: () => import('@/components/order/dashboard/OrderDashboardView.vue'),
				meta: { onBoarding: ONBOARDING.Order },
				children: [
					{
						path: 'tableau-de-bord',
						name: 'OrderDashboardOverView',
						component: () => import('@/components/order/dashboard/OrderDashboardOverView.vue'),
					},
					{
						path: 'gestion-interimaires',
						name: 'OrderDashboardGestionTempWorker',
						component: () => import('@/components/order/dashboard/OrderDashboardGestionTempWorker.vue'),
					},
					{
						path: 'statistiques',
						name: 'OrderDashboardStats',
						component: () => import('@/components/order/dashboard/OrderDashboardStats.vue'),
					},
					{
						path: 'parametre-agences',
						name: 'OrderDashboardAgencyParams',
						component: () => import('@/components/unlock-module/order/UnlockModuleOrderList.vue'),
					},
				],
			},
			{
				path: 'mes-commandes',
				name: 'Order',
				component: () => import('@/components/order/OrderPagedListView.vue'),
				meta: { onBoarding: ONBOARDING.Order },
			},
			{
				path: 'mes-commandes/:id',
				name: 'OrderDetails',
				component: () => import('@/components/order/details/OrderDetails.vue'),
				meta: { onBoarding: `${ONBOARDING.OrderDetails}Agency` },
				redirect: () => ({ name: 'OrderDetailsPipeline' }),
				children: [
					{
						path: 'details',
						name: 'OrderDetailsRecap',
						component: () => import('@/components/order/details/OrderDetailsRecap.vue'),
					},
					{
						path: 'pipeline',
						name: 'OrderDetailsPipeline',
						component: () => import('@/components/order/details/OrderDetailsPipeline.vue'),
					},
					{
						path: 'status',
						name: 'OrderDetailsStatus',
						component: () => import('@/components/order/details/OrderDetailsStatus.vue'),
					},
					{
						path: 'historique',
						name: 'OrderDetailsHistory',
						component: () => import('@/components/order/OrderHistoryPagedListView.vue'),
					},
					{
						path: 'contrats',
						name: 'OrderDetailsContracts',
						component: () => import('@/components/order/details/OrderDetailsContracts.vue'),
						redirect: () => ({ name: 'OrderDetailsClientContract' }),
						children: [
							{
								path: 'contrats-clients',
								name: 'OrderDetailsClientContract',
								component: () => import('@/components/order/details/OrderDetailsClientContracts.vue'),
								children: [
									{
										path: ':contractId',
										name: 'OrderDetailsClientContractDetails',
										component: () => import('@/components/contract/ContractAsideDetails.vue'),
										props: { documentType: DOCUMENT_TYPE.ClientContract },
									},
								],
							},
							{
								path: 'contrats-interimaires',
								name: 'OrderDetailsTempWorkerContract',
								component: () => import('@/components/order/details/OrderDetailsTempWorkerContracts.vue'),
								children: [
									{
										path: ':contractId',
										name: 'OrderDetailsTempWorkerContractDetails',
										component: () => import('@/components/contract/ContractAsideDetails.vue'),
										props: { documentType: DOCUMENT_TYPE.TempWorkerContract },
									},
								],
							},
							/* {
								path: 'contrats-interimaires',
								name: 'OrderDetailsTempWorkerContractDetails',
								// component: () => import('@/components/contract/ContractAsideDetails.vue'),
								component: () => import('@/components/order/details/OrderDetailsTempWorkerContracts.vue'),
								// props: { documentType: DOCUMENT_TYPE.TempWorkerContract },
							}, */
						],
					},
				],
			},
			{
				path: '/ma-boite-a-outils',
				name: 'OrderToolbox',
				component: () => import('@/components/order/toolbox/OrderToolboxView.vue'),
				children: [
					{
						path: 'viviers',
						meta: { title: `Viviers` },
						name: 'ToolboxTalentPool',
						component: () => import('@/components/order/toolbox/OrderToolboxTalentPool.vue'),
					},
					{
						path: 'modeles-de-tache',
						meta: { title: `Modèles de tâche` },
						name: 'ToolboxTaskTemplate',
						component: () => import('@/components/order/toolbox/OrderToolboxTaskTemplate.vue'),
					},
					{
						path: 'descriptions-de-poste',
						meta: { title: `Descriptions de poste` },
						name: 'ToolboxJobDescription',
						component: () => import('@/components/order/toolbox/OrderToolboxJobDescription.vue'),
					},
				],
			},
			// #endregion

			// #region Mes paramètres
			{
				path: 'mes-parametres/statistiques',
				name: 'Statistic',
				component: () => import('@/components/statistic/StatisticView.vue'),
			},
			{
				path: 'mes-parametres/cgv-ctt',
				name: 'CgvCttBase',
				redirect: () => ({
					name: 'CgvCtt',
					params: { status: 'en-cours' },
				}),
				component: () => import('@/components/settings/SettingsAgencyCgvCtt.vue'),
			},
			{
				path: 'mes-parametres/cgv-ctt/:status',
				name: 'CgvCtt',
				component: () => import('@/components/settings/SettingsAgencyCgvCtt.vue'),
			},
			{
				path: 'mes-parametres/parametres',
				name: 'Settings',
				component: () => import('@/components/settings/SettingsAgency.vue'),
				children: [
					{
						path: 'relances',
						meta: { title: `Relances` },
						name: 'SettingsReminders',
						component: () => import('@/components/settings/SettingsAgencyReminders.vue'),
					},
					{
						path: 'factures',
						meta: { title: `Factures` },
						name: 'SettingsBills',
						component: () => import('@/components/settings/SettingsAgencyBills.vue'),
					},
					{
						path: 'demandes-acompte',
						meta: { title: `Demandes d'acompte` },
						name: 'SettingsAdvancedPayment',
						component: () => import('@/components/settings/SettingsAgencyAdvancedPayment.vue'),
					},
					{
						path: 'representant',
						meta: { title: `Représentant légal` },
						name: 'SettingsDelegates',
						component: () => import('@/components/settings/SettingsDelegates.vue'),
					},
				],
			},
			/* {
				path: 'mes-parametres/conditions-generales',
				name: 'GeneralConditions',
				component: () => import('@Views/settings/GeneralConditions.vue'),
			}, */
			{
				path: 'mes-parametres/actualites',
				name: 'AgencyNews',
				component: () => import('@/components/agency-news/AgencyNewsPagedListView.vue'),
			},
			// #endregion

			// #region Organization
			{
				path: 'organisation/vision-d-ensemble',
				name: 'OrganizationDashboard',
				component: () => import('@/components/organization/OrganizationDashboard.vue'),
				redirect: { name: 'OrganizationPendingActions' },
				children: [
					{
						path: 'actions-en-attente',
						name: 'OrganizationPendingActions',
						component: () => import('@/components/organization/OrganizationPendingActions.vue'),
					},
					{
						path: 'demandes-de-fusion',
						name: 'OrganizationFusionRequests',
						component: () => import('@/components/organization/OrganizationFusionRequests.vue'),
					},
					{
						path: 'fonctionnement',
						name: 'OrganizationUsage',
						component: () => import('@/components/organization/OrganizationUsage.vue'),
					},
				],
			},
			{
				path: 'organisation/societes',
				name: 'OrganizationCompany',
				component: () => import('@/components/organization-company/OrganizationCompanyPagedListView.vue'),
			},
			{
				path: 'organisation/roles',
				name: 'OrganizationRoles',
				component: () => import('@/components/organization/OrganizationRoles.vue'),
				meta: { helpArmadoPage: HELP.Role },
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationRoleDetails',
						component: () => import('@/components/role/RoleDetails.vue'),
						meta: { helpArmadoPage: HELP.Role },
						props: true,
					},
				],
			},
			{
				path: 'organisation/tags',
				name: 'OrganizationTags',
				component: () => import('@/components/organization/OrganizationTags.vue'),
				meta: { helpArmadoPage: HELP.Tag },
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationTagDetails',
						component: () => import('@/components/tag/TagDetails.vue'),
						meta: { helpArmadoPage: HELP.Tag },
						props: true,
					},
				],
			},
			{
				path: 'organisation/utilisateurs',
				name: 'OrganizationUser',
				component: () => import('@/components/organization-user/OrganizationUserPagedListView.vue'),
				children: [
					{
						path: ':id(\\d+)',
						name: 'OrganizationUserDetails',
						component: () => import('@/components/organization-user/OrganizationUserAsideDetails.vue'),
						meta: { helpArmadoPage: HELP.Tag },
						props: true,
					},
				],
			},
			// #endregion

			/* {
				path: 'discussions',
				name: 'Discussions',
				component: () => import('@Views/Discussions.vue'),
			}, */
			/* {
				path: 'actualites',
				name: 'News',
				component: () => import('@Views/News.vue'),
			}, */
		],
	},
] as const;

export default routesAgency;
