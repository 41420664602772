import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useModal } from '@orion.ui/orion';
import routesLogin from './login.router';
import routesTempWorker from './tempWorker.router';
import routesAgency from './agency.router';
import routesClient from './client.router';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import { Sentry } from '@/app';


const userType = localStorage.getItem('userType');

const routes = [
	...routesLogin,
	{
		path: '/signup',
		name: 'Signup',
		component: () => import('@/components/signup/SignupView.vue'),
		meta: {
			browserTitle: 'Inscription',
			description: 'Créez votre compte sur la plateforme Armado.',
		},
	},
	{
		path: '/code-agence',
		name: 'OnboardingAgencyCompanyCode',
		component: () => import('@/components/onboarding/OnboardingCodeScanView.vue'),
		meta: { browserTitle: 'Code agence' },
	},
	{
		path: '/',
		name: 'Home',
		component: () => import('@/components/shared/SharedLayout.vue'),
		meta: { requiresAuth: true },
		children: [
			{
				path: '',
				name: 'Dashboard',
				component: () => import('@/components/dashboard/DashboardView.vue'),
			},
			/*
			{
				path: 'aide-en-ligne',
				name: 'Help',
				component: Help,
			},
		],*/
		],
	},
	{
		path: '/signature-statut/:status',
		name: 'Signature statut',
		component: () => import('@/components/yousign/YousignSignatureStatus.vue'),
		props: true,
	},
	{
		path: '/legal',
		name: 'Legal',
		component: () => import('@/components/legal/LegalView.vue'),
		meta: { browserTitle: 'CGU & Politiques' },
	},
	{
		path: '/impersonate',
		name: 'Impersonate',
		component: () => import('@/components/ImpersonateView.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'Redirect',
		redirect: { name: 'Dashboard' },
	},
] as const;


const router = createRouter({
	history: createWebHistory(),
	routes: routes as unknown as RouteRecordRaw[],
});

if (userType) addRoutes(+userType);


export function addRoutes (userType: USER_TYPE) {
	switch (Number(userType)) {
	case USER_TYPE.TempWorker:
		routesTempWorker.forEach((x) => {
			router.addRoute(x as unknown as RouteRecordRaw);
		});
		break;
	case USER_TYPE.Agency:
		routesAgency.forEach((x) => {
			router.addRoute(x as unknown as RouteRecordRaw);
		});
		break;
	case USER_TYPE.Client:
		routesClient.forEach((x) => {
			router.addRoute(x as unknown as RouteRecordRaw);
		});
		break;
	}
}

router.beforeEach((to, from, next) => {
	document.title = to.meta.browserTitle ? `Armado - ${to.meta.browserTitle}` : 'Armado';
	document?.querySelector('meta[name="description"]')?.setAttribute('content', to.meta.description as string
	?? 'L\'intérim digital pour les intérimaires, les agences, les entreprises. \
	Une plateforme riche en fonctionnalités avec une interface agréable et conviviale, accompagnée d\'une application mobile');
	return next();
});

router.onError((error) => {
	if (/chunk/.test(error.message) || /dynamically imported module/.test(error.message)) {
		Sentry.captureException(error);

		useModal({
			size: 'xs',
			hideOnOverlayClick: false,
			hideOnEsc: false,
			hideClose: true,
			title: `Une erreur s'est produite`,
			message: `Ces erreurs peuvent se produire lors de mises à jour d'Armado
				et signifient qu'une nouvelle version est disponible.<br>
				Il vous suffit de <strong>recharger la page</strong>.`,
			actions: [
				{
					label: `Recharger la page`,
					color: 'brand',
					callback: () => {
						setTimeout(() => {
							window.location.reload();
						}, 500);
						throw `Post MEP - Reload page`;
					},
				},
			],
		});
	}
});

export default router;


// eslint-disable-next-line local-rules/no-export-type-in-ts
export type RouteNamesAgency =
	| typeof routes[number]['name']
	| typeof routesAgency[0]['children'][number]['name']

// eslint-disable-next-line local-rules/no-export-type-in-ts
export type RouteNamesClient =
	| typeof routes[number]['name']
	| typeof routesClient[0]['children'][number]['name']

// eslint-disable-next-line local-rules/no-export-type-in-ts
export type RouteNamesTempWorker =
	| typeof routes[number]['name']
	| typeof routesTempWorker[0]['children'][number]['name']
