import { nextTick, PropType, reactive, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { useElementBounding } from '@vueuse/core';

type Props = SetupProps<typeof SharedProgressBarSetupService.props>

export default class SharedProgressBarSetupService extends BaseSetupService<Props> {
	static readonly props = {
		color: {
			type: String as PropType<Orion.Color>,
			default: 'info',
		},
		width: {
			type: Number,
			default: 4,
		},
		value: {
			type: Number,
			default: 0,
		},
		gradientValue: {
			type: Number,
			default: undefined,
		},
		gradientColor: {
			type: String as PropType<Orion.Color>,
			default: 'warning',
		},
		tooltip: {
			type: String,
			default: '0%',
		},
	};

	_el = ref<RefDom>();
	_value = ref<RefDom>();
	_mask = ref<RefDom>();

	private readonly state = reactive({ _elBounding: useElementBounding(this._el) });

	get elWidth () { return this.state._elBounding.width; }


	constructor (props?: Props) {
		super(props);
	}

	protected onMounted (): void {
		nextTick(() => this.handleMask());

		watch(this.state._elBounding, () => {
			nextTick(() => this.handleMask());
		});
	}

	handleMask () {
		if (!this._mask.value || !this._value.value) return;

		const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
		svg.innerHTML = this._mask.value.innerHTML;

		let str = new XMLSerializer().serializeToString(svg);
		str = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(str);

		this._value.value.style.webkitMaskImage = `url(${str})`;
		this._value.value.style.maskImage = `url(${str})`;
	}
}
