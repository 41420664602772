import AuthApi from '@/api/AuthApi';

class AuthService {
	async signInAsync (...args: Parameters<typeof AuthApi.signIn>) {
		const { data } = await AuthApi.signIn(...args);
		return data;
	}

	async resetPasswordAsync (...args: Parameters<typeof AuthApi.resetPassword>) {
		const { data } = await AuthApi.resetPassword(...args);
		return data;
	}
}

export default function useAuthService () {
	return new AuthService();
}
