import { reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import MissionPlaceEntity from '@/entity/mission-place/MissionPlaceEntity';
import useMissionPlaceService from '@/services/MissionPlaceService';
import { useValidation } from '@orion.ui/orion';

type Props = SetupProps<typeof MissionPlaceModalPromptSetupService.props>

export default class MissionPlaceModalPromptSetupService extends BaseSetupService<Props> {
	static readonly props = {
		id: {
			type: Number,
			default: undefined,
		},
	};

	missionPlace = new MissionPlaceEntity();
	validator = useValidation(this.missionPlace, {
		name: 'required',
		googleAddress: () => !!this.missionPlace.address && !!this.missionPlace.city && !!this.missionPlace.country,
	});

	private _modal?: OrionModal;
	private readonly state = reactive({ dataLoaded: false });

	get dataLoaded () { return this.state.dataLoaded; }


	constructor (props?: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
	}

	protected async onBeforeMountAsync () {
		if (this.props.id) {
			this._modal?._loader()?.show(`Chargement des données`);
			const data = await useMissionPlaceService().getAsync(this.props.id);
			this.missionPlace.assignData(data);
			this._modal?._loader()?.hide();
		}
		this.state.dataLoaded = true;
	}


	async checkAndCreateOrUpdateAsync () {
		this._modal?._loader()?.show(`Enregistrement...`);

		try {
			this.missionPlace.isNew
				? await this.missionPlace.storeAsync()
				: await this.missionPlace.updateAsync();

			this._modal?.close();
		} finally {
			this._modal?._loader()?.hide();
		}
	}
}
