import ApiClient from '@/api/ApiClient';

const client = new ApiClient('company');

class CompanyApi {
	static getDetailed () {
		return client.get<ICompanyDetailedModel[]>('detailed');
	}

	static get (params: OmitUserId<ICompanyListByUserIdParameters>) {
		return client.get<ICompanyModel[]>('', { params });
	}

	static getForOrganizationalChart (pagedListParameters: ICompanyFilterForOrganizationPagedListParameters) {
		return client.get<ICompanyForOrganizationalChartModel[]>('organizationalChart', { params: pagedListParameters });
	}

	static getAvailableForOrganization (pagedListParameters: ICompanyAvailableForOrganizationPagedListParameters) {
		return client.get<IPagedList<ICompanyModel>>('availablefororganization', { params: pagedListParameters });
	}

	static getByCode (code: string) {
		return client.get<ICompanyModel>(`code/${code}`);
	}
}

export default CompanyApi;
