import BaseEntity from '@/entity/BaseEntity';
import useMissionPlaceService from '@/services/MissionPlaceService';
import { useCountry } from '@orion.ui/orion';


export default class MissionPlaceListItemEntity<T extends IMissionPlaceItemModel> extends BaseEntity<T> {

	get name () { return this.state.name; }
	get address () { return this.state.address; }
	get addressComplement () { return this.state.addressComplement; }
	get postalCode () { return this.state.postalCode; }
	get city () { return this.state.city; }
	get countryCode () { return this.state.countryCode as Orion.Country['code']; }

	get country () {
		return useCountry().getCountryByCode(this.countryCode ?? 'FR')?.name;
	}

	async showAsync () {
		await useMissionPlaceService().showMissionPlaceAsideDetailsAsync(this.id);
	}

	async editAsync () {
		await useMissionPlaceService().promptMissionPlaceModal(this.id);
	}

	async deleteAsync () {
		await useMissionPlaceService().deleteAsync(this.id);
	}

}
