import { PropType, reactive, ref } from 'vue';
import { useNotif } from '@orion.ui/orion';
import BaseSetupService from '@/setup/BaseSetupService';
import useCompanyService from '@/services/CompanyService';
import type OrganizationCompanyListItemEntity from '@/entity/organization-company/OrganizationCompanyListItemEntity';

type Props = SetupProps<typeof SharedLogoCompanyModalSetupService.props>

export default class SharedLogoCompanyModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		companyId: {
			type: Number,
			required: true as const,
		},
		companyLogoId: {
			type: Number,
			default: undefined,
		},
		companyEntity: {
			type: Object as PropType<OrganizationCompanyListItemEntity>,
			default: undefined,
		},
	};

	readonly _cropper = ref<OrionCropper>();
	private _modal?: OrionModal;
	private readonly state = reactive({ files: [] as File[] });

	get files () { return this.state.files; }
	get selectedFile () { return this.state.files[0]; }


	constructor (props?: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
	}


	async uploadAsync () {
		if (!this.files || !this.files[0]) {
			useNotif.warning(`Veuillez sélectionner un fichier`);
			return;
		}

		this._modal?._loader()?.show(`Enregistrement de votre nouveau logo...`);

		const croppedFile = await this._cropper.value?.crop();
		if (!croppedFile) return;

		if (this.props.companyLogoId) {
			await useCompanyService(this.props.companyEntity).updateLogoAsync({
				file: croppedFile,
				id: this.props.companyLogoId,
			});
		} else {
			await useCompanyService(this.props.companyEntity).addLogoAsync({
				file: croppedFile,
				companyId: this.props.companyId,
			});
		}

		this._modal?._loader()?.hide();
		this._modal?.close();
		useNotif.success(`Modification enregistrée`, `Votre logo a bien été modifié`);
	}

	async deleteAsync () {
		if (!this.props.companyLogoId) return;
		this._modal?._loader()?.show(`Suppression de votre logo...`);

		try {
			await useCompanyService(this.props.companyEntity).deleteLogoAsync(this.props.companyLogoId);
			useNotif.success(`Modification enregistrée`, `Votre logo a bien été supprimé`);
			this._modal?.close();
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		} finally {
			this._modal?._loader()?.hide();
		}
	}
}
