import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';
import { AxiosResponse } from 'axios';

const ROUTE = 'tempworker/digitalsafedocument';
const client = new ApiClient(ROUTE);

class DigitalSafeDocumentApiTempWorker {

	static add (file: File) {
		const formData = new FormData();
		formData.append(file.name, file);
		return client.post<IDigitalSafeDocumentModel>('', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	}

	static totalSizeSafe () {
		return client.get<number>('size');
	}

	static list () {
		return client.get<IDigitalSafeDocumentModel[]>('');
	}

	static async listPagedListAsync () {
		const res = await this.list();

		(res.data as unknown as IPagedList<IDigitalSafeDocumentModel>).list = res.data;
		(res.data as unknown as IPagedList<IDigitalSafeDocumentModel>).count = res.data.length;

		return res as unknown as AxiosResponse<IPagedList<IDigitalSafeDocumentModel>>;
	}

	static rename (renameDigitalSafeDocumentModel: OmitUserId<IRenameDigitalSafeDocumentModel>) {
		return client.put<void>('', renameDigitalSafeDocumentModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static view (id: number) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/digitalsafedocument/${id}/file`, '_blank');
	}

	static download (id: number, name: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/digitalsafedocument/${id}/file?download=true&name=${name}`, '_blank');
	}

	static downloadZip (ids: number[]) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/digitalsafedocument/download?ids=${ids.join('&ids=')}`, '_blank');
	}

	static addLabel (addLabelDigitalSafeDocumentModel: OmitUserId<IAddLabelDigitalSafeDocumentModel>) {
		return client.post<IDigitalSafeDocumentModel>('label', addLabelDigitalSafeDocumentModel);
	}

	static deleteLabel (deleteLabelDigitalSafeDocumentModel: OmitUserId<IDeleteLabelDigitalSafeDocumentModel>) {
		return client.post<IDigitalSafeDocumentModel>('label/delete', deleteLabelDigitalSafeDocumentModel);
	}

	static toTrash (id: number) {
		return client.post<IDigitalSafeDocumentModel>(`${id}/totrash`);
	}

	static restore (id: number) {
		return client.post<IDigitalSafeDocumentModel>(`${id}/restore`);
	}

	static clearTrash () {
		return client.delete<void>('cleartrash');
	}
}

export default DigitalSafeDocumentApiTempWorker;
