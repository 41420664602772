import ApiClient from '@/api/ApiClient';

const client = new ApiClient('tag');

class TagApi {

	static add (addTagModel: IAddTagModel) {
		return client.post<ITagDetailModel>(``, addTagModel);
	}

	static selectById (id: number) {
		return client.get<ITagDetailModel>(id.toString());
	}

	static list (organizationId: number) {
		return client.get<ITagListItemModel[]>(``, { params: { organizationId } });
	}

	static listFiltered (organizationId: number) {
		return client.get<ITagListItemModel[]>(`list`, { params: { organizationId } });
	}

	static update (id: number, updateTagModel: IUpdateTagModel) {
		return client.put<void>(id.toString(), updateTagModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

}

export default TagApi;
