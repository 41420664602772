import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedTagDotsExpandableSetupService.props>

export default class SharedTagDotsExpandableSetupService extends BaseSetupService<Props> {
	static readonly props = {
		tags: {
			type: Array as PropType<ITagListItemModel[]>,
			default: () => [],
		},
		tagLinks: {
			type: Array as PropType<(ITagLinkModel | ITagUserModel)[]>,
			default: () => [],
		},
	};

	get formattedTags (): { name?: string; color?: Orion.ColorExtended; }[] {
		let res: { name?: string, color?: Orion.ColorExtended }[] = [];
		if (this.props.tags.length) {
			res = this.props.tags.map(t => ({
				name: t.name,
				color: t.color?.name as Orion.ColorExtended,
			}));
		} else if (this.props.tagLinks.length) {
			res = this.props.tagLinks.map(t => ({
				name: t.tagName,
				color: t.tagColorName as Orion.ColorExtended,
			}));
		}
		return res.map(x => ({
			...x,
			color: x.color?.replace(/(grad|light)/, 'alt') as Orion.ColorExtended,
		}));
	}
}
