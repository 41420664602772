import ApiClient from '@/api/ApiClient';

const client = new ApiClient('auth');

class AuthApi {
	static signIn (payload: ISignInModel) {
		return client.post<ITokenModel>('signin', payload);
	}

	static updateEmail (updateEmailModel: IUpdateAuthLoginModel) {
		return client.put<void>('updatelogin', updateEmailModel);
	}

	static updatePassword (userReset: IUpdateAuthPasswordModel) {
		return client.put<void>('updatePassword', userReset);
	}

	static confirmPassword (password: IConfirmPasswordModel) {
		return client.post<Boolean>('confirmPassword', password);
	}

	static resetPassword (resetPasswordModel: IResetAuthPasswordModel) {
		return client.post<void>('resetpassword', resetPasswordModel);
	}

	static checkLogin (email?: string) {
		return client.post<IAuthWithUserModel>('checklogin', { login: email });
	}

	static updateEmailFromSignup (email: IUpdateAuthLoginFromSignupModel) {
		return client.post<void>('updateloginsignup', { email });
	}
}

export default AuthApi;
