<template>
	<o-select
		v-if="setup.companies.length"
		v-model="setup.vModel"
		:options="setup.selectOptions"
		track-key="id"
		display-key="name"
		value-key="id"
		disabled-key="disabled"
		v-bind="{
			...$attrs,
			label: setup.computedLabel(),
			clearable,
			searchable: setup.searchable,
			multiple,
		}"
		@select="emits('select', $event)">
		<template
			v-if="setup.self.isClient()"
			#option="{ item }">
			<div>
				<div>
					{{ `${item.name} (${item.contactDetailCity})` }}
				</div>
				<o-label
					v-if="item.agencyCompanyName && !hideAgencyName"
					outline
					style="margin-top:5px">
					{{ item.agencyCompanyName }}
				</o-label>
				<o-label
					v-if="checkOrderPlugin && !item.canCreateOrder"
					style="margin-top:5px"
					color="warning"
					outline>
					Veuillez vous rapprocher de cette agence pour lui adresser une commande
				</o-label>
			</div>
		</template>
	</o-select>
	<slot
		v-else
		name="empty"/>
</template>

<script setup lang="ts">
import SharedSelectUserCompanySetupService from '@/setup/shared/SharedSelectUserCompanySetupService';
const props = defineProps(SharedSelectUserCompanySetupService.props);
type Emits = {
	(e: 'update:modelValue' | 'input', id: number | number[]): void
	(e: 'update:selected', companyModel?: ICompanyModel | ICompanyModel[]): void,
	(e: 'select', companyModel: ICompanyModel | ICompanyModel[]): void
}
const emits = defineEmits<Emits>();
const setup = new SharedSelectUserCompanySetupService(props, emits);
defineExpose(setup.publicInstance);
</script>
