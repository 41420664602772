import { nextTick, reactive } from 'vue';
import useSelfService from '@/services/SelfService';
import { cloneDeep } from 'lodash-es';

/**
 * Specify that the id can be undef to handle ajax loading or entity creation
 * So we can use v-if or if (state.id)
 * to be sure all the data has been loaded
 */
type RawEntity = { id?: number }
type Instance<E> = {
	__proto__: {
		constructor: new (...x: any[]) => E
	}
}

export default class BaseEntity<T extends RawEntity> {
	static map <E extends BaseEntity<any>> (this: new (x?: any) => E, entries: RawEntity[], callback?: (entity: E) => void) {
		if (typeof callback === 'function') {
			return entries.map((x) => {
				const entity = new this(x);
				callback(entity);
				return entity;
			});
		}
		return entries.map(x => new this(x));
	}

	protected state = reactive({}) as T;
	protected selfState = reactive({});
	protected _clone?: typeof this;
	protected _origin?: typeof this;

	get id (): T['id'] { return this.state.id; }
	get self () { return useSelfService().self; }
	get isNew () { return !this.id; }
	get isCloneDiff () { return !!this._clone && this.state !== this._clone.state; }


	constructor (payload?: T, selfState?: Record<string, any>) {
		if (payload) this.assignData(payload, selfState);

		setTimeout(() => this.onInstanceCreated(), 0);
	}

	protected onInstanceCreated () {}
	protected onDataAssigned () {}
	protected onDataAssignedNextTick () {} // TODO: remove after check remaining

	clone <E extends BaseEntity<T>> (copySelfState = true) {
		const construct = (this as unknown as Instance<E>).__proto__.constructor;
		copySelfState
			? this._clone = new construct(cloneDeep(this.state), cloneDeep(this.selfState)) as unknown as typeof this
			: this._clone = new construct(cloneDeep(this.state), {}) as unknown as typeof this;

		this._clone.setCloneOrigin(this);

		return this._clone;
	}

	applyClone (unsetClone = true, copySelfState = true) {
		if (this._clone) {
			copySelfState
				? this.assignData(this._clone.state, this._clone.selfState)
				: this.assignData(this._clone.state);

			if (unsetClone) this._clone = undefined;
		} else if (this._origin) {
			this._origin.assignData(this.state);
			if (unsetClone) this._origin = undefined;
		}
	}

	resetClone () {
		this._clone?.assignData(this.state, this.selfState);
	}

	setCloneOrigin (entity: typeof this) {
		this._origin = entity;
	}

	assignData (payload: Partial<T>, selfState?: Record<string, any>) {
		Object.assign(this.state, payload);
		if (selfState) {
			Object.assign(this.selfState, selfState);
		}

		setTimeout(() => this.onDataAssigned(), 0);
		nextTick(() => this.onDataAssignedNextTick()); // TODO: remove after check remaining
	}
}
