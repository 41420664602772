import { useValidation } from '@orion.ui/orion';
import BaseSetupService from '@/setup/BaseSetupService';
import TagDetailEntity from '@/entity/TagDetailEntity';

type Props = SetupProps<typeof TagAsideNewSetupService.props>

export default class TagAsideNewSetupService extends BaseSetupService<Props> {
	static readonly props = {};

	private _aside?: OrionAside;

	tag = new TagDetailEntity({
		id: 0,
		color: {
			id: 1,
			name: 'brand',
			value: '#2bb9a6',
		},
		name: undefined,
		description: undefined,
		shareToAgency: false,
	});

	validator = useValidation(this.tag, { name: 'required' });


	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;
	}


	cancel () {
		this._aside?.trigger('cancel');
		this._aside?.close();
	}

	save () {
		this.validator.validate()
			? this._aside?.trigger('save', this.tag)
			: this.validator.showValidationState();
	}
}
