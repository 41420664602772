<template>
	<div>
		<div id="map"/>
		<o-select
			:ref="setup._address"
			v-model="setup.address"
			v-bind="$attrs"
			:label="label"
			:prefill-search="modelValue"
			autocomplete
			suffix-font-icon="icon-travel-map-location-pin"
			track-key="place_id"
			display-key="description"
			:custom-fetch="setup.getGooglePredictionsAsync.bind(setup)"
			@select="setup.handleSelect($event)">
			<template #after-options>
				<div class="powered-by-google">
					<img src="@Img/powered_by_google_on_white_hdpi.png">
				</div>
			</template>
		</o-select>
	</div>
</template>

<script setup lang="ts">
import SharedGoogleAutoCompleteSetupService from '@/setup/shared/SharedGoogleAutoCompleteSetupService';
type Emits = {
  (e: 'google-found', value: any): void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedGoogleAutoCompleteSetupService.props);
const setup = new SharedGoogleAutoCompleteSetupService(props, emits);
</script>

<style scoped lang="less">
.powered-by-google {
	text-align: right;
	padding: 0.3rem 0.3rem 0 0;

	> img {
		display: inline-block;
		height: 0.8rem;
	}
}
</style>
