const DiscussionTyping = 'DiscussionTyping';

const MessageAdded = 'MessageAdded';

const MessageUpdated = 'MessageUpdated';

const UserPresenceJoin = 'UserPresenceJoin';

const UserPresenceLeave = 'UserPresenceLeave';

const DiscussionKicked = 'DiscussionKicked';

const TagUserUpdate = 'TagUserUpdate';

const BroadcastAddOrUpdate = 'BroadcastAddOrUpdate';

const BroadcastDelete = 'BroadcastDelete';

export default {
	DiscussionTyping,
	MessageAdded,
	MessageUpdated,
	UserPresenceJoin,
	UserPresenceLeave,
	DiscussionKicked,
	TagUserUpdate,
	BroadcastAddOrUpdate,
	BroadcastDelete,
};
