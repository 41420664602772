import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'agency/tripattestation';
const client = new ApiClient(ROUTE);

class TripAttestationApiAgency {
	static grid (filter: ITripAttestationPagedListForAgencyParameters) {
		return client.post<IPagedList<ITripAttestationItemForAgencyModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tripattestation${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tripattestation${idsQuery}`, '_blank');
	}
}

export default TripAttestationApiAgency;
