import DigitalSafeDocumentApiAgency from '@/api/DigitalSafeDocumentApiAgency';
import DigitalSafeDocumentApiTempWorker from '@/api/DigitalSafeDocumentApiTempWorker';
import { reactive } from 'vue';
import LabelApiTempWorker from '@/api/LabelApiTempWorker';
import LabelApiAgency from '@/api/LabelApiAgency';
import { sortBy } from 'lodash-es';
import useSelfService from '../SelfService';
import useColorService from '../ColorService';

export class DigitalSafeService {
	protected get api () {
		return useSelfService().self.isAgency() ? DigitalSafeDocumentApiAgency : DigitalSafeDocumentApiTempWorker;
	}

	private get apiLabel () {
		return useSelfService().self.isTempWorker() ? LabelApiTempWorker : LabelApiAgency;
	}

	private readonly serviceState = reactive({
		digitalSafeLabels: [] as Armado.LabelForCompanyModelExtended[],
		activeLabelId: undefined as Undef<number>,
		digitalSafeDocuments: [] as IDigitalSafeDocumentModel[],
	});

	readonly colorOptions = [
		'grey-darker',
		'grey-dark',
		'grey',
		'grey-light',
		'grey-lighter',
		'brand',
		'brand-alt',
		'info',
		'info-alt',
		'success',
		'success-alt',
		'warning',
		'warning-alt',
		'danger',
		'danger-alt',
		'pink',
		'pink-alt',
	];


	get digitalSafeLabels () { return this.serviceState.digitalSafeLabels;}
	get digitalSafeDocuments () { return this.serviceState.digitalSafeDocuments;}
	get activeLabelId () { return this.serviceState.activeLabelId;}
	set activeLabelId (val) { this.serviceState.activeLabelId = val;}

	get activeLabel () {
		return this.serviceState.digitalSafeLabels.find(x => x.id === this.serviceState.activeLabelId);
	}

	get userLabels () {
		return sortBy(
			this.serviceState.digitalSafeLabels
				?.filter(x => !x.readOnly)
				?.map(x => ({
					...x,
					color: useColorService().swapColor(x.color),
				})),
			'caption',
		);
	}

	get userFirstLevelLabels () {
		return sortBy(this.userLabels.filter(x => !x.parentId), 'caption');
	}


	getLabelsWithPath (includeSelf = false) {
		return useDigitalSafeService().digitalSafeLabels.map((x) => {
			const path = [] as string[];
			function getPath (labelId?: number) {
				if (!labelId) return;
				const label = useDigitalSafeService().digitalSafeLabels.find(x => x.id === labelId);
				if (label && label.caption) {
					path.unshift(label.caption);
					getPath(label.parentId);
				}
			}

			getPath(includeSelf ? x.id : x.parentId);

			return {
				...x,
				path: path.join('/'),
			};
		});
	}

	async getLabelDigitalSafeDocumentsAsync () {
		const { data } = await this.apiLabel.list();
		this.serviceState.digitalSafeLabels = data as Armado.LabelForCompanyModelExtended[];
	}

	getChildrenLabels (id: number) {
		return sortBy(this.digitalSafeLabels.filter(x => x.parentId === id), 'caption');
	}

	async saveLabelFoldingAsync (id: number, isFold: boolean) {
		await LabelApiTempWorker.updateIsFold({
			id,
			isFold,
		});

		this.digitalSafeLabels.map((x) => {
			if (x.id === id)
				x.isFold = isFold;
		});
	}

	viewDocument (id: number) {
		this.api.view(id);
	}

	downloadDocument (digitalSafeDocument: IDigitalSafeDocumentForCompanyModel | IDigitalSafeDocumentModel) {
		if (digitalSafeDocument.title)
			this.api.download(digitalSafeDocument.id, digitalSafeDocument.title + digitalSafeDocument.document?.extension);
	}

	batchDownloadDocument (selected: IDigitalSafeDocumentForCompanyModel[]) {
		this.api.downloadZip(selected.map(x => x.id));
	}
}

const digitalSafeServiceSingleton = new DigitalSafeService();

export default function useDigitalSafeService () {
	return digitalSafeServiceSingleton;
}
