import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/talentpool');

class TalentPoolApiAgency {

	static list () {
		return client.get<ITalentPoolForProfileModel[]>('');
	}

	static grid (talentPoolPagedListParameters: ITalentPoolPagedListParameters) {
		return client.post<IPagedList<ITalentPoolModel>>('grid', talentPoolPagedListParameters);
	}

	static add (addTalentPoolModel: IAddTalentPoolModel) {
		return client.post<ITalentPoolModel>('', addTalentPoolModel);
	}

	static update (id: number, updateTalentPoolModel: IUpdateTalentPoolModel) {
		return client.put<void>(id.toString(), updateTalentPoolModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static addTalent (id: number, addTalentModel: IAddTalentsModel) {
		return client.post<ITalentWithUserModel[]>(`${id}/talent`, addTalentModel);
	}

	static deleteTalent (id: number, userId: number) {
		return client.delete<void>(`${id}/talent/${userId}`);
	}

	static deleteMultipleTalent (id: number, userIds: IDeleteTalentModel) {
		return client.put<void>(`${id}/talent/delete`, { userIds });
	}
}

export default TalentPoolApiAgency;
