import { nextTick, ref } from 'vue';
import BaseAsideEntitySetupService from '@/setup/BaseAsideEntitySetupService';
import MissionPlaceEntity from '@/entity/mission-place/MissionPlaceEntity';
import MissionPlaceApiClient from '@/api/MissionPlaceApiClient';
import { initGoogleMap } from '@/utils/tools';
import { useNotif } from '@orion.ui/orion';

type Props = SetupProps<typeof MissionPlaceAsideDetailsSetupService.props>

export default class MissionPlaceAsideDetailsSetupService extends BaseAsideEntitySetupService<Props, IMissionPlaceDetailModel> {
	// static props = {};

	protected get api () { return MissionPlaceApiClient; }

	_map = ref<HTMLElement>();
	protected readonly entity = new MissionPlaceEntity();
	private _aside?: OrionAside;

	get missionPlace () { return this.entity; };


	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;

		this.registerBusEvent(`${this.props.id}:update`, (payload: IMissionPlaceItemModel) => {
			this.missionPlace.assignData(payload);
			this.initGoogleMapPoster();
		});
	}

	protected onMounted () {
		this._aside?.open();
		this._aside?.bus.on('leave-end', () => {
			if (this.route.name === 'MissionPlaceDetails') {
				this.router.push({ name: 'MissionPlace' });
			}
		});
	}

	protected onUnmounted () {
		this._aside?.close();
	}

	protected async onEntityLoadedAsync () {
		this.getAdditionalDataAsync();
	}


	async getAdditionalDataAsync () {
		try {
			this._aside?._loader()?.show('Chargement...');

			await this.missionPlace.getLinkedClientContractAsync();
			await this.missionPlace.getLinkedTimeTicketAsync();
			await this.missionPlace.getLinkedOrderAsync();

			nextTick(() => this.initGoogleMapPoster());
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		} finally {
			this._aside?._loader()?.hide();
		}
	}

	initGoogleMapPoster () {
		if (this._map.value) {
			initGoogleMap(
				this.missionPlace.latitude,
				this.missionPlace.longitude,
				this._map.value,
				`${this.missionPlace.address} ${this.missionPlace.postalCode} ${this.missionPlace.city}`,
			);
		}
	}
}
