import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/register');

class RegisterApiAgency {

	static async grid (filter: IRegisterPagedListForAgencyParameters) {
		return client.post<IPagedList<IRegisterItemForAgencyModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/register${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/register${idsQuery}`, '_blank');
	}
}

export default RegisterApiAgency;
