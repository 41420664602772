<template>
	<div class="role-aside-new">
		<o-section title="Création d'un nouveau rôle">
			<o-timeline
				ref="timeline"
				v-model="setup.activePane"
				class="role-aside-new__timeline"
				horizontal
				@pill-click="setup.handlePillClick($event)">
				<o-timeline-pane
					:name="1"
					font-icon="icon-interface-bookmark-tag">
					<role-aside-new-step1
						:role="setup.role"
						@next="setup.goToNextStep()"/>
				</o-timeline-pane>
				<o-timeline-pane
					:name="2"
					lazy
					font-icon="icon-interface-setting-cog">
					<role-aside-new-step2
						:role="setup.role"
						@previous="setup.goToPreviousStep()"
						@next="setup.goToNextStep()"/>
				</o-timeline-pane>
			</o-timeline>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import RoleAsideNewSetupService from '@/setup/role/RoleAsideNewSetupService';
import RoleAsideNewStep1 from './RoleAsideNewStep1.vue';
import RoleAsideNewStep2 from './RoleAsideNewStep2.vue';
const props = defineProps(RoleAsideNewSetupService.props);
const setup = new RoleAsideNewSetupService(props);
</script>


<style scoped lang="less">
.role-aside-new {
	&__title {
		text-align: center;
		margin: 0.6rem 0 3.1rem;
	}

	&__role-title {
		margin-bottom: 1.3rem;
	}
}
</style>
