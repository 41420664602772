import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/timeticket');

class TimeTicketApiClient {

	static countByMissionPlaceId (missionPlaceId: number) {
		return client.get<number>(`countByMissionPlace/${missionPlaceId}`);
	}

}

export default TimeTicketApiClient;
