import useRoleUserService from '@/services/RoleUserService';
import useOrganizationRoleService from '@/services/OrganizationRoleService';
import BaseEntity from './BaseEntity';
import { Bus } from '@orion.ui/orion';

export default class OrganizationRoleDetailEntity extends BaseEntity<IOrganizationRoleDetailModel> implements ResourceEntity {
	get rolePermissions () { return this.state.rolePermissions ?? []; }
	set rolePermissions (val) { this.state.rolePermissions = val;}
	get roleUsers () { return this.state.roleUsers; }

	get name () { return this.state.name; }
	set name (val) { this.state.name = val; }

	get color () { return this.state.color; }
	set color (val) { this.state.color = val; }

	get colorId () { return this.color?.id ?? 0; }
	set colorId (val) {
		if (this.color)
			this.color.id = val;
	}

	get usersForRolesAndTags (): Armado.UserForRolesAndTags[] {
		return this.roleUsers.map(u => ({
			id: u.id,
			avatarId: u.userAvatarId,
			authLogin: u.userAuthLogin,
			firstName: u.userFirstName,
			lastName: u.userLastName,
		}));
	}


	async updateAsync (payload?: IUpdateOrganizationRoleModel) {
		return await useOrganizationRoleService().updateAsync(this.id, {
			name: payload?.name ?? this.name,
			colorId: payload?.colorId ?? this.colorId,
		});
	}

	async deleteAsync () {
		return await useOrganizationRoleService().deleteAsync(this.id);
	}

	async deleteRoleUserAsync (roleUserId: number) {
		await useRoleUserService().deleteAsync(roleUserId);
		Bus.emit('RoleDetailsSetupService:role:refresh');
	}

	async promptAddRoleUsersAsync () {
		await useRoleUserService().promptUserSelectAsync(this.id);
		Bus.emit('RoleDetailsSetupService:role:refresh');
	}
}
