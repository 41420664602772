<template>
	<div
		:class="[
			`shared-state-icon shared-state-icon--${state}`,
			{ 'shared-state-icon--small': small },
			{ 'shared-state-icon--clickable': clickable },
		]">
		<svg
			v-if="state === 'complete' && !small"
			viewBox="0 0 12 10">
			<polyline points="1.5 6 4.5 9 10.5 1"/>
		</svg>
	</div>
</template>

<script setup lang="ts">
import SharedStateIconSetupService from '@/setup/shared/SharedStateIconSetupService';
const props = defineProps(SharedStateIconSetupService.props);
const setup = new SharedStateIconSetupService(props);
</script>

<style lang="less">
@import '@/styles/mixins';
.shared-state-icon {
	transition: all .3s;
	position: relative;
	.circle(1rem);
	background-color: white;
	justify-content: center;
	display: flex;
	align-items: center;
	font-size: 0.6rem;
	color: white;

	&--small {
		.circle(0.5rem);
	}

	&--complete {
		background-color: var(--success);
	}

	&--empty {
		background: rgba(var(--rgb-grey-light), 1)
	}

	&--danger {
		background: rgba(var(--rgb-danger-alt), 1)
	}

	&--partial {
		border: solid 0.1rem var(--info);
	}

	&--warning {
		background: rgba(var(--rgb-warning-alt), 1)
	}

	&--clickable {
		cursor: pointer;
		&::before {
			content: "";
			border: 1px solid var(--grey-light);
			height: calc(100% + 1rem);
			width: calc(100% + 1rem);
			border-radius: 50%;
			position: absolute;
			z-index: -1;
			transition: all .3s;
		}
		&:hover {
			&::before {
				border-color: var(--info);
				background: rgba(var(--rgb-info), 0.1);
			}
		}
	}

	svg {
			width: 0.75rem;
			height: 0.5rem;
			fill: none;
			stroke: white;
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
			stroke-dasharray: 1rem;
		}
}

</style>
