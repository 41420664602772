import ApiClient from '@/api/ApiClient';

const client = new ApiClient('featureflag');

class FeatureFlagApi {

	static get (name: string) {
		return client.get<{value: string}>(`${name}`);
	}

}

export default FeatureFlagApi;
