import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useMissionPlaceService from '@/services/MissionPlaceService';

type Props = SetupProps<typeof SharedMissionPlaceSelectSetupService.props>

type Emits = {
	(e: 'input', val: number): void,
	(e: 'update:missionPlace', val: IMissionPlaceDropDownListItemModel) : void
}

export default class SharedMissionPlaceSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		label: {
			type: String,
			default: `Sélectionnez un lieu de mission`,
		},
		value: {
			type: Number,
			default: undefined as Undef<Number>,
		},
		missionPlace: {
			type: Object as PropType<IMissionPlaceModel>,
			default: undefined,
		},
	};

	private emits: Emits;

	private readonly state = reactive({
		dataLoaded: false,
		options: [] as IMissionPlaceDropDownListItemModel[],
	});

	get dataLoaded () { return this.state.dataLoaded; }
	get options () { return this.state.options; }

	get missionPlaceId () { return this.props.value;}
	set missionPlaceId (val: number) {
		this.emits('input', val);
		const value = this.options.find(x => x.id === val);
		if (value)
			this.emits('update:missionPlace', value);
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	protected async onBeforeMountAsync () {
		this.state.options = await useMissionPlaceService().listAsync();
		this.state.dataLoaded = true;
	}
}
