import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { TAG_LINK_TYPE } from '@/typings/enums/TAG_LINK_TYPE';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import { getApi } from '@/utils/ApiTool';
import { itemHas } from '@orion.ui/orion';
import BaseDocumentService from './BaseDocumentService';
import useSelfService from './SelfService';

type ReadableDocumentData = {
  title: string
  title_plural: string
  title_short: string
  title_short_plural: string // max 17 char.
  icon: string
}

type ReadableDocument = {
  title: string
  title_short: string
  icon: string
}

class DocumentService extends BaseDocumentService<Armado.ArmadoDocument> {
	static get data () {
		return new Map<keyof typeof DOCUMENT_TYPE, ReadableDocumentData>([
			['Bill', {
				title: `Facture`,
				title_plural: `Factures`,
				title_short: `Facture`,
				title_short_plural: `Factures`,
				icon: `icon-money-cash-file-dollar`,
			}],
			['Certificate', {
				title: `Certificat de travail`,
				title_plural: `Certificats de travail`,
				title_short: `Certif. travail`,
				title_short_plural: `Certif. travail`,
				icon: `icon-interface-file-bookmark`,
			}],
			['ClientContract', {
				title: `Contrat client`,
				title_plural: `Contrats clients`,
				title_short: `Contrat client`,
				title_short_plural: `Contrats clients`,
				icon: `icon-shopping-business-handshake`,
			}],
			['ClientProvisionDocument', {
				title: `Document mis à disposition ${useSelfService().self.isAgency() ? 'des clients' : ''}`,
				title_plural: `Documents mis à disposition ${useSelfService().self.isAgency() ? 'des clients' : ''}`,
				title_short: `Doc. mis à dispo.`,
				title_short_plural: `Doc. mis à dispo.`,
				icon: `icon-programming-script-2`,
			}],
			['ContractProlongation', {
				title: `Prolongation de contrat`,
				title_plural: `Prolongations de contrats`,
				title_short: `Prolong. contrat`,
				title_short_plural: `Prolong. contrats`,
				icon: `icon-shopping-business-handshake`,
			}],
			['HandicappedWorkerList', {
				title: `Liste des travaillleurs handicapés`,
				title_plural: `Listes des travaillleurs handicapés`,
				title_short: `Liste trav. hand`,
				title_short_plural: `Listes trav. hand`,
				icon: `icon-programming-script-2`,
			}],
			['Order', {
				title: `Commande`,
				title_plural: `Commandes`,
				title_short: `Commande`,
				title_short_plural: `Commandes`,
				icon: `icon-shopping-cart-1`,
			}],
			['PoleEmploiAttestation', {
				title: `Attestation employeur France Travail`,
				title_plural: `Attestations employeur France Travail`,
				title_short: `Attest. Pôle Emp.`,
				title_short_plural: `Attest. Pôle Emp.`,
				icon: `icon-interface-file-bookmark`,
			}],
			['Register', {
				title: `Registre du personnel`,
				title_plural: `Registres du personnel`,
				title_short: `Registre du per.`,
				title_short_plural: `Registres du per.`,
				icon: `icon-programming-script-2`,
			}],
			['SalarySlip', {
				title: `Bulletin de salaire`,
				title_plural: `Bulletins de salaire`,
				title_short: `Bulletin de sal.`,
				title_short_plural: `Bulletins de sal.`,
				icon: `icon-money-cash-file-dollar`,
			}],
			['TempWorkerContract', {
				title: `Contrat intérimaire`,
				title_plural: `Contrats intérimaires`,
				title_short: `Contrat intérim.`,
				title_short_plural: `Contrats intérim.`,
				icon: `icon-shopping-business-handshake`,
			}],
			['TempWorkerProvisionDocument', {
				title: `Document mis à disposition des intérimaires`,
				title_plural: `Documents mis à disposition des intérimaires`,
				title_short: `Doc. mis à dispo.`,
				title_short_plural: `Doc. mis à dispo.`,
				icon: `icon-programming-script-2`,
			}],
			['TimeTicket', {
				title: `Relevé d'heures`,
				title_plural: `Relevés d'heures`,
				title_short: `Relevé d'heures`,
				title_short_plural: `Relevés d'heures`,
				icon: `icon-interface-time-book-2`,
			}],
			['TripAttestation', {
				title: `Jutificatif de déplacement`,
				title_plural: `Jutificatifs de déplacements`,
				title_short: `Jutif. déplacem.`,
				title_short_plural: `Jutif. déplacem.`,
				icon: `icon-travel-map-triangle-flag`,
			}],
			['WithholdingTaxAttestation', {
				title: `Attestation d'impôts`,
				title_plural: `Attestations d'impôts`,
				title_short: `Attest. d'impôts`,
				title_short_plural: `Attest. d'impôts`,
				icon: `icon-legal-justice-scale-2`,
			}],
		]);
	};

	static getEnumTypeKey (enumTypeValue: DOCUMENT_TYPE) {
		for (const key in DOCUMENT_TYPE) {
			if (DOCUMENT_TYPE[key as keyof typeof DOCUMENT_TYPE] === enumTypeValue) return key as keyof typeof DOCUMENT_TYPE;
		}
	}

	static getReadableDocumentByEnumKey (key: keyof typeof DOCUMENT_TYPE, qty = 0) {
		const data = useDocumentService().data.get(key);
		if (!data) return;

		const res: ReadableDocument = {
			icon: data.icon,
			title_short: qty > 1 ? data.title_short_plural : data.title_short,
			title: qty > 1 ? data.title_plural : data.title,
		};

		return res;
	}

	static getTagLinkType (documentType: DOCUMENT_TYPE) {
		const key = DocumentService.getEnumTypeKey(documentType) as keyof typeof TAG_LINK_TYPE;
		if (key && TAG_LINK_TYPE[key] !== undefined) return TAG_LINK_TYPE[key];
	}

	static getDocumentTypeFromTagLink (tagLinkType: TAG_LINK_TYPE) {
		let tagKey;

		for (const key in TAG_LINK_TYPE) {
			if (TAG_LINK_TYPE[key as keyof typeof TAG_LINK_TYPE] === +tagLinkType) tagKey = key;
		}

		if (tagKey && DOCUMENT_TYPE[tagKey as keyof typeof DOCUMENT_TYPE] !== undefined) {
			return DOCUMENT_TYPE[tagKey as keyof typeof DOCUMENT_TYPE] as unknown as DOCUMENT_TYPE;
		}
	}


	private userType!: USER_TYPE;
	private documentType!: DOCUMENT_TYPE;

	get api () {
		const targetApi = getApi(this.userType, this.documentType);
		if (itemHas(targetApi, 'view') && itemHas(targetApi, 'download')) {
			return targetApi;
		} else {
			return {
				view: () => undefined,
				download: () => undefined,
			};
		}
	}


	constructor (userType: USER_TYPE, documentType: DOCUMENT_TYPE) {
		super();
		this.userType = userType;
		this.documentType = documentType;
	}
}

export default function useDocumentService (): typeof DocumentService;
export default function useDocumentService (userType: USER_TYPE, documentType: DOCUMENT_TYPE): DocumentService;
export default function useDocumentService (userType?: USER_TYPE, documentType?: DOCUMENT_TYPE): typeof DocumentService | DocumentService {
	if (userType !== undefined && documentType !== undefined) {
		return new DocumentService(userType, documentType);
	}
	return DocumentService;
}
