import { PropType, reactive, ref } from 'vue';
import { useNotif, useValidation } from '@orion.ui/orion';
import { regexName } from '@/utils/tools';
import BaseSetupService from '@/setup/BaseSetupService';
import usePersonalInformationService from '@/services/PersonalInformationService';

type Props = SetupProps<typeof ModalTempWorkerInfosSetupService.props>

export default class ModalTempWorkerInfosSetupService extends BaseSetupService<Props> {
	static readonly props = {
		resolve: {
			type: Function,
			default: undefined,
		},
		fieldToFocus: {
			type: String as PropType<'PhoneNumber' | 'FirstName' | 'LastName'>,
			default: undefined,
		},
	};

	readonly _FirstName = ref<OrionInput>();
	readonly _LastName = ref<OrionInput>();
	readonly _PhoneNumber = ref<OrionPhone>();

	private _modal?: OrionModal;
	private dbUserInfos?: IPersonalInformationModel;
	private readonly regexName = regexName;
	private readonly state = reactive({
		phone: {
			phoneCountryCode: undefined as Undef<string>,
			phoneNumber: undefined as Undef<string>,
		},
		userFirstName: '',
		userLastName: '',
		mobilePhone: undefined as Undef<string>,
		mobilePhoneCountry: 'FR' as Undef<string>,
	});

	readonly validator = useValidation(this.state, {
		userFirstName: () => this.regexName.test(this.state.userFirstName),
		userLastName: () => this.regexName.test(this.state.userLastName),
		phone: 'required|phone:mobile',
	});


	get mobilePhone () { return this.state.mobilePhone; }

	get phone () { return this.state.phone; }
	set phone (val) {
		this.state.phone = val;
		this.state.mobilePhone = val.phoneNumber;
		this.state.mobilePhoneCountry = val.phoneCountryCode;
	}

	get firstName () { return this.state.userFirstName; }
	set firstName (val) { this.state.userFirstName = val; }

	get lastName () { return this.state.userLastName; }
	set lastName (val) { this.state.userLastName = val; }


	constructor (props?: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
	}

	protected onMounted () {
		this._modal?.bus.on('enter-end', () => {
			if (this.props.fieldToFocus) {
				this[`_${this.props.fieldToFocus}`].value?.focus();
			}
		});

		this.initDataAsync();
	}


	async initDataAsync () {
		this._modal?._loader()?.show('Chargement des informations...');

		const data = await usePersonalInformationService().getAsync();
		this.dbUserInfos = data;
		Object.assign(this.state, data);

		this._modal?._loader()?.hide();
	}

	cancel () {
		this.props.resolve?.(false);
		this._modal?.close();
	}

	async saveAsync () {
		if (!this.validator.validate()) {
			useNotif.warning(`Attention`, `Vous devez renseigner tous les champs`);
			this.validator.showValidationState();
			return;
		}

		this._modal?._loader()?.show(`Enregistrement de vos informations...`);

		if (this.dbUserInfos?.id) {
			const data = await usePersonalInformationService().updateAsync(
				this.dbUserInfos.id,
				{
					...this.dbUserInfos,
					...this.state,
				},
			);

			if (data) {
				this.self.assignData({
					firstName: data.userFirstName,
					lastName: data.userLastName,
				});
				this._modal?.close();
				this.props.resolve?.(data);
			} else {
				this._modal?._loader()?.hide();
			}
		}
	}
}
