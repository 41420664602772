import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('tempworker/tempworkerprovisiondocument');

class TempWorkerProvisionDocumentApiTempWorker {
	static grid (filters: ITempWorkerProvisionDocumentPagedListForTempWorkerParameters) {
		return client.post<IPagedList<ITempWorkerProvisionDocumentItemForTempWorkerModel>>('grid', filters);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tempworkerprovisiondocument${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/tempworkerprovisiondocument${idsQuery}`, '_blank');
	}

	static getSiriusProcedure (ids: number[]) {
		return client.get<void>(`signature${`?ids=${ids.join('&ids=')}`}`);
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}

	static getNotSigned () {
		return client.get<number[]>('notsigned');
	}
}

export default TempWorkerProvisionDocumentApiTempWorker;
