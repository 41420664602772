import ApiClient from '@/api/ApiClient';
import { cloneDeep, isNil, isDate } from 'lodash-es';

const client = new ApiClient('tempworker/personalinformation');

class PersonalInformationApi {
	static get () {
		return client.get<IPersonalInformationModel>('');
	}

	static update (id: number, updateModel: OmitUserId<IUpdatePersonalInformationModel>) {
		const data = cloneDeep(updateModel);
		if (!isNil(data.birthDay) && isDate(data.birthDay)) {
			data.birthDay = data.birthDay?.toPost();
		}
		return client.put<IUpdatePersonalInformationModel>(id.toString(), data);
	}
}

export default PersonalInformationApi;
