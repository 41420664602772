<template>
	<div class="list-document-item-common-actions">
		<o-icon
			v-if="actions.includes('visualize')"
			v-tooltip="entity.hasBeenVisualized
				? `Visualisé le ${entity.document?.viewedDate?.toReadableDate()}`
				: `Visualiser`"
			font-icon="icon-interface-edit-view"
			:ripple="entity.hasBeenVisualized ? 'success' : 'info'"
			:class="{ 'text--success': entity.hasBeenVisualized }"
			@click.stop="entity.visualizeAsync()"/>

		<o-icon
			v-if="actions.includes('download')"
			v-tooltip="entity.hasBeenDownloaded
				? `Téléchargé le ${entity.document?.downloadedDate?.toReadableDate()}`
				: `Télécharger`"
			font-icon="icon-interface-download-button-2"
			:ripple="entity.hasBeenDownloaded ? 'success' : 'info'"
			:class="{ 'text--success': entity.hasBeenDownloaded }"
			@click.stop="entity.downloadAsync()"/>

		<slot/>

		<shared-tag-flat-select
			v-if="actions.includes('tag') && entity.tagLinks && setup.self.can('TagLink')"
			v-model="entity.tagLinks"
			v-model:opened="setup.tagSelectOpen"
			v-tooltip="`Assigner un tag`"
			:link-ids="[entity.id]"
			:link-type="entity.tagLinkType"/>

		<o-icon
			v-if="actions.includes('select') && setup.isTouch"
			v-tooltip="`Sélectionner`"
			icon="check"
			ripple="info"
			:class="{ 'text--info' : selected }"
			@click.stop="emits('select')"/>
	</div>
</template>

<script setup lang="ts">
import SharedListDocumentItemActionsSetupService from '@/setup/shared/SharedListDocumentItemActionsSetupService';
type Emits = {
  (e: 'select'): void,
  (e: 'update:tagOpened', val: boolean): void
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedListDocumentItemActionsSetupService.props);
const setup = new SharedListDocumentItemActionsSetupService(props, emits);
</script>

<style lang="less" scoped>
.list-document-item-common-actions {
  .ci-check {
    .arm-aside-top-actions & {
      display: none;
    }
  }
}
</style>
