import ApiClient from '@/api/ApiClient';

const client = new ApiClient('color');

class ColorApi {

	static list () {
		return client.get<IColorModel[]>(``);
	}

}

export default ColorApi;
