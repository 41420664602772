import { PropType, reactive } from 'vue';
import BaseDocumentEntity from '@/entity/BaseDocumentEntity';
import useDocumentService from '@/services/DocumentService';
import BaseSetupService from '../BaseSetupService';

type Props = SetupProps<typeof SharedListDocumentItemActionsSetupService.props>
export type Action = 'visualize' | 'download' | 'tag' | 'select'
type Emits = {
  (e: 'select'): void,
  (e: 'update:tagOpened', val: boolean): void
}

export default class SharedListDocumentItemActionsSetupService extends BaseSetupService<Props> {
	static readonly props = {
		selected: Boolean,
		tagOpened: Boolean,
		entity: {
			type: Object as PropType<BaseDocumentEntity<Armado.ArmadoDocument>>,
			required: true as const,
		},
		actions: {
			type: Array as PropType<Action[]>,
			default: () => ['visualize', 'download', 'tag', 'select'],
		},
	};

	documentService = useDocumentService(this.self.userType, this.props.entity.documentType);

	private emits: Emits;
	private readonly state = reactive({ tagSelectOpen: false });

	get routeIsDetails () {
		return !!this.router.currentRoute.value.name && /Details$/.test(this.router.currentRoute.value.name.toString());
	}

	get tagSelectOpen () { return this.state.tagSelectOpen; }
	set tagSelectOpen (val) {
		this.state.tagSelectOpen = val;
		this.emits('update:tagOpened', val);
	}


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}
}
