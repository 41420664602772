<template>
	<div
		class="shared-digital-safe-arbo-label"
		:class="{ 'shared-digital-safe-arbo-label--no-children': !setup.children.length }"
		@click.stop="setup.setActiveLabel()">
		<div
			class="shared-digital-safe-arbo-label__content"
			:class="{ 'shared-digital-safe-arbo-label__content--active': setup.service.activeLabelId === id }">
			<o-icon
				v-if="setup.children.length"
				class="shared-digital-safe-arbo-label__children-toggle"
				:font-icon="setup.isFolded ? 'icon-interface-arrows-button-right' : 'icon-interface-arrows-button-down'"
				@click.stop
				@click="setup.toggleFold()"/>

			<o-icon
				v-if="caption === 'Corbeille'"
				font-icon="icon-interface-delete-bin-2"
				class="shared-digital-safe-arbo-label__picto"/>
			<o-label
				v-else
				class="shared-digital-safe-arbo-label__color"
				:color="setup.swapColor"/>

			<span class="shared-digital-safe-arbo-label__caption">{{ caption }}</span>
		</div>

		<div
			v-if="setup.children.length && !setup.isFolded"
			class="shared-digital-safe-arbo-label__children">
			<shared-digital-safe-arbo-label
				v-for="label in setup.children"
				:key="label.id"
				v-bind="{ ...$attrs, ...label }"
				:color="label.color"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedDigitalSafeArboLabelSetupService from '@/setup/shared/SharedDigitalSafeArboLabelSetupService';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(SharedDigitalSafeArboLabelSetupService.props);
const setup = new SharedDigitalSafeArboLabelSetupService(props, _aside);
</script>


<style scoped lang="less">
@import '@/styles/mixins.less';
@import '@/styles/variables.less';

.shared-digital-safe-arbo-label {
  &__content {
    transition: border-color 0.3s, background 0.3s;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: var(--grey-darker);
    cursor: pointer;

    &:not(.shared-digital-safe-arbo-label__content--active):hover {
      border-color: var(--grey-light);
      background: rgb(var(--rgb-grey-light), 0.3);
    }

    &--active {
      border-color: rgb(var(--rgb-info), 0.3);
      background: rgb(var(--rgb-info), 0.15);;
    }
  }

  &__color {
    .square(1rem);
    padding: 0;
  }

  &__picto {
    font-size: 1rem;
  }

  &__caption {
    .ellipsis();
  }

  &__children {
    margin-left: 1rem;
  }

  &__children-toggle {
    transform: translateY(1px);
  }

  &--no-children {
    .shared-digital-safe-arbo-label__children & {
      margin-left: 1.25rem;
    }
  }
}
</style>
