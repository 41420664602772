<template>
	<div
		v-if="setup.dataLoaded"
		class="mission-place-aside-details">
		<teleport
			v-if="_aside"
			:to="`#OrionAside-${_aside.uid}__poster`">
			<div
				:ref="setup._map"
				class="mission-place-aside-details__map"/>
		</teleport>

		<o-section
			:title="setup.missionPlace.name"
			class="mission-place">
			<o-section>
				<shared-details-list-item
					legend="Adresse"
					:value="setup.missionPlace.address"/>
				<shared-details-list-item
					v-if="setup.missionPlace.addressComplement"
					legend="Complément d'adresse"
					:value="setup.missionPlace.addressComplement"/>
				<shared-details-list-item
					legend="Code postal"
					:value="setup.missionPlace.postalCode"/>
				<shared-details-list-item
					legend="Ville"
					:value="setup.missionPlace.city"/>
				<shared-details-list-item
					legend="Pays"
					:value="setup.missionPlace.country"/>
			</o-section>

			<o-section>
				<o-sticker @click="setup.missionPlace.linkedClientContract ? setup.missionPlace.goToLinked('ClientContract') : undefined">
					<template #thumbnail>
						<img src="@/assets/img/undraw/undraw_interview_re_e5jn.svg">
					</template>
					<div v-if="setup.missionPlace.linkedClientContract > 1">
						Voir les <strong>{{ setup.missionPlace.linkedClientContract }} contrats</strong> liés à ce lieu de mission.
					</div>
					<div v-else-if="setup.missionPlace.linkedClientContract">
						Voir <strong>le contrat</strong> lié à ce lieu de mission.
					</div>
					<div v-else>
						Il n'y a aucun contrat lié à ce lieu de mission.
					</div>
				</o-sticker>

				<o-sticker @click="setup.missionPlace.linkedTimeTicket ? setup.missionPlace.goToLinked('TimeTicketYear') : undefined">
					<template #thumbnail>
						<img src="@/assets/img/illustration/clock.svg">
					</template>
					<div v-if="setup.missionPlace.linkedTimeTicket > 1">
						Voir les <strong>{{ setup.missionPlace.linkedTimeTicket }} relevés d'heures</strong> liés à ce lieu de mission.
					</div>
					<div v-else-if="setup.missionPlace.linkedTimeTicket">
						Voir <strong>le relevé d'heures</strong> lié à ce lieu de mission.
					</div>
					<div v-else>Il n'y a aucun relevé d'heures lié à ce lieu de mission.</div>
				</o-sticker>

				<o-sticker @click="setup.missionPlace.linkedOrder ? setup.missionPlace.goToLinked('Order') : undefined">
					<template #thumbnail>
						<img src="@/assets/img/illustration/history.svg">
					</template>
					<div v-if="setup.missionPlace.linkedOrder > 1">
						Voir les <strong>{{ setup.missionPlace.linkedOrder }} commandes</strong> liées à ce lieu de mission.
					</div>
					<div v-else-if="setup.missionPlace.linkedOrder">
						Voir <strong>la commande</strong> liée à ce lieu de mission.
					</div>
					<div v-else>Il n'y a aucune commande liée à ce lieu de mission.</div>
				</o-sticker>
			</o-section>
		</o-section>

		<teleport
			v-if="_aside"
			:to="`#OrionAside-${_aside.uid}__footer`">
			<o-section align="stretch">
				<o-button
					color="danger"
					outline
					@click="_aside?.close()">
					Fermer
				</o-button>
				<o-button
					color="info"
					outline
					@click="setup.missionPlace.editAsync()">
					Modifier
				</o-button>
			</o-section>
		</teleport>
	</div>
</template>

<script setup lang="ts">
import MissionPlaceAsideDetailsSetupService from '@/setup/mission-place/MissionPlaceAsideDetailsSetupService';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(MissionPlaceAsideDetailsSetupService.props);
const setup = new MissionPlaceAsideDetailsSetupService(props, _aside);
</script>

<style scoped lang="less">
.mission-place-aside-details {
  &__map {
    border-radius: var(--radius-aside) var(--radius-aside) 0 0;
		height: 15rem;
	}
}

:deep(.orion-sticker) {
  &__thumbnail {
    max-width: 6rem;
  }
  &__content {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}
</style>
