import { AxiosResponse } from 'axios';
import { getUid } from '@orion.ui/orion';


type PendingRequest<T> = {
  uid: number,
  // eslint-disable-next-line no-unused-vars
  resolver?: (result: T) => void
}

class AjaxCooler<T> {
	private lastCall = 0;
	private lastCallPending = false;
	private debounce!: number;
	private callable!: Function;
	private result?: T;
	private pending = [] as PendingRequest<T>[];

	// eslint-disable-next-line no-unused-vars
	constructor (callable: (...arg: any[]) => Promise<AxiosResponse<T>>, debounce = 10000) {
		this.callable = callable;
		this.debounce = debounce;
	}

	async coolAsync (forceRefresh = false, ...arg: any[]) {
		if (this.lastCallPending && !forceRefresh) {
			const pendingRequest: PendingRequest<T> = {
				uid: getUid(),
				resolver: undefined,
			};
			this.pending.push(pendingRequest);
			return this.setPendingRequestAsync(pendingRequest);
		} else if (forceRefresh || Date.now() - this.lastCall > this.debounce) {
			this.lastCallPending = true;

			const { data } = await this.callable(...arg);
			this.result = data;
			this.lastCall = Date.now();

			// Resolve all pending request
			this.pending.forEach(x => x.resolver?.(data));

			this.lastCallPending = false;
		}

		return this.result as T;
	}

	private setPendingRequestAsync (pendingRequest: PendingRequest<T>) {
		return new Promise<T>((resolve) => {
			pendingRequest.resolver = (result) => {
				resolve(result);
			};
		});
	}
}

export default AjaxCooler;
