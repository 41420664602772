<template>
	<div class="temp-worker-add-membership">
		<h3>Saisie du code de votre agence</h3>

		<p v-if="setup.codePrefilled">
			L'agence correspondant au code ci-dessous vous invite à vous lier sur Armado.
		</p>
		<p v-else>
			Si vous avez déjà été en contact avec une agence d’intérim utilisant Armado,
			celle-ci a dû vous communiquer son code agence à 4 caractères que vous pouvez saisir ci-dessous.
		</p>

		<div class="temp-worker-add-membership__digit">
			<o-otp
				:ref="setup._otp"
				:value="setup.companyCode"
				@filled="setup.fetchCompanyAsync($event)"
				@vue:mounted="setup._otp.value?.focus()"/>
		</div>

		<div
			v-if="setup.loading"
			class="temp-worker-add-membership__skeleton"
			:class="[
				{ 'temp-worker-add-membership__skeleton--fetching': setup.loading },
			]">
			<div class="temp-worker-add-membership__skeleton-container">
				<div class="temp-worker-add-membership__skeleton-logo"/>
				<div class="temp-worker-add-membership__skeleton-main">
					<div class="temp-worker-add-membership__skeleton-title"/>
					<div class="temp-worker-add-membership__skeleton-address"/>
				</div>
			</div>
		</div>

		<o-sticker
			v-if="setup.agency && setup.agency.id && !setup.loading"
			class="temp-worker-add-membership__agency">
			<div class="temp-worker-add-membership__agency-content">
				<div class="temp-worker-add-membership__agency-logo">
					<shared-logo-company :company="setup.agency"/>
				</div>
				<div class="temp-worker-add-membership__agency-text">
					<div class="temp-worker-add-membership__agency-name">{{ setup.agencyName }}</div>
					<div class="temp-worker-add-membership__agency-address">{{ setup.agencyAddress }}</div>
				</div>
			</div>
		</o-sticker>

		<p v-if="setup.codePrefilled && setup.agency?.id">
			Si vous êtes bien en relation avec cette agence vous pouvez accepter l'invitation,
			dans le cas contraire nous vous invitons à vérifier le code ou à contacter votre agence.
		</p>

		<o-section align="stretch">
			<o-button
				outline
				color="danger"
				@click="_modal?.close()">
				Fermer
			</o-button>
			<o-button
				:disabled="!setup.agency"
				:outline="!setup.agency"
				color="brand"
				@click="_modal?.trigger('confirm', { id: setup.agency?.id, name: setup.agency?.name })">
				Confirmer
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import OnboardingTempWorkerAddMembershipModalSetupService from '@/setup/onboarding/OnboardingTempWorkerAddMembershipModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OnboardingTempWorkerAddMembershipModalSetupService.props);
const setup = new OnboardingTempWorkerAddMembershipModalSetupService(props, _modal);
</script>

<style scoped lang="less">
.temp-worker-add-membership {
  padding: 0.75rem;

  h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }

  &__skeleton {
    width: 100%;
    background: var(--grey-lighter);
    border-radius: 5px;
    padding: 1.25rem 1.5rem;
    margin-bottom: 1.25rem;

    &-container {
      display: flex;
      gap: 1.25rem;
    }

    &-logo, &-title, &-address {
      background: var(--grey-light);
      position: relative;
      overflow:hidden;

      &::after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
          90deg,
          rgba(var(--rgb-grey-lighter), 0) 0,
          rgba(var(--rgb-grey-lighter), 0.2) 20%,
          rgba(var(--rgb-grey-lighter), 0.5) 60%,
          rgba(var(--rgb-grey-lighter), 0)
        );
      }
    }

    &-logo {
      height: 4rem;
      width: 4rem;
      border-radius: 0.25rem;
    }

    &-main {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      flex: 1;
      padding-right: 2rem;
    }

    &-title, &-address {
      height: 1.25rem;
      border-radius: 0.25rem;
    }

    &-title {
      width: 11.25rem;
    }

    &-address {
      flex: 1;
    }

		&--fetching {
				.temp-worker-add-membership__skeleton-logo,
				.temp-worker-add-membership__skeleton-title,
				.temp-worker-add-membership__skeleton-address {
					&::after {
						animation: shimmer 2s infinite;
				}
			}
		}
  }

  &__digit {
    margin-bottom: 2.5rem;
  }

  &__agency {
    margin-bottom: 1.25rem;

    &-content {
      display: flex;
      gap: 1.25rem;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &-name {
      font-size: 1rem;
      color: var(--grey-darker);
      font-weight: 700;
    }
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
