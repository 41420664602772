import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'agency/withholdingtaxattestation';
const client = new ApiClient(ROUTE);

class WithholdingTaxAttestationApiAgency {
	static grid (filter: IWithholdingTaxAttestationPagedListForAgencyParameters) {
		return client.post<IPagedList<IWithholdingTaxAttestationItemForAgencyModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/withholdingtaxattestation${idsQuery}&isDownload=true`, '_blank');
	}


	static async view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/withholdingtaxattestation${idsQuery}`, '_blank');
	}
}

export default WithholdingTaxAttestationApiAgency;
