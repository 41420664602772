import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/missionplace');

class MissionPlaceApiClient {

	static create (addMissionPlaceModel: IAddMissionPlaceModel) {
		return client.post<void>(``, addMissionPlaceModel);
	}

	static read (id: number) {
		return client.get<IMissionPlaceDetailModel>(`${id}`);
	}

	static update (id: number, updateMissionPlaceModel: IUpdateMissionPlaceModel) {
		return client.put<void>(id.toString(), updateMissionPlaceModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static grid (pagedListParameters: IMissionPlacePagedListParameters) {
		return client.post<IPagedList<IMissionPlaceItemModel>>(`grid`, pagedListParameters);
	}

	static list () {
		return client.get<IMissionPlaceDropDownListItemModel[]>('');
	}

}

export default MissionPlaceApiClient;
