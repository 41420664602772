<template>
	<div v-if="setup.role">
		<o-section id="role-aside-new__step-1">
			<h4 class="role-aside-new__title">
				<span class="text--brand">
					Étape 2
				</span> - Renseignez les permissions
			</h4>
			<permission-list
				togglable
				hide-active-rights-toggle
				:role-id="setup.role.id"
				:roles-permissions="setup.role.rolePermissions"
				@input="setup.updatePermissionList($event)"/>
		</o-section>

		<o-section align="center">
			<o-button
				outline
				prefix-font-icon="icon-interface-arrows-button-left"
				@click="$emit('previous')">
				Précédent
			</o-button>
			<o-button
				color="brand"
				@click="setup.save()">
				Sauvegarder
			</o-button>
		</o-section>
	</div>
</template>

<script setup lang="ts">
import RoleAsideNewStep2SetupService from '@/setup/role/RoleAsideNewStep2SetupService';
import PermissionList from '@/components/permission/PermissionList.vue';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
defineEmits<{(e: 'previous'): void}>();
const props = defineProps(RoleAsideNewStep2SetupService.props);
const setup = new RoleAsideNewStep2SetupService(props, _aside);
</script>
