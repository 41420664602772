import { } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedAddButtonSetupService.props>

export default class SharedAddButtonSetupService extends BaseSetupService<Props> {

	static readonly props = {};

	constructor (props?: Props) {
		super(props);
	}
}
