export enum PERMISSION_ACTION {
	ClientContractRead = 0,
	ClientContractValidate = 1,
	ClientContractSign = 2,
	TempWorkerContractRead = 3,
	BillRead = 4,
	RegisterRead = 5,
	TimeTicketRead = 6,
	TimeTicketUpdate = 7,
	TimeTicketValidate = 8,
	CertificateRead = 9,
	SalarySlipRead = 10,
	ConditionRead = 11,
	ConditionCreate = 12,
	ContractProlongation = 13,
	WithholdingTaxAttestationRead = 14,
	HandicappedWorkerListRead = 15,
	TripAttestationRead = 16,
	PoleEmploiAttestationRead = 17,
	TempWorkerProvisionDocumentRead = 18,
	ClientProvisionDocumentRead = 19,
	ClientProvisionDocumentSign = 20,
	CompanyDigitalSafeDocumentRead = 21,
	OrderCreate = 22,
	OrderRead = 23,
	OrderUpdate = 24,
	UserCreate = 25,
	UserRead = 26,
	UserUpdate = 27,
	UserDelete = 28,
	OrganizationCreate = 29,
	OrganizationRead = 30,
	TagCreate = 31,
	TagUpdate = 32,
	TagRead = 33,
	TagDelete = 34,
	OrganizationRoleCreate = 35,
	OrganizationRoleRead = 36,
	OrganizationRoleUpdate = 37,
	OrganizationRoleDelete = 38,
	OrganizationUpdate = 39,
	TagLink = 40,
	CompanySettingUpdate = 42,
	CompanyLogo = 43,
	CompanyUpdateDelegate = 44,
	OrderToolbox = 45,
	Plugin = 46,
	ClientUserRead = 47,
	TempWorkerUserRead = 48,
	AdvancedPaymentDemandRead = 49,
	AgencyNewsRead = 50,
	AgencyNewsCreate = 51,
	MissionPlace = 52,
	SignatureManagement = 53,
	Statistics = 54,
	Discussion = 55,
	OrderCandidateUpdate = 56,
	OldAdmin = 99,
}
