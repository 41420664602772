<template>
	<o-select
		v-if="setup.dataLoaded"
		v-model="setup.selectedTagIds"
		label="Tag"
		display-key="name"
		track-key="id"
		value-key="id"
		:searchable="setup.options.length > 10"
		:options="setup.options"
		v-bind="{ ...$attrs, multiple }">
		<template
			v-if="!multiple"
			#value="{ item: tag }">
			<div
				v-if="tag"
				class="tag-option">
				<div
					class="tag-color"
					:class="`background--${tag.colorName}`"/>
				{{ tag.name }}
			</div>
		</template>

		<template
			v-else
			#value="{ item: tag }">
			<div
				v-if="tag && typeof tag === 'number'"
				class="tag-option">
				<div
					class="tag-color"
					:class="`background--${setup.getTagById(tag)?.colorName}`"/>
				{{ setup.getTagById(tag)?.name }}
			</div>
			<div
				v-else-if="tag"
				class="tag-option">
				<div
					class="tag-color"
					:class="`background--${tag.colorName}`"/>
				{{ tag.name }}
			</div>
		</template>

		<template #option="{ item: tag }">
			<div class="tag-option">
				<div
					class="tag-color"
					:class="`background--${tag.colorName}`"/>
				{{ tag.name }}
			</div>
		</template>
	</o-select>
</template>

<script setup lang="ts">
import SharedTagSelectSetupService from '@/setup/shared/SharedTagSelectSetupService';
type Emits = {(e: 'update:modelValue', val: number | number[] | undefined): void}
const emits = defineEmits<Emits>();
const props = defineProps(SharedTagSelectSetupService.props);
const setup = new SharedTagSelectSetupService(props, emits);
</script>

<style scoped lang="less">
.tag-color {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;

  .orion-select__selected-item & {
    margin-left: calc((5px) * -1);
  }
}

.tag-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  .orion-select__selected-item & {
    gap: 0.25rem;
  }
}
</style>
