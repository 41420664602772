import { useModal, useNotif } from '@orion.ui/orion';
import TagUserApi from '@/api/TagUserApi';
import useSelfService from '../SelfService';
import router from '@/router/index.router';
import SharedSelectUserList from '@/components/shared/SharedSelectUserList.vue';
import useColorService from '../ColorService';

class TagUserService {
	private get api () { return TagUserApi; }
	private get self () { return useSelfService().self; }


	async addAsync (tagId: number, userIds: number[], organizationId = this.self.organization?.id) {
		if (!organizationId) return;

		try {
			const { data } = await this.api.add({
				tagId,
				userIds,
				organizationId,
			});
			return data;
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async deleteAsync (ids: number[]) {
		try {
			const { data } = await this.api.delete({ ids });
			return data;
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async userListAsync (tagId: number) {
		try {
			const { data } = await TagUserApi.listUser(tagId);
			return data;
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async promptUsersToAddTagAsync (tagId: number, organizationId = this.self.organization?.id) {
		if (!organizationId) {
			router.push({ name: 'UserGroupDashboard' });
			return;
		}

		return new Promise<Undef<IOrganizationUserListItemModel[]>>((resolve) => {
			useModal({
				Nested: SharedSelectUserList,
				NestedProps: {
					title: `Sélectionnez des utilisateurs`,
					excludeTagId: tagId,
				},
				size: 'sm',
				customClass: 'user-select-modal-wrapper',
				events: {
					'cancel': () => resolve(undefined),
					'add': async (Modal, payload: IOrganizationUserListItemModel[]) => {
						Modal._loader()?.show(`Enregistrement des tags...`);

						try {
							const userIds = payload.map(x => x.user?.id) as number[];
							await this.api.add({
								userIds,
								tagId,
								organizationId,
							});
						} catch (e: any) {
							useNotif.danger(`Oops`, e?.response?.data);
						}

						Modal._loader()?.hide();
						resolve(payload);
						Modal.close();
					},
				},
			});
		});
	}

	async selfAsync (...args: Parameters<typeof TagUserApi.self>) {
		const { data } = await TagUserApi.self(...args);
		return data.map((x) => {
			return {
				...x,
				tagColorName: useColorService().swapColor(x.tagColorName),
			};
		});
	}
}

const tagUserServiceSingleton = new TagUserService();

export default function useTagUserService () {
	return tagUserServiceSingleton;
}
