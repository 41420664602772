import { reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationRoleDetailEntity from '@/entity/OrganizationRoleDetailEntity';
import useColorService from '@/services/ColorService';
import RolePermissionApi from '@/api/RolePermissionApi';

type Props = SetupProps<typeof RoleAsideNewSetupService.props>

export default class RoleAsideNewSetupService extends BaseSetupService<Props> {
	static readonly props = {};

	private readonly state = reactive({ activePane: 1 });

	role = new OrganizationRoleDetailEntity();

	get activePane () {return this.state.activePane;}
	set activePane (val) { this.state.activePane = val;}

	constructor (props?: Props) {
		super(props);
	}

	protected async onBeforeMountAsync (): Promise<void> {
		this.role.color = (await useColorService().listAsync()).first();
		await this.getPermissionListAsync();
	}

	goToNextStep () {
		this.state.activePane++;
	}

	goToPreviousStep () {
		this.state.activePane--;
	}

	async getPermissionListAsync () {
		const { data } = await RolePermissionApi.getDefaultListByUserType();
		this.role.rolePermissions = data;
	}

	handlePillClick (pane: OrionTimelinePane) {
		this.state.activePane = +pane.name;
	}
}
