import AppSignalR from '@/signalr/App.signalr';
import signalrClientMethod from './names/Signalr.clientMethod';

class SignalRTagUser {

	static onTagUserUpdate (listener: () => void) {
		AppSignalR.off(signalrClientMethod.TagUserUpdate, listener);

		return AppSignalR.on(signalrClientMethod.TagUserUpdate, listener);
	}
}

export default SignalRTagUser;
