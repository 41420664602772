<template>
	<o-card
		v-tooltip="picto.tooltip ? picto.tooltip : !label ? picto.label : undefined"
		class="picto-card"
		:selected="selected"
		:class="[
			{
				'picto-card--error': error,
				'picto-card--selected': selected,
				'picto-card--selectable': selectable,
				'picto-card--callback': picto.callback,
				'picto-card--marker': marker,
			},
			`picto-card--${color}`,
		]"
		@click="setup.handleClick()">
		<div class="picto-card__content">
			<o-icon
				v-if="closable"
				ripple="danger"
				class="picto-card__close"
				font-icon="icon-interface-delete-1"
				@click="$emit('delete', picto)"/>
			<o-icon
				class="picto-card__icon"
				:font-icon="`icon-${picto.icon}`"/>
			<small
				v-if="picto.label && label"
				class="picto-card__label">
				<slot>{{ picto.label }}</slot>
			</small>
		</div>
	</o-card>
</template>

<script setup lang="ts">
import SharedPictoCardSetupService, { Picto } from '@/setup/shared/SharedPictoCardSetupService';
type Emits = { (e: 'delete' | 'click', value: Picto) : void}
const emits = defineEmits<Emits>();
const props = defineProps(SharedPictoCardSetupService.props);
const setup = new SharedPictoCardSetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/variables';
@import '@/styles/mixins.less';

.picto-card {
  color: var(--grey);
  background: rgba(var(--rgb-grey-light), 0.40);
  box-shadow: none;
	position: relative;

  :deep(.orion-card__body) {
    padding: 0.250rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

	&__alert-icon {
		position: absolute;
		bottom: -1.25rem;
		right: calc(50% - 0.6rem);
		font-size: 1.25rem;
		color: var(--warning);
	}

  &__content {
    .flex-align(center, center);
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.3rem;
    height: 100%;
    position: relative;
    font-size: 1.75rem;
    padding: 0.3rem 0;
  }

  &__close {
    position: absolute;
    top: 0.1rem;
    right: 0rem;
    font-size: 0.45rem;
		color: var(--grey-darker);

    &:hover {
      color: var(--danger);
    }
  }

  &__icon {
    align-self: center;
  }

  &__label {
    flex: 1;
    font-size: 0.6rem;
    line-height: 1.1;
    text-align: center;
    color: var(--grey-dark);
    max-width: 100%;
  }

  img, svg {
    .square(1.5rem);
    display: block;
  }

  &--selected {
    > .orion-card__selected {
      transform: scale(0.7);
      &::before, &::after {
        display: none;
      }
    }
  }

  &--selectable, &--callback {
    cursor: pointer;
  }

	&--marker {
		&::after {
			.circle(0.5rem);
			content: '';
			display: block;
			position: absolute;
			top: -0.125rem;
			right: -0.125rem;
			background: var(--warning);
		}
	}

  &--error{
    outline: 0.06rem solid var(--danger);
  }


	each(@colorsAndGreys, {
		@color: ~"--@{value}";
		@colorDark: ~"--@{value}-dark";
		@colorRgba: ~"--rgb-@{value}";
		&--@{value} {
			background-color: rgba(var(@colorRgba), 0.25);
      color: var(@color);

      .picto-card__label {
        color: var(@colorDark);
      }
		}
	})

	&--pending {
		background: rgba(var(--rgb-warning), 0.15);
		color: var(--warning-dark);
	}

  &:not(.picto-card--selectable):not(.picto-card--callback):hover {
    box-shadow: none !important;
    transform: none !important;
  }
}
</style>

