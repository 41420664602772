<template>
	<v-dropdown
		:ref="setup._popover"
		class="shared-company-flat-select"
		@apply-show="setup.handlePopoverShowAsync()"
		@apply-hide="setup.handlePopoverHide()">
		<slot>
			<div class="shared-company-flat-select__select">
				<div class="shared-company-flat-select__display">
					<o-button
						nude
						color="info">
						Associer à une société
					</o-button>
				</div>
			</div>
		</slot>

		<template #popper>
			<o-button
				v-if="displayAddAll"
				v-tooltip="modelValue?.length === setup.companiesList.length
					? `Toutes les sociétés sont déjà liées`
					: undefined"
				color="info"
				outline
				block
				:disabled="modelValue?.length === setup.companiesList.length"
				@click="setup.addAllCompaniesAsync()">
				Lier toutes les sociétés
			</o-button>

			<shared-flat-select
				v-if="setup.companiesList.length"
				v-model:search="setup.search"
				:show-search="setup.companies.length > 10"
				:loading="setup.loading"
				:value="modelValue"
				:selected-function="setup.companyIsSelected.bind(setup)"
				:options="setup.companiesListOptionsForFlatSelect"
				@add="setup.addCompanyAsync($event)"
				@delete="setup.deleteCompanyAsync($event)">
				<template #default="{ item: company }">
					<div
						v-if="company"
						class="shared-company-flat-select__item">
						<p
							class="shared-company-flat-select__item-name"
							v-html="company.name.mark(setup.search)"/>
						<p
							v-if="company.contactDetailCity"
							class="shared-company-flat-select__item-city"
							v-html="company.contactDetailCity.mark(setup.search)"/>
						<o-label
							v-if="setup.self.isClient()"
							outline>
							{{ company.agencyCompanyName }}
						</o-label>
					</div>
				</template>

				<template #empty>
					<o-alert
						color="info"
						center>
						Vous n'avez pas encore de sociétés.
					</o-alert>
				</template>
			</shared-flat-select>
		</template>
	</v-dropdown>
</template>

<script setup lang="ts">
import SharedCompanyFlatSelectSetupService from '@/setup/shared/SharedCompanyFlatSelectSetupService';
type Emits = {
	(e: 'update:opened', val: boolean): void
	(e: 'update:modelValue', val?: ICompanyModel[]): void
	(e: 'add' | 'delete', val?: ICompanyModel): void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedCompanyFlatSelectSetupService.props);
const setup = new SharedCompanyFlatSelectSetupService(props, emits);
</script>

<style scoped lang="less">
.shared-company-flat-select {
	&__item-name {
		color: var(--grey-darker);
		font-size: 13px;
		font-weight: 700;
		margin-bottom: unset;
	}

	&__item-city {
		font-size: 13px;
		font-weight: 400;
		color: var(--grey-dark);
		margin-bottom: unset;

		+ .arm-label {
			margin-top: 4px;
		}
	}
}
</style>
