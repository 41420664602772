import { itemIs, useLoader } from '@orion.ui/orion';
import { DOCUMENT_EVENT_TYPE } from '@/typings/enums/DOCUMENT_EVENT_TYPE';
import useSelfService from './SelfService';
import { idsQuery } from '@/utils/tools';

export default abstract class BaseDocumentService<T extends Armado.ArmadoDocument> {
	protected abstract api: {
		view(idsQuery: string): void;
		download(idsQuery: string): void;
	}

	self = useSelfService().self;


	async visualizeAsync (documents: T[]) {
		try {
			useLoader().show(`Préparation de la visualisation...`);

			const queryStr = idsQuery(documents);
			if (queryStr) {
				await this.api.view(queryStr);
				this.addDocumentEvent(documents, DOCUMENT_EVENT_TYPE.View);
			}
		} finally {
			useLoader().hide();
		}
	}

	async downloadAsync (documents: T[]) {
		try {
			useLoader().show(`Préparation du téléchargement...`);

			const queryStr = idsQuery(documents);

			if (queryStr) {
				await this.api.download(queryStr);
				this.addDocumentEvent(documents, DOCUMENT_EVENT_TYPE.Download);
			}
		} finally {
			useLoader().hide();
		}
	}

	private addDocumentEvent (items: T[], event: DOCUMENT_EVENT_TYPE) {
		if (!this.self.isAgency()) {
			const isoDate = new Date().toISOString();
			items.forEach((x) => {
				if (!!x.document && itemIs<IDocumentWithEventModel>(x.document, 'viewedDate', 'downloadedDate'))
					if (event === DOCUMENT_EVENT_TYPE.View && x.document?.viewedDate === null) {
						x.document.viewedDate = isoDate;
					} else if (event === DOCUMENT_EVENT_TYPE.Download && x.document?.downloadedDate === null) {
						x.document.downloadedDate = isoDate;
					}
			});
		}
	}
}
