import FeatureFlagApi from '@/api/FeatureFlagApi';
import { reactive } from 'vue';

const features = {
	userfingerprint: false,
	onboarding: false,
} as const;

type Feature = keyof typeof features;
type Features = Record<keyof typeof features, boolean>;

class FeatureFlagService {
	private readonly state = reactive({ requestedFeatures: [] as Feature[] });

	private features = reactive({ ...features }) as Features;

	get (feature: Feature) {
		return this.features[feature];
	}

	/**
   * Calls to this method shoul be placed in src/main.ts
   * to use only the .get method inside the app (avoiding async/await)
   */
	async getAsync (feature: Feature) {
		if (!this.state.requestedFeatures.includes(feature)) {
			const { data } = await FeatureFlagApi.get(feature);
			this.features[feature] = data.value === 'true';
		}

		return this.features[feature];
	}
}

const featureFlagServiceSingleton = new FeatureFlagService();

export default function useFeatureFlagService () {
	return featureFlagServiceSingleton;
}
