<template>
	<o-section title="Modifier votre logo">
		<o-upload
			v-model="setup.files"
			:file-types="['image/jpeg', 'image/png']"
			:label="`Glisser et déposer un fichier ici au format PNG/JPEG (1 Mo max)`"/>

		<o-cropper
			v-if="setup.selectedFile"
			:ref="setup._cropper"
			:file="setup.selectedFile"
			:crop-width="200"
			:crop-height="200"/>
	</o-section>

	<o-section align="stretch">
		<o-button
			color="danger"
			outline
			@click="_modal?.close()">
			Annuler
		</o-button>
		<o-pop-confirm @confirm="setup.deleteAsync()">
			<o-button
				v-if="companyLogoId"
				color="danger">
				Supprimer
			</o-button>
		</o-pop-confirm>
		<o-button
			color="info"
			@click="setup.uploadAsync()">
			Valider
		</o-button>
	</o-section>
</template>

<script setup lang="ts">
import SharedLogoCompanyModalSetupService from '@/setup/shared/SharedLogoCompanyModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(SharedLogoCompanyModalSetupService.props);
const setup = new SharedLogoCompanyModalSetupService(props, _modal);
</script>
