import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/contractprolongation');

class ContractProlongationApiAgency {
	static grid (filter: IContractProlongationPagedListForAgencyParameters) {
		return client.post<IPagedList<IContractProlongationItemForAgencyModel>>('grid', filter);
	}
}

export default ContractProlongationApiAgency;
