import OrganizationCompanyListItemEntity from './OrganizationCompanyListItemEntity';

export default class OrganizationCompanyDetailedEntity extends OrganizationCompanyListItemEntity<ICompanyDetailedModel> {
	get contactDetailAddress () { return this.state.contactDetailAddress; }
	get contactDetailCity () { return this.state.contactDetailCity; }
	get contactDetailDelegateFirstName () { return this.state.contactDetailDelegateFirstName; }
	get contactDetailDelegateLastName () { return this.state.contactDetailDelegateLastName; }
	get contactDetailEmail () { return this.state.contactDetailEmail; }
	get contactDetailHomePhone () { return this.state.contactDetailHomePhone; }
	get contactDetailSiret () { return this.state.contactDetailSiret; }
	get contactDetailWebsite () { return this.state.contactDetailWebsite; }
}
