import { TAG_LINK_TYPE } from '@/typings/enums/TAG_LINK_TYPE';
import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import { qsTo64 } from '@/utils/tools';
import TagLinkApi from '@/api/TagLinkApi';
import router from '@/router/index.router';
import useDocumentService from '../DocumentService';

class TagLinkService {
	private get api () { return TagLinkApi; }


	getKeyFromType (type: TAG_LINK_TYPE) {
		return Object.keys(TAG_LINK_TYPE).find(k => TAG_LINK_TYPE[k as keyof typeof TAG_LINK_TYPE] === type);
	}

	getReadableDoc (type: TAG_LINK_TYPE, qty = 0) {
		const documentType = useDocumentService().getDocumentTypeFromTagLink(type);
		if (!documentType) return;

		const key = useDocumentService().getEnumTypeKey(documentType) as keyof typeof DOCUMENT_TYPE;
		if (key) return useDocumentService().getReadableDocumentByEnumKey(key as keyof typeof DOCUMENT_TYPE, qty);
	}

	goToDocumentListFilteredByTag (tagId: number, linkType: TAG_LINK_TYPE, newTab = false) {
		const key = this.getKeyFromType(linkType);
		if (!key) return;

		let routeName!: string;
		const params: Record<string, any> = {};
		switch (key) {
		case 'TimeTicket':
			routeName = 'TimeTicketYear';
			params.year = new Date().getFullYear();
			break;
		default:
			routeName = key;
			break;
		}

		const route = {
			name: routeName,
			params,
			query: { filter: qsTo64({ tagIds: [tagId] }) },
		};

		if (newTab) {
			const url = router.resolve(route);
			window.open(url.href, '_blank');
		} else {
			router.push(route);
		}

	}

	async addAsync (tagId: number, type: TAG_LINK_TYPE, linkIds: number[]) {
		const { data } = await this.api.add({
			tagId,
			type,
			linkIds,
		});
		return data;
	}

	async deleteAsync (ids: number[]) {
		const { data } = await this.api.delete({ ids });
		return data;
	}

	async linkListAsync (tagId: number) {
		const { data } = await TagLinkApi.countByType(tagId);
		return data;
	}
}

const tagLinkServiceSingleton = new TagLinkService();

export default function useTagLinkService () {
	return tagLinkServiceSingleton;
}
