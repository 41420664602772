<template>
	<div class="shared-progress-bar">
		<div class="shared-progress-bar__circle shared-progress-bar__circle--start"/>
		<div
			v-tooltip="{ content: setup.props.tooltip }"
			class="shared-progress-bar__circle shared-progress-bar__circle--track"
			:style="{ marginLeft : value > 100 ? `100%` : `${value}%` }"/>
		<div class="shared-progress-bar__circle shared-progress-bar__circle--end"/>
		<div
			class="shared-progress-bar__track"
			:style="{ width : `${value}%` }"/>
		<div
			class="shared-progress-bar__path"
			:style="{
				background:
					gradientValue ? `linear-gradient(
					to right,
					var(--grey-light) ${gradientValue}%,
					transparent ${gradientValue}% calc(${ gradientValue }% + 0.25rem),
					rgba(var(--rgb-warning), 0.4) calc(${ gradientValue }% + 0.25rem) calc(100% - 0.5rem),
					transparent calc(100% - 0.75rem)` : undefined,
			}"/>
	</div>
</template>

<script setup lang="ts">
import SharedProgressBarSetupService from '@/setup/shared/SharedProgressBarSetupService';
const props = defineProps(SharedProgressBarSetupService.props);
const setup = new SharedProgressBarSetupService(props);
</script>

<style lang="less">
@import '@/styles/variables';
@import '@/styles/mixins';

.shared-progress-bar {
	position: relative;
	display: flex;
	align-items: center;
	height: 2rem;
	width: 100%;

	&__circle {
		position: absolute;
		border-radius: 50%;
		height: 0.5rem;
		width: 0.5rem;

		&--start {
			left: 0;
			background-color: var(--info);
			transform: translateX(-50%);
		}

		&--track {
			height: 1rem;
			width: 1rem;
			background: white;
			border: 3px solid var(--info);
			transform: translateX(-50%);
			z-index: 3;
			box-shadow:
			0 0.125rem 0.125rem rgba(0, 0, 0, 0.03),
			0 0.25rem 0.25rem rgba(0, 0, 0, 0.03),
			0 0.5rem 0.5rem rgba(0, 0, 0, 0.03),
			0 1rem 1rem rgba(0, 0, 0, 0.03),
			0 2rem 2rem rgba(0, 0, 0, 0.03);
		}

		&--end {
			background-color: var(--grey-light);
			right: 0;
			transform: translateX(50%);

		}
	}

	&__path {
		position: absolute;
		height: 0.125rem;
		width: 100%;
		z-index: 0;
	}

	&__track {
		position: relative;
		display: flex;
		align-items: center;
		height: 0.125rem;
		background-color: var(--info);
		z-index: 1;

		&::before {
			position: absolute;
			content: "";
			border-radius:2px;
			height: 2rem;
			width: 100%;
			background: rgba(var(--rgb-info), 0.1);
			z-index: 3;
			right: 0;
			left: 0;
		}
	}
}

/* .shared-progress-bar {
	position: relative;
	align-items: center;
	display: flex;
	width: 100%;

	&__path {
		background-color: var(--grey-light);
		width: 100%;
		&-start, &-end {
			position: absolute;
			background-color: var(--grey-light);
		}

		&-start {
			left: 0;
		}

		&-end {
			right: 0;
		}
	}

	&__value {
		transition: width 0.6s;
		will-change: width;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		max-width: 100%;
		height: 100%;

		each(@colors, {
			@color: ~"--@{value}";
      .shared-progress-bar--@{value} & {
        background: var(@color);
      }
    });
	}
} */
</style>
