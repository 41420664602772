import PersonalInformationApi from '@/api/PersonalInformationApiTempWorker';

class PersonalInformationService {
	async getAsync () {
		const { data } = await PersonalInformationApi.get();
		return data;
	}

	async updateAsync (...arg: Parameters<typeof PersonalInformationApi.update>) {
		const { data } = await PersonalInformationApi.update(...arg);
		return data;
	}
}

const personalInformationServiceSingleton = new PersonalInformationService();

export default function usePersonalInformationService () {
	return personalInformationServiceSingleton;
}
