import { reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import CompanyCandidateCodeApi from '@/api/CompanyCandidateCodeApi';
import useMembershipService from '@/services/MembershipService';
import { useNotif, useValidation } from '@orion.ui/orion';

type Props = SetupProps<typeof OnboardingCompanyCodeModalSetupService.props>

export default class OnboardingCompanyCodeModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		id: {
			type: Number,
			required: true as const,
		},
	};


	private _modal?: OrionModal;
	private readonly state = reactive({
		agencyCompanyId: undefined as Undef<number>,
		selectedAgency: undefined as Undef<ICompanyModel>,
		selectedShareMethod: 'phone' as 'phone' | 'email' | 'copy',
		code: undefined as Undef<ICompanyCandidateCodeModel>,
		email: undefined as Undef<string>,
		phone: {
			phoneCountryCode: null,
			phoneNumber: null,
		},
	});

	get code () { return this.state.code; }

	get isValid () {
		return this.selectedShareMethod === 'phone'
			? useValidation().check(this.phone, 'required|phone:mobile')
			: useValidation().check(this.email, 'required|email');
	}

	get selectedAgency () { return this.state.selectedAgency; }
	set selectedAgency (val) { this.state.selectedAgency = val; }

	get selectedShareMethod () { return this.state.selectedShareMethod; }
	set selectedShareMethod (val) { this.state.selectedShareMethod = val; }

	get email () { return this.state.email; }
	set email (val) { this.state.email = val; }

	get phone () { return this.state.phone; }
	set phone (val) { this.state.phone = val; }

	get agencyCompanyId () { return this.state.agencyCompanyId; }
	set agencyCompanyId (val) {
		this.state.code = undefined;
		this.state.agencyCompanyId = val;
		this.getCompanyCandidateCodeAsync();
	}


	constructor (props?: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
	}


	onSelectMounted () {
		setTimeout(() => {
			this.agencyCompanyId = this.props.id;
		}, 300);
	}

	share () {
		if (!this.isValid) return;

		this._modal?.trigger('shared', {
			phone: this.state.phone,
			email: this.state.email,
			selectedShareMethod: this.selectedShareMethod,
		});
	}

	async getCompanyCandidateCodeAsync () {
		let code = undefined as Undef<ICompanyCandidateCodeModel>;

		if (this.agencyCompanyId) {
			code = await useMembershipService().getCodeCompanyByCompanyIdAsync(this.agencyCompanyId);
		}

		setTimeout(() => {
			this.state.code = code;
		}, 1000);
	}

	downloadCode () {
		if (this.agencyCompanyId) {
			CompanyCandidateCodeApi.download(this.agencyCompanyId);
		}
	}

	copyCodeToClipboard () {
		if (this.state.code?.code) {
			this.state.selectedShareMethod = 'copy';
			navigator.clipboard.writeText(this.state.code.code);
			useNotif.info(`Code copié dans votre presse-papier`);
		}
	}
}
