import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { itemIs } from '@orion.ui/orion';

type Props = SetupProps<typeof SharedDetailsDocumentHistorySetupService.props>

export default class SharedDetailsDocumentHistorySetupService extends BaseSetupService<Props> {
	static readonly props = {
		useTimeStamp: Boolean,
		document: {
			type: Object as PropType<Armado.ArmadoDocument>,
			default: null,
		},
	};


	constructor (props?: Props) {
		super(props);
	}

	isDocumentWithSignature (): this is { props: { document: { document: IDocumentWithSignatureModel } } } {
		return this.props.document.document
			? itemIs<IDocumentWithSignatureModel>(this.props.document.document, 'agencySignatureDate')
			: false;
	}
}
