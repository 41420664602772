<template>
	<div class="shared-document-sticker">
		<o-sticker
			:ref="setup._el"
			:selected="selected"
			v-bind="$attrs"
			:class="{
				'tag-select-open': setup.tagSelectOpen,
				'is-opened': opened,
				'floating-actions' : !setup.isTouch,
			}"
			@touchstart="setup.handleTouchStart()"
			@touchend="setup.handleTouchEnd()"
			@touchmove="setup.handleScroll()">
			<div class="shared-document-sticker__header flex ai-c jc-sb mb-xs">
				<div class="flex ai-c g-xs">
					<div class="document__logo">
						<slot name="logo"/>
					</div>
					<div
						v-if="title"
						class="document__ref">
						{{ title }}
					</div>
					<slot name="title"/>
				</div>

				<div class="document__label">
					<slot name="label"/>
				</div>
			</div>

			<slot/>

			<shared-tag-dots-expandable :tag-links="entity.tagLinks"/>

			<template #actions>
				<shared-list-document-item-actions
					v-bind="{ entity, selected, actions }"
					v-model:tag-opened="setup.tagSelectOpen"
					@select="$emit('select')"
					@touchstart.stop>
					<slot name="actions"/>
				</shared-list-document-item-actions>
			</template>
		</o-sticker>
		<o-checkbox
			v-if="!setup.isTouch && !hideCheckbox"
			:model-value="selected"
			class="shared-document-sticker__checkbox"
			@click.stop="emits('select')"/>
	</div>
</template>

<script setup lang="ts">
import SharedDocumentStickerSetupService from '@/setup/shared/SharedDocumentStickerSetupService';
type Emits = { (e: 'select'): void }
const emits = defineEmits<Emits>();
const props = defineProps(SharedDocumentStickerSetupService.props);
const setup = new SharedDocumentStickerSetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/variables.less';

:deep(.orion-sticker) {
	width: inherit;
}

.shared-document-sticker {
	display: flex;
	width: 100%;
	position: relative;
	justify-content: stretch;

	&__checkbox {
		position: absolute;
		right: -2rem;
    bottom: calc(50% - 1rem);
	}
}
</style>
