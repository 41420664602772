import UserFingerprintApi from '@/api/UserFingerprintApi';
import { initSentry, getArmadoApp } from '@/app';
import router from '@/router/index.router';
import * as Sentry from '@sentry/vue';

const fpModuleUrl = 'https://m.myarmado.fr/Ra9DZpFBwhqac2Sx/i7yjP5TR271KPppH?apiKey=6ApraYr3voVWasSebAVs';

class FingerprintService {
	async getPhoneNumberAsync (...args: Parameters<typeof UserFingerprintApi.getPhoneNumber>) {
		const { data } = await UserFingerprintApi.getPhoneNumber(...args);
		return data;
	}

	async validateAsync (...args: Parameters<typeof UserFingerprintApi.validate>) {
		const { data } = await UserFingerprintApi.validate(...args);
		return data;
	}

	async resendCodeAsync (...args: Parameters<typeof UserFingerprintApi.resendCode>) {
		const { data } = await UserFingerprintApi.resendCode(...args);
		return data;
	}

	async resendSmsCodeAsync (...args: Parameters<typeof UserFingerprintApi.resendSmsCode>) {
		const { data } = await UserFingerprintApi.resendSmsCode(...args);
		return data;
	}

	private async callFingerprintAsync () {
		Sentry.init(); // prevents FingerPrint error

		const fpPromise = import(/* @vite-ignore */ fpModuleUrl)
			.then(FingerprintJS => FingerprintJS.load({
				apiKey: '6ApraYr3voVWasSebAVs',
				region: 'eu',
				endpoint: 'https://fp-dev.myarmado.fr',
			})) as Promise<{ get: (...arg: any[]) => Promise<Record<string, any>>}>;

		const fp = await fpPromise;
		const res = await fp.get({ extendedResult: true });

		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + 30);
		res.expirationDate = expirationDate;

		localStorage.setItem('fpLocal', JSON.stringify(res));

		initSentry(getArmadoApp(), router);
	}

	async getFingerprintAsync (): Promise<VisitorData> {
		let fpLocal = localStorage.getItem('fpLocal');
		const parsedFpLocal = JSON.parse(fpLocal ?? '{}');

		if (fpLocal) {
			if (Date.now() > new Date(parsedFpLocal.expirationDate).valueOf()) {
				fpLocal = null;
			}
		}

		if (fpLocal === null || !parsedFpLocal.visitorId) {
			await this.callFingerprintAsync();
			fpLocal = localStorage.getItem('fpLocal');
		}

		const visitorData = JSON.parse(fpLocal as string) as VisitorData;

		return {
			visitorId: visitorData?.visitorId,
			ipAddress: visitorData?.ip,
			place: `${visitorData?.ipLocation?.country?.name} ${visitorData?.ipLocation?.city?.name}`,
			browserName: visitorData?.browserName,
			browserMajorVersion: visitorData?.browserMajorVersion,
			os: visitorData?.os,
			osVersion: visitorData?.osVersion,
			device: visitorData?.device,
		};
	}
}

const fingerprintServiceSingleton = new FingerprintService();

export default function useFingerprintService () {
	return fingerprintServiceSingleton;
}
