<template>
	<div class="list-document-common-actions">
		<o-button
			outline
			prefix-icon="download"
			color="info"
			@click="setup.documentService.downloadAsync(selected)">
			Télécharger
		</o-button>

		<o-button
			v-tooltip="selected.length > 20 ? `Vous ne pouvez pas visualiser plus de 20 documents simultanément` : undefined"
			outline
			prefix-icon="show"
			color="info"
			:disabled="selected.length > 20"
			@click="selected.length <= 20 ? setup.documentService.visualizeAsync(selected) : undefined">
			Visualiser
		</o-button>

		<shared-tag-flat-select
			v-if="setup.self.can('TagLink')"
			v-tooltip="`Assigner un tag`"
			:mapped-value="setup.mappedValue()"
			v-bind="{ linkType }"
			class="list-document-common-actions__tag-select"
			@add="setup.handleTagAdd($event as ITagLinkModel[])"
			@delete="setup.handleTagDelete($event as unknown as [number[]])">
			<o-button
				v-tooltip="selected.length > 20 ? `Vous ne pouvez pas taguer plus de 20 documents simultanément` : undefined"
				outline
				prefix-font-icon="icon-interface-bookmark-tag"
				color="info"
				:disabled="selected.length > 20">
				Taguer
			</o-button>
		</shared-tag-flat-select>

		<slot/>
	</div>
</template>

<script setup lang="ts">
import SharedListDocumentActionsSetupService from '@/setup/shared/SharedListDocumentActionsSetupService';
const props = defineProps(SharedListDocumentActionsSetupService.props);
const setup = new SharedListDocumentActionsSetupService(props);
</script>

<style lang="less" scoped>
@import '@/styles/variables';
.list-document-common-actions {
  display: flex;
  flex-wrap: wrap;

	&__tag-select {
		@media @layoutNavBottom {
			width: 100%;
		}
	}
}
</style>
