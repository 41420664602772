import { isNil, cloneDeepWith, isDate, isArray, pick, get, set } from 'lodash-es';
import { useModal, toggleGlobalListener, getUid } from '@orion.ui/orion';
import { DOCUMENT_EXTENSION } from './CONST';
import { PAYMENT_TYPE } from '@/typings/enums/PAYMENT_TYPE';
import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import ModalConfirmPassword from '@/components/modal/ModalConfirmPassword.vue';
import { TEMP_WORKER_DOCUMENT_TYPE } from '@/typings/enums/TEMP_WORKER_DOCUMENT_TYPE';
import { USER_STATUS } from '@/typings/enums/USER_STATUS';
import { RECOURS_TYPE } from '@/typings/enums/RECOURS_TYPE';
import { CONTRACT_TYPE } from '@/typings/enums/CONTRACT_TYPE';
import ModalTempWorkerInfos from '@/components/modal/ModalTempWorkerInfos.vue';


export const hashCode = (str: string) => [...str].reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0);

export const regexName = /^[A-Za-zÀ-ÖØ-öø-ÿ-'\s]+$/;
export const regexMobile = /^([+]33|0)(6|7)\d{8}$/;
export const regexPhone = /^([+]33|0)\d{9}$/;

export const advancedPaymentTypesOptions = [
	{
		value: PAYMENT_TYPE.Cheque,
		label: `Chèque`,
	},
	{
		value: PAYMENT_TYPE.Virement,
		label: `Virement`,
	},
	{
		value: PAYMENT_TYPE.Espece,
		label: `Espèces`,
	},
];

export const missingOptions = [
	{
		libelle: 'Maladie',
		code: 'M',
	},
	{
		libelle: 'Absence',
		code: 'A',
	},
	{
		libelle: 'Absence justifiée',
		code: 'J',
	},
	{
		libelle: 'Congés',
		code: 'C',
	},
	{
		libelle: 'Absence non justifiée',
		code: 'N',
	},
	{
		libelle: 'Intempérie',
		code: 'I',
	},
	{
		libelle: 'RTT',
		code: 'R',
	},
	{
		libelle: 'Accident de travail',
		code: 'V',
	},
	{
		libelle: 'Repos',
		code: 'Q',
	},
];

export const taskIconOptionsOld = [
	'phone',
	'calendar-1',
	'folder-empty',
	'check',
	'pencil',
	'envelope',
	'email-action-at',
	'tool-box',
	'pen-write-paper',
	'diagram-down',
] as const;

export const taskIconOptions = [
	'icon-phone-telephone',
	'icon-interface-calendar-mark',
	'icon-interface-file-folder',
	'icon-interface-validation-check',
	'icon-interface-edit-pencil',
	'icon-mail-send-email-envelope',
	'icon-mail-send-email',
	'icon-interface-setting-tool-box',
	'icon-interface-edit-pen-2',
	'icon-interface-arrows-bend-down-right-2',
] as const;

export const taskDurationOptions = [
	{
		id: 1,
		label: '30 minutes',
		value: 0.5,
	},
	{
		id: 2,
		label: '1 heure',
		value: 1,
	},
	{
		id: 3,
		label: '2 heures',
		value: 2,
	},
	{
		id: 4,
		label: '3 heures',
		value: 3,
	},
	{
		id: 5,
		label: '4 heures',
		value: 4,
	},
	{
		id: 6,
		label: '5 heures',
		value: 5,
	},
	{
		id: 7,
		label: '6 heures',
		value: 6,
	},
	{
		id: 8,
		label: '7 heures',
		value: 7,
	},
	{
		id: 9,
		label: '8 heures',
		value: 8,
	},
	{
		id: 10,
		label: '9 heures',
		value: 9,
	},
	{
		id: 11,
		label: '10 heures',
		value: 10,
	},
] as const;

export const fakeUser = {
	'id': 1,
	'user': {
		'id': 799618,
		'firstName': 'Michel',
		'lastName': 'Dupont',
		'name': 'Michel Dupont',
		'avatarId': undefined,
		'authLogin': 'michel.dupont@mdupont-mail.fr',
		'roleUsers': [
			{
				'id': 148178,
				'organizationRole': {
					'id': 148178,
					'name': 'Créateur',
					'colorName': 'brand',
					'rolePermissions': [{
						'id': 3352102,
						'value': true,
						'action': 0,
					},
					{
						'id': 3352103,
						'value': true,
						'action': 1,
					},
					{
						'id': 3352104,
						'value': true,
						'action': 2,
					},
					{
						'id': 3352105,
						'value': true,
						'action': 4,
					},
					{
						'id': 3352106,
						'value': true,
						'action': 5,
					},
					{
						'id': 3352107,
						'value': true,
						'action': 6,
					},
					{
						'id': 3352108,
						'value': true,
						'action': 7,
					},
					{
						'id': 3352109,
						'value': true,
						'action': 8,
					},
					{
						'id': 3352110,
						'value': true,
						'action': 9,
					},
					{
						'id': 3352111,
						'value': true,
						'action': 16,
					},
					{
						'id': 3352112,
						'value': true,
						'action': 18,
					},
					{
						'id': 3352113,
						'value': true,
						'action': 22,
					},
					{
						'id': 3352114,
						'value': true,
						'action': 23,
					},
					{
						'id': 3352115,
						'value': true,
						'action': 25,
					},
					{
						'id': 3352116,
						'value': true,
						'action': 26,
					},
					{
						'id': 3352117,
						'value': true,
						'action': 27,
					},
					{
						'id': 3352118,
						'value': true,
						'action': 28,
					},
					{
						'id': 3352119,
						'value': true,
						'action': 29,
					},
					{
						'id': 3352120,
						'value': true,
						'action': 30,
					},
					{
						'id': 3352121,
						'value': true,
						'action': 31,
					},
					{
						'id': 3352122,
						'value': true,
						'action': 32,
					},
					{
						'id': 3352123,
						'value': true,
						'action': 33,
					},
					{
						'id': 3352124,
						'value': true,
						'action': 34,
					},
					{
						'id': 3352125,
						'value': true,
						'action': 35,
					},
					{
						'id': 3352126,
						'value': true,
						'action': 36,
					},
					{
						'id': 3352127,
						'value': true,
						'action': 37,
					},
					{
						'id': 3352128,
						'value': true,
						'action': 38,
					},
					{
						'id': 3352129,
						'value': true,
						'action': 39,
					},
					{
						'id': 3352130,
						'value': true,
						'action': 40,
					},
					{
						'id': 3352131,
						'value': true,
						'action': 41,
					},
					{
						'id': 3352132,
						'value': true,
						'action': 42,
					},
					{
						'id': 3352133,
						'value': true,
						'action': 43,
					},
					{
						'id': 3352134,
						'value': true,
						'action': 44,
					},
					{
						'id': 3352135,
						'value': true,
						'action': 45,
					}],
				},
			},
			{
				'id': 149180,
				'organizationRole': {
					'id': 149178,
					'name': 'developpeur',
					'colorName': 'danger-grad',
					'rolePermissions': [{
						'id': 3353102,
						'value': true,
						'action': 0,
					}, {
						'id': 3353103,
						'value': true,
						'action': 1,
					}, {
						'id': 3353104,
						'value': false,
						'action': 2,
					}, {
						'id': 3353105,
						'value': false,
						'action': 4,
					}, {
						'id': 3353106,
						'value': false,
						'action': 5,
					}, {
						'id': 3353107,
						'value': false,
						'action': 6,
					}, {
						'id': 3353108,
						'value': false,
						'action': 7,
					}, {
						'id': 3353109,
						'value': false,
						'action': 8,
					}, {
						'id': 3353110,
						'value': false,
						'action': 9,
					}, {
						'id': 3353111,
						'value': false,
						'action': 16,
					}, {
						'id': 3353112,
						'value': false,
						'action': 18,
					}, {
						'id': 3353113,
						'value': false,
						'action': 22,
					}, {
						'id': 3353114,
						'value': false,
						'action': 23,
					}, {
						'id': 3353115,
						'value': false,
						'action': 25,
					}, {
						'id': 3353116,
						'value': false,
						'action': 26,
					}, {
						'id': 3353117,
						'value': false,
						'action': 27,
					}, {
						'id': 3353118,
						'value': false,
						'action': 28,
					}, {
						'id': 3353119,
						'value': false,
						'action': 29,
					}, {
						'id': 3353120,
						'value': false,
						'action': 30,
					}, {
						'id': 3353121,
						'value': false,
						'action': 31,
					}, {
						'id': 3353122,
						'value': false,
						'action': 32,
					}, {
						'id': 3353123,
						'value': false,
						'action': 33,
					}, {
						'id': 3353124,
						'value': false,
						'action': 34,
					}, {
						'id': 3353125,
						'value': false,
						'action': 35,
					}, {
						'id': 3353126,
						'value': false,
						'action': 36,
					}, {
						'id': 3353127,
						'value': false,
						'action': 37,
					}, {
						'id': 3353128,
						'value': false,
						'action': 38,
					}, {
						'id': 3353129,
						'value': false,
						'action': 39,
					}, {
						'id': 3353130,
						'value': false,
						'action': 40,
					}, {
						'id': 3353131,
						'value': false,
						'action': 41,
					}, {
						'id': 3353132,
						'value': false,
						'action': 42,
					}, {
						'id': 3353133,
						'value': false,
						'action': 43,
					}, {
						'id': 3353134,
						'value': false,
						'action': 44,
					}, {
						'id': 3353135,
						'value': false,
						'action': 45,
					}],
				},
			},
			{
				'id': 151178,
				'organizationRole': {
					'id': 148179,
					'name': 'Administrateur',
					'colorName': 'warning',
					'rolePermissions': [{
						'id': 3352136,
						'value': true,
						'action': 0,
					}, {
						'id': 3352137,
						'value': true,
						'action': 1,
					}, {
						'id': 3352138,
						'value': true,
						'action': 2,
					}, {
						'id': 3352139,
						'value': true,
						'action': 4,
					}, {
						'id': 3352140,
						'value': true,
						'action': 5,
					}, {
						'id': 3352141,
						'value': true,
						'action': 6,
					}, {
						'id': 3352142,
						'value': true,
						'action': 7,
					}, {
						'id': 3352143,
						'value': true,
						'action': 8,
					}, {
						'id': 3352144,
						'value': true,
						'action': 9,
					}, {
						'id': 3352145,
						'value': true,
						'action': 16,
					}, {
						'id': 3352146,
						'value': true,
						'action': 18,
					}, {
						'id': 3352147,
						'value': true,
						'action': 22,
					}, {
						'id': 3352148,
						'value': true,
						'action': 23,
					}, {
						'id': 3352149,
						'value': true,
						'action': 25,
					}, {
						'id': 3352150,
						'value': true,
						'action': 26,
					}, {
						'id': 3352151,
						'value': true,
						'action': 27,
					}, {
						'id': 3352152,
						'value': true,
						'action': 28,
					}, {
						'id': 3352153,
						'value': true,
						'action': 29,
					}, {
						'id': 3352154,
						'value': true,
						'action': 30,
					}, {
						'id': 3352155,
						'value': true,
						'action': 31,
					}, {
						'id': 3352156,
						'value': true,
						'action': 32,
					}, {
						'id': 3352157,
						'value': true,
						'action': 33,
					}, {
						'id': 3352158,
						'value': true,
						'action': 34,
					}, {
						'id': 3352159,
						'value': true,
						'action': 35,
					}, {
						'id': 3352160,
						'value': true,
						'action': 36,
					}, {
						'id': 3352161,
						'value': true,
						'action': 37,
					}, {
						'id': 3352162,
						'value': true,
						'action': 38,
					}, {
						'id': 3352163,
						'value': true,
						'action': 39,
					}, {
						'id': 3352164,
						'value': true,
						'action': 40,
					}, {
						'id': 3352165,
						'value': true,
						'action': 41,
					}, {
						'id': 3352166,
						'value': true,
						'action': 42,
					}, {
						'id': 3352167,
						'value': true,
						'action': 43,
					}, {
						'id': 3352168,
						'value': true,
						'action': 44,
					}, {
						'id': 3352169,
						'value': true,
						'action': 45,
					}],
				},
			},
		],
		'userEvents': [
			{
				'date': '2020-12-04T16:12:33.4420951+01:00',
				'type': 0,
			},
			{
				'date': '2020-12-04T16:12:33.4508516+01:00',
				'type': 1,
			},
			{
				'date': '2020-12-04T16:12:33.4642364+01:00',
				'type': 2,
			},
			{
				'date': '2020-12-04T17:25:17.1937832+01:00',
				'type': 3,
			},
			{
				'date': '2020-12-04T17:25:17.2353382+01:00',
				'type': 5,
			},
		],
	},
} as unknown as IUserForOrganizationUserDetailModel;

export function getReferenceArmado (document: any, documentType: DOCUMENT_TYPE) {
	if (!document) return;
	let ref = document.document.createdDate.split('-')[0];
	switch (+documentType) {
	case DOCUMENT_TYPE.ClientContract:
		ref += 'CC';
		break;
	case DOCUMENT_TYPE.TempWorkerContract:
		ref += 'CS';
		break;
	case DOCUMENT_TYPE.Bill:
		ref += 'FA';
		break;
	case DOCUMENT_TYPE.Register:
		ref += 'RP';
		break;
	case DOCUMENT_TYPE.Certificate:
		ref += 'CT';
		break;
	case DOCUMENT_TYPE.SalarySlip:
		ref += 'BU';
		break;
	}
	ref += 'ARM';
	ref += document.id;
	return ref;
}

export function getRecoursType (type: RECOURS_TYPE) {
	switch (type) {
	default:
		return '';
	case RECOURS_TYPE.MinimalDuration:
		return 'Durée minimale';
	case RECOURS_TYPE.PreciseTerm:
		return 'Terme précis';
	case RECOURS_TYPE.Formation:
		return 'Formation';
	case RECOURS_TYPE.CDI:
		return 'CDI';
	case RECOURS_TYPE.Apprentissage:
		return 'Apprentissage';
	case RECOURS_TYPE.Professionnalisation:
		return 'Professionnalisation';
	}
}

export function getContractType (type: CONTRACT_TYPE) {
	switch (type) {
	default:
		return '';
	case CONTRACT_TYPE.Initial:
		return 'Initial';
	case CONTRACT_TYPE.Prolongation:
		return 'Prolongation';
	case CONTRACT_TYPE.Modification:
		return 'Modification';
	}
}

export function downloadDocument (data: any, name: string, type: keyof typeof DOCUMENT_EXTENSION) {
	const blob = new Blob([data], { type });
	if ((window.navigator as any).msSaveOrOpenBlob) {
		(window.navigator as any).msSaveOrOpenBlob(
			blob,
			`${name}${DOCUMENT_EXTENSION[type]}`,
		);
	} else {
		const link = window.document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		window.document.body.appendChild(link);
		link.download = `${name}${DOCUMENT_EXTENSION[type]}`;
		link.click();
		window.URL.revokeObjectURL(link.href);
		window.document.body.removeChild(link);
	}
}

export function formatContent (content: string) {
	return content.replace(/<br\s*[/]?>/gi, '\n');
}

export function generateFormData (object: Record<string, any>) {
	const formData = new FormData();

	Object.entries(object).forEach((x) => {
		if (isArray(x[1])) {
			x[1].forEach((p) => {
				formData.append(x[0], p);
			});
		} else {
			formData.append(x[0], isNil(x[1]) ? '' : x[1]);
		}
	});

	return formData;
}

export function removeDateOffset (object: Record<string, any>) {
	return cloneDeepWith(object, (v) => {
		if (isDate(v)) {
			return v.toPost(false);
		}
	});
}

export function sanitizeDateOffset (object: Record<string, any>) {
	// Fix pour gérer l'offset des new Date dans Safari
	if (!object || typeof object !== 'object') return;

	Object.keys(object).forEach((k) => {
		object[k] = cloneDeepWith(object[k], (v) => {
			const backDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
			if (backDateRegex.test(v)) {
				const date = new Date(v);
				const dateOffset = date.getTimezoneOffset();
				const offset = dateOffset / -60;
				return v + `${offset >= 0 ? '+' : '-'}${Math.abs(offset).toHoursMinutes()}`;
			}
		});
	});
}

export function askTempWorkerInfos (fieldToFocus: 'PhoneNumber' | 'FirstName' | 'LastName', options?: OrionModal.Props) {
	return new Promise((resolve) => {
		useModal({
			hideClose: true,
			hideOnOverlayClick: false,
			size: 'xs',
			Nested: ModalTempWorkerInfos,
			NestedProps: {
				resolve,
				fieldToFocus,
			},
			...options,
		});
	});
}

export function idsQuery (items: any[], idKey = 'id') {
	if (!items?.length) return;

	if (typeof items[0] === 'number') {
		return `?ids=${items.join('&ids=')}`;
	} else {
		return `?ids=${items.map(x => x[idKey]).join('&ids=')}`;
	}
}

export function toggleGlobalKeyCallback (key: string, callback: Function) {
	const eventUid = getUid();
	toggleGlobalListener('keydown', (e) => {
		if ((e as KeyboardEvent).key === key) callback();
	}, { uid: eventUid });

	return () => toggleGlobalListener(eventUid);
}

export function qsTo64 (queryObj: Record<string, any>) {
	try {
		return window.btoa(JSON.stringify(queryObj));
	} catch (e) {
		return '';
	}
}

export function qsFrom64 (queryStr?: string) {
	return queryStr
		? cloneDeepWith(JSON.parse(window.atob(queryStr)), (val) => {
			if (/^\d{4}-\d{2}-\d{2}T[\d:\.]{12}Z$/.test(val)) {
				return new Date(val);
			}
		})
		: {};
}

export function assignReactive <T extends Record<string | number, any>> (target: T, payload: Partial<T>) {
	Object.keys(payload).forEach((key) => {
		if (key)
		//set(target as object, key as string | number, payload[key]);
			(target as Record<string | number, any>)[key] = payload[key as keyof T];
	});
}

export function initGoogleMap (latitude: number, longitude: number, $ref: HTMLElement, content?: string) {
	const { google } = window as any;

	if (google) {
		const position = {
			lat: latitude,
			lng: longitude,
		};

		const map = new google.maps.Map($ref, {
			center: position,
			disableDefaultUI: true,
			zoom: 15,
		});

		const marker = new google.maps.Marker({
			position,
			map,
		});

		if (content) {
			const infowindow = new google.maps.InfoWindow({ content });

			marker.addListener('click', () => {
				infowindow.open(map, marker);
			});
		}
	}
}

export function hoursLeftToSign (startDate: string) {
	const now = new Date();
	const dateToSign = new Date(startDate.valueOf());

	dateToSign.setDate(dateToSign.getDate() + 2);
	const diffDate = dateToSign.getTime() - now.getTime();

	return Math.round(diffDate / 3600000);
}

export function timeLeftToSign (startDate: string, longFormat = false) {
	const hoursLeft = hoursLeftToSign(startDate);
	if (hoursLeft === null) return new Date(startDate).toReadable();
	if (hoursLeft <= 0) return 'Hors délai';
	else if (hoursLeft <= 48) return `${hoursLeft}h`;
	else {
		const days = Math.round(hoursLeft / 24);
		return longFormat ? `jour`.pluralize(days) : `${days}j`;

	}
}

export function progressPercent (startDate: string) {
	const hoursLeft = hoursLeftToSign(startDate);
	const progressPercent = Math.min(((48 - hoursLeft) / 48) * 100, 100);
	return progressPercent < 0 ? 0 : progressPercent;
}

export function isSpecific (type: TEMP_WORKER_DOCUMENT_TYPE) {
	return [
		TEMP_WORKER_DOCUMENT_TYPE.Diploma,
		TEMP_WORKER_DOCUMENT_TYPE.Custom,
		TEMP_WORKER_DOCUMENT_TYPE.CurriculumVitae].includes(type);
};

export function confirmPassword (message?: string, title = `Saisissez votre mot de passe`, options?: Orion.Modal.Options) {
	return new Promise((resolve) => {
		useModal({
			title,
			message,
			hideClose: true,
			hideOnOverlayClick: false,
			size: 'xs',
			Nested: ModalConfirmPassword,
			NestedProps: { resolve },
			...options,
		});
	});
};

export function applyMixins (derivedCtor: any, constructors: any[]) {
	constructors.forEach((baseCtor) => {
		Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
			Object.defineProperty(
				derivedCtor.prototype,
				name,
				Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
        Object.create(null),
			);
		});
	});
}

export function pickFrom <T extends object, K extends keyof T> (target: T, ...keys: K[]): Pick<T, K> {
	return pick(target, ...keys);
}

export function userIsCreator (user: { roleUsers: IRoleUserModel[] }) {
	return !!user.roleUsers?.filter(x => x?.organizationRole?.name === 'Créateur').length;
}

export function sanitizeMailPrefix (payload: string) {
	const mail = payload
		.normalize('NFD')
		.replace(/[\u0300-\u036f ]/g, '')
		.replace(/[^\w\d\-\+\.]/g, '')
		.toLowerCase()
		.split(' ')
		.join('.');

	return mail;
}

export function getEnumKeyFromValue <T extends Record<string, any>> (targetEnum: T, value: any): keyof T {
	return targetEnum[value];
}

export function enumKeys <T extends Record<string, number | string>> (targetEnum: T): (keyof T)[] {
	return Object.keys(targetEnum).filter(v => isNaN(Number(v)));
}

export function enumValues <T extends Record<string, number | string>> (targetEnum: T): T[keyof T][] {
	return Object.values(targetEnum).filter(v => !isNaN(Number(v))) as T[keyof T][];
}

export function toRequestType (type: TEMP_WORKER_DOCUMENT_TYPE | REQUESTED_INFORMATION_TYPE) {
	return type as REQUESTED_INFORMATION_TYPE;
}

export function sanitizeDate (value: string | Record<string, any>, path: string) {
	const dateRegex = new RegExp(/^\d{2,4}[-/]\d{2}[-/]\d{2,4}(T[\d:.+]*)?$/);
	if (typeof value === 'string') {
		if (dateRegex.test(value)) {
			return new Date(value);
		} else {
			return value;
		}
	} else if (path && get(value, path, null)) {
		if (dateRegex.test(get(value, path))) {
			set(value, path, new Date(get(value, path)));
		}
	}
}

export function readableUserStatus (status?: USER_STATUS): { color: Orion.Color, label: string } | undefined {
	switch (status) {
	case USER_STATUS.Inactivated:
		return {
			color: 'default',
			label: 'Utilisateur non activé',
		};
	case USER_STATUS.HasToConfirmedEmail:
		return {
			color: 'warning',
			label: 'Email non confirmé',
		};
	case USER_STATUS.Activated:
		return {
			color: 'success',
			label: 'Utilisateur activé',
		};
	case USER_STATUS.Created:
		return {
			color: 'info',
			label: 'Non dématérialisé',
		};
	}
}

export function getWeekDatesFromWeekNumber (weekNumber: number, year: number) {
	const firstDayOfYear = new Date(year, 0, 1);

	while (firstDayOfYear.getWeekNumber() !== 1) {
		firstDayOfYear.setDate(firstDayOfYear.getDate() + 1);
	}
	firstDayOfYear.setDateToFirstDow();

	const firstDayOfWeek = new Date(firstDayOfYear);
	if (weekNumber > 1) {
		firstDayOfWeek.setDate(firstDayOfWeek.getDate() + 7 * (weekNumber - 1));
	}

	const lastDayOfWeek = new Date(firstDayOfWeek);
	lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

	return {
		'start': firstDayOfWeek,
		'end': lastDayOfWeek,
	};
}

export function getWeekDays (weekNumber: number, year: number): Date[] {
	const weekDays = [];

	for (let i = 0; i < 7; i++) {
		const fdow = new Date(getWeekDatesFromWeekNumber(weekNumber, year).start);
		fdow.setDate(fdow.getDate() + i);
		weekDays.push(fdow);
	}

	return weekDays;
}

// eslint-disable-next-line max-len
export function mapProperty <T extends Record<string, any>, K extends keyof T, M extends string> (target: T, originalKey: K, mappedKey: M): Omit<T, M> & Record<M, T[K]> {
	(target)[mappedKey as keyof T] = target[originalKey];
	return target as unknown as Omit<T, M> & Record<M, T[K]>;
}

export function formatSiret (rawSiret?: string) {
	if (!rawSiret) return undefined;
	const siret = rawSiret.replace(/\D/g, '');
	return /\d{14}/.test(siret)
		? siret.replace(/(\d{3})(\d{3})(\d{3})(\d{5})/, '$1 $2 $3 $4')
		: rawSiret;
}

export function formatPhone (rawPhone?: string) {
	if (!rawPhone) return undefined;
	const phone = rawPhone.replace(/^\+33/, '0').replace(/\D/g, '');
	return /\d{10}/.test(phone)
		? phone.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5')
		: rawPhone;
}

export function getReadableDay (day: number) {
	switch (day) {
	case 1:
		return 'Lundi';
	case 2:
		return 'Mardi';
	case 3:
		return 'Mercredi';
	case 4:
		return 'Jeudi';
	case 5:
		return 'Vendredi';
	case 6:
		return 'Samedi';
	case 0:
		return 'Dimanche';
	}
}

export function contractHoursToReadable (contractHour: IContractHourModel) {
	const hoursList = [];

	if (contractHour.morningStartHour && contractHour.morningEndHour) {
		hoursList.push({
			startHour: contractHour.morningStartHour,
			startMinute: contractHour.morningStartMinute,
			endHour: contractHour.morningEndHour,
			endMinute: contractHour.morningEndMinute,
		});
	}

	if (contractHour.afternoonStartHour && contractHour.afternoonEndHour) {
		hoursList.push({
			startHour: contractHour.afternoonStartHour,
			startMinute: contractHour.afternoonStartMinute,
			endHour: contractHour.afternoonEndHour,
			endMinute: contractHour.afternoonEndMinute,
		});
	}

	if (
		!contractHour.morningEndHour &&
    !contractHour.afternoonStartHour &&
    contractHour.morningStartHour &&
    contractHour.afternoonEndHour
	) {
		hoursList.push({
			startHour: contractHour.morningStartHour,
			startMinute: contractHour.morningStartMinute,
			endHour: contractHour.afternoonEndHour,
			endMinute: contractHour.afternoonEndMinute,
		});
	}

	// label formatting
	const labels: string[] = [];

	hoursList.forEach((hours) => {
		labels.push(
			`De ${(hours.startHour ?? 0)}h${(hours.startMinute ?? 0).toString().padStart(2, '0')}` +
      ` à ${(hours.endHour ?? 0)}h${(hours.endMinute ?? 0).toString().padStart(2, '0')}`,
		);
	});

	if (!isNil(contractHour.firstDayStartHour)) {
		labels.push(`Démarre le premier jour à ` +
			`${contractHour.firstDayStartHour}h${(contractHour.firstDayStartMinute ?? 0).toString().padStart(2, '0')}`);
	}

	return labels.join('<br>');
}
