<template>
	<o-section title="Lieu de mission">
		<div
			v-if="setup.dataLoaded"
			class="flex fd-c g-md">
			<o-alert
				color="info"
				center>
				Attribuez un libellé au lieu de mission<br>
				pour le retrouver plus facilement
			</o-alert>

			<div class="form flex fd-c g-sm">
				<o-input
					v-model="setup.missionPlace.name"
					label="Libellé"
					autofocus
					required
					:validation="setup.validator.rule('name')"/>
				<shared-google-auto-complete
					required
					:validation="setup.validator.rule('googleAddress')"
					:model-value="setup.missionPlace.isNew
						? undefined
						: `${setup.missionPlace.address}, ${setup.missionPlace.city}, ${setup.missionPlace.country}`"
					@google-found="setup.missionPlace?.setLocationFromGoogleAutocomple($event)"/>
			</div>

			<o-section
				align="stretch">
				<o-button
					color="danger"
					outline
					@click="_modal?.close()">
					Annuler
				</o-button>
				<o-button
					:color="setup.missionPlace.isNew ? 'success' : 'info'"
					@click="setup.checkAndCreateOrUpdateAsync()">
					Enregistrer
				</o-button>
			</o-section>
		</div>
	</o-section>
</template>

<script setup lang="ts">
import MissionPlaceModalPromptSetupService from '@/setup/mission-place/MissionPlaceModalPromptSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(MissionPlaceModalPromptSetupService.props);
const setup = new MissionPlaceModalPromptSetupService(props, _modal);
</script>
