import { PropType, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationRoleDetailEntity from '@/entity/OrganizationRoleDetailEntity';
import { useNotif } from '@orion.ui/orion';

type Props = SetupProps<typeof RoleAsideNewStep1SetupService.props>
type Emits = { (e: 'next'): void }

export default class RoleAsideNewStep1SetupService extends BaseSetupService<Props> {
	static readonly props = {
		role: {
			type: Object as PropType<OrganizationRoleDetailEntity>,
			required: true as const,
		},
	};

	readonly _title = ref<OrionInput>();

	private _aside?: OrionAside;
	private emits: Emits;

	constructor (props: Props, emits: Emits, aside?: OrionAside) {
		super(props);
		this.emits = emits;
		this._aside = aside;
	}

	cancel () {
		this._aside?.trigger('cancel');
		this._aside?.close();
	}

	verifyAndGoNext () {
		if (this.props.role.name) {
			this.emits('next');
		} else {
			useNotif.danger('Veuillez saisir un nom.');
		}
	}
}
