import ApiClient from '@/api/ApiClient';

const client = new ApiClient('userfingerprint');

class UserFingerprintApi {

	static validate (token: string, validationCode: string) {
		return client.put(`validate/${token}`, { validationCode });
	}

	static resendCode (token: string) {
		return client.get(`resendCode/${token}`);
	}

	static resendSmsCode (token: string) {
		return client.get(`resendSmsCode/${token}`);
	}

	static getPhoneNumber (token: string) {
		return client.get<IUserFingerprintPhoneModel>(`getPhoneNumber/${token}`);
	}

	static grid (pagedListParameters: IUserFingerprintPagedListParameters) {
		return client.post<IPagedList<IUserFingerprintListItemModel>>('grid', pagedListParameters);
	}

	static delete (id: Number) {
		return client.delete(id.toString());
	}

}

export default UserFingerprintApi;
