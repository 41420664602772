import router from '@/router/index.router';
import MissionPlaceListItemEntity from './MissionPlaceListItemEntity';
import { nextTick, reactive } from 'vue';
import { qsTo64 } from '@/utils/tools';
import useMissionPlaceService from '@/services/MissionPlaceService';

type MissionPlace = IMissionPlaceDetailModel | IMissionPlaceDropDownListItemModel;

export default class MissionPlaceEntity extends MissionPlaceListItemEntity<MissionPlace> implements ResourceEntity {
	private linked = reactive({
		ClientContract: 0,
		TimeTicket: 0,
		Order: 0,
	});

	get latitude () { return this.state.latitude; }
	get longitude () { return this.state.longitude; }
	get linkedClientContract () { return this.linked.ClientContract; }
	get linkedTimeTicket () { return this.linked.TimeTicket; }
	get linkedOrder () { return this.linked.Order; }

	get name () { return this.state.name; }
	set name (val) { this.state.name = val; }


	setLocationFromGoogleAutocomple (payload: GoogleAutoCompleteFoundPayload) {
		Object.assign(this.state, payload);
	}

	goToShow () {
		router.push({
			name: 'UserGroupMissionPlacesShow',
			params: { id: this.id.toString() },
		});
	}

	async getLinkedClientContractAsync () {
		this.linked.ClientContract = await useMissionPlaceService().countLinkedClientContractsAsync(this.id) ?? 0;
	}

	async getLinkedTimeTicketAsync () {
		this.linked.TimeTicket = await useMissionPlaceService().countLinkedTimeTicketsAsync(this.id) ?? 0;
	}

	async getLinkedOrderAsync () {
		this.linked.Order = await useMissionPlaceService().countLinkedOrdersAsync(this.id) ?? 0;
	}

	async storeAsync () {
		await useMissionPlaceService().storeAsync(this.state);
	}

	async updateAsync () {
		await useMissionPlaceService().updateAsync(this.id, this.state);
	}

	goToLinked (name: 'ClientContract' | 'TimeTicketYear' | 'Order') {
		const query = { missionPlaceId: this.id };

		nextTick(() => {
			router.push({
				name,
				query: { filter: qsTo64(query) },
				params: { year: new Date().getFullYear().toString() },
			});
		});
	}
}
