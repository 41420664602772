import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('tempworker/certificate');

class CertificateApiTempWorker {
	static grid (filter: ICertificatePagedListForTempWorkerParameters) {
		return client.post<IPagedList<ICertificateItemForTempWorkerModel>>('grid', filter);
	}

	static getDetailed (id: number) {
		return client.get<ICertificateOverPageForTempWorkerModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/certificate${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/certificate${idsQuery}`, '_blank');
	}
}

export default CertificateApiTempWorker;
