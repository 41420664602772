import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/companycandidatecode');

class CompanyCandidateCodeApi {

	static selectByCompanyId (companyId: number) {
		return client.get<ICompanyCandidateCodeModel>(`company/${companyId}`);
	}

	static download (companyId: number) {
		client.windowOpen(`${ARMADO_URL}/app/agency/companycandidatecode/company/${companyId}/file`, '_blank');
	}

	static shareByEmail (email:string, companyId: number) {
		return client.post(`company/${companyId}/send/email`, { email });
	}

	static shareBySms (mobilePhone:string, mobilePhoneCountry: number, companyId:number) {
		return client.post(`company/${companyId}/send/sms`, {
			mobilePhone,
			mobilePhoneCountry,
		});
	}

}


export default CompanyCandidateCodeApi;
