import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useColorService from '@/services/ColorService';

type Props = SetupProps<typeof SharedSelectColorSetupService.props>

type Emits = {
	(e: 'input' | 'update:modelValue', val: IColorModel): void,
	(e: 'click'): void,
}

export default class SharedSelectColorSetupService extends BaseSetupService<Props> {
	static readonly props = {
		disabled: Boolean,
		modelValue: {
			type: Object as PropType<IColorModel>,
			default: () => {
				return {
					id: 1,
					name: 'brand',
					value: '#2bb9a6',
				};
			},
		},
	};

	private emits: Emits;

	private readonly state = reactive({
		state: {
			visible: true,
			loading: false,
		},
		colorOptions: [] as IColorModel[],
	});

	get colorOptions () { return this.state.colorOptions;}

	get color () { return this.props.modelValue;}
	set color (val) { this.emits('input', val); this.emits('update:modelValue', val);}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	async onBeforeMountAsync () {
		await this.getColorOptionsAsync();
	}

	selectColor (color: IColorModel) {
		this.color = color;
		this.emits('click');
	}

	async getColorOptionsAsync () {
		this.state.colorOptions = await useColorService().listAsync();
	}
}
