import { PropType, reactive, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationUserApi from '@/api/OrganizationUserApi';

type Props = SetupProps<typeof SharedSelectUserListSetupService.props>
type Emits = {(e: 'input', val: (number|undefined)[]): void};
type QuickFilter = {
  type: 'all' | 'role__without' | 'tag__without'
  label: string
  count: number
}

export default class SharedSelectUserListSetupService extends BaseSetupService<Props> {
	static readonly props = {
		transferCreator: Boolean,
		uniq: Boolean,
		hideSearch: Boolean,
		hideQuickFilters: Boolean,
		title: {
			type: String,
			default: undefined,
		},
		subtitle: {
			type: String,
			default: undefined,
		},
		quickFiltersType: {
			type: String as PropType<'role' | 'tag'>,
			default: undefined,
		},
		value: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		excludeRoleId: {
			type: Number,
			default: undefined,
		},
		excludeTagId: {
			type: Number,
			default: undefined,
		},
	};


	private _modal?: OrionModal;
	private emits: Emits;
	private readonly state = reactive({
		total: 0,
		list: [] as IOrganizationUserListItemModel[],
		userSelected: [] as IOrganizationUserListItemModel[],
		activeQuickFilter: undefined as Undef<QuickFilter>,
		filter: {
			organizationId: this.self.organization?.id,
			organizationRoleIdToExclude: this.props.excludeRoleId,
			tagIdToExclude: this.props.excludeTagId,
			showOnlyWithoutRole: false,
			search: undefined,
			page: {
				index: 1,
				size: 20,
			},
		} as IOrganizationUserPagedListParameters,
		quickFilters: [
			{
				type: 'all',
				label: 'Tous',
				count: 0,
			},
			{
				type: 'role__without',
				label: 'Sans rôle',
				count: 0,
			},
			{
				type: 'tag__without',
				label: 'Sans tag',
				count: 0,
			},
		] as QuickFilter[],
	});

	get userSelected () { return this.state.userSelected; }
	get list () { return this.state.list; }
	get total () { return this.state.total; }

	get quickFiltersOptions (): QuickFilter[] {
		return this.state.quickFilters.filter(x => x.type === 'all' || x.type.split('__')[0] === this.props.quickFiltersType);
	}

	get search () { return this.state.filter.search; }
	set search (val) { this.state.filter.search = val; }

	get page () { return this.state.filter.page; }
	set page (val) { this.state.filter.page = val; }


	constructor (props: Props, emits: Emits, _modal?: OrionModal) {
		super(props);
		this.emits = emits;
		this._modal = _modal;

		this.state.activeQuickFilter = this.state.quickFilters.first();

		watch(() => this.state.userSelected, (val: IOrganizationUserListItemModel[]) => {
			this.emits('input', val.mapKey('user').map(x => x?.id));
		});
	}

	protected async onBeforeMountAsync () {
		await this.refreshListAsync();
	}


	async refreshListAsync (resetPagination = false) {
		if (this.state.filter.organizationId) {
			if (resetPagination) this.state.filter.page.index = 1;

			this._modal?._loader()?.show(`Chargement...`);
			const { data: users } = await OrganizationUserApi.grid(this.state.filter);
			this.state.list = users.list;
			this.state.total = users.count;
			this.state.quickFilters[0].count = users.count;
			this._modal?._loader()?.hide();
		}
	}

	addUsers () {
		this._modal?.trigger('add', [...this.userSelected]);
	}

	toggleSelectUser (user: IOrganizationUserListItemModel) {
		if (this.props.uniq) {
			this.state.userSelected = [user];
		} else {
			this.state.userSelected.toggle(user);
		}
	}

	selectFilter (filter: QuickFilter) {
		this.state.activeQuickFilter = filter;
		this.state.filter.showOnlyWithoutRole = filter.type === 'role__without';
		this.refreshListAsync(true);
	}

	filterIsSelected (filter: QuickFilter) {
		return this.state.activeQuickFilter?.type === filter.type;
	}

	userIsSelected (user: IOrganizationUserListItemModel) {
		return !!this.userSelected.findByKey(user.id);
	}
}
