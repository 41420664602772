import QualificationApiAgency from '@/api/QualificationApiAgency';
import BaseService from '@/services/BaseService';
import AjaxCooler from '@/utils/AjaxCooler';

class QualificationService extends BaseService {
	private cooler = new AjaxCooler(QualificationApiAgency.list);

	async listAsync (forceRefresh = false, ...args: Parameters<typeof QualificationApiAgency.list>) {
		return await this.cooler.coolAsync(forceRefresh, ...args);
	}
}

const qualificationServiceSingleton = new QualificationService();

export default function useQualificationService () {
	return qualificationServiceSingleton;
}
