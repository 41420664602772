import { reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import useMembershipService from '@/services/MembershipService';

type Props = SetupProps<typeof OnboardingTempWorkerAddMembershipModalSetupService.props>

export default class OnboardingTempWorkerAddMembershipModalSetupService extends BaseSetupService<Props> {
	static readonly props = {
		code: {
			type: String,
			default: undefined,
		},
	};

	readonly _otp = ref<OrionOtp>();
	private _modal?: OrionModal;
	private readonly state = reactive({
		loading: false,
		visible: true,
		companyCode: undefined as Undef<string>,
		agency: undefined as Undef<ICompanyModel>,
	});


	get loading () { return this.state.loading; }
	get agency () { return this.state.agency; }
	get agencyName () { return this.state.agency?.name; }
	get codePrefilled () { return !!this.route.query.agencyCompanyCode || !!this.props.code; }
	get agencyAddress () {
		return [
			this.state.agency?.contactDetailPostCode,
			this.state.agency?.contactDetailCity,
		].filter(x => !!x?.length).join(' ');
	}

	get companyCode () { return this.state.companyCode; }
	set companyCode (val) { this.state.companyCode = val; }


	constructor (props?: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
		this._modal?.bus.on('enter-end', () => this._otp.value?.focus());
	}

	protected async onBeforeMountAsync () {
		if (this.props.code) {
			this.state.companyCode = this.props.code;
		} else if (this.codePrefilled) {
			this.state.companyCode = this.route.query.agencyCompanyCode as string;
		}
	}


	async fetchCompanyAsync (payload: string) {
		try {
			this.state.loading = true;
			this.state.agency = undefined;
			this.state.agency = await useMembershipService().getCompanyByCodeAsync(payload);
		} catch (error: any) {
			this._otp.value?.reset();
		} finally {
			this.state.loading = false;
		}
	}
}
