import ApiClient from '@/api/ApiClient';

const client = new ApiClient('tempworker/label');

class LabelApiTempWorker {
	static add (addLabelModel: OmitUserId<IAddLabelModel>) {
		return client.post<ILabelModel>('', addLabelModel);
	}

	static list () {
		return client.get<ILabelModel[]>('');
	}

	static update (updateLabelModel: OmitUserId<IUpdateLabelModel>) {
		return client.put<ILabelModel>('', updateLabelModel);
	}

	static updateIsFold (updateIsFoldLabelModel: OmitUserId<IUpdateIsFoldLabelModel>) {
		return client.put<void>('fold', updateIsFoldLabelModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}
}


export default LabelApiTempWorker;
