import { PropType, ref, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedFlatSelectSetupService.props>
type Emits = {
	(e: 'delete' | 'add', val: FlatSelectOptionItem) : void
	(e: 'update:search', val: string | undefined) : void
}

export type FlatSelectOptionItem = {
  id: number;
	name: string,
} & Record<string, any>

export default class SharedFlatSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		loading: Boolean,
		showSearch: Boolean,
		popoverOpened: Boolean,
		modelValue: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		partial: {
			type: Array as PropType<number[]>,
			default: () => [],
		},
		options: {
			type: Array as PropType<FlatSelectOptionItem[]>,
			default: () => [],
		},
		search: {
			type: String,
			default: undefined,
		},
		selectedFunction: {
			type: Function,
			default: undefined,
		},
		partialFunction: {
			type: Function,
			default: undefined,
		},
		newLabel: {
			type: String,
			default: undefined,
		},
	};

	private emits: Emits;

	readonly _search = ref<OrionInput>();

	get searchTerm () {return this.props.search;}
	set searchTerm (val) {this.emits('update:search', val);}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;

		watch(() => this.props.popoverOpened, (isOpen) => {
			if (isOpen) {
				setTimeout(() => this._search.value?.focus(), 100);
			}
		});
	}

	itemIsSelected (item: FlatSelectOptionItem) {
		if (!!this.props.selectedFunction) return this.props.selectedFunction(item);
		return !!this.props.modelValue.find(x => x === item.id);
	}

	itemIsPartialSelected (item: FlatSelectOptionItem) {
		if (!!this.props.partialFunction) return this.props.partialFunction(item);
		return !!this.props.partial.find(x => x === item.id);
	}

	async selectItemAsync (item: FlatSelectOptionItem) {
		if (this.itemIsSelected(item)) {
			this.emits('delete', item);
		} else {
			this.emits('add', item);
		}
	}
}
