import DigitalSafeDocumentApiTempWorker from '@/api/DigitalSafeDocumentApiTempWorker';
import { sortBy } from 'lodash-es';
import useDigitalSafeService, { DigitalSafeService } from './DigitalSafeService';
import { Bus, useAside } from '@orion.ui/orion';
import DigitalSafeTempWorkerDocumentLabelsManager from '@/components/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentLabelsManager.vue';
import LabelApiTempWorker from '@/api/LabelApiTempWorker';
import type DigitalSafeTempWorkerDocumentEntity from '@/entity/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentEntity';

class DigitalSafeTempWorkerService extends DigitalSafeService {

	get userLabelsWithLevels () {
		const labels = this.userLabels;

		labels.forEach((label) => {
			function setLabelLevel (label: Armado.LabelForCompanyModelExtended, iteratee?: Armado.LabelForCompanyModelExtended) {
				if (iteratee && iteratee.parentId && label.level !== undefined) {
					label.level++;
					setLabelLevel(label, labels.find(x => x.id === iteratee.parentId));
				}
			}

			label.level = 0;
			setLabelLevel(label, label);
		});

		return sortBy(labels, 'caption');
	}

	getArmadoLabels () {
		return sortBy(this.digitalSafeLabels.filter(x => x.readOnly), 'caption');
	}

	async clearTrashAsync () {
		await DigitalSafeDocumentApiTempWorker.clearTrash();
		Bus.emit('DigitalSafeTempWorkerDocumentListViewSetupService:refresh-list');
	}

	async addDigitalSafeDocumentAsync (file: File) {
		const { data } = await DigitalSafeDocumentApiTempWorker.add(file);
		this.digitalSafeDocuments.push(data);
	}

	getDocumentsByLabel () {
		if (!this.activeLabelId) {
			return this.digitalSafeDocuments.filter(x => !x.programmedDeleteDate);
		} else {
			return this.digitalSafeDocuments.filter((x) => {
				return x.labels?.map(x => x.id).includes(this.activeLabelId as number);
			});
		}
	}

	openAsideLabelsManager (payload?: DigitalSafeTempWorkerDocumentEntity) {
		useAside({
			Nested: DigitalSafeTempWorkerDocumentLabelsManager,
			NestedProps: payload,
			events:
				{
					'refreshList': async () => {
						await useDigitalSafeService().getLabelDigitalSafeDocumentsAsync();
						Bus.emit('DigitalSafeTempWorkerDocumentListViewSetupService:refresh-list');
					},
				},
		});
	}

	async getDigitalSafeDocumentsAsync () {
		const { data } = await DigitalSafeDocumentApiTempWorker.list();
		this.digitalSafeDocuments.empty().push(...data);
	}

	async createLabelDigitalSafeDocumentAsync (newLabel: OmitUserId<IAddLabelModel>) {
		const { data } = await LabelApiTempWorker.add(newLabel);
		return data;
	}

	async addLabelToDigitalSafeDocumentAsync (documentId: number, labelId: number) {
		await DigitalSafeDocumentApiTempWorker.addLabel({
			digitalSafeDocumentId: documentId,
			labelId,
		});
	}

	async deleteLabelDigitalSafeDocumentAsync (id: number) {
		await LabelApiTempWorker.delete(id);
	}

	async updateLabelDigitalSafeDocumentAsync (label: OmitUserId<IUpdateLabelModel>) {
		await LabelApiTempWorker.update({ ...label });
		Bus.emit('DigitalSafeTempWorkerDocumentListViewSetupService:refresh-list');
	}

	async renameAsync (...args: Parameters<typeof DigitalSafeDocumentApiTempWorker.rename>) {
		const { data } = await DigitalSafeDocumentApiTempWorker.rename(...args);
		return data;
	}

	async deleteAsync (...args: Parameters<typeof DigitalSafeDocumentApiTempWorker.delete>) {
		const { data } = await DigitalSafeDocumentApiTempWorker.delete(...args);
		return data;
	}

	async deleteLabelAsync (...args: Parameters<typeof DigitalSafeDocumentApiTempWorker.deleteLabel>) {
		const { data } = await DigitalSafeDocumentApiTempWorker.deleteLabel(...args);
		return data;
	}

	async toTrashAsync (...args: Parameters<typeof DigitalSafeDocumentApiTempWorker.toTrash>) {
		const { data } = await DigitalSafeDocumentApiTempWorker.toTrash(...args);
		return data;
	}

	async restoreAsync (...args: Parameters<typeof DigitalSafeDocumentApiTempWorker.restore>) {
		const { data } = await DigitalSafeDocumentApiTempWorker.restore(...args);
		return data;
	}
}

const digitalSafeTempWorkerServiceSingleton = new DigitalSafeTempWorkerService();

export default function useDigitalSafeTempWorkerService () {
	return digitalSafeTempWorkerServiceSingleton;
}
