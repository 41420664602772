import { hashCode } from '@/utils/tools';
import { Log } from '@orion.ui/orion';

type BusEvent = {
  event: string,
  hash: number,
  cb: (...args: any[]) => void
  once: boolean
}

type BusEventOptions = {
  once: boolean,
  override: boolean,
}

const defaultOptions: BusEventOptions = {
	once: false,
	override: true,
};

class BusService {
	private readonly events = [] as BusEvent[];
	private logEvents = false;

	on (event: string, cb: (...args: any[]) => void, options?: Partial<BusEventOptions>) {
		const hash = hashCode(cb.toString());
		const registeredEvent = this.events.find(ev => ev.event === event && ev.hash === hash);
		const eventOptions = {
			...defaultOptions,
			...options,
		};

		if (event.charAt(0) === '*') eventOptions.override = false;

		const { once, override } = eventOptions;

		if (!!registeredEvent && override) {
			this.off(event, cb);
		}

		this.events.push({
			event,
			hash,
			cb,
			once,
		});
	}

	once (event: string, cb: (...args: any[]) => void, override = false) {
		this.on(event, cb, {
			override,
			once: true,
		});
	}

	emit (event: string, ...payload: any[]) {
		const eventsIndexToRemove: number[] = [];

		if (this.logEvents && !this.events.mapKey('event').includes(event)) {
			Log.error(payload, `BusService ~ no listener for ${event}`);
		}

		this.events
			.forEach((ev, index) => {
				if (ev.event === event) {
					ev.cb(...payload);

					if (this.logEvents) {
						Log.warn(payload, `BusService ~ ${ev.event}`);
					}

					if (ev.once) eventsIndexToRemove.push(index);
				}
			});

		eventsIndexToRemove.sort().reverse().forEach(i => this.events.splice(i, 1));
	}

	off (event: string, cb?: (...args: any[]) => void) {
		const hash = cb ? hashCode(cb.toString()) : undefined;

		hash
			? this.events.deleteWhere(ev => ev.event === event && ev.hash === hash)
			: this.events.deleteWhere(ev => ev.event === event);
	}

	logBusEvents (val = true) {
		this.logEvents = val;
	}
}

const busServiceSingleton = new BusService();
// busServiceSingleton.logBusEvents();

export default function useBusService () {
	return busServiceSingleton;
}
