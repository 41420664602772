<template>
	<div
		:parent-validation="setup.isValid"
		:parent-display-validation="setup.displayValidationState"
		class="select-picto-card"
		@click.stop.prevent="setup.displayValidationState ? setup.displayValidationState = false : undefined">
		<shared-picto-card
			v-for="picto in options"
			:key="picto.id"
			tag="icon"
			selectable
			label
			:picto="picto"
			:selected="setup.isSelected(picto)"
			:error="(setup.displayValidationState && !setup.isValid)"
			@click="setup.selectItem(picto)"/>
	</div>
</template>

<script setup lang="ts">
import SharedSelectPictoCardSetupService from '@/setup/shared/SharedSelectPictoCardSetupService';
import { Picto } from '@/setup/shared/SharedPictoCardSetupService';
type Emits = {
	(e: 'input'| 'update:modelValue', value: Picto[] | Picto): void,
	(e: 'add' | 'remove', value: any): void
}

const props = defineProps(SharedSelectPictoCardSetupService.props);
const emits = defineEmits<Emits>();
const setup = new SharedSelectPictoCardSetupService(props, emits);
</script>

<style scoped lang="less">
.select-picto-card {
  display: grid;
  grid-template-columns: repeat(5, 4rem);
  gap: 1rem;

	> .picto-card {
    height: 100%;

    :deep(.orion-card__selected) {
      z-index: 1;
    }
	}
}
</style>
