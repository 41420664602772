import { nextTick, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { useNotif } from '@orion.ui/orion';
import { debounce } from 'lodash-es';

type Props = SetupProps<typeof SharedDetailsListItemSetupService.props>
type Emits = {
	(e: 'input', val: Undef<string>): void};

export default class SharedDetailsListItemSetupService extends BaseSetupService<Props> {
	private emits : Emits;
	static readonly props = {
		editable: Boolean,
		valueAlignRight: Boolean,
		valueEllipsis: Boolean,
		valueMaxWidth: {
			type: Number,
			default: null,
		},
		legend: {
			type: String,
			default: null,
		},
		value: {
			type: [String, Number],
			default: null,
		},
		editionValueMaxLength: {
			type: Number,
			default: 256,
		},
		colClass: {
			type: Array,
			default: () => ['col-sm-5 col-md-4', 'col-sm-7 col-md-8'],
		},
	};

	contenteditable = ref(false);
	_editionZone = ref<HTMLElement>();

	handleEditableDebounced = debounce(this.handleEditable.bind(this), 100);


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	valueAdditionalStyle () {
		const styles = [];

		if (this.props.valueMaxWidth) {
			styles.push({ 'maxWidth': this.props.valueMaxWidth + 'px' });
		}

		return styles;
	}

	private handleEditable () {
		this.contenteditable.value = !this.contenteditable.value;

		if (this.contenteditable.value) {
			this.triggerEdition();
		} else {
			const newTitle = this._editionZone.value?.textContent?.trim();

			if (newTitle?.length && newTitle.length > this.props.editionValueMaxLength) {
				useNotif.warning('Oops', `La limite de caractères est de ${this.props.editionValueMaxLength} maximum`);
				this.handleEditable();
			} else {
				this.emits('input', newTitle);
			}
		}

	}

	triggerEdition () {
		nextTick(() => {
			const el = this._editionZone.value;
			el?.setAttribute('tabindex', '1');
			if (el) {
				const range = document.createRange();
				range.selectNodeContents(el);
				const sel = window.getSelection();
				sel?.removeAllRanges();
				sel?.addRange(range);
				el.addEventListener('blur', this.handleEditableDebounced.bind(this), { once: true });
				el.focus();
			}
		});
	}
}
