<template>
	<div
		:ref="setup._el"
		class="shared-paged-list-actions">
		<template v-if="use.includes('search')">
			<o-input
				v-show="setup.displayListSearchField"
				:ref="setup._listSearchField"
				v-model="setup.listFilterResearch"
				class="shared-paged-list-actions__search"
				clearable
				label="Recherche"
				@blur="setup.hideListSearchField()"
				@change="setup.refreshListAsync(true)"/>
			<o-icon
				v-tooltip="`Rechercher`"
				font-icon="icon-interface-search"
				@click="setup.showListSearchField()"/>
		</template>
		<o-icon
			v-if="use.includes('checkAll') && setup.isTouch"
			v-tooltip="`${setup.listService.listHasAllItemsSelected ? `Déselectionner` : `Sélectionner` } tout`"
			class="shared-paged-list-actions__checkAll"
			:class="{ 'text--success': setup.listService.listHasAllItemsSelected }"
			icon="check_all"
			@click="setup.listService.toggleSelectAllListItems()"/>
		<o-icon
			v-if="use.includes('filter')"
			v-tooltip="`Filtrer`"
			font-icon="icon-interface-text-formatting-filter-1"
			:marker="setup.listService.listHasActiveFilter"
			marker-color="info"
			@marker-click="setup.filterService.resetAndApplyAsync()"
			@click="setup.filterService.showFilterAside()"/>
	</div>
</template>

<script setup lang="ts">
import SharedPagedListActionsSetupService from '@/setup/shared/SharedPagedListActionsSetupService';
const props = defineProps(SharedPagedListActionsSetupService.props);
const setup = new SharedPagedListActionsSetupService(props);
</script>

<style lang="less" scoped>
.shared-paged-list-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-height: 2.5rem;

  :deep(.orion-icon) {
    font-size: 1rem;
  }

  &__search {
    margin: 0;
  }

  #left-aside > & {
    .shared-paged-list-actions__search {
      order: 9;
      width: 100%;
      display: block !important;

      + .orion-icon.icon-interface-search {
        display: none;
      }
    }

    .icon-interface-text-formatting-filter-1 {
      display: none;
    }
  }
}
</style>
