import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedPictoCardSetupService.props>
type Emits = { (e: 'delete' | 'click', value: Picto) : void }

export type PictoType = 'icon' | 'svg'

export type Picto = {
	id: number,
	icon?: string,
	label?: string,
	type?: PictoType,
	callback?: Function,
	tooltip?: string,
}

export default class SharedPictoCardSetupService extends BaseSetupService<Props> {
	static readonly props = {
		closable: Boolean,
		selected: Boolean,
		selectable: Boolean,
		marker: Boolean,
		error: Boolean,
		label: Boolean,
		picto: {
			type: Object as PropType<Picto>,
			required: true as const,
		},
		tag: {
			type: String as PropType<PictoType>,
			default: 'icon',
		},
		color: {
			type: String,
			default: undefined,
		},
	};

	private emits: Emits;


	get computedTag () {
		return this.props.picto.type ?? this.props.tag;
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	handleClick () {
		if (typeof this.props.picto.callback === 'function') {
			this.props.picto.callback(this.props.picto);
		}
		this.emits('click', this.props.picto);
	}
}
