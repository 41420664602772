import ApiClient from '@/api/ApiClient';

const client = new ApiClient('rolepermission');

class RolePermissionApi {

	static update (id: number, updateRolePermissionModel: IUpdateRolePermissionModel) {
		return client.put<void>(id.toString(), updateRolePermissionModel);
	}

	static getDefaultListByUserType () {
		return client.get<IRolePermissionModel[]>('');
	}

	static self () {
		return client.get<IRolePermissionSelfModel[]>('self');
	}

	static hasPermission (permissionAction: number, companyId: Number | null = null) {
		return client.get<Boolean>('hasPermission', {
			params: {
				permissionAction,
				companyId,
			},
		});
	}

}

export default RolePermissionApi;
