import { reactive } from 'vue';
import { USER_TYPE } from '@/typings/enums/USER_TYPE';
import { useLoader, useNotif } from '@orion.ui/orion';
import router, { addRoutes } from '@/router/index.router';
import SignalRTagUser from '@/signalr/TagUser.signalr';
import sessionManager from '@/utils/SessionManager';
import useFeatureFlagService from './FeatureFlagService';
import useFingerprintService from './FingerprintService';
import type SelfEntity from '@/entity/SelfEntity';
import useAuthService from './AuthService';

class SelfService {
	private readonly state = reactive({ selfEntity: undefined as Undef<SelfEntity> });

	get self (): SelfEntity { return (this.state.selfEntity ?? { isConnected: false }) as SelfEntity; }

	get isAgency () { return this.self.isAgency(); }
	get isClient () { return this.self.isClient(); }
	get isTempWorker () { return this.self.isTempWorker(); }

	constructor (selfEntity?: SelfEntity) {
		if (selfEntity) this.setSelfEntity(selfEntity);
	}

	setSelfEntity (selfEntity: SelfEntity) {
		this.state.selfEntity = selfEntity;
		return this.self;
	}

	async loginAsync (payload: {username: string, password: string}, visitorData?: VisitorData) {
		useLoader().show(`Connexion à votre compte...`);

		try {
			if (!visitorData && useFeatureFlagService().get('userfingerprint')) {
				visitorData = await useFingerprintService().getFingerprintAsync();
			}

			const data = await useAuthService().signInAsync({
				login: payload.username,
				password: payload.password,
				...visitorData,
			});

			localStorage.setItem('userType', data.userType.toString());

			const getTokenAsync = async () => {
				await sessionManager.getTokenAsync();
			};

			addRoutes(data.userType);

			if (data.userType !== USER_TYPE.TempWorker) {
				SignalRTagUser.onTagUserUpdate(getTokenAsync);
			}

			router.push(router.currentRoute?.value?.query?.redirect as string || { name: 'Dashboard' });
		} catch (error: any) {
			const errorMessage = error?.response?.data;
			if (typeof errorMessage === 'string') {
				if (errorMessage.includes('minutes')) {
					useNotif.danger({
						message: errorMessage,
						duration: 0,
					});
					return 'Compte bloqué';
				}

				if (errorMessage.includes('multiples tentatives incorrectes')) {
					return 'Compte bloqué';
				}

				// useNotif.warning(errorMessage);
			} else if (typeof errorMessage === 'object') {
				if (errorMessage?.message.includes('Valider la connexion')) {
					return errorMessage;
				}
			} else {
				useNotif.warning('Vos identifiants sont incorrects');
			}
		} finally {
			useLoader().hide();
		}
	}
}

const selfServiceSingleton = new SelfService();

export default function useSelfService () {
	return selfServiceSingleton;
}
