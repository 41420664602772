import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/qualification');

class QualificationApiAgency {

	static list () {
		return client.get<IQualificationForListModel[]>('list');
	}
}

export default QualificationApiAgency;
