import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedStateIconSetupService.props>

export default class SharedStateIconSetupService extends BaseSetupService<Props> {
	static readonly props = {
		small: Boolean,
		clickable: Boolean,
		state: {
			type: String as PropType<StateIcon>,
			default: 'complete',
		},
	};

	constructor (props?: Props) {
		super(props);
	}
}
