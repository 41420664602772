import ClientContractApiClient from '@/api/ClientContractApiClient';
import TimeTicketApiClient from '@/api/TimeTicketApiClient';
import OrderApiClient from '@/api/OrderApiClient';
import { Bus, useAside, useModal, useNotif } from '@orion.ui/orion';
import AjaxCooler from '@/utils/AjaxCooler';
import MissionPlaceApiClient from '@/api/MissionPlaceApiClient';
import MissionPlaceModalPrompt from '@/components/mission-place/MissionPlaceModalPrompt.vue';
import MissionPlaceAsideDetails from '@/components/mission-place/MissionPlaceAsideDetails.vue';
import useSelfService from './SelfService';

class MissionPlaceService implements ResourceService {

	private cooler = new AjaxCooler(this.api.list);

	private get api () {
		return MissionPlaceApiClient;
	}

	async storeAsync (payload: IAddMissionPlaceModel) {
		return new Promise<void>(async (resolve) => {
			try {
				await this.api.create(payload);
				Bus.emit(`MissionPlacePagedListViewSetupService:refresh-list`, true);
				resolve();
			} catch (e: any) {
				useNotif.danger(`Oops`, e?.response?.data);
			}
		});
	}

	async getAsync (id: number) {
		const { data } = await this.api.read(id);
		return data;
	}

	async updateAsync (id: number, payload: IUpdateMissionPlaceModel) {
		try {
			await this.api.update(id, payload);
			Bus.emit(`MissionPlaceCardSetupService:${id}:update`, payload);
			Bus.emit(`MissionPlaceAsideDetailsSetupService:${id}:update`, payload);
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async deleteAsync (id: number) {
		try {
			await this.api.delete(id);
			Bus.emit(`MissionPlacePagedListViewSetupService:refresh-list`);
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async listAsync (forceRefresh = false) {
		return await this.cooler.coolAsync(forceRefresh);
	}

	promptMissionPlaceModal (id?: number) {
		useModal({
			Nested: MissionPlaceModalPrompt,
			NestedProps: { id },
			size: 'sm',
		});
	}

	async showMissionPlaceAsideDetailsAsync (id: number) {
		useAside({
			Nested: MissionPlaceAsideDetails,
			NestedProps: { id },
		});
	}

	async countLinkedOrdersAsync (...args: Parameters<typeof OrderApiClient.countByMissionPlaceId>) {
		if (!useSelfService().self.isClient()) return;
		const { data } = await OrderApiClient.countByMissionPlaceId(...args);
		return data;
	}

	async countLinkedTimeTicketsAsync (...args: Parameters<typeof TimeTicketApiClient.countByMissionPlaceId>) {
		if (!useSelfService().self.isClient()) return;
		const { data } = await TimeTicketApiClient.countByMissionPlaceId(...args);
		return data;
	}

	async countLinkedClientContractsAsync (...args: Parameters<typeof ClientContractApiClient.countByMissionPlaceId>) {
		if (!useSelfService().self.isClient()) return;
		const { data } = await ClientContractApiClient.countByMissionPlaceId(...args);
		return data;
	}
}

const missionPlaceServiceSingleton = new MissionPlaceService();

export default function useMissionPlaceService () {
	return missionPlaceServiceSingleton;
}
