import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'tempworker/withholdingtaxattestation';
const client = new ApiClient(ROUTE);

class WithholdingTaxAttestationApiTempWorker {
	static grid (filter: IWithholdingTaxAttestationPagedListForTempWorkerParameters) {
		return client.post<IPagedList<IWithholdingTaxAttestationItemForTempWorkerModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/withholdingtaxattestation${idsQuery}&isDownload=true`, '_blank');
	}


	static async view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/withholdingtaxattestation${idsQuery}`, '_blank');
	}
}

export default WithholdingTaxAttestationApiTempWorker;
