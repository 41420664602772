import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationRoleDetailEntity from '@/entity/OrganizationRoleDetailEntity';
import { cloneDeep } from 'lodash-es';

type Props = SetupProps<typeof RoleAsideNewStep2SetupService.props>

export default class RoleAsideNewStep2SetupService extends BaseSetupService<Props> {
	static readonly props = {
		role: {
			type: Object as PropType<OrganizationRoleDetailEntity>,
			required: true as const,
		},
	};

	_aside?: OrionAside;

	private readonly state = reactive({ role: {} as OrganizationRoleDetailEntity });

	get rolesPermissions (): { roleId: number, active: true, permissionType: IRolePermissionModel }[] {
		return this.role?.rolePermissions.map((p) => {
			return {
				roleId: this.role.id,
				active: true,
				permissionType: p,
			};
		});
	}

	get role () { return this.state.role;}
	set role (val) { this.state.role = val;}

	protected onMounted () {
		this.state.role = cloneDeep(this.props.role);
	}

	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;
	}

	updatePermissionList (payload: { action: PERMISSION_ACTION, value: boolean }) {
		const permission = this.role.rolePermissions.find(p => p.action === payload.action);
		if (permission) permission.value = payload.value;
	}

	save () {
		this._aside?.trigger('save', this.state.role);
	}
}
