import { PropType, reactive } from 'vue';
import { useNotif } from '@orion.ui/orion';
import BaseSetupService from '@/setup/BaseSetupService';
import useRolePermissionsService from '@/services/RolePermissionService';

type Props = SetupProps<typeof PermissionItemSetupService.props>
type Emits = { (e: 'input' | 'update:permission', val: IUpdateRolePermissionModel): void }

export default class PermissionItemSetupService extends BaseSetupService<Props> {
	static readonly props = {
		togglable: Boolean,
		permission: {
			type: Object as PropType<Armado.RolePermission.PermissionListChildren>,
			default: null,
		},
		roleId: {
			type: Number,
			default: null,
		},
		rolesPermissions: {
			type: Array as PropType<Armado.RolePermission.PermissionListItem[]>,
			default: () => [],
		},
		mark: {
			type: String,
			default: undefined,
		},
		showDescription: {
			type: Boolean,
			default: true,
		},
	};

	private readonly state = reactive({
		visible: true,
		loading: false,
	});

	private emit !: Emits;

	get shouldUpdatePermissionRole () {
		return this.props.rolesPermissions.filter(r => r.id).length;
	}

	get itemIsActive () {
		if (this.props.togglable) return true;
		return !!this.props.rolesPermissions.filter(rp => rp.value && rp.active).length;
	}


	get active () {
		if (this.props.togglable) {
			return !!this.props.rolesPermissions.filter(x => x.action === this.props.permission.action && x.value).length;
		} else {
			return !this.props.rolesPermissions.length
				|| !!this.props.rolesPermissions.filter(x => x.action === this.props.permission.action && x.value).length;
		}
	}

	async toggleActiveAsync () {
		if (!this.shouldUpdatePermissionRole) {
			this.emit('update:permission', {
				action: this.props.permission.action,
				value: !this.active,
			});
		} else {
			try {
				const firstId = this.props.rolesPermissions.filter(x => x.action === this.props.permission.action).first()?.id;

				if (!firstId) return;

				await useRolePermissionsService().updateAsync(
					firstId,
					{
						action: this.props.permission.action,
						value: !this.active,
					},
				);

				this.emit('update:permission', {
					action: this.props.permission.action,
					value: !this.active,
				});

				useNotif.success(`Le droit de ${this.props.permission.label} a été ${this.active ? 'activé' : 'supprimé'}`);
			} catch (e: any) {
				useNotif.danger('Oops', e?.response?.data);
			}
		}
	}

	get dots () {
		return this.props.rolesPermissions.filter(rp => rp.value).map((x => ({
			...x,
			color: x.color ?? undefined,
			active: x.active ?? undefined,
		})));
	}


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emit = emits;
	}
}
