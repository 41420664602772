import ApiClient from '@/api/ApiClient';

const client = new ApiClient('organizationrole');

class OrganizationRoleApi {

	static add (addOrganizationRoleModel: IAddOrganizationRoleModel) {
		return client.post<IOrganizationRoleDetailModel>(``, addOrganizationRoleModel);
	}

	static selectById (id: number) {
		return client.get<IOrganizationRoleDetailModel>(`${id}`);
	}

	static list (organizationId: number) {
		return client.get<IOrganizationRoleListItemModel[]>(``, { params: { organizationId } });
	}

	static update (id: number, updateOrganizationRoleModel: IUpdateOrganizationRoleModel) {
		return client.put<void>(id.toString(), updateOrganizationRoleModel);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}

	static grid (pagedListParameters: IOrganizationRolePagedListParameters) {
		return client.post<IPagedList<IOrganizationRoleListItemModel>>('grid', pagedListParameters);
	}

	static self () {
		return client.get<IOrganizationRoleSelfModel[]>(`self`);
	}

}

export default OrganizationRoleApi;
