<template>
	<div class="flat-select">
		<o-input
			v-if="showSearch"
			:ref="setup._search"
			v-model="setup.searchTerm"
			suffix-icon="search_magnifying_glass"
			label="Rechercher..."/>

		<ul
			v-if="options.length || loading"
			class="flat-select__options">
			<li
				v-for="(item, index) in options"
				:key="item.id"
				class="flat-select__item"
				:class="{
					'flat-select__item--selected': setup.itemIsSelected(item),
					'flat-select__item--partial-selected': setup.itemIsPartialSelected(item),
				}"
				@click="setup.selectItemAsync(item)">
				<slot v-bind="{ item, index }"/>
				<div>
					<o-icon
						class="flat-select__item-check"
						icon="check"/>
					<span
						v-if="setup.itemIsPartialSelected(item)"
						class="flat-select__item-check-partial">
						&minus;
					</span>
					<o-icon
						v-if="setup.itemIsSelected(item)"
						class="flat-select__item-uncheck"
						icon="trash_empty"/>
				</div>
			</li>
		</ul>

		<div
			v-else
			class="flat-select__options">
			<slot name="empty"/>
		</div>

		<o-button
			v-if="!!newLabel"
			class="flat-select__new"
			block
			nude
			prefix-font-icon="icon-interface-add-circle"
			color="info"
			@click="$emit('new')">
			{{ newLabel }}
		</o-button>

		<o-loader
			:visible="loading"
			size="md"
			message="Chargement..."/>
	</div>
</template>

<script setup lang="ts">
import SharedFlatSelectSetupService, { FlatSelectOptionItem } from '@/setup/shared/SharedFlatSelectSetupService';

type Emits = {
	(e: 'delete' | 'add', val: FlatSelectOptionItem) : void
	(e:'update:search', val: string | undefined) : void
	(e:'new') : void
}

const emits = defineEmits<Emits>();
const props = defineProps(SharedFlatSelectSetupService.props);
const setup = new SharedFlatSelectSetupService(props, emits);
</script>

<style lang="less" scoped>
.flat-select {
  &__options {
    max-height: max(30vh, 12.5rem);
    overflow: auto;
    min-height: 2rem;
    min-width: 10rem;
    padding: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    padding: 0.5rem 0.75rem;
    font-size: calc(14rem / 16);
    cursor: pointer;

    :not(&--selected) {
      .flat-select__item-check {
        opacity: 0;
      }
    }

    &:hover:not(&--selected) {
      background: var(--grey-lighter);
      .flat-select__item-check {
        opacity: 1;
      }
    }

    &:first-of-type {
      border-radius: 0.25rem 0.25rem 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 0.25rem 0.25rem;
    }

    &--partial-selected {
      background: var(--grey-light);
      font-weight: 600;
      .flat-select__item-check-partial {
        color: var(--grey-dark);
      }

      &:hover {
        .flat-select__item-check-partial {
          display: none;
        }
      }
    }

    &--selected {
      background-color: rgba(var(--rgb-info), 0.2);
      font-weight: 600;

      .orion-icon {
        color: var(--info);
        opacity: 1;
      }

      .flat-select__item-uncheck {
        display: none;
      }

      &:hover {
        background-color: rgba(var(--rgb-danger), 0.15);
        .orion-icon {
          color: var(--danger);
        }

        .flat-select__item-check {
          display: none;
        }

        .flat-select__item-uncheck {
          display: block;
        }
      }
    }
  }

  &__new {
    background: var(--grey-lighter);
    margin-top: var(--space-sm);
  }

  .orion-input {
    margin: 0 0 1rem;
    min-width: 14rem;
  }
}
</style>
