<template>
	<o-select
		v-if="setup.dataLoaded"
		v-model="setup.selectedRolesIds"
		label="Rôle"
		display-key="name"
		track-key="id"
		value-key="id"
		:searchable="setup.options.length > 10"
		:options="setup.options"
		v-bind="{ ...$attrs, multiple }">
		<template
			v-if="!multiple"
			#value="{ item: role }">
			<div
				v-if="role"
				class="role-option">
				<div
					class="role-color"
					:class="`background--${role.colorName}`"/>
				{{ role.name }}
			</div>
		</template>

		<template
			v-else
			#value="{ item: role }">
			<div
				v-if="role && typeof role === 'number'"
				class="role-option">
				<div
					class="role-color"
					:class="`background--${getRoleById(role)?.colorName}`"/>
				{{ getRoleById(role)?.name }}
			</div>
			<div
				v-else-if="role"
				class="role-option">
				<div
					class="role-color"
					:class="`background--${role.colorName}`"/>
				{{ role.name }}
			</div>
		</template>

		<template #option="{ item: role }">
			<div class="role-option">
				<div
					class="role-color"
					:class="`background--${role.colorName}`"/>
				{{ role.name }}
			</div>
		</template>
	</o-select>
</template>

<script setup lang="ts">
import SharedRoleSelectSetupService from '@/setup/shared/SharedRoleSelectSetupService';
type Emits = {(e: 'update:modelValue', val: number | number[] | undefined): void}
const emits = defineEmits<Emits>();
const props = defineProps(SharedRoleSelectSetupService.props);
const setup = new SharedRoleSelectSetupService(props, emits);
</script>

<style scoped lang="less">
.role-color {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;

  .orion-select__selected-item & {
    width: 0.75rem;
  }
}

.role-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;

  .orion-select__selected-item & {
    margin-right: 0.25rem;
  }
}
</style>
