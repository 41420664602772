import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'agency/digitalsafedocument';
const client = new ApiClient(ROUTE);

class DigitalSafeDocumentApiAgency {
	static grid (filter: IDigitalSafeDocumentForCompanyPagedListParameters) {
		return client.post<IPagedList<IDigitalSafeDocumentForCompanyModel>>('grid', filter);
	}

	static view (id: number) {
		client.windowOpen(`${ARMADO_URL}/app/agency/digitalsafedocument/${id}/file`, '_blank');
	}

	static download (id: number, name: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/digitalsafedocument/${id}/file?download=true&name=${name}`, '_blank');
	}

	static downloadZip (ids: number[]) {
		client.windowOpen(`${ARMADO_URL}/app/agency/digitalsafedocument/download?ids=${ids.join('&ids=')}`, '_blank');
	}
}


export default DigitalSafeDocumentApiAgency;
