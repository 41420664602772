import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/bill');

class BillApiAgency {
	static grid (filter: IBillPagedListForAgencyParameters) {
		return client.post<IPagedList<IBillItemForAgencyModel>>('grid', filter);
	}

	static getDetailed (id: number) {
		return client.get<IBillOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static getNotViewed () {
		return client.get('notviewed');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/bill${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/bill${idsQuery}`, '_blank');
	}
}

export default BillApiAgency;
