<template>
	<div
		class="permission-item"
		:class="{ 'permission-item--inactive': !setup.itemIsActive && !togglable }">
		<o-toggle
			v-if="togglable"
			:model-value="setup.active"
			:disabled="(!!roleId && !setup.self.can('OrganizationRoleUpdate'))"
			reverse
			class="permission-item__toggle"
			:label="permission.label.mark(mark)"
			@input="setup.toggleActiveAsync()"/>
		<div
			v-else
			class="flex ai-c g-xs">
			<p
				class="permission-item__label"
				v-html="permission.label.mark(mark)"/>
			<div class="permission-item__color-dots-section">
				<span
					v-for="(dot, index) in setup.dots"
					:key="index"
					class="permission-item__color-dot"
					:class="[
						{ 'permission-item__color-dot--inactive' : !dot.active },
						`permission-item__color-dot--${dot.color}`,
					]"/>
			</div>
		</div>

		<p
			v-if="showDescription"
			class="permission-item__description"
			v-html="permission.description.mark(mark)"/>
	</div>
</template>

<script setup lang="ts">
import PermissionItemSetupService from '@/setup/permission/PermissionItemSetupService';
type Emits = { (e: 'input' | 'update:permission', val: IUpdateRolePermissionModel): void }
const emits = defineEmits<Emits>();
const props = defineProps(PermissionItemSetupService.props);
const setup = new PermissionItemSetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/variables';

.permission-item {
  border-bottom: calc(1rem / 16) solid rgb(var(--rgb-grey-light), 0.6);
  transition: opacity .3s;

  &__toggle {
    position: relative;
    width: 100%;

		:deep(.orion-toggle__label) {
			margin-right: auto;
		}

    &--disabled {
      pointer-events: none;

      :deep(.orion-toggle__core) {
        display: none;
      }
    }
  }

  &__label {
    margin: unset;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
  }

  &__description {
    color: var(--grey-dark);
    margin-top: var(--space-xs);
		padding-right: 4rem;
  }

  &__color-dots-section {
    display: flex;
    align-items: center;
    gap: 0.2rem;
  }

  &__color-dot {
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    opacity: 1;
    transition: opacity .3s;

    &--inactive {
      opacity: 0.2;
    }

		each(@labelColors, {
			@color: ~"--@{value}";
      &--@{value} {
        background:  var(@color);
      }
    })
  }

  &--inactive {
    opacity: 0.2;

    .permission__color-dot {
      opacity: 1;
    }
  }
}
</style>
