import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'tempworker/poleemploiattestation';
const client = new ApiClient(ROUTE);

class PoleEmploiAttestationApiTempWorker {
	static grid (filter: IPoleEmploiAttestationPagedListForTempWorkerParameters) {
		return client.post<IPagedList<IPoleEmploiAttestationItemForTempWorkerModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/poleemploiattestation${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/tempworker/poleemploiattestation${idsQuery}`, '_blank');
	}

	/* static count () {
		return client.get('count');
	} */
}

export default PoleEmploiAttestationApiTempWorker;
