import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/tempworkercontract');

class TempWorkerContractApiAgency {
	static getDetailed (id: number) {
		return client.get<ITempWorkerContractOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static grid (filters: ITempWorkerContractPagedListForAgencyParameters) {
		return client.post<IPagedList<ITempWorkerContractItemForAgencyModel>>('grid', filters);
	}

	static getNotSigned () {
		return client.get<number>('notsigned');
	}

	static getNotSignedLate () {
		return client.get<number>('notsignedLate');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tempworkercontract${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tempworkercontract${idsQuery}`, '_blank');
	}

	static getProof (id:number) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tempworkercontract/${id}/proof`, '_blank');
	}
}

export default TempWorkerContractApiAgency;
