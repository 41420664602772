import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/contractprolongation');

class ContractProlongationApiClient {
	static grid (filter: IClientContractProlongationPagedListForClientParameters) {
		return client.post<IPagedList<IClientContractProlongationItemForClientModel>>('grid', filter);
	}

	static add (prolongation: OmitUserId<IAddContractProlongationModel>) {
		return client.post<void>('', prolongation);
	}

	static update (id: number, prolongation: OmitUserId<IUpdateContractProlongationModel>) {
		return client.put<void>(id.toString(), prolongation);
	}

	static delete (id: number) {
		return client.delete<void>(id.toString());
	}
}

export default ContractProlongationApiClient;
