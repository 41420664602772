<template>
	<div class="temp-worker-no-membership">
		<div class="temp-worker-no-membership__img">
			<img
				src="@Img/undraw/undraw_interview_re_e5jn.svg"
				alt="Aucune agence liée">
		</div>

		<o-section>
			<h4>
				Votre compte n'est pas encore lié à une agence.
			</h4>

			<p class="text--grey-dark">
				Si vous êtes déjà en relation avec une agence,
				vous pouvez scanner son <strong>QRcode Armado</strong>
				ou lui demander son <strong>code à 4 caractères</strong>
				afin de lier vos comptes sur Armado.
			</p>
		</o-section>

		<div class="mt-md flex fd-c g-xs">
			<o-button
				color="brand"
				block
				@click="_modal?.trigger('enter-code')">
				Je dispose du code !
			</o-button>
			<o-button
				color="info"
				outline
				block
				@click="_modal?.trigger('remind-later')">
				Me le rappeler demain
			</o-button>
			<!-- <o-button
				color="danger"
				nude
				block
				@click="_modal.$emit('remind-never')">
				Ignorer et ne plus afficher
			</o-button> -->
		</div>
	</div>
</template>

<script setup lang="ts">
import OnboardingTempWorkerNoMembershipModalSetupService from '@/setup/onboarding/OnboardingTempWorkerNoMembershipModalSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(OnboardingTempWorkerNoMembershipModalSetupService.props);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const setup = new OnboardingTempWorkerNoMembershipModalSetupService(props);
</script>

<style scoped lang="less">
.temp-worker-no-membership {
	padding: 0.5rem;
	text-align: center;

	&__img {
		display: flex;
		align-items: center;
		justify-content: center;
    margin: 1rem auto 2.5rem;

		img {
			width: 80%;
		}
	}
}
</style>
