import BaseService from '@/services/BaseService';
import { RouteLocationNormalized } from 'vue-router';

class RouterHistoryService extends BaseService {
	readonly history: RouteLocationNormalized[] = [];

	get previousRoute () { return this.history[this.history.length - 2]; }

	addRouterHistory (route: RouteLocationNormalized) {
		this.history.push(route);
	}
}

const routerHistoryServiceSingleton = new RouterHistoryService();

export default function useRouterHistoryService () {
	return routerHistoryServiceSingleton;
}
