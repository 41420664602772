import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';
import { AxiosResponse } from 'axios';

const client = new ApiClient('client/timeticketweek');

class TimeTicketWeekApiClient {

	static listByYear (year: number, filter: ITimeTicketWeekPagedListParameters) {
		return client.get<ITimeTicketWeekYearForClientModel[]>(`year/${year}`, { params: { ...filter } });
	}

	static async listByYearPagedListAsync (year: number, filter: ITimeTicketWeekPagedListParameters) {
		const res = await this.listByYear(year, filter);
		(res.data as unknown as IPagedList<ITimeTicketWeekYearForClientModel>).list = res.data;
		(res.data as unknown as IPagedList<ITimeTicketWeekYearForClientModel>).count = res.data.length;

		return res as unknown as AxiosResponse<IPagedList<Armado.TimeTicketWeekYear>>;
	}

	static grid (pagedListParameters: ITimeTicketWeekPagedListParameters) {
		return client.post<IPagedList<ITimeTicketWeekDetailForClientModel>>('grid', pagedListParameters);
	}

	static update (id: number, updateTimeTicketWeekModel: IUpdateTimeTicketWeekModel) {
		return client.put<void>(id.toString(), updateTimeTicketWeekModel);
	}

	static validate (id: number) {
		return client.put<void>(`${id}/validate`);
	}

	static validateMultiple (ids: IValidateTimeTicketWeekModel) {
		return client.put<void>(`validate`, { ids });
	}

	static download (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/client/timeticketweek${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/client/timeticketweek${idsQuery}`, '_blank');
	}

	static updateComment (id: number, comment: IUpdateTimeTicketWeekCommentModel) {
		return client.put<void>(`${id}/comment`, { comment });
	}

	static countToFill () {
		return client.get<number>('tofill');
	}

	static changeStatus (id: number) {
		return client.put<void>(`${id}/override`);
	}

	static discussionLink (id: number) {
		return client.get<ITimeTicketWeekForDiscussionLinkModel>(`${id}/link`);
	}
}

export default TimeTicketWeekApiClient;
