<template>
	<div class="select-color swap-colors">
		<span class="select-color__text text--grey-dark">Choix de la couleur : </span>
		<div
			v-for="(c) in setup.colorOptions"
			:key="c.id"
			:tabindex="0"
			class="select-color__custom-color-select"
			:class="[
				`background--${c.name}`,
				`select-color__custom-color-select--${c.name}`,
				{ 'select-color__custom-color-select--selected' : c.id === setup.color.id },
				{ 'select-color__custom-color-select--disabled' : disabled },
			]"
			@click="setup.selectColor(c)"
			@keyup.enter="setup.selectColor(c)">
			<o-icon
				v-show="c.id === setup.color.id"
				icon="check"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedSelectColorSetupService from '@/setup/shared/SharedSelectColorSetupService';

type Emits = {
	(e: 'input' | 'update:modelValue', val: IColorModel): void,
	(e: 'click'): void,
}

const emits = defineEmits<Emits>();
const props = defineProps(SharedSelectColorSetupService.props);
const setup = new SharedSelectColorSetupService(props, emits);
</script>

<style scoped lang="less">
@import '@/styles/variables.less';
.select-color {
  display: flex;
  gap: 0.5rem;

  &__text {
    white-space: nowrap;
  }

  &__custom-color-select {
    height: 1rem;
    width: 1rem;
    border-radius: 0.2rem;
    background: var(--grey-light);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform .2s;

		&:hover:not(.select-color__custom-color-select--selected) {
			transform: scale(1.2);
		}

    &::after {
      content: '';
      bottom: 0;
      transition: bottom .2s;
    }

    .orion-icon {
      color: white;
      font-size: 0.5rem;
    }

    &--selected {
      position: relative;
      transform: translateY(-0.3rem);

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 0.13rem;
        background: inherit;
        bottom: -0.4rem;
      }
    }

		&--disabled {
			pointer-events: none;
			&:not(.select-color__custom-color-select--selected){
				opacity: 0.5;
			}
		}
  }
}
</style>
