export enum TAG_LINK_TYPE {
	ClientContract = 0,
	TempWorkerContract = 1,
	TimeTicket = 2,
	Bill = 3,
	Register = 4,
	HandicappedWorkerList = 5,
	ClientProvisionDocument = 6,
	Order = 7,
	SalarySlip = 8,
	Certificate = 9,
	WithholdingTaxAttestation = 10,
	PoleEmploiAttestation = 11,
	TripAttestation = 12,
	TempWorkerProvisionDocument = 13,
}
