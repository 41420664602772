const routesLogin = [
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/components/login/LoginForm.vue'),
		meta: {
			browserTitle: 'Login',
			description: 'Connectez-vous sur Armado pour accéder à votre espace.',
		},
	},
	{
		path: '/resetlogin',
		name: 'LoginReset',
		component: () => import('@/components/login/LoginReset.vue'),
		meta: { browserTitle: 'Mot de passe oublié' },
	},
	{
		path: '/activation',
		name: 'LoginActivation',
		component: () => import('@/components/login/LoginActivation.vue'),
		meta: { browserTitle: 'Activation de votre compte' },
	},
	{
		path: '/resetpassword',
		name: 'LoginResetPassword',
		component: () => import('@/components/login/LoginResetPassword.vue'),
		meta: { browserTitle: 'Réinitialisation de votre mot de passe' },
	},
] as const;

export default routesLogin;
