import BaseSetupService from '@/setup/BaseSetupService';
import { useConfirm, useNotif } from '@orion.ui/orion';
import { debounce } from 'lodash-es';
import useDigitalSafeTempWorkerService from '@/services/digital-safe/DigitalSafeTempWorkerService';
import { PropType } from 'vue';
import useColorService from '@/services/ColorService';

type Props = SetupProps<typeof DigitalSafeTempWorkerDocumentLabelEditionSetupService.props>

export default class DigitalSafeTempWorkerDocumentLabelEditionSetupService extends BaseSetupService<Props> {
	static readonly props = {
		label: {
			type: Object as PropType<Armado.LabelForCompanyModelExtended>,
			required: true as const,
		},
	};

	readonly _aside?: OrionAside;

	readonly digitalSafeTempWorkerService = useDigitalSafeTempWorkerService();

	userLabelsWithLevels = this.digitalSafeTempWorkerService.userLabelsWithLevels;

	readonly debouncedNotif = debounce(() => {
		useNotif.success(`Enregistré !`, `Vos modifications ont bien été sauvegardées`);
	}, 2000);

	readonly debouncedLabelUpdate = debounce(async (label) => {
		await this.digitalSafeTempWorkerService.updateLabelDigitalSafeDocumentAsync(label);
		this.debouncedNotif();
	}, 500);

	get children () {
		return this.digitalSafeTempWorkerService.getChildrenLabels(this.props.label.id);
	}

	get paddingLeft () {
		if (!this.ui.onLayoutNavBottom && this.props.label.level) {
			return this.props.label.level * 15 + 'px';
		} else {
			return 0;
		}
	}

	get labelColor () { return useColorService().swapColor(this.props.label.color); }
	set labelColor (val) { this.props.label.color = val; }

	constructor (props: Props, _aside?: OrionAside) {
		super(props);
		this._aside = _aside;
	}

	async callDebouncedLabelUpdateAsync (label: Armado.LabelForCompanyModelExtended, debounced?: any) {
		if (debounced) {
			this.debouncedLabelUpdate(label);
		} else {
			await this.digitalSafeTempWorkerService.updateLabelDigitalSafeDocumentAsync({
				id: label.id,
				caption: label.caption,
				color: label.color,
				parentId: label.parentId,
			});
			this.props.label.color = label.color;
			this.debouncedNotif();
		}
	}

	async deleteLabelAsync (id: number) {
		let confirmMsg = `<p>Confirmez-vous la suppression de ce label ?</p>`;
		if (this.children.length) {
			confirmMsg += `<span class="text--warning">Tous les labels lui appartenant seront également supprimés.</span>`;
		}

		if (await useConfirm(confirmMsg)) {
			await this.digitalSafeTempWorkerService.deleteLabelDigitalSafeDocumentAsync(id);
			this._aside?.bus.emit('refreshList');
			useNotif.success(`Suppression du label réussie !`);
		}
	}
}
