import { useAside, useNotif } from '@orion.ui/orion';
import type TagDetailEntity from '@/entity/TagDetailEntity';
import TagApi from '@/api/TagApi';
import AjaxCooler from '@/utils/AjaxCooler';
import useSelfService from '../SelfService';
import TagAsideNew from '@/components/tag/TagAsideNew.vue';

class TagService implements ResourceService {
	private get api () { return TagApi; }
	private get self () { return useSelfService().self; }

	private cooler = new AjaxCooler(this.api.list);
	private coolerFiltered = new AjaxCooler(this.api.listFiltered);


	async storeAsync (payload: Omit<IAddTagModel, 'organizationId'>, organizationId = this.self.organization?.id) {
		if (organizationId) {
			return new Promise<ITagDetailModel>(async (resolve) => {
				try {
					const { data: newTag } = await this.api.add({
						...payload,
						organizationId,
					});
					resolve(newTag);
				} catch (e: any) {
					useNotif.danger(`Oops`, e?.response?.data);
				}
			});
		}
	}

	async getAsync (id: number) {
		const { data } = await this.api.selectById(id);
		return data;
	}

	async updateAsync (id: number, payload: IUpdateTagModel) {
		try {
			await this.api.update(id, payload);
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async deleteAsync (id: number) {
		try {
			await this.api.delete(id);
		} catch (e: any) {
			useNotif.danger(`Oops`, e?.response?.data);
		}
	}

	async listAsync (forceRefresh = false, organizationId = this.self.organization?.id) {
		if (!organizationId) return [];
		return await this.cooler.coolAsync(forceRefresh, organizationId);
	}

	async listFilteredAsync (forceRefresh = false, organizationId = this.self.organization?.id) {
		if (!organizationId) return [];
		return await this.coolerFiltered.coolAsync(forceRefresh, organizationId);
	}

	async orderedListAsync (forceRefresh = false, organizationId = this.self.organization?.id) {
		return await (await this.listFilteredAsync(forceRefresh, organizationId)).sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
	}

	async promptNewTagAsideAsync () {
		if (!this.self.organizationGuard()) return;

		return new Promise<Undef<ITagDetailModel>>((resolve) => {
			useAside({
				Nested: TagAsideNew,
				events: {
					'leave-end': () => resolve(undefined),
					'cancel': () => resolve(undefined),
					'save': async (Aside, payload: TagDetailEntity) => {
						if (payload.color) {
							const res = await this.storeAsync({
								name: payload.name,
								description: payload.description,
								colorId: payload.color?.id,
								shareToAgency: payload.shareToAgency,
							});
							await this.listAsync(true);
							resolve(res);
							Aside.close();
							useNotif.success('Tag créé avec succès !');
						}
					},
				},
			});
		});
	}
}

const tagServiceSingleton = new TagService();

export default function useTagService (): TagService;
// eslint-disable-next-line no-unused-vars
export default function useTagService (id: number): Promise<ITagDetailModel>;
export default function useTagService (id?: number) {
	if (typeof id === 'number') {
		return tagServiceSingleton.getAsync(id);
	}
	return tagServiceSingleton;
}
