/* eslint-disable local-rules/props-are-static-readonly */
import { reactive, onBeforeMount, onMounted, onUnmounted, onBeforeUnmount } from 'vue';
import { Bus, isTouch, itemHas, useResponsive } from '@orion.ui/orion';
import router from '@/router/index.router';
import useSelfService from '@/services/SelfService';
import useUiService from '@/services/UiService';
import useFeatureFlagService from '@/services/FeatureFlagService';
import SelfEntity from '@/entity/SelfEntity';


export default abstract class BaseSetupService<P> {
	props = {} as P;
	readonly router = router;
	readonly ui = useUiService();
	readonly responsive = useResponsive();
	readonly featureFlag = useFeatureFlagService();
	readonly isTouch = isTouch();
	readonly itemHas = itemHas;

	private readonly baseState = reactive({ registeredEvents: [] as string[] });

	get self () { return useSelfService().self; }
	get route () { return this.router.currentRoute.value; }

	get publicInstance () {
		return {};
	}


	constructor (props?: P) {
		this.props = props ?? {} as P;

		onBeforeMount(() => {
			this.onBeforeMountAsync();
		});

		onMounted(() => {
			this.onMounted();
		});

		onBeforeUnmount(() => {
			this.onBeforeUnmount();
		});

		onUnmounted(() => {
			if (this.baseState.registeredEvents.length) {
				this.baseState.registeredEvents.forEach(ev => Bus.off(ev));
			}

			this.onUnmounted();
		});
	}

	protected async onBeforeMountAsync () {}
	protected onMounted () {}
	protected onBeforeUnmount () {}
	protected onUnmounted () {}

	registerBusEvent (evName: string, cb: (...arg: any) => void) {
		const setupName = (this as any).constructor.name;
		const busEventName = `${setupName}:${evName}`;
		this.baseState.registeredEvents.push(busEventName);
		Bus.on(busEventName, (...arg) => cb(...arg));
	}

	organizationGuard (): this is { self: typeof SelfEntity & { organization: { id: number }}} {
		return !!this.self.organization?.id;
	}
}
