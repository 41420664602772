import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/handicappedworkerlist');

class HandicappedWorkerListApiAgency {

	static grid (filter: IHandicappedWorkerListPagedListForAgencyParameters) {
		return client.post<IPagedList<IHandicappedWorkerListItemForAgencyModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/handicappedworkerlist${idsQuery}&isDownload=true&name=liste-travailleurs-handicapes`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/handicappedworkerlist${idsQuery}`, '_blank');
	}
}

export default HandicappedWorkerListApiAgency;
