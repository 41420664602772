import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('client/handicappedworkerlist');

class HandicappedWorkerListApiClient {

	static grid (filter: IHandicappedWorkerListPagedListForClientParameters) {
		return client.post<IPagedList<IHandicappedWorkerListItemForClientModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/handicappedworkerlist${idsQuery}&isDownload=true&name=liste-travailleurs-handicapes`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/handicappedworkerlist${idsQuery}`, '_blank');
	}
}

export default HandicappedWorkerListApiClient;
