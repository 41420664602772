import ApiClient from '@/api/ApiClient';

const client = new ApiClient('agency/companyLogo');

class CompanyLogoApiAgency {

	static add (addLogoModel: OmitUserId<IAddCompanyLogoModel>) {
		const formData = new FormData();

		/* Object.keys(addLogoModel).forEach((k) => {
			formData.append(k, addLogoModel[k]);
		}); */

		formData.append(addLogoModel.file.name, addLogoModel.file);
		formData.append('companyId', addLogoModel.companyId.toString());

		return client.post<number>('', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	}

	static update (updateLogoModel: OmitUserId<IUpdateCompanyLogoModel>) {
		const formData = new FormData();
		/* Object.keys(formUpload).forEach((k) => {
			formData.append(k, formUpload[k]);
		}); */

		formData.append(updateLogoModel.file.name, updateLogoModel.file);
		formData.append('id', updateLogoModel.id.toString());

		return client.put<void>('', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	}

	static delete (companyId: number) {
		return client.delete<void>(companyId.toString());
	}
}

export default CompanyLogoApiAgency;
