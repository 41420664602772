import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('client/clientprovisiondocument');

class ClientProvisionDocumentApiClient {
	static grid (filters: IClientProvisionDocumentPagedListForClientParameters) {
		return client.post<IPagedList<IClientProvisionDocumentItemForClientModel>>('grid', filters);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/clientprovisiondocument${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/client/clientprovisiondocument${idsQuery}`, '_blank');
	}

	static sign (ids: number[]) {
		return client.get<void>(`signature${`?ids=${ids.join('&ids=')}`}`);
	}

	static listForConfirm (ids: number[]) {
		return client.get<IClientProvisionDocumentForConfirmAsideModel[]>(`confirmSignature${ids && ids.length ? `?ids=${ids.join('&ids=')}` : null}`);
	}

	static getNotSigned () {
		return client.get<number[]>('notsigned');
	}

	static getNotViewed () {
		return client.get<number>('notviewed');
	}
}

export default ClientProvisionDocumentApiClient;
