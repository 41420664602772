import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const ROUTE = 'agency/poleemploiattestation';
const client = new ApiClient(ROUTE);

class PoleEmploiAttestationApiAgency {
	static grid (filter: IPoleEmploiAttestationPagedListForAgencyParameters) {
		return client.post<IPagedList<IPoleEmploiAttestationItemForAgencyModel>>('grid', filter);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/poleemploiattestation${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/poleemploiattestation${idsQuery}`, '_blank');
	}

	/* static count () {
		return client.get('count');
	} */
}

export default PoleEmploiAttestationApiAgency;
