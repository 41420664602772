import { createApp, watch } from 'vue';
import { version } from '../package.json';

import Orion, { logBusEvents, setThemeMode } from '@orion.ui/orion';
import '@orion.ui/orion/dist/monkey-patching';

// Import App after Orion to correctly override Orion's styles
import App from '@/App.vue';
import router from '@/router/index.router';
import useFeatureFlagService from './services/FeatureFlagService';
import useBusService from './services/BusService';
import ArmadoVuePlugin from '../plugin';
import { initSentry, setArmadoApp } from './app';


// #region Global Components
import SharedAddButton from './components/shared/SharedAddButton.vue';
import SharedAnnotation from './components/shared/SharedAnnotation.vue';
import SharedAppAsideItem from './components/shared/SharedAppAsideItem.vue';
import SharedAsideNavigation from './components/shared/SharedAsideNavigation.vue';
import SharedCompanyFlatSelect from './components/shared/SharedCompanyFlatSelect.vue';
import SharedDetailsDocumentHistory from './components/shared/SharedDetailsDocumentHistory.vue';
import SharedDetailsListItem from './components/shared/SharedDetailsListItem.vue';
import SharedDigitalSafeArboLabel from './components/shared/SharedDigitalSafeArboLabel.vue';
import SharedDocumentSticker from './components/shared/SharedDocumentSticker.vue';
import SharedFlatSelect from './components/shared/SharedFlatSelect.vue';
import SharedFoldable from './components/shared/SharedFoldable.vue';
import SharedGoogleAutoComplete from './components/shared/SharedGoogleAutoComplete.vue';
import SharedOrderGroupSelect from './components/shared/SharedOrderGroupSelect.vue';
import SharedListDocumentActions from './components/shared/SharedListDocumentActions.vue';
import SharedListDocumentItemActions from './components/shared/SharedListDocumentItemActions.vue';
import SharedListQuickFilter from './components/shared/SharedListQuickFilter.vue';
import SharedLogoCompany from './components/shared/SharedLogoCompany.vue';
import SharedMagneticBlob from './components/shared/SharedMagneticBlob.vue';
import SharedMissionPlaceSelect from './components/shared/SharedMissionPlaceSelect.vue';
import SharedPagedListActions from './components/shared/SharedPagedListActions.vue';
import SharedPagedListOrderHeader from './components/shared/SharedPagedListOrderHeader.vue';
import SharedPictoCard from './components/shared/SharedPictoCard.vue';
import SharedProgressBar from './components/shared/SharedProgressBar.vue';
import SharedRoleFlatSelect from './components/shared/SharedRoleFlatSelect.vue';
import SharedRoleSelect from './components/shared/SharedRoleSelect.vue';
import SharedSelectAgencyQualifications from './components/shared/SharedSelectAgencyQualifications.vue';
import SharedSelectColor from './components/shared/SharedSelectColor.vue';
import SharedSelectCompany from './components/shared/SharedSelectCompany.vue';
import SharedSelectPictoCard from './components/shared/SharedSelectPictoCard.vue';
import SharedSelectUserCompany from './components/shared/SharedSelectUserCompany.vue';
import SharedSettingCard from './components/shared/SharedSettingCard.vue';
import SharedStateIcon from './components/shared/SharedStateIcon.vue';
import SharedTagDotsExpandable from './components/shared/SharedTagDotsExpandable.vue';
import SharedTagFlatSelect from './components/shared/SharedTagFlatSelect.vue';
import SharedTagQuickFilter from './components/shared/SharedTagQuickFilter.vue';
import SharedTagSelect from './components/shared/SharedTagSelect.vue';
import SharedToggle from './components/shared/SharedToggle.vue';
import SharedStepper from './components/shared/SharedStepper.vue';
import useRouterHistoryService from './services/RouterHistoryService';
// #endregion


(async () => {

	if (!/MSIE|Trident/.test(navigator.userAgent)) { // Filter Internet Explorer

		// Browser CSS class for specific rules
		if (navigator.userAgent.includes('Firefox')) {
			document.body.classList.add('firefox');
		}

		await useFeatureFlagService().getAsync('userfingerprint');
		await useFeatureFlagService().getAsync('onboarding');

		watch(router.currentRoute, (to, from) => {
			useRouterHistoryService().addRouterHistory(to);
		});


		const app = createApp(App);

		setArmadoApp(app);
		initSentry(app, router);

		app.use(ArmadoVuePlugin)
			.use(router)
			.use(Orion, {
				lang: 'fr',
				router,
			} as Orion.Config)
			.provide('appVersion', version)
			.component('shared-add-button', SharedAddButton)
			.component('shared-annotation', SharedAnnotation)
			.component('shared-app-aside-item', SharedAppAsideItem)
			.component('shared-aside-navigation', SharedAsideNavigation)
			.component('shared-company-flat-select', SharedCompanyFlatSelect)
			.component('shared-details-document-history', SharedDetailsDocumentHistory)
			.component('shared-details-list-item', SharedDetailsListItem)
			.component('shared-digital-safe-arbo-label', SharedDigitalSafeArboLabel)
			.component('shared-document-sticker', SharedDocumentSticker)
			.component('shared-flat-select', SharedFlatSelect)
			.component('shared-foldable', SharedFoldable)
			.component('shared-google-auto-complete', SharedGoogleAutoComplete)
			.component('shared-order-group-select', SharedOrderGroupSelect)
			.component('shared-list-document-actions', SharedListDocumentActions)
			.component('shared-list-document-item-actions', SharedListDocumentItemActions)
			.component('shared-list-quick-filter', SharedListQuickFilter)
			.component('shared-logo-company', SharedLogoCompany)
			.component('shared-magnetic-blob', SharedMagneticBlob)
			.component('shared-mission-place-select', SharedMissionPlaceSelect)
			.component('shared-paged-list-actions', SharedPagedListActions)
			.component('shared-paged-list-order-header', SharedPagedListOrderHeader)
			.component('shared-picto-card', SharedPictoCard)
			.component('shared-progress-bar', SharedProgressBar)
			.component('shared-role-flat-select', SharedRoleFlatSelect)
			.component('shared-role-select', SharedRoleSelect)
			.component('shared-select-agency-qualifications', SharedSelectAgencyQualifications)
			.component('shared-select-color', SharedSelectColor)
			.component('shared-select-company', SharedSelectCompany)
			.component('shared-select-picto-card', SharedSelectPictoCard)
			.component('shared-select-user-company', SharedSelectUserCompany)
			.component('shared-setting-card', SharedSettingCard)
			.component('shared-state-icon', SharedStateIcon)
			.component('shared-tag-dots-expandable', SharedTagDotsExpandable)
			.component('shared-tag-flat-select', SharedTagFlatSelect)
			.component('shared-tag-quick-filter', SharedTagQuickFilter)
			.component('shared-tag-select', SharedTagSelect)
			.component('shared-toggle', SharedToggle)
			.component('shared-stepper', SharedStepper)
			.mount('#app');

		setThemeMode('light');

		if (import.meta.env.VITE_NODE_ENV === 'development') {
			logBusEvents();
			useBusService().logBusEvents();
		}
	}

})();
