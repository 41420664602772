import { reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import AuthApi from '@/api/AuthApi';
import { useNotif } from '@orion.ui/orion';

type Props = SetupProps<typeof ModalConfirmPasswordSetupService.props>

export default class ModalConfirmPasswordSetupService extends BaseSetupService<Props> {
	static readonly props = {
		resolve: {
			type: Function,
			default: undefined,
		},
	};

	private readonly state = reactive({ password: undefined as Undef<string> });
	private _modal?: OrionModal;

	get password () {return this.state.password;}
	set password (val) {this.state.password = val;}

	constructor (props: Props, modal?: OrionModal) {
		super(props);
		if (modal)
			this._modal = modal;
	}

	cancel () {
		if (this.props.resolve)
			this.props.resolve(false);
		this._modal?.close();
	}

	checkPasswordLength = () => !!this.password?.trim()?.length;

	async validateAsync () {
		if (!this.checkPasswordLength()) {
			return;
		}

		this._modal?._loader()?.show(`Vérification du mot de passe...`);
		const { data } = await AuthApi.confirmPassword({
			userId: this.self.id,
			password: this.password,
		});
		if (data) {
			this._modal?.close();
			if (this.props.resolve) {
				this.props.resolve(data);
			}
		} else {
			this._modal?._loader()?.hide();
			useNotif.warning(`Oops`, `Le mot de passe est incorrect`);
		}
	}
}
