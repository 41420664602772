import { } from 'vue';
import { Bus } from '@orion.ui/orion';

class NavLoaderService {
	static show (message?: string) {
		Bus.emit('navigation-loader:show', message);
	}

	static hide () {
		Bus.emit('navigation-loader:hide');
	}
}

export default function useNavLoaderService () {
	return NavLoaderService;
}
