import { } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof OnboardingTempWorkerNoMembershipModalSetupService.props>

export default class OnboardingTempWorkerNoMembershipModalSetupService extends BaseSetupService<Props> {
	static readonly props = {};

	constructor (props?: Props) {
		super(props);
	}
}
