import { PropType, reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import BaseDocumentEntity from '@/entity/BaseDocumentEntity';
import { Action } from './SharedListDocumentItemActionsSetupService';

type Props = SetupProps<typeof SharedDocumentStickerSetupService.props>
type Emits = { (e: 'select'): void }

export default class SharedDocumentStickerSetupService extends BaseSetupService<Props> {
	static readonly props = {
		selected: Boolean,
		opened: Boolean,
		hideCheckbox: Boolean,
		entity: {
			type: Object as PropType<BaseDocumentEntity<Armado.ArmadoDocument>>,
			required: true as const,
		},
		title: {
			type: String,
			default: undefined,
		},
		actions: {
			type: Array as PropType<Action[]>,
			default: undefined,
		},
	};

	private emits: Emits;

	readonly _el = ref<OrionSticker>();
	private readonly state = reactive({
		tagSelectOpen: false,
		touchTimer: 0,
		isScolling: false,
	});

	get tagSelectOpen () { return this.state.tagSelectOpen; }
	set tagSelectOpen (val) { this.state.tagSelectOpen = val; }

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	protected onMounted () {
		super.onMounted();
		if (this.props.opened) {
			document.getElementsByClassName('is-opened')[0].scrollIntoView({ block: 'center' });
		}
	}

	handleTouchStart () {
		if (this.state.touchTimer === 0) {
			this.state.touchTimer = setTimeout(() => {
				if (!this.state.isScolling) {
					this.emits('select');
					this.handleTouchEnd();
					if (this.isTouch && typeof window.navigator.vibrate !== 'undefined') {
						navigator.vibrate(200);
					}
				}
			}, 400);
		}
	}

	handleTouchEnd () {
		clearTimeout(this.state.touchTimer);
		this.state.touchTimer = 0;
		this.state.isScolling = false;
	}

	handleScroll () {
		this.state.isScolling = true;
	}
}
