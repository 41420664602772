import ApiClient from '@/api/ApiClient';

const client = new ApiClient('taglink');

class TagLinkApi {

	static add (addTagLinkModel: IAddTagLinkModel) {
		return client.post<ITagLinkModel[]>(``, addTagLinkModel);
	}

	static delete (deleteTagLinkModel: IDeleteTagLinkModel) {
		return client.post<void>('delete', deleteTagLinkModel);
	}

	static countByType (tagId: number) {
		return client.get<ICountByTagLinkTypeModel[]>(`count/${tagId}`);
	}

}

export default TagLinkApi;
