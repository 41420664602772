<template>
	<div
		class="label-details"
		:class="{ 'label-details--children': label.level !== undefined && label.level > 0 }">
		<div class="row row--gutter-sm row--middle">
			<div class="col-sm-4">
				<div :style="`padding-left: ${setup.paddingLeft}`">
					<o-input
						v-model="label.caption"
						label="Nom"
						@input="setup.callDebouncedLabelUpdateAsync(label, true)"/>
				</div>
			</div>
			<div class="col-xs-5 col-sm-3">
				<o-select
					v-model="setup.labelColor"
					:options="setup.digitalSafeTempWorkerService.colorOptions"
					label="Couleur"
					@input="setup.callDebouncedLabelUpdateAsync(label)">
					<template #value="{ item }">
						<o-label
							class="new-label-color"
							:color="item"/>
					</template>
					<template #option="{ item }">
						<o-label
							class="new-label-color"
							:color="item"/>
					</template>
				</o-select>
			</div>
			<div class="col-xs-7 col-sm-4">
				<o-select
					v-if="setup.digitalSafeTempWorkerService.userLabels.length"
					v-model="label.parentId"
					label="Imbriquer sous"
					:options="setup.userLabelsWithLevels.filter(x => x.id !== label.id && x.level !== undefined && x.level <= 1 && x.parentId !== label.id)"
					track-key="id"
					value-key="id"
					display-key="caption"
					clearable
					@input="setup.callDebouncedLabelUpdateAsync(label)">
					<template #value="{ item }">
						<div
							v-if="item"
							class="option-digital-safe-label">
							<o-label
								class="option-digital-safe-label__color"
								:color="item.color"/>
							{{ item.caption }}
						</div>
					</template>
					<template #option="{ item }">
						<div class="option-digital-safe-label">
							<o-label
								class="option-digital-safe-label__color"
								:color="item.color"/>
							{{ item.caption }}
						</div>
					</template>
				</o-select>
			</div>
			<div class="col-sm-1">
				<o-icon
					v-tooltip="`Supprimer ce label`"
					class="label-details__delete"
					font-icon="icon-interface-delete-bin-2"
					ripple="danger"
					@click="setup.deleteLabelAsync(label.id)"/>
			</div>
		</div>

		<div class="label-details__children">
			<digital-safe-temp-worker-document-label-edition
				v-for="child in setup.children"
				:key="child.id"
				:label="{ ...child, level: label.level ? label.level + 1 : undefined }"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import DigitalSafeTempWorkerDocumentLabelEditionSetupService
	from '@/setup/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentLabelEditionSetupService';
import { inject } from 'vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(DigitalSafeTempWorkerDocumentLabelEditionSetupService.props);
const setup = new DigitalSafeTempWorkerDocumentLabelEditionSetupService(props, _aside);
</script>
