import ApiClient from '@/api/ApiClient';

const client = new ApiClient('organization');

class OrganizationApi {

	static list () {
		return client.get<IOrganizationSimpleModel[]>('');
	}

	static listCreator () {
		return client.get<[IOrganizationCreatorModel]>('creator');
	}

	static add (addOrganizationClientModel: IAddOrganizationClientModel) {
		return client.post<void>(``, addOrganizationClientModel);
	}

	static selectById (id: number) {
		return client.get<IOrganizationDetailsForClientModel>(`${id}`);
	}

	static grid (organizationClientPagedListParameters: IOrganizationClientPagedListParameters) {
		return client.post<IPagedList<IOrganizationListItemForClientModel>>(`grid`, organizationClientPagedListParameters);
	}

	static addCompany (addCompanyToOrganizationModel: IAddCompanyToOrganizationModel) {
		return client.post<void>(`company/add`, addCompanyToOrganizationModel);
	}

	static removeCompany (removeCompanyToOrganizationModel: IRemoveCompanyToOrganizationModel) {
		return client.post<void>(`company/remove`, removeCompanyToOrganizationModel);
	}

	static gridAvailableCompanies (companyAvailableForOrganizationPagedListParameters: ICompanyAvailableForOrganizationPagedListParameters) {
		return client.post<IPagedList<ICompanyItemAvailableForOrganizationModel>>('company/grid/available', companyAvailableForOrganizationPagedListParameters);
	}

	static checkOrganizationNameAndEmail (checkOrganizationNameModel: ICheckOrganizationNameModel) {
		return client.post<void>('checkNameAndEmail', checkOrganizationNameModel);
	}

	static dashboardStatistic () {
		return client.get<IOrganizationStatisticsModel>('dashboard/statistic');
	}

	static overviewMarkers (organizationId: number) {
		return client.get<IOrganizationOverviewMarkersModel>(`overviewMarkers`, { params: { organizationId } });
	}

	static search (searchOrganizationModel: ISearchOrganizationModel) {
		return client.post<ISearchResultOrganizationModel>('search', searchOrganizationModel);
	}

	static update (id: number, updateOrganizationModel: IUpdateOrganizationModel) {
		return client.put<void>(id.toString(), updateOrganizationModel);
	}

	static transferCreator (id: number, transferCreatorOrganizationModel: ITransferCreatorOrganizationModel) {
		return client.put<void>(`${id}/transferCreator`, transferCreatorOrganizationModel);
	}
}

export default OrganizationApi;
