import ApiClient from '@/api/ApiClient';

const client = new ApiClient('refreshtoken');

class RefreshTokenApi {
	static logout () {
		return client.post<void>('logout');
	}

	static refreshToken () {
		return client.get<ITokenModel>('refreshtoken');
	}
}

export default RefreshTokenApi;
