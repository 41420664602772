<template>
	<div class="permission-list">
		<div class="flex jc-sb mb-md">
			<o-toggle
				v-model="setup.showDescriptions"
				:label="setup.descriptionLabel"/>
			<o-toggle
				v-if="!hideActiveRightsToggle"
				v-model="setup.onlyActiveRights"
				reverse
				:label="setup.rightsLabel"/>
			<o-toggle
				v-if="(!roleId && !hideGlobalToggle)"
				v-model="setup.toggleAllRights"
				reverse
				label="Attribuer tous les droits"
				@input="setup.updateActiveRights($event)"/>
		</div>

		<o-input
			v-model="setup.permissionSearch"
			suffix-font-icon="icon-interface-search"
			label="Rechercher parmi les permissions..."/>

		<div
			v-for="permissionSection in setup.permissionsList"
			:key="permissionSection.title"
			class="mt-lg">
			<h4
				class="permission-list__title mb-sm"
				:class="{ 'permission-list__title--inactive': !setup.permissionSectionIsActive(permissionSection) && !togglable }">
				{{ permissionSection.title }}
			</h4>

			<div class="flex fd-c g-sm">
				<permission-item
					v-for="permission in permissionSection.children"
					:key="permission.action"
					:permission="permission"
					:togglable="togglable"
					:show-description="setup.showDescriptions"
					:roles-permissions="setup.permissionsForItem(permission.action)"
					:role-id="roleId"
					:mark="setup.permissionSearch"
					@update:permission="setup.handlePermissionDependenciesAsync($event)"/>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import PermissionListSetupService from '@/setup/permission/PermissionListSetupService';
import PermissionItem from './PermissionItem.vue';
type Emits = { (e: 'input', val: IRolePermissionModel): void }
const emits = defineEmits<Emits>();
const props = defineProps(PermissionListSetupService.props);
const setup = new PermissionListSetupService(props, emits);
</script>

<style lang="less" scoped>
.permission-list {
	&__title {
		opacity: 1;
		transition: opacity .3s;

		&--inactive {
			opacity: 0.2;
		}
	}
}
</style>
