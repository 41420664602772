import ApiClient from '@/api/ApiClient';

const client = new ApiClient('membership');

class MembershipApi {

	static add (addMembershipModel: IAddMembershipModel) {
		return client.post<IMembershipModel[]>('', addMembershipModel);
	}

	static delete (id: number, organizationId: number) {
		return client.delete<void>(id.toString(), { params: { organizationId } });
	}

	static hasCompanyInAnotherOrganization () {
		return client.get<boolean>('hasCompanyInAnotherOrganization');
	}

	static countInUserOrganization () {
		return client.get<number>('countInUserOrganization');
	}

	static listWithOrganization () {
		return client.get<IMembershipWithOrganizationModel[]>('organization');
	}

	static deleteForCompanyInAnotherOrganization (ids: number[]) {
		return client.delete<void>(`companyInAnotherOrganization${`?ids=${ids.join('&ids=')}`}`);
	}

	static addForCandidate (companyId: number) {
		return client.post(`addforcandidate`, { companyId });
	}
}

export default MembershipApi;
