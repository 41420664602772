import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedListQuickFilterSetupService.props>

export default class SharedListQuickFilterSetupService extends BaseSetupService<Props> {
	static readonly props = {
		displaySearch: Boolean,
		hideTagFilter: Boolean,
		filterService: {
			type: Object as PropType<PagedListFilterServiceList>,
			required: true as const,
		},
	};

	get filter () { return this.props.filterService.filter as PagedListFilter; }
	get quickFilters () { return this.props.filterService.quickFilters as ListQuickFilter<PagedListFilter>[]; }


	getQuickFilterColor (quickFilter: ListQuickFilter<PagedListFilter>) {
		return quickFilter?.active() ? 'info' : 'default' as Orion.Color;
	}

	applyQuickFilter (quickFilter: ListQuickFilter<PagedListFilter>) {
		const filterKey = quickFilter?.filterKey as keyof typeof this.filter;
		if (!filterKey) return;

		if (quickFilter?.active()) {
			if (filterKey !== 'page' && (filterKey as string) !== 'research') {
				const filterValue = quickFilter?.filterValue;
				if (typeof filterValue === 'function') {
					(this.filter[filterKey] as PagedListFilter[keyof PagedListFilter]) = filterValue();
				} else {
					this.props.filterService.setOriginalFilterKeyValue(filterKey as any);
				}
			}
		} else if (quickFilter) {
			const filterValue = quickFilter?.filterValue;
			if (typeof filterValue === 'function') {
				(this.filter[filterKey] as PagedListFilter[keyof PagedListFilter]) = filterValue();
			} else {
				(this.filter[filterKey] as PagedListFilter[keyof PagedListFilter]) = filterValue;
			}
		}

		this.props.filterService.applyWithRouterAsync(true);
	}
}
