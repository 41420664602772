import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { isNil } from 'lodash-es';
import { useNotif } from '@orion.ui/orion';
import { AxiosResponse } from 'axios';

type Props = SetupProps<typeof SharedAnnotationSetupService.props>

export default class SharedAnnotationSetupService extends BaseSetupService<Props> {
	static readonly props = {
		value: {
			type: String,
			default: null,
		},
		api: {
			type: Object as PropType<{ saveNote (id: number, note: string): Promise<AxiosResponse<void>> }>,
			default: null,
		},
		id: {
			type: Number,
			default: null,
		},
	};

	private _modal?: OrionModal;
	private readonly state = reactive({ annotation: '' });

	get annotation () { return this.state.annotation; }
	set annotation (val: string) { this.state.annotation = val; }

	constructor (props: Props, _modal?: OrionModal) {
		super(props);
		this._modal = _modal;
	}

	protected onMounted () {
		this.state.annotation = this.props.value;
	}

	async saveAnnotationAsync () {
		if (isNil(this.annotation)) return;

		try {
			if (this.self.readableUserType) {
				await this.props.api.saveNote(this.props.id, this.annotation);
				this._modal?.trigger('updated', this.annotation);
				useNotif.success(`Annotation`, `Votre annotation a bien été enregistrée.`);
			}
		} catch {
			useNotif.danger(`Annotation`, `Votre annotation n'a pas pu être enregistrée.`);
		}
	}
}
