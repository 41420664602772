<template>
	<div
		class="shared-app-aside-item"
		:class="{ 'shared-app-aside-item--stats': stats }">
		<h5
			v-if="title"
			class="shared-app-aside-item__title">
			{{ title }}
			<o-icon
				v-if="icon"
				:font-icon="icon"
				:ripple="callback ? 'info' : undefined"
				@click="callback ? callback() : undefined"/>
		</h5>

		<div
			class="shared-app-aside-item__content"
			:class="{ 'shared-app-aside-item__content--stats': stats }">
			<slot/>

			<o-label
				v-if="progress !== undefined && progress !== null"
				class="shared-app-aside-item__progress"
				:color="progressColor"
				outline>
				<o-icon :icon="setup.progressIcon"/>
				{{ progress }}
			</o-label>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedAppAsideItemSetupService from '@/setup/shared/SharedAppAsideItemSetupService';
const props = defineProps(SharedAppAsideItemSetupService.props);
const setup = new SharedAppAsideItemSetupService(props);
</script>

<style lang="less" scoped>
.shared-app-aside-item {
  padding: 1rem 0;
  border-bottom: 0.06rem solid var(--grey-light);

  &__title {
    color: var(--grey-dark);
    font-weight: 400;
    position: relative;

    > .orion-icon {
      right: 0.5rem;
      position: absolute
    }
  }

  &__content {
    color: var(--grey-darker);
    font-weight: 600;

    &--stats {
      font-size: 2rem;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .router-link-active & {
      color: var(--info);
    }
  }

  &__progress {
    height: 1.5rem;
    display: inline-flex;
    align-items: center;

    > :deep(.orion-label__content) {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }
  }
}
</style>
