<template>
	<o-select
		v-if="setup.companies.length > 0"
		v-model="setup.vModel"
		:options="setup.companies"
		track-key="id"
		display-key="name"
		value-key="id"
		v-bind="{
			label: setup.label,
			clearable,
			searchable: setup.searchable,
		}"
		@select="$emit('select', $event)">
		<template #option="{ item }">
			<div>
				<div>
					{{ `${item.name} (${item.contactDetailCity})` }}
				</div>
				<o-label
					v-if="item.agencyCompanyName && !hideAgencyName"
					outline
					style="margin-top:5px">
					{{ item.agencyCompanyName }}
				</o-label>
				<o-label
					v-if="checkOrderPlugin && !item.canCreateOrder"
					style="margin-top:5px"
					color="warning"
					outline>
					Veuillez vous rapprocher de cette agence pour lui adresser une commande
				</o-label>
			</div>
		</template>
	</o-select>
	<slot
		v-else
		name="empty"/>
</template>

<script setup lang="ts">
import SharedSelectCompanySetupService from '@/setup/shared/SharedSelectCompanySetupService';
type Emits = {
	(e: 'update:modelValue' | 'input', id: Undef<number | number[]>): void,
	(e: 'select', companyModel: ICompanyModel | [ICompanyModel]): void,
	(e: 'loaded'): void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedSelectCompanySetupService.props);
const setup = new SharedSelectCompanySetupService(props, emits);
defineExpose(setup.publicInstance);
</script>
