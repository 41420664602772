import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { cloneDeep, isArray, isNil } from 'lodash-es';
import { Picto } from './SharedPictoCardSetupService';

type Props = SetupProps<typeof SharedSelectPictoCardSetupService.props>

type Emits = {
	(e: 'input' | 'update:modelValue', value: Picto[] | Picto): void,
	(e: 'add' | 'remove', value: any): void
}

export default class SharedSelectPictoCardSetupService extends BaseSetupService<Props> {
	static readonly props = {
		required: Boolean,
		options: {
			type: Array as PropType<Picto[]>,
			default: null,
		},
		modelValue: {
			type: [Array, Object] as PropType<Picto[] | Picto>,
			default: null,
		},
		size: {
			type: Number,
			default: undefined,
		},
	};

	private emits: Emits;

	private readonly state = reactive({
		selectedItems: [] as Picto[],
		displayValidationState: undefined as Undef<boolean>,
		toggleSelectAll: false,
	});

	get isMultiple () { return isArray(this.props.modelValue);}
	get displayValidationState () { return this.state.displayValidationState;}
	set displayValidationState (val) { this.state.displayValidationState = val;}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	get vModel () { return this.props.modelValue;}
	set vModel (val) {
		this.emits('update:modelValue', val);
	}

	onMounted () {
		if (isArray(this.props.modelValue)) {
			this.state.selectedItems = this.props.modelValue ?? [];
		} else {
			this.state.selectedItems = this.props.modelValue?.icon ? [this.props.modelValue] : [];
		}
	}

	isValid () {
		return this.state.selectedItems.length > 0;
	}

	isSelected (e: Picto) {
		//return !!this.state.selectedItems?.find(x => x.id === e.id);
		return isArray(this.vModel)
			? !!this.vModel.find(x => x.id === e.id)
			: this.vModel === e;

	}

	private itemMatchOption (option: Picto, item: Picto) {
		return !isNil(item)
			? item.id === option.id
			: false;
	}

	optionIsSelected (option: Picto) {
		let isSelect = false;

		if (this.isMultiple) {
			if (isArray(this.vModel)) {
				const index = this.vModel.findIndex(item => this.itemMatchOption(option, item));
				isSelect = index > -1;
			}
		} else {
			isSelect = this.itemMatchOption(option, this.vModel as Picto);
		}

		return isSelect;
	}

	private emitValue (valueToEmit: any) {
		this.vModel = valueToEmit;
	}

	selectItem (value: Picto) {

		if (this.isMultiple) {
			if (this.optionIsSelected(value)) {
				this.emits('remove', value);
				let valueToEmit = cloneDeep(this.vModel as Picto[]);
				let index;

				if (!valueToEmit?.length) {
					valueToEmit = [];
				} else {
					index = valueToEmit.findIndex((x: Picto) => x.id === value.id);

					if (index > -1) {
						valueToEmit.splice(index, 1);
					}
				}
				this.emitValue(valueToEmit);
			} else {
				this.emits('add', value);
				let valueToEmit = cloneDeep(this.vModel);

				if (!isArray(valueToEmit)) valueToEmit = [];
				valueToEmit.push(value);
				this.emitValue(valueToEmit);
			}
		} else {
			this.emits('input', value);
			this.emitValue(value);
		}
	}
}
