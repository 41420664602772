import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { TAG_LINK_TYPE } from '@/typings/enums/TAG_LINK_TYPE';
import useDocumentService from '@/services/DocumentService';
import { DOCUMENT_TYPE } from '@/typings/enums/DOCUMENT_TYPE';
import useBusService from '@/services/BusService';

type Props = SetupProps<typeof SharedListDocumentActionsSetupService.props>

type TimeTicket = {
  timeTicketId: number
  timeTicket: { tagLinks: ITagLinkModel[] }
}
export default class SharedListDocumentActionsSetupService extends BaseSetupService<Props> {
	static readonly props = {
		tagOpened: Boolean,
		selected: {
			type: Array as PropType<{ id: number, tagLinks: ITagLinkModel[] }[]>,
			default: () => [],
		},
		tag: {
			type: Function,
			default: () => {},
		},
		documentType: {
			type: Number as PropType<DOCUMENT_TYPE>,
			required: true as const,
		},
		linkType: {
			type: Number as unknown as PropType<Undef<TAG_LINK_TYPE>>,
			default: undefined,
		},
	};

	private readonly state = reactive({ tags: [] });
	documentService = useDocumentService(this.self.userType, this.props.documentType);

	get inTimeTicketContext () {
		return this.props.linkType === TAG_LINK_TYPE.TimeTicket;
	}

	constructor (props?: Props) {
		super(props);
	}

	mappedValue () {
		if (this.inTimeTicketContext) {
			return new Map((this.props.selected as unknown as TimeTicket[]).map(x => [
				x.timeTicketId,
				x.timeTicket.tagLinks.map(x => ({
					id: x.id,
					tagId: x.tagId,
				})),
			]));
		} else {
			return new Map(this.props.selected.map(x => [
				x.id,
				x.tagLinks.map(x => ({
					id: x.id,
					tagId: x.tagId,
				})),
			]));
		}
	}

	handleTagAdd (payload: ITagLinkModel[]) {
		if (this.inTimeTicketContext) {
			payload.forEach((tag) => {
				(this.props.selected as unknown as TimeTicket[])
					.find(x => x.timeTicketId === tag.linkId)?.timeTicket.tagLinks.push(tag);
				useBusService().emit(`timeticket-list-item-${this.props.linkType}-${tag.linkId}:add-tag`, tag);
			});
		} else {
			payload.forEach((tag) => {
				this.props.selected.find(x => x.id === tag.linkId)?.tagLinks.push(tag);
				useBusService().emit(`document-list-item-${this.props.linkType}-${tag.linkId}:add-tag`, tag);
			});
		}
	}

	handleTagDelete ([tagLinkIds]: [number[]]) {
		if (this.inTimeTicketContext) {
			(this.props.selected as unknown as TimeTicket[]).forEach((x) => {
				x.timeTicket.tagLinks.deleteWhere(tl => tagLinkIds.includes(tl.id));
				useBusService().emit(`timeticket-list-item-${this.props.linkType}-${x.timeTicketId}:delete-tag`, tagLinkIds);
			});
		} else {
			this.props.selected.forEach((x) => {
				x.tagLinks.deleteWhere(tl => tagLinkIds.includes(tl.id));
				useBusService().emit(`document-list-item-${this.props.linkType}-${x.id}:delete-tag`, tagLinkIds);
			});
		}
	}
}
