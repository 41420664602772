<template>
	<div class="row row--gutter shared-details-list-item">
		<div :class="colClass[0]">
			<div class="shared-details-list-item__legend">
				<slot name="legend">
					<div v-html="legend"/>
				</slot>
			</div>
		</div>

		<div :class="colClass[1]">
			<div
				v-if="editable"
				:ref="setup._editionZone"
				class="shared-details-list-item__value"
				:class="{ 'text--right': valueAlignRight }"
				:style="setup.valueAdditionalStyle()"
				v-bind="{ contenteditable: setup.contenteditable.value }"
				@keydown.enter.prevent="setup._editionZone?.value?.blur()">
				{{ value }}
			</div>
			<div
				v-else
				class="shared-details-list-item__value"
				:class="{ 'text--right': valueAlignRight }"
				:style="setup.valueAdditionalStyle()">
				<slot>
					<div
						:class="{ 'text--ellipsis': valueEllipsis }"
						v-html="value"/>
				</slot>
			</div>

			<o-icon
				v-if="editable"
				v-tooltip="!setup.contenteditable.value ? 'Modifier' : 'Valider'"
				class="shared-details-list-item__edit-icon"
				:font-icon="setup.contenteditable.value ? 'icon-interface-validation-check' : 'icon-interface-edit-pencil'"
				:ripple="setup.contenteditable.value ? 'success' : 'info'"
				@click="setup.handleEditableDebounced()"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedDetailsListItemSetupService from '@/setup/shared/SharedDetailsListItemSetupService';
const props = defineProps(SharedDetailsListItemSetupService.props);
const emits = defineEmits<{(e: 'input', val: Undef<string>): void;}>();
const setup = new SharedDetailsListItemSetupService(props, emits);
</script>

<style lang="less">
.shared-details-list-item {
	position: relative;
	padding: 0.5rem;

  &__legend, &__value {
    min-height: 1.25rem;
    line-height: 1.25rem;
  }

	&__legend {
		color: var(--grey-dark);
	}

	&__value {
		overflow-wrap: break-word;
		white-space: pre-line;
		color: var(--grey-darker);
    font-weight: 600;
	}

	&__edit-icon {
		position: absolute !important;
		top: 0.65rem;
		right: 1rem;
	}

	&:hover {
		background: var(--grey-lighter);

		.details-list-item {
			&__legend {
				color: var(--info);
			}
		}
	}
}
</style>
