<template>
	<o-card
		v-bind="{ title }"
		class="card--settings">
		<template #header>
			<o-icon v-bind="{ fontIcon }"/>
		</template>

		<slot/>
	</o-card>
</template>

<script setup lang="ts">
import SharedSettingCardSetupService from '@/setup/shared/SharedSettingCardSetupService';
const props = defineProps(SharedSettingCardSetupService.props);
const setup = new SharedSettingCardSetupService(props);
</script>

<style scoped lang="less">
:deep(.orion-card) {
  &__header {
    .card--settings &{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: var(--grey-lighter);
        padding: 25px;
    }

    .orion-icon {
      font-size: 40px;
      margin-top: 25px;
      color: var(--grey);
    }
  }
}
</style>
