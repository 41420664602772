import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/tempworkerprovisiondocument');

class TempWorkerProvisionDocumentApiAgency {
	static grid (filters: ITempWorkerProvisionDocumentPagedListForAgencyParameters) {
		return client.post<IPagedList<ITempWorkerProvisionDocumentItemForAgencyModel>>('grid', filters);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tempworkerprovisiondocument${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/tempworkerprovisiondocument${idsQuery}`, '_blank');
	}
}

export default TempWorkerProvisionDocumentApiAgency;
