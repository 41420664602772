
<template>
	<div class="shared-stepper">
		<div
			v-for="(step, index) in steps"
			:key="index"
			class="shared-stepper__step"
			:class="[
				{ 'shared-stepper__step--clickable': clickable && setup.stepIsPassed(index) },
				{ 'shared-stepper__step--isActive': setup.stepIsActive(step.name) },
				{ 'shared-stepper__step--isPassed': setup.stepIsPassed(index) },
				{ 'shared-stepper__step--isMax': setup.stepIsMax(index) },
				{ 'shared-stepper__step--isLast': index === (steps.length - 1 ) },
				{ 'shared-stepper__step--isFirst': index === 0 },
				{ 'shared-stepper__step--withChevron': withChevron },
			]"
			@click="setup.goToStep(step, index)">
			<div
				class="shared-stepper__step-index"
				:class="`shared-stepper__step-index-${step.color}`">
				<o-icon
					v-if="(setup.stepIsPassed(index) && !setup.stepIsMax(index) && !setup.stepIsActive(step.name))
						|| (withChevron && setup.stepIsPassed(index) && !setup.stepIsActive(step.name))
						|| (withChevron && setup.stepIsMax(index) && index === (steps.length - 1 ))
						|| step.icon"
					:icon="step.icon ?? 'check'"/>
				<span v-else>{{ index + 1 }}</span>
			</div>
			<div
				v-if="!setup.responsive.onPhone || setup.stepIsActive(step.name)"
				class="shared-stepper__step-label"
				:style="`color: ${step.labelColor}`">
				<slot
					:name="`label(${step.name})`"
					v-bind="{ step }">
					{{ step.label }}
				</slot>
			</div>
			<o-icon
				v-if="withChevron && index !== (steps.length - 1)"
				class="shared-stepper__step-next-icon"
				font-icon="icon-interface-arrows-button-right"/>
		</div>
	</div>
</template>

<script setup lang="ts">
import SharedStepperSetupService from '@/setup/shared/SharedStepperSetupService';
const props = defineProps(SharedStepperSetupService.props);
type Emits = {
	(e: 'update:modelValue', val:string): void,
};
const emits = defineEmits<Emits>();
const setup = new SharedStepperSetupService(props, emits);
</script>

<style lang="less" scoped>
@import '@/styles/variables';
@import '@/styles/mixins.less';
.shared-stepper {
	display: flex;
	justify-content: center;

	&__step {
		position: relative;
		display: flex;
		flex-direction: column;
		flex: 0 0 140px;
		gap: 0.5rem;
		align-items: center;

		&--clickable {
			cursor: pointer;
		}

		&--withChevron {
			flex-direction: row;
			flex: unset;
		}

		&-next-icon {
			color: black;
			margin-right: 1rem;
			font-weight: 600;
		}

		&:not(.shared-stepper__step--isLast)&:not(.shared-stepper__step--withChevron)::after {
			content: "";
			position: absolute;
			transform: translate(50%, 0.9rem);
			height: 2px;
			background-color: var(--grey-light);
			z-index: 0;
			width: 100%;
		}

		&--isPassed {
			transition: opacity .2s;
			&:not(.shared-stepper__step--isActive) {
				&:hover {
					.shared-stepper__step-index {
						box-shadow: unset;
					}
				}
			}
			&:not(.shared-stepper__step--withChevron)::after {
				background-color: var(--info) !important;
			}
			opacity : 1;


			.shared-stepper__step-index {
				border: 2px solid var(--info);
				background: white;
				color: var(--info);
				font-size: 1rem;
			}

			.shared-stepper__step-label {
				color: var(--grey-darker);
			}
		}

		&--isActive {
			opacity: 1;
			.shared-stepper__step-index {
				background: var(--info);
				border: unset;
				color: white;
			}
			.shared-stepper__step-label {
				color: var(--info);
			}
			&::after {
				background-color: var(--info) !important;
			}
		}
		&--isMax {
			&:not(.shared-stepper__step--withChevron)::after {
				background: linear-gradient(to right, var(--info) 50%, var(--grey-light) 50%, var(--grey-light)) !important;
				animation: 1s linear linearLoading infinite;
			}
		}

		&--isFirst {
			&:not(.shared-stepper__step--withChevron)::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 2px;
				background: linear-gradient(to right, transparent, var(--info));
				// background-color: var(--grey);
				transform: translate(-50%, 0.9rem);
				z-index: 0;
			}
		}

		&--isLast {
			&:not(.shared-stepper__step--isPassed)&:not(.shared-stepper__step--withChevron)::after {
				position: absolute;
				content: '';
				width: 100%;
				height: 2px;
				background: linear-gradient(to right, var(--grey-light), transparent) !important;
				transform: translate(50%, 0.9rem);
				z-index: 0;
			}
			&:not(.shared-stepper__step--withChevron)::after {
				position: absolute;
				content: '';
				width: 100%;
				height: 2px;
				background: linear-gradient(to right, var(--info), transparent) !important;
				// background-color: var(--grey);
				transform: translate(50%, 0.9rem);
				z-index: 0;
			}
		}

		&--withChevron {
			&.shared-stepper__step--isPassed&:not(.shared-stepper__step--isActive),
			&.shared-stepper__step--isActive.shared-stepper__step--isLast {
				.shared-stepper__step-label {
					color: var(--brand);
				}
			}

			&.shared-stepper__step--isActive{
				.shared-stepper__step-label  {
					color: black;
				}
			}
		}
	}

	&__step-index {
		position: relative;
		font-size: 0.85rem;
		display: flex;
		font-weight: 600;
		color: var(--grey-darker);
		border-radius: 50%;
		width: 2rem;
		height: 2rem;
		align-items: center;
		justify-content: center;
		transition: all .3s;
		background: var(--grey-light);
		z-index: 1;

		.shared-stepper__step--withChevron & {
			width: 1.5rem;
			min-width: 1.5rem;
			height: 1.5rem;
		}
		.shared-stepper__step--withChevron:not(.shared-stepper__step--isActive) & {
			background: unset;
			border: 0.02rem solid var(--grey-light);
			color: var(--grey);
		}

		.shared-stepper__step--withChevron.shared-stepper__step--isPassed &,
		.shared-stepper__step--withChevron.shared-stepper__step--isPassed.shared-stepper__step--isLast & {
			background: var(--brand);
			border: unset;

			.orion-icon {
				color: white
			}
		}

		.shared-stepper__step--withChevron.shared-stepper__step--isActive &{
			background: var(--info);
		}

		&-warning {
			background: var(--warning) !important;
			border: unset !important;
			color:white !important;
		}

		&-info {
			background: var(--info) !important;
			border: unset !important;
			color:white !important;
		}
	}

	&__step-label {
		font-size: 0.9rem;
		color: var(--grey);
		font-weight: 600;
		transition: all .3s;
	}

	&__step-next-icon {
		font-size: 0.75rem;
		margin-left: 1rem;
	}
}
@keyframes linearLoading {
	from {
		background: linear-gradient(to right, var(--info) 50%, var(--grey-light) 50%, var(--grey-light));
	}
	to {
		background: linear-gradient(to right, var(--info) 100%, var(--grey-light) 100%, var(--grey-light));
	}
}
</style>
