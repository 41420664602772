import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/order');

class OrderApiClient {
	static add (addOrderClientModel: IAddOrderModel) {
		return client.post<IOrderClientOverPageModel>('', addOrderClientModel);
	}

	static grid (orderClientPagedListParameters: IOrderClientPagedListParameters) {
		return client.post<IPagedList<IOrderClientListItemModel>>('grid', orderClientPagedListParameters);
	}

	static gridCount () {
		return client.get<IOrderClientGridCountModel>(`grid/count`);
	}

	static getDetailed (id: number) {
		return client.get<IOrderClientOverPageModel>(id.toString());
	}

	static countByMissionPlaceId (missionPlaceId: number) {
		return client.get<number>(`countByMissionPlace/${missionPlaceId}`);
	}
}

export default OrderApiClient;
