<template>
	<h3 class="shared-list-quick-filter-title">
		Filtres rapides
	</h3>

	<div class="shared-list-quick-filter">
		<!-- <shared-tag-quick-filter
			v-if="setup.self.isClient() || setup.self.isAgency() && !hideTagFilter"
			v-model="setup.filter.tagIds"
			@update:model-value="filterService.applyWithRouterAsync(true)"/> -->

		<o-chips
			v-for="(quickFilter, index) in setup.quickFilters"
			:key="index"
			:outline="setup.getQuickFilterColor(quickFilter) === 'info'"
			:color="setup.getQuickFilterColor(quickFilter)"
			@click="setup.applyQuickFilter(quickFilter)">
			{{ quickFilter?.label }}

			<template
				v-if="quickFilter?.dual"
				#dual>
				{{ quickFilter?.dual }}
			</template>
		</o-chips>
	</div>
</template>

<script setup lang="ts">
import SharedListQuickFilterSetupService from '@/setup/shared/SharedListQuickFilterSetupService';
const props = defineProps(SharedListQuickFilterSetupService.props);
const setup = new SharedListQuickFilterSetupService(props);
</script>

<style lang="less" scoped>
@import '@/styles/variables.less';

.shared-list-quick-filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.75rem;

  :deep(.orion-chips) {
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  @media @phone {
    justify-content: flex-start;
  }

  &-title {
    display: none;
    font-weight: var(--font-weight-section-title);
    margin: var(--space-md) 0 var(--space-sm);

    #left-aside > &:first-child {
      margin-top: 0.5rem;
    }
  }

  #left-aside > & {
    justify-content: flex-start;

    &-title {
      display: block;
    }
  }
}
</style>
