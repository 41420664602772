import { nextTick, PropType, reactive, ref } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import BasePagedListSetupService, { ItemGeneric } from '@/setup/BasePagedListSetupService';
import { highlightDomElement } from '@orion.ui/orion';

type Props = SetupProps<typeof SharedPagedListActionsSetupService.props>
type Actions = 'search' | 'checkAll' | 'filter'
export default class SharedPagedListActionsSetupService extends BaseSetupService<Props> {
	static readonly props = {
		use: {
			type: Array as PropType<Actions[]>,
			default: () => ['search', 'checkAll', 'filter'] as Actions[],
		},
		listService: {
			type: Object as PropType<BasePagedListSetupService<{}, ItemGeneric>>,
			required: true as const,
		},
	};

	readonly _listSearchField = ref<OrionInput>();
	readonly _el = ref<HTMLElement>();

	private readonly state = reactive({ displayListSearchField: false });

	get displayListSearchField () { return this.state.displayListSearchField; }
	get listService () { return this.props.listService; }
	get filterService () { return this.listService.filterService; }

	get listFilterResearch () {
		return (this.filterService.filter as PagedListFilter).research;
	}

	set listFilterResearch (val: string | undefined) {
		if (!this.filterService) return;
		(this.filterService.filter as PagedListFilter).research = val;
	}


	constructor (props: Props) {
		super(props);

		if ((this.filterService.filter as PagedListFilter).research?.length) {
			this.state.displayListSearchField = true;
		}

		this.registerBusEvent('breakpoint', (val: ('onLayoutFull'
      | 'onLayoutOneAside'
      | 'onLayoutNoAside'
      | 'onLayoutNavFull'
      | 'onLayoutNavCollapsed'
      | 'onLayoutNavBottom')[]) => {
			setTimeout(() => {
				if (val.includes('onLayoutNoAside')) {
					highlightDomElement(this._el.value);
				}
			}, 500);
		});
	}


	async refreshListAsync (resetPagination = false) {
		await this.filterService?.applyWithRouterAsync(resetPagination);
		this.hideListSearchField();
	}

	showListSearchField () {
		this.state.displayListSearchField = true;
		nextTick(() => this._listSearchField.value?.focus());
	}

	hideListSearchField () {
		if (this.listFilterResearch?.length) return;
		this.state.displayListSearchField = false;
	}
}
