import { PropType, reactive } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import OrganizationRoleListItemEntity from '@/entity/OrganizationRoleListItemEntity';
import useOrganizationRoleService from '@/services/OrganizationRoleService';

type Props = SetupProps<typeof SharedRoleSelectSetupService.props>
type Emits = {(e: 'update:modelValue', val: number | number[] | undefined): void}

export default class SharedRoleSelectSetupService extends BaseSetupService<Props> {
	static readonly props = {
		multiple: Boolean,
		modelValue: {
			type: [Number, Array] as PropType<number | number[] | undefined>,
			default: undefined,
		},
	};

	private emits : Emits;
	private readonly state = reactive({
		dataLoaded: false,
		options: [] as OrganizationRoleListItemEntity[],
	});

	get dataLoaded () { return this.state.dataLoaded; }
	get options () { return this.state.options.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? '')); }

	get selectedRolesIds () { return this.props.modelValue; }
	set selectedRolesIds (val) { this.emits('update:modelValue', val); }


	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}

	async onBeforeMountAsync () {
		this.state.options = OrganizationRoleListItemEntity.map(await useOrganizationRoleService().listAsync());
		this.state.dataLoaded = true;
	}


	getRoleById (id:number) {
		return this.state.options.findByKey(id);
	}
}
