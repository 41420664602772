// import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';
import { PropType } from 'vue';

type Props = SetupProps<typeof SharedToggleSetupService.props>
type Emits = {(e: 'update:modelValue', val: SharedToggleItemModel): void}
export type SharedToggleItemModel = {
	label: string,
	counter?: number,
}


export default class SharedToggleSetupService extends BaseSetupService<Props> {
	static readonly props = {
		modelValue: {
			type: Object as PropType<SharedToggleItemModel>,
			default: () => {
				return {
					label: 'Non défini',
					counter: undefined,
				};
			},
		},
		options: {
			type: Array as PropType<SharedToggleItemModel[]>,
			default: () => ['premier', 'second'],
		},
	};

	private emits: Emits;


	get options () { return this.props.options; }

	get modelValue () { return this.props.modelValue; }
	set modelValue (val) {
		this.emits('update:modelValue', val ?? {
			label: 'non renseigné',
			counter: undefined,
		});
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;
	}
}
