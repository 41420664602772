import { PropType, reactive, watch } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedStepperSetupService.props>
type Emits = {
	(e: 'update:modelValue', val:string): void,
};

export default class SharedStepperSetupService extends BaseSetupService<Props> {
	static readonly props = {
		clickable: Boolean,
		withChevron: Boolean,
		steps: {
			type: Array as PropType<Armado.SharedStepperStep[]>,
			default: () => [{
				label: 'Première étape',
				name: 'first',
			}],
		},
		modelValue: {
			type: String,
			default: () => 'first',
		},
	};

	private emits: Emits;

	private readonly state = reactive({ maxPossibleStepIndex: 0 });

	get activeStep () { return this.props.modelValue; }
	set activeStep (val) {
		this.emits('update:modelValue', val);
	}

	get indexOfActiveStep () {return this.props.steps.findIndex(x => x.name === this.activeStep);}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;

		watch(() => this.indexOfActiveStep, (val: number) => {
			if (val > this.state.maxPossibleStepIndex) {
				this.state.maxPossibleStepIndex = val;
			}
		});
	}

	protected onMounted (): void {
		this.state.maxPossibleStepIndex = this.indexOfActiveStep;
	}

	stepIsActive (value: string) {
		return this.activeStep === value;
	}

	stepIsPassed (value: number) {
		return value <= this.state.maxPossibleStepIndex;
	}

	stepIsMax (value: number) {
		return this.state.maxPossibleStepIndex === value;
	}

	goToStep (step: Armado.SharedStepperStep, index: number) {
		if (!this.props.clickable) return;
		if (index > this.state.maxPossibleStepIndex) return;
		this.activeStep = step.name;
	}
}
