<template>
	<o-section
		v-if="api"
		title="Annotations">
		<o-textarea
			v-model="setup.annotation"
			autofocus
			label="Saisissez une annotation"
			@submit="setup.saveAnnotationAsync()"/>

		<o-section
			class="mt-md"
			align="stretch">
			<o-button
				color="danger"
				outline
				@click="_modal?.close()">
				Fermer
			</o-button>
			<o-button
				color="info"
				prefix-icon="check"
				@click="setup.saveAnnotationAsync()">
				Enregistrer
			</o-button>
		</o-section>
	</o-section>
</template>

<script setup lang="ts">
import SharedAnnotationSetupService from '@/setup/shared/SharedAnnotationSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(SharedAnnotationSetupService.props);
const setup = new SharedAnnotationSetupService(props, _modal);
</script>
