import { ARMADO_URL } from '@/utils/CONST';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

class SignalrApp {

	readonly connection: HubConnection;

	constructor () {
		this.connection = new HubConnectionBuilder()
			.configureLogging(LogLevel.Critical)
			.withAutomaticReconnect()
			.withUrl(`${ARMADO_URL}/app/hub`)
			.build();
	}

	// Start the hub connection. If it fails, try to refresh the token and try again once.
	// eslint-disable-next-line @orion.ui/orion/async-suffix
	async tryStart () {
		try {
			if (this.connection?.state === HubConnectionState.Connected
				|| this.connection?.state === HubConnectionState.Connecting) {
				return;
			}

			this.connection?.start();
		} catch (error) {
			return;
		}
	}

	stop () {
		return this.connection?.stop();
	}

	on (methodName: string, listener: (...args: any[]) => void) {
		this.connection?.off(methodName, listener);
		return this.connection?.on(methodName, listener);
	}

	off (methodName: string, listener: (...args: any[]) => void) {
		return this.connection?.off(methodName, listener);
	}

	// eslint-disable-next-line @orion.ui/orion/async-suffix
	async invoke (methodName: string, ...args: any[]) {
		try {
			return await this.connection?.invoke(methodName, ...args);
		} catch (e) {
			return;
		}
	}

}

const SignalrAppInstance = new SignalrApp();

export default SignalrAppInstance;
