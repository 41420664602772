import { PropType } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedAppAsideItemSetupService.props>

export default class SharedAppAsideItemSetupService extends BaseSetupService<Props> {
	static readonly props = {
		stats: Boolean,
		title: {
			type: String,
			default: undefined,
		},
		progress: {
			type: [Number, String],
			default: undefined,
		},
		progressColor: {
			type: String as PropType<Orion.Color>,
			default: undefined,
		},
		icon: {
			type: String,
			default: undefined,
		},
		callback: {
			type: Function,
			default: undefined,
		},
	};

	get progressIcon (): Orion.Icon {
		if (this.props.progressColor === 'success') {
			return 'trending_up';
		} else if (this.props.progressColor === 'danger') {
			return 'trending_down';
		} else {
			return 'arrow_right_md';
		}
	}

	constructor (props?: Props) {
		super(props);
	}
}
