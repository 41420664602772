<template>
	<o-section
		title="Infos personnelles"
		subtitle="Informations nécessaires à la signature">
		<div style="margin-top:35px">
			<o-input
				:ref="setup._FirstName"
				v-model.trim="setup.firstName"
				label="Prénom"
				required
				:validation="setup.validator.rule('userFirstName')"/>
			<o-input
				:ref="setup._LastName"
				v-model.trim="setup.lastName"
				label="Nom"
				required
				:validation="setup.validator.rule('userLastName')"/>
			<o-phone
				:ref="setup._PhoneNumber"
				v-model="setup.phone"
				label="Numéro de téléphone portable"
				mobile
				:validation="setup.validator.rule('phone')"/>
		</div>
	</o-section>
	<o-section align="right">
		<o-button
			outline
			color="danger"
			@click="setup.cancel()">
			Annuler
		</o-button>
		<o-button
			color="info"
			@click="setup.saveAsync()">
			Enregistrer
		</o-button>
	</o-section>
</template>

<script setup lang="ts">
import ModalTempWorkerInfosSetupService from '@/setup/modal/ModalTempWorkerInfosSetupService';
import { inject } from 'vue';
const _modal = inject<OrionModal>('_modal');
const props = defineProps(ModalTempWorkerInfosSetupService.props);
const setup = new ModalTempWorkerInfosSetupService(props, _modal);
</script>
