import ApiClient from '@/api/ApiClient';

const client = new ApiClient('client/company');

class CompanyApiClient {
	static getAgencyList (permissionAction?: number, checkOrderPlugin = false) {
		return client.get<ICompanyModel[]>('agencylist', {
			params: {
				permissionAction,
				checkOrderPlugin,
			},
		});
	}

	static getAgencyForOrganizationalChart (pagedListParameters: ICompanyFilterForOrganizationPagedListParameters) {
		return client.get<ICompanyForOrganizationalChartModel[]>('organizationalChart/agencyList', { params: pagedListParameters });
	}

	static getCompaniesByUserId (userId: number, pagedListParameters: ICompanyForDetailOrganizationClientUserPagedListParameters) {
		return client.post<IPagedList<ICompanyForDetailOrganizationClientUserModel>>(userId.toString(), pagedListParameters);
	}

	static getCompaniesForOrganization (pagedListParameters: ICompanyForClientOrganizationPagedListParameters) {
		return client.get<IPagedList<ICompanyForClientOrganizationItemModel>>('organization', { params: pagedListParameters });
	}
}

export default CompanyApiClient;
