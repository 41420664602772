<template>
	<div>
		<o-section title="Organisez vos labels">
			<digital-safe-temp-worker-document-label-edition
				v-for="label in setup.userLabelsWithLevels.filter(x => x.level === 0)"
				:key="label.id"
				v-bind="{ label }"/>
		</o-section>

		<transition
			mode="out-in"
			name="fade">
			<div
				v-if="setup.showNewLabel || !setup.digitalSafeTempWorkerService.userLabels.length"
				class="new-label-creation">
				<hr v-if="setup.digitalSafeTempWorkerService.userLabels.length">
				<o-section
					class="label-details"
					:subtitle="setup.digitalSafeTempWorkerService.userLabels.length ? undefined : `Créez votre premier label`">
					<div class="row row--gutter-sm label-details__new">
						<div class="col-sm-5">
							<o-input
								:ref="setup._newLabelCaption"
								v-model="setup.newLabel.caption"
								label="Nom"/>
						</div>
						<div class="col-xs-5 col-sm-3">
							<o-select
								v-model="setup.newLabel.color"
								:options="setup.digitalSafeTempWorkerService.colorOptions"
								label="Couleur">
								<template #value="{ item }">
									<o-label
										class="new-label-color"
										:color="item"/>
								</template>
								<template #option="{ item }">
									<o-label
										class="new-label-color"
										:color="item"/>
								</template>
							</o-select>
						</div>
						<div class="col-xs-7 col-sm-4">
							<o-select
								v-if="setup.digitalSafeTempWorkerService.userLabels.length"
								v-model="setup.newLabel.parentId"
								label="Imbriquer sous"
								:options="setup.userLabelsWithLevels.filter(x => x.level !== undefined && x.level <= 1)"
								track-key="id"
								value-key="id"
								display-key="caption">
								<template #value="{ item }">
									<div
										v-if="item"
										class="option-digital-safe-label">
										<o-label
											class="option-digital-safe-label__color"
											:color="item.color"/>
										{{ item.caption }}
									</div>
								</template>
								<template #option="{ item }">
									<div class="option-digital-safe-label">
										<o-label
											class="option-digital-safe-label__color"
											:color="item.color"/>
										{{ item.caption }}
									</div>
								</template>
							</o-select>
						</div>
					</div>
				</o-section>
				<o-section
					size="xs"
					align="left">
					<o-button
						v-if="setup.digitalSafeTempWorkerService.userLabels.length"
						outline
						color="danger"
						@click="setup.showNewLabel = false">
						Annuler
					</o-button>
					<o-button
						outline
						color="success"
						@click="setup.createLabelAsync()">
						Enregistrer le nouveau label
					</o-button>
				</o-section>
			</div>
			<o-section
				v-else
				size="sm"
				align="left">
				<o-button
					outline
					color="info"
					@click="setup.showNewLabelCreationForm()">
					Créer un nouveau label
				</o-button>
			</o-section>
		</transition>
	</div>
</template>

<script setup lang="ts">
import DigitalSafeTempWorkerDocumentLabelsManagerSetupService
	from '@/setup/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentLabelsManagerSetupService';
import { inject } from 'vue';
import DigitalSafeTempWorkerDocumentLabelEdition from '@/components/digital-safe-temp-worker-document/DigitalSafeTempWorkerDocumentLabelEdition.vue';
const _aside = inject<OrionAside>('_aside');
const props = defineProps(DigitalSafeTempWorkerDocumentLabelsManagerSetupService.props);
const setup = new DigitalSafeTempWorkerDocumentLabelsManagerSetupService(props, _aside);
</script>


<style lang="less">
@import '@/styles/variables';
@import '@/styles/mixins';

.label-details {
  position: relative;

	&__new {
		align-items: center;
	}

  @media @phone {
    text-align: center;

    &:not(&--children) {
      padding: 0 0.6rem;
      border: 0.06rem dotted var(--grey-light);

      + .label-details {
        margin-top: 1.9rem;
      }
    }

    &--children {
      padding-left: 0.6rem;
    }

    &__delete {
      .circle(1.9rem);
      .flex-align();
      position: absolute;
      top: 0.9rem;
      left: -1.6rem;
      background: white;
    }
  }

  .orion-select {
    margin: 0;

    .orion-input__input {
      min-width: 0;
    }
  }
}

.new-label-creation hr {
  margin: 2.8rem 0;
}

.new-label-color {
  height: 0.9rem;
  width: 2.8rem;
}
</style>
