import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/clientprovisiondocument');

class ClientProvisionDocumentApiAgency {
	static grid (filters: IClientProvisionDocumentPagedListForAgencyParameters) {
		return client.post<IPagedList<IClientProvisionDocumentItemForAgencyModel>>('grid', filters);
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/clientprovisiondocument${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/clientprovisiondocument${idsQuery}`, '_blank');
	}
}

export default ClientProvisionDocumentApiAgency;
