import router from '@/router/index.router';
import RefreshTokenApi from '@/api/RefreshTokenApi';

import * as Sentry from '@sentry/browser';
import SignalrAppInstance from '@/signalr/App.signalr';

class SessionManager {
	private isRefreshing = false;
	private promise?: Promise<any>;

	constructor () {
		const userType = localStorage.getItem('userType');
		const requiredAuth = router.currentRoute.value?.matched?.findIndex(x => x.meta.requiresAuth) !== -1;

		if (!userType && router.currentRoute.value?.name !== 'Login' && requiredAuth) router.push({ name: 'Login' });
	}

	async getTokenAsync () {
		try {
			if (this.isRefreshing) {
				return this.promise;
			} else {
				this.isRefreshing = true;
				this.promise = RefreshTokenApi.refreshToken();

				await this.promise?.finally(() => {
					this.promise = undefined;
					this.isRefreshing = false;
				});
			}
			return true;
		} catch (error) {
			return false;
		}
	}

	async logoutAsync () {
		Sentry.configureScope((scope) => {
			scope.clear();
		});
		localStorage.removeItem('userType');
		SignalrAppInstance.stop();
		await RefreshTokenApi.logout();
	}
}

const sessionManager = new SessionManager();

export default sessionManager;
