import { PropType, reactive, watchEffect } from 'vue';
import BaseSetupService from '@/setup/BaseSetupService';

type Props = SetupProps<typeof SharedFoldableSetupService.props>
export type Emits = { (e: 'update:open', val: boolean): void }

export default class SharedFoldableSetupService extends BaseSetupService<Props> {
	static readonly props = {
		open: Boolean,
		icon: {
			type: String as PropType<Orion.Icon>,
			default: undefined,
		},
		fontIcon: {
			type: String,
			default: undefined,
		},
		color: {
			type: String as PropType<Orion.Color | Orion.ColorAlt>,
			default: 'grey',
		},
	};

	private emits: Emits;
	private readonly state = reactive({ isOpen: false });

	get rgbColor () { return `var(--rgb-${this.props.color})`; }

	get isOpen () { return this.state.isOpen; }
	set isOpen (val) {
		this.state.isOpen = val;
		this.emits('update:open', val);
	}

	constructor (props: Props, emits: Emits) {
		super(props);
		this.emits = emits;

		watchEffect(() => this.state.isOpen = this.props.open);
	}
}
