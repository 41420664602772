import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';

const client = new ApiClient('agency/certificate');

class CertificateApiAgency {
	static grid (filter: ICertificatePagedListForAgencyParameters) {
		return client.post<IPagedList<ICertificateItemForAgencyModel>>('grid', filter);
	}

	static getDetailed (id: number) {
		return client.get<ICertificateOverPageForAgencyModel>(id.toString());
	}

	static saveNote (id: number, note: string) {
		return client.put<void>(`${id}/note`, note?.toPost());
	}

	static getNotViewed () {
		return client.get('notviewed');
	}

	static download (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/certificate${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		client.windowOpen(`${ARMADO_URL}/app/agency/certificate${idsQuery}`, '_blank');
	}
}

export default CertificateApiAgency;
