<template>
	<v-dropdown
		:ref="setup._popover"
		:placement="placement"
		class="shared-role-select"
		:class="{ 'shared-role-select--disabled' : disabled }"
		@apply-show="setup.handlePopoverShowAsync()"
		@apply-hide="setup.handlePopoverHide()">
		<slot>
			<div class="shared-role-select flex ai-c g-xs">
				<o-chips
					outline
					:color="setup.swapRoleColor(modelValue?.first()?.organizationRole?.colorName)"
					class="shared-role-select__role">
					{{ modelValue?.first()?.organizationRole?.name ?? label }}
					<template
						v-if="modelValue?.length && modelValue.length > 1"
						#dual>
						+{{ modelValue?.length - 1 }}
					</template>
				</o-chips>
				<o-icon
					v-if="!disabled"
					class="shared-role-select__role-arrow"
					:font-icon="opened ? 'icon-interface-arrows-button-up' : 'icon-interface-arrows-button-down'"/>
			</div>
		</slot>

		<template #popper>
			<shared-flat-select
				v-model:search="setup.search"
				:show-search="setup.roles.length > 10"
				:loading="setup.loading"
				:options="setup.rolesListOptionsForFlatSelect"
				:selected-function="setup.roleIsSelected.bind(setup)"
				:new-label="hideNew ? undefined : 'Nouveau rôle'"
				:popover-opened="setup.popoverOpened"
				@add="setup.linkAsync($event)"
				@delete="setup.unlinkAsync($event)"
				@new="setup.createRoleAsync()">
				<template #default="{ item: role }">
					<div class="role-option swap-colors">
						<div
							class="role-color"
							:class="`background--${role.colorName}`"/>
						<span v-html="role.name.mark(setup.search)"/>
					</div>
				</template>

				<template #empty>
					<o-alert
						color="info"
						center>
						Vous n'avez pas encore de roles.
					</o-alert>
				</template>
			</shared-flat-select>
		</template>
	</v-dropdown>
</template>

<script setup lang="ts">
import type OrganizationRoleListItemEntity from '@/entity/OrganizationRoleListItemEntity';
import SharedRoleFlatSelectSetupService from '@/setup/shared/SharedRoleFlatSelectSetupService';
type Emits = {
	(e: 'update:opened', value: boolean): void,
	(e: 'update:modelValue', val: IRoleUserModel[]): void,
	(e: 'add' | 'delete', val:
    | OrganizationRoleListItemEntity[]
    | OrganizationRoleListItemEntity
    | [number[], OrganizationRoleListItemEntity]
  ): void,
}
const emits = defineEmits<Emits>();
const props = defineProps(SharedRoleFlatSelectSetupService.props);
const setup = new SharedRoleFlatSelectSetupService(props, emits);
</script>

<style scoped lang="less">
.shared-role-select {
  cursor: pointer;
}

.role-color {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
}

.role-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
</style>
