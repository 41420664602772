import TalentPoolApiAgency from '@/api/TalentPoolApiAgency';

class TalentPoolService {
	async listAsync (...args: Parameters<typeof TalentPoolApiAgency.list>) {
		const { data } = await TalentPoolApiAgency.list(...args);
		return data;
	}

	async gridAsync (...args: Parameters<typeof TalentPoolApiAgency.grid>) {
		const { data } = await TalentPoolApiAgency.grid(...args);
		return data;
	}

	async addTalentAsync (...args: Parameters<typeof TalentPoolApiAgency.addTalent>) {
		const { data } = await TalentPoolApiAgency.addTalent(...args);
		return data;
	}

	async deleteTalentAsync (...args: Parameters<typeof TalentPoolApiAgency.deleteTalent>) {
		const { data } = await TalentPoolApiAgency.deleteTalent(...args);
		return data;
	}
}

const talentPoolServiceSingleton = new TalentPoolService();

export default function useTalentPoolService () {
	return talentPoolServiceSingleton;
}
