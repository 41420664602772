import ApiClient from '@/api/ApiClient';
import { ARMADO_URL } from '@/utils/CONST';
import { AxiosResponse } from 'axios';

const client = new ApiClient('agency/timeticketweek');

class TimeTicketWeekApiAgency {
	static listByYear (year: number, filter: ITimeTicketWeekPagedListParameters) {
		return client.get<ITimeTicketWeekYearForClientModel[]>(`year/${year}`, { params: { ...filter } });
	}

	static async listByYearPagedListAsync (year: number, filter: ITimeTicketWeekPagedListParameters) {
		const res = await this.listByYear(year, filter);
		(res.data as unknown as IPagedList<ITimeTicketWeekYearForClientModel>).list = res.data;
		(res.data as unknown as IPagedList<ITimeTicketWeekYearForClientModel>).count = res.data.length;

		return res as unknown as AxiosResponse<IPagedList<Armado.TimeTicketWeekYear>>;
	}

	static grid (pagedListParameters: ITimeTicketWeekPagedListParameters) {
		return client.post<IPagedList<ITimeTicketWeekDetailForClientModel>>('grid', pagedListParameters);
	}

	static download (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/agency/timeticketweek${idsQuery}&isDownload=true`, '_blank');
	}

	static view (idsQuery: string) {
		window.open(`${ARMADO_URL}/app/agency/timeticketweek${idsQuery}`, '_blank');
	}

	static discussionLink (id: number) {
		return client.get<ITimeTicketWeekForDiscussionLinkModel>(`${id}/link`);
	}
}

export default TimeTicketWeekApiAgency;
